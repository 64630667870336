import { User } from "@supabase/supabase-js";
import { createContext, PropsWithChildren, useContext } from "react";
import { Navigate } from "react-router-dom";
import { isFulfilled } from "src/utils/Async";
import { useUserAuthState } from "src/utils/common";
import SuspenseLoader from "../SuspenseLoader";
import { useSession } from "src/contexts/SupabaseContext";

export const UserContext = createContext<User>(undefined);
export const useUser = () => useContext(UserContext);

export default function Authenticated(props: PropsWithChildren<{}>) {
  const session = useSession();
  const userState = useUserAuthState();

  return (
    <>
      {userState === "logged-in-cyclist" ||
      userState === "logged-in-coach" ||
      userState === "logged-in-admin" ? (
        <>
          {isFulfilled(session) && (
            <UserContext.Provider value={session.result.data.session.user}>
              {props.children}
            </UserContext.Provider>
          )}
        </>
      ) : userState === "anon" ? (
        <Navigate to="/account/login" />
      ) : userState === "missing-info" ? (
        <Navigate to="/account/profile-setup" />
      ) : (
        <SuspenseLoader />
      )}
    </>
  );
}
