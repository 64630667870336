import { Autocomplete, Box, Paper, TextField } from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useCallback, useEffect, useState } from "react";
import { useUser } from "src/components/Authenticated";
import PreloadComponent from "src/utils/PreloadComponent";
import { useTitleCoach } from "../Dashboard/CoachDashboardLayout";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import CoachCalendar from "../customComponents/CoachCalendar";

export type AthletesProps = {
  users: {
    athlete: {
      name: string;
      user_id: string;
    };
  }[];
};
export default function CoachDashboardPreload() {
  const user = useUser();
  return (
    <>
      <PreloadComponent<{
        users: {
          athlete: {
            name: string;
            user_id: string;
          };
        }[];
      }>
        promises={{
          users: async (supabase) =>
            supabase
              .from("coach_athletes")
              .select("athlete(name,user_id)")
              .eq("coach_id", user.id)
              .then((res) => res.data),
        }}
        component={(props) => (
          <>
            <CoachCalendarView users={props.users} />
          </>
        )}
      />
    </>
  );
}

function CustomPaperAuto({ children }) {
  return (
    <Paper
      sx={{
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option[aria-selected='true']": {
            bgcolor: "rgba(221, 79, 74, 0.1)",
            "&.Mui-focused": {
              bgcolor: "rgba(221, 79, 74, 0.5)",
            },
          },
        },
        "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
          bgcolor: "rgba(221, 79, 74, 0.5)",
        },
        background: "#201B20",
        border: "1px solid #000000",
        borderRadius: "6px",
        boxShadow: "0px 4px 24px -1px rgba(0, 0, 0, 0.25)",
      }}
    >
      {children}
    </Paper>
  );
}

function CoachCalendarView(props: AthletesProps) {
  const user = useUser();
  const [, setTitle] = useTitleCoach();
  const supabase = useSupabase();
  useEffect(() => {
    setTitle("Calendar");
  }, []);

  const [userId, setUserId] = useState("");
  const [sessions, setSessions] = useState([]);
  const [assignedWorkouts, setAssignedWorkouts] = useState([]);

  const assignedWorkoutsPromise = useCallback(
    () =>
      supabase
        .from("assigned_workout")
        .select("*,assigned_training_plan(*)")
        .order("date", { ascending: true })
        .eq("user_id", userId)
        .eq("assigned_training_plan.assigned_by", user.id)
        .then((res) => setAssignedWorkouts(res.data)),
    [supabase, userId],
  );

  const assignedWorkoutsCall = useAsyncState<SupabaseCall<typeof assignedWorkoutsPromise>>();

  useEffect(() => {
    if (isUnloaded(assignedWorkoutsCall) && userId !== "") {
      assignedWorkoutsCall.fire(async () => assignedWorkoutsPromise());
    } else setAssignedWorkouts([]);
  }, [userId]);

  useEffect(() => {
    if (isFulfilled(assignedWorkoutsCall)) assignedWorkoutsCall.unload();
  }, [assignedWorkoutsCall]);

  const sessionPromise = useCallback(
    () =>
      supabase
        .from("session")
        .select("date,recording_name,id,total_time,total_distance,tss")
        .order("date", { ascending: true })
        .eq("athlete_id", userId)
        .then((res) => setSessions(res.data)),
    [supabase, userId],
  );

  const sessionCall = useAsyncState<SupabaseCall<typeof sessionPromise>>();

  useEffect(() => {
    if (isUnloaded(sessionCall) && userId !== "") {
      sessionCall.fire(async () => sessionPromise());
    } else setSessions([]);
  }, [userId]);

  useEffect(() => {
    if (isFulfilled(sessionCall)) sessionCall.unload();
  }, [sessionCall]);

  return (
    <>
      <Box
        sx={{
          background: "#201B20",
          borderRadius: "10px",
          width: "97.5%",
          marginX: "1rem",
          marginY: "1rem",
          padding: "1em",
        }}
      >
        <Autocomplete
          PaperComponent={CustomPaperAuto}
          disablePortal
          popupIcon={<SearchTwoToneIcon />}
          clearIcon={<HighlightOffIcon style={{ opacity: 0.5 }} />}
          onChange={(event, newValue) => {
            setUserId(newValue !== null ? newValue["user_id"] : "");
          }}
          getOptionLabel={(option) => option["name"]}
          options={props.users.map((user) => user.athlete)}
          sx={{
            width: 300,
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#DD4F4A",
              },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#DD4F4A",
            },

            "& .MuiInputBase-input": {
              backgroundColor: "#3D343E",
              borderRadius: "6px",
            },
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search Athletes" />
          )}
        />
      </Box>
      <Box sx={{ margin: "1rem" }}>
        <CoachCalendar sessions={sessions} assignedWorkouts={assignedWorkouts}/>
      </Box>
    </>
  );
}