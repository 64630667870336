import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import TooltipZonesC from "../../../../assets/img/layout/coach/MyAthletes/TooltipZonesC.svg";

export type CoachOverallDuoCardProps = {
  value1: string | number;
  value2: string | number;
  label1?: string;
  label2?: string;
  icon1?: JSX.Element;
  icon2?: JSX.Element;
  tooltip?: boolean;
  tooltipDescription?: string;
};

export default function CoachOverallDuoCard({
  value1,
  value2,
  label1,
  label2,
  icon1,
  icon2,
  tooltip,
  tooltipDescription,
}: CoachOverallDuoCardProps) {
  return (
    <>
      <Card
        sx={{
          background: "#201B20",
          borderRadius: "10px",
          minHeight: { xs: "100px", xl: "150px" },
        }}
      >
        <CardContent
          sx={{
            paddingX: {
              xs: "8px",
              xl: "16px",
            },
          }}
        >
          <Box mt={"5px"}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h5"
                color={"#DD4F4A"}
                sx={{
                  fontSize: {
                    xs: ".875rem",
                    xl: "1.125rem",
                  },
                }}
              >
                {value1}
              </Typography>
              {icon1}
            </Box>
            <Box sx={{ display: "flex" }} gap={"2px"}>
              <Typography variant="body2" color={"#64605D"}>
                {label1}
              </Typography>
              {tooltip && (
                <Tooltip title={tooltipDescription} placement="right" arrow>
                  <img
                    src={TooltipZonesC}
                    alt="info_icon"
                    width="10"
                    height="11"
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box mt={"20px"}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h5"
                color={"#DD4F4A"}
                sx={{
                  fontSize: {
                    xs: ".875rem",
                    xl: "1.125rem",
                  },
                }}
              >
                {value2}
              </Typography>
              {icon2}
            </Box>
            <Typography variant="body2" color={"#64605D"}>
              {label2}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
