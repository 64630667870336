import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  InputAdornment,
  Tab,
  TextField,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import CoachPlanComponent from "./CoachPlanComponent";
import { useParams } from "react-router-dom";

type TabValue = "active" | "scheduled" | "completed";

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};

    .MuiOutlinedInput-root {
      width:15.5rem
    } 
  `,
);

export type CoachTrainingPlansProps = {
  tab?: TabValue;
};

export default function CoachTrainingPlans(props: CoachTrainingPlansProps) {
  const params = useParams<{ userId: string }>();
  const [value, setValue] = useState<TabValue>("active");
  const [search, setSearch] = useState("");
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  const handleChange = useCallback((_, newValue: TabValue) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    if (!!props?.tab) {
      setValue(props?.tab);
    }
  }, [props]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#201B20",
          width: "96%",
          padding: "2em",
          borderRadius: "10px",
        }}
      >
        <TabContext value={value}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                borderColor: "divider",
                display: "flex",
                paddingRight: "20px",
                justifyContent: "space-between",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="Tab list"
                sx={{
                  "& .MuiTab-root:not(.Mui-selected)": {
                    opacity: 0.5,
                  },
                  minHeight: "1.5rem",
                  "& .Mui-selected": {
                    color: "#DD4F4A !important",
                  },
                }}
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: "#DD4F4A",
                  },
                }}
              >
                <Tab
                  label="Active"
                  value="active"
                  sx={{
                    minHeight: 0,
                    padding: "10px",
                  }}
                />
                <Tab
                  label="Scheduled"
                  value="scheduled"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
                <Tab
                  label="Completed"
                  value="completed"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
              </TabList>
            </Box>
            <Box>
              <SearchInputWrapper
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon sx={{ opacity: "0.5" }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "15.5rem",
                  "& .MuiOutlinedInput-root": {
                    width: isMobileScreen ? "12rem" : "15.5rem",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#DD4F4A",
                    },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#DD4F4A",
                  },
                }}
                placeholder="Find Plan"
                onKeyDown={(e) => e.code === "Enter"}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
          </Box>
          <TabPanel
            value="active"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <CoachPlanComponent tab={"active"} athleteId={params.userId} />
          </TabPanel>
          <TabPanel
            value="scheduled"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <CoachPlanComponent tab={"scheduled"} athleteId={params.userId} />
          </TabPanel>
          <TabPanel
            value="completed"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <CoachPlanComponent tab={"completed"} athleteId={params.userId} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
