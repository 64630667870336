import {
  Box,
  LinearProgress,
  Typography,
  alpha,
  styled,
  linearProgressClasses,
  useMediaQuery,
} from "@mui/material";
import { timeFormatter } from "src/utils/common";
import { ZoneWithStats } from "../customPages/SessionOverviewViewPreload";

const LinearProgressCustom = styled(LinearProgress)(
  ({ theme }) => `
            height: 14px;
            border-radius: ${theme.general.borderRadiusLg};
    
            &.${linearProgressClasses.colorPrimary} {
                background-color: ${alpha(theme.colors.alpha.black[100], 0.07)};
                box-shadow: inset 0 1px 2px ${alpha(
                  theme.colors.alpha.black[100],
                  0.15,
                )};
            }
            
            & .${linearProgressClasses.bar} {
                border-radius: ${theme.general.borderRadiusLg};
                background-color: ${(props) => props.color};
            }
        `,
);

export type ZonesComponentProps = {
  zones: Array<ZoneWithStats>;
  title?: string;
  units: string;
};

export default function ZonesComponent(props: ZonesComponentProps) {
  const isMobileScreen = useMediaQuery("(max-width:480px)");

  return (
    <>
      {props.zones.map((zone) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingRight: isMobileScreen ? "1em" : "",
          }}
          key={zone.name}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: "#64605D", marginRight: "5px" }}
            >
              {!zone.max
                ? `> ${zone.min} ${props.units}`
                : !zone.min
                ? `< ${zone.max} ${props.units}`
                : `${zone.min} - ${zone.max} ${props.units}`}
            </Typography>
            <Typography variant="subtitle2" sx={{ color: "#64605D" }}>
              • {zone.name}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", marginBottom: { xl: "20px" } }}>
            <Typography
              key={zone.index}
              width={isMobileScreen ? "35%" : "20%"}
              sx={{ color: zone.color }}
            >
              Zone {zone.index}
            </Typography>
            <Box width={isMobileScreen ? "40%" : "60%"} alignSelf={"center"}>
              <LinearProgressCustom
                variant="determinate"
                value={
                  isNaN(zone.percentage) ? 0 : Math.round(zone.percentage * 100)
                }
                sx={{
                  "& .MuiLinearProgress-bar1Determinate": {
                    backgroundColor: zone.color,
                  },
                  height: "6px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: isMobileScreen ? "30%" : "25%",
                marginLeft: "10px",
              }}
            >
              <Typography variant="subtitle2" sx={{ marginRight: "5px" }}>
                {isNaN(zone.total_time)
                  ? "00:00:00"
                  : timeFormatter((zone.total_time / 1000).toString())}
              </Typography>
              <Typography variant="subtitle2">
                {isNaN(zone.percentage)
                  ? 0
                  : (zone.percentage * 100).toFixed(0)}
                %
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
}
