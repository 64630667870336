import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

//Date

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import "../../assets/scss/Calendar.scss";

import convert from "convert-units";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSession } from "src/contexts/SupabaseContext";
import { isFulfilled } from "src/utils/Async";
import { imperialToMeters } from "src/utils/common";
import * as yup from "yup";

export type CoachPersonalSetupInfoType = {
  name: string;
  gender: "male" | "female";
};

export type CoachPersonalSetupInfoProps = {
  onSubmit: (validatedPersonalSetupInfo: CoachPersonalSetupInfoType) => void;
  onBack: () => void;
  onChange: (currentPersonalSetupInfo: CoachPersonalSetupInfoType) => void;
  initialValues?: CoachPersonalSetupInfoType;
};

export default function CoachPersonalSetupInfo(props: CoachPersonalSetupInfoProps) {
  const [openDate, setOpenDate] = useState(false);
  const isMobileScreen = useMediaQuery("(max-width:450px)");

  const buttonRef = useRef(null);

  const sessionData = useSession();

  const formik = useFormik<CoachPersonalSetupInfoType>({
    initialValues: props.initialValues ?? {
      name: "",
      gender: null,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      gender: yup
        .string()
        .oneOf(["male", "female"], "Please select a valid gender")
        .required("Please select a valid gender"),
    }),
    onSubmit: (values) => {
      props.onSubmit(values);
    },
  });

  useEffect(() => {
    if (isFulfilled(sessionData)) {
      if (!!sessionData?.result?.data?.session?.user?.user_metadata?.name)
        formik.setFieldValue(
          "name",
          sessionData.result.data.session.user.user_metadata.name,
        );
    }
  }, [sessionData]);

  useEffect(() => {
    props.onChange(formik.values);
  }, [formik.values]);

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box marginTop={"40px"}>
          <Box>
            <Typography
              variant="h1"
              sx={{ mb: ".5rem", color: "#DD4F4A" }}
            >
              Profile Setup
            </Typography>
            <Typography
              variant="body1"
              fontSize={"13px"}
              lineHeight={"15px"}
              pl={".1875rem"}
            >
              Personal Information
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "1.5rem",
              mt: "1.5rem",
            }}
          >
            <FormControl>
              <TextField
                sx={{
                  color: "#F2F2F2",
                  "& .Mui-focused.MuiInputLabel-shrink": {
                    color: "#DD4F4A",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#DD4F4A !important",
                  },
                }}
                name="name"
                label="Full Name *"
                onBlur={formik.handleBlur}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="gender" sx={{"&.Mui-focused.MuiInputLabel-shrink":{color:"#DD4F4A"}}}>Gender *</InputLabel>
              <Select
                id="gender"
                name="gender"
                value={formik.values.gender ?? ""}
                onChange={formik.handleChange}
                label="Gender *"
                sx={{
                  "& .MuiSelect-icon": { marginRight: ".4375rem" },
                  color: "#F2F2F2",
                  "& .MuiSvgIcon-root": {
                    color: "#F2F2F2",
                  },
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#64605D",
                    },
                    "&:hover fieldset": {
                      borderColor: "#64605D",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#DD4F4A",
                    },
                  },
                }}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: "1.375rem",
          }}
        >
          <Button
            onClick={props.onBack}
            size="small"
            variant="outlined"
            sx={{ width: "9.75rem", lineHeight: "1rem", "&:hover": {color:"#DD4F4A", backgroundColor: "rgba(221, 79, 74, 0.1)", borderColor: "#DD4F4A"} }}
          >
            Back
          </Button>

          <Button
            disabled={
              !(formik.isValid && (formik.dirty || !!props.initialValues))
            }
            variant="coachVariant"
            size="small"
            type="submit"
            sx={{ width: "9.75rem", lineHeight: "1rem", ml: "1rem" }}
          >
            Next
          </Button>
        </Box>
      </form>
    </>
  );
}
