import { PostgrestFilterBuilder } from "@supabase/postgrest-js";
import { GenericSchema } from "@supabase/postgrest-js/dist/module/types";
import { Reducer, useEffect, useReducer, useState } from "react";
import { useSupabase } from "src/contexts/SupabaseContext";

type State<
  Schema extends GenericSchema,
  Row extends Record<string, unknown>,
  Result,
> = {
  filterBuilder: PostgrestFilterBuilder<Schema, Row, Result>;
  pageSize: number;
  from: number;
  to: number;
  fetch: boolean;
  noData?: true;
};

export type Action<
  Schema extends GenericSchema,
  Row extends Record<string, unknown>,
  Result,
> =
  | { kind: "FETCH" }
  | { kind: "NEXT_PAGE" }
  | { kind: "PREV_PAGE" }
  | { kind: "CHANGE_PAGE_SIZE"; pageSize: number }
  | {
      kind: "MODIFY_CALL";
      builder: (
        filterBuilder: PostgrestFilterBuilder<Schema, Row, Result>,
      ) => PostgrestFilterBuilder<Schema, Row, Result>;
      pageSize: number;
    }
  | {
      kind: "NO_DATA";
    };

export const useSupabaseReducer = <
  Schema extends GenericSchema,
  Row extends Record<string, unknown>,
  Result,
>(
  filterBuilder: PostgrestFilterBuilder<Schema, Row, Result>,
  pageSize: number,
) => {
  const [result, setResult] = useState<Result | "unloaded">("unloaded");
  const [count, setCount] = useState<number>();

  const reducer: Reducer<
    State<Schema, Row, Result>,
    Action<Schema, Row, Result>
  > = (state, action) => {
    switch (action.kind) {
      case "NEXT_PAGE":
        return {
          ...state,
          from: state.from + state.pageSize,
          to: state.to + state.pageSize,
          fetch: true,
        };
      case "PREV_PAGE":
        return {
          ...state,
          from: state.from - state.pageSize,
          to: state.to - state.pageSize,
          fetch: true,
        };
      case "CHANGE_PAGE_SIZE":
        return {
          ...state,
          from: 0,
          to: action.pageSize - 1,
          pageSize: action.pageSize,
          fetch: true,
        };
      case "FETCH":
        return {
          ...state,
          fetch: true,
          noData: null,
        };
      case "MODIFY_CALL":
        return {
          fetch: false,
          filterBuilder: action.builder(filterBuilder),
          from: 0,
          to: action.pageSize - 1,
          pageSize: action.pageSize,
          count: null,
        };
      case "NO_DATA":
        return {
          ...state,
          noData: true,
          fetch: false,
        };
    }
  };

  const [config, dispatch] = useReducer(reducer, {
    filterBuilder,
    pageSize,
    from: 0,
    to: pageSize - 1,
    fetch: false,
  });

  useEffect(() => {
    if (config.fetch && !config.noData) {
      config.filterBuilder.range(config.from, config.to).then((res) => {
        setCount(res.count);
        setResult(res.data);
      });
    }
  }, [config]);

  return [result, dispatch, count] as const;
};
