import { Grid, Typography, useMediaQuery } from "@mui/material";
import DistanceIcon from "../../../../assets/img/views/dashboard-overview/distance-icon.svg";
import MovingTime from "../../../../assets/img/views/dashboard-overview/movingtime-icon.svg";
import CaloriesIcon from "../../../../assets/img/views/dashboard-overview/calories-icon.svg";
import ElevationGainIcon from "../../../../assets/img/views/dashboard-overview/elevation-gain.svg";
import ElevationLossIcon from "../../../../assets/img/views/dashboard-overview/elevation-loss.svg";
import CoachOverallTable from "./CoachOverallTable";
import { useCallback, useMemo } from "react";
import CoachOverallGraph from "./CoachOverallGraph";
import CoachNotesComponent from "../CoachNotesComponent";
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import { useRideSession } from "./CoachSessionViewPreload";
import { useRideSession as RideSession } from "../../Sessions/CoachSessionViewsPreload";
import StravaLogo from "../../../../assets/img/views/settings/strava.svg";
import GarminLogo from "../../../../assets/img/views/settings/garminLogo.svg";
import DareforeLogo from "../../../../assets/img/views/dashboard-sessions/darefore-icon.svg";
import ManualLogo from "../../../../assets/img/views/settings/manualLogoFit.svg";
import IndoorIcon from "../../../../assets/img/layout/coach/indoor-active.svg";
import OutdoorActIcon from "../../../../assets/img/layout/coach/outdoor-active.svg";
import AeroScore from "../../../../assets/img/layout/coach/aeroScore-coach.svg";
import BodyAngle from "../../../../assets/img/layout/coach/bodyAngle-coach.svg";
import BalanceIcon from "../../../../assets/img/views/dashboard-overview/balance-icon.png";
import CoachOverviewDuoCard from "./CoachOverviewDuoCard";
import CoachOverviewBalanceCard from "./CoachOverviewBalanceCard";

export default function CoachOverviewView() {
  const units = useUnits();
  const session =
    location.pathname.startsWith("/coach/my_athletes") &&
    !location.pathname.startsWith("/coach/my_athletes/session")
      ? useRideSession()
      : RideSession();

  const timeFormatter = useCallback((valStr) => {
    const val = Number(valStr);
    const hours = Math.floor(Math.floor(val / 60) / 60);
    const minutes = Math.trunc((val % 3600) / 60);
    const seconds = Math.trunc(val % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  }, []);

  const providerIcon =
    session?.provider === "Darefore"
      ? DareforeLogo
      : session?.provider === "Strava"
      ? StravaLogo
      : session?.provider === "Garmin"
      ? GarminLogo
      : ManualLogo;

  const rideTypeIcon =
    session?.ride_types.name === "Indoor" ? IndoorIcon : OutdoorActIcon;

  const cardInfo = useMemo(
    () => [
      {
        value:
          units === "metric"
            ? `${session?.total_distance?.toFixed(2) ?? 0} km`
            : `${
                convert(session?.total_distance)
                  ?.from("km")
                  ?.to("mi")
                  ?.toFixed(2) ?? "0"
              } mi`,
        label: "Total Distance",
        icon: (
          <img
            src={DistanceIcon}
            alt="distance-icon"
            width={"26.56px"}
            height={"22px"}
          />
        ),
      },
      {
        value: timeFormatter(session?.total_time / 1000) ?? "-",
        label: "Moving Time",
        icon: (
          <img
            src={MovingTime}
            alt="moviing-icon"
            width={"30px"}
            height={"22px"}
          />
        ),
      },
    ],
    [session, units],
  );

  const cardBalanceInfo = useMemo(
    () => [
      {
        value1: `${
          Boolean(session.average_pedal_balance_left?.toFixed(0))
            ? session.average_pedal_balance_left?.toFixed(0)
            : "-"
        }%`,
        value2: `${
          Boolean(session.average_pedal_balance_left?.toFixed(0))
            ? (100 - session.average_pedal_balance_left)?.toFixed(0)
            : "-"
        }%`,
        label: "LR Balance",
        icon: <img src={BalanceIcon} alt="Balance-icon" width={"18px"} />,
      },
      {
        value:
          (session?.calories?.toFixed(0) ?? "-") ||
          (session?.calories === 0 ? "0" : session?.calories?.toFixed(0)),
        label: "Active Calories",
        icon: <img src={CaloriesIcon} alt="calories-icon" width={"18px"} />,
      },
    ],
    [session, units],
  );

  const cardDuoInfo = useMemo(
    () => [
      {
        value1: session?.athlete_bikes?.bike_name ?? "-",
        value2: `${session?.athlete_bikes?.bike_types?.name ?? "-"}`,
        label1: "Bike Name",
        label2: "Bike Type",
      },
      {
        value1:
          units === "metric"
            ? `${session.elevation_gain?.toFixed(0) ?? "-"} m `
            : `${
                convert(session.elevation_gain).from("m").to("ft").toFixed(0) ??
                "-"
              } ft`,
        value2:
          units === "metric"
            ? `${session.elevation_loss?.toFixed(0) ?? "-"} m `
            : `${
                convert(session.elevation_loss)
                  .from("m")
                  .to("ft")
                  ?.toFixed(0) ?? "-"
              } ft`,
        label1: "Elevation Gain",
        label2: "Elevation Loss",
        icon1: (
          <img
            src={ElevationGainIcon}
            alt="elevationgain-icon"
            width={"20px"}
          />
        ),
        icon2: (
          <img
            src={ElevationLossIcon}
            alt="elevationloss-icon"
            width={"20px"}
          />
        ),
      },
      {
        value1: session?.provider,
        value2: session?.ride_types?.name,
        label1: "Provider",
        label2: "Ride Type",
        icon1: <img src={providerIcon} alt="provider-icon" width={"25px"} />,
        icon2: <img src={rideTypeIcon} width={"27px"} alt="ride-type" />,
      },
      {
        value1: session?.aero_score?.toFixed(2) ?? "-",
        value2: `${
          session?.average_body_angle === null
            ? "-"
            : `${session?.average_body_angle?.toFixed(1)}°`
        }`,
        label1: "Aeroscore",
        label2: "Avg Body Angle",
        icon1: (
          <img src={AeroScore} alt="aero-icon" width={"30px"} height={"22px"} />
        ),
        icon2: (
          <img
            src={BodyAngle}
            width={"30px"}
            height={"22px"}
            alt="average-body-angle"
          />
        ),
        tooltip: true,
        tooltipDescription:
          "Aeroscore [0-1]: is a metric that helps cyclists understand their aerodynamic efficiency. It combines factors like body angle, power output. A higher Aeroscore indicates a more aerodynamic position and efficient power usage, which can lead to faster speeds. Currently, wind resistance is not directly included in the Aeroscore formula.",
      },
    ],
    [session, units],
  );

  const tableData = useMemo(
    () => [
      {
        header: "Power",
        valueAvg: `${session.average_power?.toFixed(0) ?? "-"} W `,
        valueMax: `${session.max_power?.toFixed(0) ?? "-"} W `,
      },
      {
        header: "Speed",
        valueAvg:
          units === "metric"
            ? `${session.average_speed?.toFixed(1) ?? "-"} kph`
            : `${(session.average_speed * 0.6213711922)?.toFixed(1) ?? "-"} mi`,
        valueMax:
          units === "metric"
            ? `${session.max_speed?.toFixed(1) ?? "-"} kph`
            : `${(session.max_speed * 0.6213711922)?.toFixed(1) ?? "-"} mi`,
      },
      {
        header: "Heart Rate",
        valueAvg: `${session.average_heart_rate?.toFixed(0) ?? "-"} bpm`,
        valueMax: `${session.max_heartrate?.toFixed(0) ?? "-"} bpm`,
      },
      {
        header: "Cadence",
        valueAvg: `${session.average_cadence?.toFixed(0) ?? "-"} rpm`,
        valueMax: `${session.max_cadence?.toFixed(0) ?? "-"} rpm`,
      },
    ],
    [session, units],
  );

  const isMobileScreen = useMediaQuery("(max-width:480px)");

  return (
    <>
      <Grid container>
        <Grid item lg={4} md={12} sm={12} xs={12}>
          <Grid
            item
            sx={{
              background: "#201B20",
              borderRadius: "10px",
              width: "97.8%",
              paddingY: "2em",
              marginBottom: "1rem",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Typography
              variant="h5"
              color={"#DD4F4A"}
              sx={{
                fontSize: {
                  xs: ".875rem",
                  xl: "1.125rem",
                },
              }}
            >
              {session.recording_name}
            </Typography>
            <Typography
              variant="h5"
              color={"#A19D9A"}
              sx={{
                fontSize: {
                  xs: ".875rem",
                  xl: "1.125rem",
                },
              }}
            >
              {new Date(session.date).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </Typography>
            <Typography
              variant="h5"
              color={"#36B37E"}
              sx={{
                fontSize: {
                  xs: ".875rem",
                  xl: "1.125rem",
                },
              }}
            >
              Planned
            </Typography>
          </Grid>
          <Grid container spacing={1} width={{ sm: "100%", md: "100%" }}>
            <Grid item width={isMobileScreen ? "97%" : "33%"}>
              <CoachOverviewDuoCard
                value1={cardInfo[0].value}
                value2={cardInfo[1].value}
                label1={cardInfo[0].label}
                label2={cardInfo[1].label}
                icon1={cardInfo[0].icon}
                icon2={cardInfo[1].icon}
              />
            </Grid>
            <Grid item width={isMobileScreen ? "97%" : "33%"}>
              <CoachOverviewDuoCard
                value1={cardDuoInfo[3].value1}
                value2={cardDuoInfo[3].value2}
                label1={cardDuoInfo[3].label1}
                label2={cardDuoInfo[3].label2}
                icon1={cardDuoInfo[3].icon1}
                icon2={cardDuoInfo[3].icon2}
                tooltip={cardDuoInfo[3].tooltip}
                tooltipDescription={cardDuoInfo[3].tooltipDescription}
              />
            </Grid>
            <Grid item width={isMobileScreen ? "97%" : "33%"}>
              <CoachOverviewDuoCard
                value1={cardDuoInfo[2].value1}
                value2={cardDuoInfo[2].value2}
                label1={cardDuoInfo[2].label1}
                label2={cardDuoInfo[2].label2}
                icon1={cardDuoInfo[2].icon1}
                icon2={cardDuoInfo[2].icon2}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            marginTop={"8px"}
            width={{ xs: "100%", md: "100%" }}
          >
            <Grid item width={isMobileScreen ? "100%" : "33%"}>
              <CoachOverviewDuoCard
                value1={cardDuoInfo[1].value1}
                value2={cardDuoInfo[1].value2}
                label1={cardDuoInfo[1].label1}
                label2={cardDuoInfo[1].label2}
                icon1={cardDuoInfo[1].icon1}
                icon2={cardDuoInfo[1].icon2}
              />
            </Grid>
            <Grid item width={isMobileScreen ? "100%" : "33%"}>
              <CoachOverviewBalanceCard
                value1={cardBalanceInfo[0].value1}
                value2={cardBalanceInfo[0].value2}
                value3={cardBalanceInfo[1].value}
                label={cardBalanceInfo[0].label}
                label2={cardBalanceInfo[1].label}
                icon1={cardBalanceInfo[0].icon}
                icon2={cardBalanceInfo[1].icon}
              />
            </Grid>
            <Grid item width={isMobileScreen ? "100%" : "33%"}>
              <CoachOverviewDuoCard
                value1={cardDuoInfo[0].value1}
                value2={cardDuoInfo[0].value2}
                label1={cardDuoInfo[0].label1}
                label2={cardDuoInfo[0].label2}
              />
            </Grid>
          </Grid>
          <Grid
            item
            marginTop={"1rem"}
            marginBottom={"1rem"}
            width={{ xs: "100%", md: "100%" }}
          >
            <CoachOverallTable data={tableData} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <CoachOverallGraph />
        </Grid>
        <Grid item xs={12} md={12} mt={"1rem"}>
          <CoachNotesComponent sessionId={session.id}/>
        </Grid>
      </Grid>
    </>
  );
}
