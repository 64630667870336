import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useSession } from "src/contexts/SupabaseContext";
import { useSupabase } from "src/contexts/SupabaseContext";
import illustration from "../../assets/img/views/successMessage/Illustration.svg";

export default function CoachSuccessMessage() {
  const supabase = useSupabase();
  const sessionData = useSession();
  const isMobileScreen = useMediaQuery("(max-width:450px)");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pt: "7.8125rem",
          "@media (max-width: 600px)": {
            alignItems: isMobileScreen ? "center" : "flex-start",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            background: "#2A252B",
            width: isMobileScreen ? "20rem" : "47.1875rem",
            height: "36.3125rem",
            borderRadius: "1.25rem",
            paddingTop: "4rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h1" sx={{color: "#DD4F4A"}}>
            Great Job!
          </Typography>

          <Box mt={"1.625rem"} mb={"1.5rem"}>
            <img src={illustration} alt="Email verification image" />
          </Box>

          <Typography variant="body2" fontSize={"14px"} lineHeight={"16px"}>
            You've successfully set up your Darefore profile. To get started,
            check out <br />
            the Dashboard to start managing your athletes and reaching your
            goals.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "1.5rem",
              mt: "1.5rem",
            }}
          >
            <Button
              variant="coachVariant"
              size="small"
              sx={{ width: { xs: "16.125rem", xl: "20rem" } }}
              onClick={(_) =>
                supabase.auth
                  .updateUser({
                    data: {
                      showSuccess: false,
                    },
                  })
                  .then((_) => {
                    if ("unload" in sessionData) {
                      sessionData.unload();
                    }
                  })
              }
            >
              <Typography variant="h5">Start managing your athletes</Typography>
            </Button>
            {/* <Button
              variant="contained"
              size="small"
              sx={{ width: { xs: "16.125rem", xl: "20rem" } }}
            >
              <Typography variant="h5">Connect to coach</Typography>
            </Button> */}
          </Box>
        </Box>
      </Box>
    </>
  );
}
