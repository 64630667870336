import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Tab, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTitle } from "src/content/customLayouts/DashboardLayout";
import WorkoutsDataGrid from "./WorkoutsDataGrid";

type TabValue = "training-plans" | "workouts";

export type LibraryTabProps = {
  tab?: TabValue;
};

export default function LibraryView(props: LibraryTabProps) {
  const [, setTitle] = useTitle();

  useEffect(() => {
    setTitle("Library");
  }, []);

  const [value, setValue] = useState<TabValue>("training-plans");
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const handleChange = useCallback((_, newValue: TabValue) => {
    navigate(`/dashboard/library/${newValue}`);
  }, []);

  useEffect(() => {
    setValue(props.tab);
  }, [props?.tab]);

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "25px",
        paddingBottom: "20px",
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            display: "flex",
            paddingRight: "20px",
            flexDirection: isMobileScreen ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="scrollable"
            aria-label="Tab list"
            sx={{
              "& .MuiTab-root:not(.Mui-selected)": {
                opacity: 0.5,
              },
              minHeight: "1.5rem",
              borderBottom: 1,
              borderColor: "rgba(188, 109, 41, 0.5)",
            }}
            TabIndicatorProps={{
              sx: {
                backgroundColor: "#BC6D29",
              },
            }}
          >
            <Tab
              label="Training Plans"
              value="training-plans"
              sx={{
                minHeight: 0,
                padding: "10px",
              }}
            />
            <Tab
              label="Workouts"
              value="workouts"
              sx={{
                minHeight: 0,
                padding: "10px",
              }}
            />
          </TabList>

          {value === "workouts" && (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: isMobileScreen ? "" : "flex-end",
                marginTop: isMobileScreen ? "0.5rem" : "",
              }}
            >
              <Button
                variant="contained"
                size={isMobileScreen ? "small" : "medium"}
                fullWidth
                onClick={(_) => navigate("add_workout")}
              >
                +ADD NEW
              </Button>
            </Box>
          )}
        </Box>
        <TabPanel
          value="training-plans"
          sx={{ padding: 0, minHeight: 0, marginTop: "0.6rem" }}
        >
          <Outlet />
        </TabPanel>

        <TabPanel
          value="workouts"
          sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
        >
          <WorkoutsDataGrid />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
