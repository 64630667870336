import { SnackbarKey, useSnackbar } from "notistack";
import {
  useEffect,
  useState,
  useCallback,
  createContext,
  useContext,
} from "react";
import { useSupabase } from "src/contexts/SupabaseContext";
import { Database } from "./DatabaseDefinitions";
import im from "immutable";
import useAsyncState, { AsyncState, isFulfilled, isUnloaded } from "./Async";
import { SupabaseCall } from "./common";

type ApiNotification = Database["public"]["Tables"]["notifications"]["Row"];

const StravaTokenContext = createContext<
  AsyncState<
    | {
        access_token: string;
        created_at: string;
        expires_at: number;
        refresh_token: string;
        user_id: string;
      }
    | "not-integrated"
  >
>(undefined);

export const useStravaToken = () => useContext(StravaTokenContext);

export type CyclistPreloadProps = {
  notifications: Array<ApiNotification>;
};

export default function CyclistPreload(
  props: React.PropsWithChildren<CyclistPreloadProps>,
) {
  const supabase = useSupabase();

  // // Notifications related
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // useEffect(() => {
  //   supabase
  //     .channel("schema-db-changes")
  //     .on(
  //       "postgres_changes",
  //       { event: "*", schema: "public", table: "notifications" },
  //       (payload) => {
  //         if (payload.table === "notifications") {
  //           switch (payload.eventType) {
  //             case "INSERT":
  //               setCurrentNotifications((map) =>
  //                 map.set(payload.new.id, [
  //                   enqueueSnackbar(
  //                     <div
  //                       className={`snackbar-notification-${payload.new.id}`}
  //                     >
  //                       {payload.new.content}
  //                     </div>,
  //                     {
  //                       anchorOrigin: {
  //                         vertical: "top",
  //                         horizontal: "center",
  //                       },
  //                       variant: payload.new.type as any,
  //                       persist: !payload.new.dismissable,
  //                     },
  //                   ),
  //                   payload.new as any,
  //                 ]),
  //               );
  //               break;
  //             case "DELETE":
  //               setForDeletion((list) => list.concat([payload.old.id]));
  //               break;
  //             case "UPDATE":
  //               // const elem = Array.from(
  //               //   document.getElementsByClassName(
  //               //     `snackbar-notification-${payload.new.id}`,
  //               //   ),
  //               // )[0];
  //               // // console.log({ elem });
  //               // Array.from(
  //               //   document.getElementsByClassName(
  //               //     `snackbar-notification-${payload.new.id}`,
  //               //   ),
  //               // )[0]["innerText"] = payload.new.content;
  //               break;
  //           }
  //         }
  //       },
  //     )
  //     .subscribe();
  // }, [supabase]);

  // const [currentNotifications, setCurrentNotifications] = useState<
  //   im.Map<number, [SnackbarKey, ApiNotification]>
  // >(im.Map());

  // const [forDeletion, setForDeletion] = useState<Array<number>>([]);

  // useEffect(() => {
  //   if (forDeletion.length > 0) {
  //     for (const id of forDeletion) {
  //       const key = currentNotifications.get(id)?.[0];
  //       if (!!key) {
  //         closeSnackbar(currentNotifications.get(id)[0]);
  //         setCurrentNotifications((map) => map.delete(id));
  //       }
  //     }
  //     setForDeletion([]);
  //   }
  // }, [forDeletion, currentNotifications]);

  // useEffect(() => {
  //   setCurrentNotifications(
  //     im.Map(
  //       props.notifications.map((notif) => [
  //         notif.id,
  //         [
  //           enqueueSnackbar(
  //             <div className={`snackbar-notification-${notif.id}`}>
  //               {notif.content}
  //             </div>,
  //             {
  //               anchorOrigin: {
  //                 vertical: "top",
  //                 horizontal: "center",
  //               },
  //               variant: notif.type as any,
  //               persist: !notif.dismissable,
  //             },
  //           ),
  //           notif,
  //         ],
  //       ]),
  //     ),
  //   );
  // }, [props.notifications]);

  // Strava integration related
  const stravaTokensPromise = useCallback(
    () =>
      supabase
        .from("strava")
        .select()
        .then((res) =>
          res.data.length === 0 ? "not-integrated" : res.data[0],
        ),
    [supabase],
  );

  const stravaTokens =
    useAsyncState<SupabaseCall<typeof stravaTokensPromise>>();

  useEffect(() => {
    if (isUnloaded(stravaTokens)) {
      stravaTokens.fire(async () => stravaTokensPromise());
    } else if (
      isFulfilled(stravaTokens) &&
      stravaTokens.result !== "not-integrated"
    ) {
      supabase.functions.invoke("get-all-strava-activities");
    }
  }, [stravaTokens]);

  return (
    <StravaTokenContext.Provider value={stravaTokens}>
      {props.children}
    </StravaTokenContext.Provider>
  );
}
