import { useCallback, useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tab,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import OverviewView from "./OverviewView";
import ZonezView from "./ZonezView";
import { useNavigate, useParams } from "react-router-dom";
import AnalyticsView from "./AnalyticsView";
import { TableRow, WithCache } from "src/contexts/CacheContext";
import PerformanceIndexView from "./PerformanceIndexView";
import PreloadComponent from "src/utils/PreloadComponent";
import { useRideSession } from "../customPages/SessionViewsPreload";
import PositionsView from "./PositionsView";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { useSupabase } from "src/contexts/SupabaseContext";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { SupabaseCall } from "src/utils/common";
import { useTitle } from "../customLayouts/DashboardLayout";
import dayjs from "dayjs";

type TabValue =
  | "overview"
  | "analytics"
  | "positions"
  | "zones"
  | "performance_index";

export type RideInsightsViewProps = {
  tab?: TabValue;
};

export default function RideInsightsView(props: RideInsightsViewProps) {
  const [value, setValue] = useState<TabValue>("overview");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogRename, setOpenDialogRename] = useState(false);
  const [name, setName] = useState("");
  const params = useParams<{ sessionId: string }>();
  const session = useRideSession();
  const supabase = useSupabase();
  const navigate = useNavigate();

  const [, setTitle] = useTitle();

  const isMobileScreen = useMediaQuery("(max-width:480px)");

  useEffect(() => {
    setTitle(
      `${isMobileScreen ? "" : "Ride Insights - "} ${
        session.recording_name.length > 50
          ? session.recording_name.substring(0, 50) + "..."
          : session.recording_name
      } - ${dayjs(session.date).format("DD/MM/YYYY")}`,
    );
  }, [isMobileScreen]);
  const handleChange = useCallback(
    (_, newValue: TabValue) => {
      setValue(newValue);
      window.history.replaceState(
        null,
        "",
        `/dashboard/session/${params.sessionId}/${newValue}`,
      );
    },
    [params],
  );

  useEffect(() => {
    if (!!props?.tab) {
      setValue(props?.tab);
    }
  }, [props]);

  const deleteSessionPromise = (session_id: string) =>
    supabase
      .from("session")
      .delete()
      .eq("id", session_id)
      .throwOnError()
      .then((_) => session_id);

  const deleteSessionCall = useAsyncState<string>();

  const updateRecordingNamePromise = useCallback(
    (newName: string) =>
      supabase
        .from("session")
        .update({ recording_name: newName })
        .eq("id", session.id)
        .throwOnError()
        .select("recording_name"),
    [supabase],
  );

  const updateRecordingNameCall =
    useAsyncState<SupabaseCall<typeof updateRecordingNamePromise>>();

  useEffect(() => {
    if (isFulfilled(updateRecordingNameCall))
      setTitle(
        `Ride Insights - ${
          updateRecordingNameCall.result.data[0].recording_name.length > 50
            ? updateRecordingNameCall.result.data[0].recording_name.substring(
                0,
                50,
              ) + "..."
            : updateRecordingNameCall.result.data[0].recording_name
        } - ${dayjs(session.date).format("DD/MM/YYYY")}`,
      );
  }, [updateRecordingNameCall]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          paddingTop: "15px",
          paddingLeft: "25px",
          paddingBottom: "20px",
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              display: isMobileScreen ? "" : "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                borderColor: "divider",
              }}
            >
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="Tab list"
                sx={{
                  "& .MuiTab-root:not(.Mui-selected)": {
                    opacity: 0.5,
                  },
                  minHeight: "1.5rem",
                }}
              >
                <Tab
                  label="Overview"
                  value="overview"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
                <Tab
                  label="Analytics"
                  value="analytics"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
                <Tab
                  label="Positions"
                  value="positions"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
                <Tab
                  label="Zones"
                  value="zones"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
                <Tab
                  label="Performance Index"
                  value="performance_index"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
              </TabList>
            </Box>
            {!isMobileScreen && (
              <Box sx={{ display: "flex" }}>
                <Tooltip title="Delete Session">
                  <IconButton onClick={() => setOpenDialog(true)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Rename Session">
                  <IconButton
                    sx={{ marginRight: 2.5 }}
                    onClick={() => setOpenDialogRename(true)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          <TabPanel
            value="overview"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <OverviewView />
          </TabPanel>
          <TabPanel
            value="analytics"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <PreloadComponent<{
              customPositions: Array<TableRow<"custom_positions">>;
            }>
              promises={{
                customPositions: async (supabase) => {
                  if (session?.athlete_bike_id === null) {
                    return [];
                  }
                  const { data } = await supabase
                    .from("custom_positions")
                    .select("*")
                    .eq("bike_id", session?.athlete_bike_id);
                  return data;
                },
              }}
              component={(preloadProps) => (
                <>
                  <WithCache
                    tables={["default_positions"]}
                    component={(cached) => (
                      <AnalyticsView
                        customPositions={preloadProps.customPositions}
                        defaultPositions={cached.default_positions}
                      />
                    )}
                  />
                </>
              )}
            />
          </TabPanel>
          <TabPanel
            value="positions"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <PreloadComponent<{
              customPositions: Array<TableRow<"custom_positions">>;
            }>
              promises={{
                customPositions: async (supabase) => {
                  if (session?.athlete_bike_id === null) {
                    return [];
                  }
                  const { data } = await supabase
                    .from("custom_positions")
                    .select("*")
                    .eq("bike_id", session?.athlete_bike_id);
                  return data;
                },
              }}
              component={(preloadProps) => (
                <>
                  <WithCache
                    tables={["default_positions"]}
                    component={(cached) => (
                      <PositionsView
                        customPositions={preloadProps.customPositions}
                        defaultPositions={cached.default_positions}
                      />
                    )}
                  />
                </>
              )}
            />
          </TabPanel>
          <TabPanel
            value="zones"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <PreloadComponent<{
              customPositions: Array<TableRow<"custom_positions">>;
            }>
              promises={{
                customPositions: async (supabase) => {
                  if (session?.athlete_bike_id === null) {
                    return [];
                  }
                  const { data } = await supabase
                    .from("custom_positions")
                    .select("*")
                    .eq("bike_id", session?.athlete_bike_id);
                  return data;
                },
              }}
              component={(preloadProps) => (
                <>
                  <WithCache
                    tables={["default_positions"]}
                    component={(cached) => (
                      <ZonezView
                        customPositions={preloadProps.customPositions}
                        defaultPositions={cached.default_positions}
                      />
                    )}
                  />
                </>
              )}
            />
          </TabPanel>
          <TabPanel
            value="performance_index"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <PerformanceIndexView />
          </TabPanel>
        </TabContext>
      </Box>
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            background: "#3D333D",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>
          {"Are you sure you want to delete this session?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By clicking Yes your selected session will be deleted permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (isUnloaded(deleteSessionCall)) {
                deleteSessionCall.fire(async () =>
                  deleteSessionPromise(session.id),
                );
                navigate("/dashboard/sessions");
                setOpenDialog(false);
              }
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setOpenDialog(false)}>No</Button>
        </DialogActions>
      </Dialog>

      {/* Rename Dialog */}
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            background: "#3D333D",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
        open={openDialogRename}
        onClose={() => setOpenDialogRename(false)}
      >
        <DialogTitle>{"Set a new session title"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="text"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if ("fire" in updateRecordingNameCall) {
                updateRecordingNameCall.fire(async () =>
                  updateRecordingNamePromise(name),
                );
              }
              setOpenDialogRename(false);
              setName("");
            }}
            disabled={name.length === 0}
          >
            Rename
          </Button>
          <Button onClick={() => setOpenDialogRename(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
