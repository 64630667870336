import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMetrics, useRideSession } from "../customPages/SessionViewsPreload";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import convert from "convert-units";
import { allBikePositions, timeFormatter } from "src/utils/common";
import { Fragment, useEffect, useMemo, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import type { Statistics } from "src/types/Statistics";

export type SessionLap = {
  aeroscore: number;
  avg_power: number;
  max_power: number;
  max_speed: number;
  start_time: Date;
  total_time: number;
  lap_trigger: string;
  max_cadence: number;
  total_ascent: number;
  average_speed: number;
  total_descent: number;
  avg_body_angle: number;
  average_cadence: number;
  normalized_power: number;
  dominant_position: string;
  total_distance: number;
  cda: number;
};

type CollapsibleDataProps = {
  title: string;
  field: string;
  unit: string;
};

export type LapProps = {
  positions: {
    default_degrees_high_offset: number;
    default_degrees_low_offset: number;
    drag_coefficient: number;
    id: number;
    primary_name: string;
    secondary_name: string;
    bike_type?: number;
    bike_id?: number;
  }[];
};

const position = [
  {
    key: "CMI",
    value: "Comfortable - Ideal",
  },
  {
    key: "CM",
    value: "Comfortable",
  },
  { key: "TTI", value: "TT - Ideal" },
  {
    key: "TT",
    value: "TT",
  },
  {
    key: "AG",
    value: "Aggressive",
  },
  {
    key: "AGI",
    value: "Aggressive - Ideal",
  },
  {
    key: "LTT",
    value: "Lower Than TT",
  },
  {
    key: "STA",
    value: "Standing",
  },
];

export default function AnalyitcsLapTable(props: LapProps) {
  const session = useRideSession();
  const metrics = useMetrics();
  const units = useUnits();
  const [expandedRow, setExpandedRow] = useState(null);
  const [finalStatistics, setFinalStatistics] = useState<
    {
      positionType: string;
      slope_average: number;
      heart_rate_average: number;
      power_average: number;
      speed_average: number;
      cadence_average: number;
      position_percentage: number;
      heart_rate_max: number;
    }[][]
  >([]);

  const handleRowClick = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  useEffect(() => {
    const hasCustom = !(
      metrics[0].default_position_id !== null &&
      metrics[0].default_position_id !== undefined
    );

    const finalStatisticsArray = [];

    for (const item of session.laps) {
      // biome-ignore lint/complexity/useLiteralKeys: <explanation>
      const startTime = new Date(item["start_time"]).getTime();
      // biome-ignore lint/complexity/useLiteralKeys: <explanation>
      const endTime = startTime + item["total_time"] * 1000;
      const filteredMetrics = metrics.filter((metric) => {
        const metricTime = new Date(metric.timestamp).getTime();
        return metricTime >= startTime && metricTime <= endTime;
      });

      const statistics = metricsToStatisticsMap(
        filteredMetrics,
        props.positions,
      );
      const finalStatistics = statisticsToFinal(
        statistics,
        hasCustom,
        filteredMetrics.length,
      );

      finalStatisticsArray.push(
        finalStatistics.sort(
          (a, b) =>
            allBikePositions.indexOf(
              a.positionType as
                | "Standing"
                | "Comfortable - Not Ideal"
                | "Comfortable - Ideal"
                | "Aggressive - Not Ideal"
                | "Aggressive - Ideal"
                | "TT - Not Ideal"
                | "TT - Ideal"
                | "Lower than TT",
            ) -
            allBikePositions.indexOf(
              b.positionType as
                | "Standing"
                | "Comfortable - Not Ideal"
                | "Comfortable - Ideal"
                | "Aggressive - Not Ideal"
                | "Aggressive - Ideal"
                | "TT - Not Ideal"
                | "TT - Ideal"
                | "Lower than TT",
            ),
        ),
      );
    }
    setFinalStatistics(finalStatisticsArray);
  }, [session, metrics]);

  const heartRates = useMemo(() => {
    return session.laps.map((lap, index) => {
      // biome-ignore lint/complexity/useLiteralKeys: <explanation>
      if (!lap["average_heart_rate"])
        return finalStatistics[index]?.reduce(
          (sum, stat) =>
            sum + stat.heart_rate_average * stat.position_percentage,
          0,
        );
    });
  }, [finalStatistics, session]);

  const maxHeartRates = useMemo(() => {
    return finalStatistics?.map((stat) =>
      Math.max(...stat.map((s) => s.heart_rate_max)),
    );
  }, [finalStatistics]);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650, backgroundColor: "#2C262D", borderWidth: "8px" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Lap
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Dominant Position
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Body Angle
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Aeroscore
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Distance
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Duration
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              CdA
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Avg Speed
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Max Speed
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Avg Power
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Max Power
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Normalized Power
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Avg Heart Rate
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Max Heart Rate
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Avg Cadence
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Max Cadence
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Ascent
            </TableCell>
            <TableCell style={{ color: "#BC6D29", fontWeight: "bold" }}>
              Descent
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {session.laps.map((row, index) => (
            // biome-ignore lint/complexity/useLiteralKeys: <explanation>
            <Fragment key={row["start_time"]}>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  backgroundColor:
                    expandedRow === index ? "#3f3641" : "#2C262D",
                }}
                hover
                onClick={() => handleRowClick(index)}
              >
                <TableCell>
                  {expandedRow === index ? (
                    <KeyboardArrowUp sx={{ color: "#E28E54" }} />
                  ) : (
                    <KeyboardArrowDown sx={{ color: "#E28E54" }} />
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                  {position.find((p) => p.key === row["dominant_position"])
                    ?.value ?? "-"}
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                  {`${row["avg_body_angle"]?.toFixed(1) ?? "-"}°`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                  {row["aeroscore"]?.toFixed(2) ?? "-"}
                </TableCell>
            
                <TableCell component="th" scope="row">
                  {units === "metric"
                    ? `${
                        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                        row["total_distance"]?.toFixed(1) ?? "-"
                      } km`
                    : `${
                        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                        convert(row["total_distance"] as number)
                          .from("mi")
                          .to("km")
                          ?.toFixed(1) ?? "-"
                      } mi`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                  {timeFormatter(row["total_time"]?.toString())}
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                  {`${row["cda"]?.toFixed(3) ?? "-"}`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {units === "metric"
                    ? `${
                        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                        row["average_speed"]?.toFixed(1) ?? "-"
                      } kph`
                    : `${
                        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                        (row["average_speed"] * 0.6213711922)?.toFixed(1) ?? "-"
                      } mph`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {units === "metric"
                    ? `${
                        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                        row["max_speed"]?.toFixed(1) ?? "-"
                      } kph`
                    : `${
                        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                        (row["max_speed"] * 0.6213711922)?.toFixed(1) ?? "-"
                      } mph`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                  {`${row["avg_power"]?.toFixed(0) ?? "-"} W`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                  {`${row["max_power"]?.toFixed(0) ?? "-"} W`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                  {`${row["normalized_power"]?.toFixed(0) ?? "-"} W`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                  {row["average_heart_rate"]
                    ? // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                      row["average_heart_rate"]?.toFixed(0)
                    : heartRates
                    ? heartRates[index]?.toFixed(0)
                    : "-"}{" "}
                  bpm
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                  {row["max_heartrate"]
                    ? // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                      row["max_heartrate"]?.toFixed(0)
                    : maxHeartRates
                    ? maxHeartRates[index]?.toFixed(0)
                    : "-"}{" "}
                  bpm
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                  {`${row["average_cadence"]?.toFixed(0) ?? "-"} rpm`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                  {`${row["max_cadence"]?.toFixed(0) ?? "-"} rpm`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {units === "metric"
                    ? `${
                        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                        (row["total_ascent"] * 1000 ?? 0)?.toFixed(0) ?? "-"
                      } m`
                    : `${
                        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                        convert(row["total_ascent"] * 1000 ?? 0)
                          .from("m")
                          .to("ft")
                          ?.toFixed(0) ?? "-"
                      } mi`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {units === "metric"
                    ? `${
                        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                        (row["total_descent"] * 1000 ?? 0)?.toFixed(0) ?? "-"
                      } m`
                    : `${
                        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                        convert(row["total_descent"] * 1000 ?? 0)
                          .from("m")
                          .to("ft")
                          ?.toFixed(0) ?? "-"
                      } mi`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    padding: 0,
                  }}
                  colSpan={18}
                >
                  <Collapse
                    in={expandedRow === index}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow hover>
                          <TableCell />
                          <TableCell
                            style={{ color: "#BC6D29", fontWeight: "bold" }}
                          >
                            Position
                          </TableCell>
                          <TableCell
                            style={{ color: "#BC6D29", fontWeight: "bold" }}
                          >
                            Percentage
                          </TableCell>
                          <TableCell
                            style={{ color: "#BC6D29", fontWeight: "bold" }}
                          >
                            Avg Speed
                          </TableCell>
                          <TableCell
                            style={{ color: "#BC6D29", fontWeight: "bold" }}
                          >
                            Avg Power
                          </TableCell>
                          <TableCell
                            style={{ color: "#BC6D29", fontWeight: "bold" }}
                          >
                            Avg Heart Rate
                          </TableCell>
                          <TableCell
                            style={{ color: "#BC6D29", fontWeight: "bold" }}
                          >
                            Avg Cadence
                          </TableCell>
                          <TableCell
                            style={{ color: "#BC6D29", fontWeight: "bold" }}
                          >
                            Avg Slope
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {finalStatistics[expandedRow]?.map((row, index) => (
                          <TableRow key={row.positionType} hover>
                            <TableCell />
                            <TableCell component="th" scope="row">
                              {row?.positionType}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {`${
                                (row?.position_percentage * 100)?.toFixed(2) ??
                                "-"
                              } %`}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {units === "metric"
                                ? `${row?.speed_average?.toFixed(1) ?? "-"} kph`
                                : `${
                                    (
                                      row?.speed_average * 0.6213711922
                                    )?.toFixed(1) ?? "-"
                                  } mph`}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {`${row?.power_average?.toFixed(0) ?? "-"} W`}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {`${
                                row?.heart_rate_average?.toFixed(0) ?? "-"
                              } bpm`}
                            </TableCell>

                            <TableCell component="th" scope="row">
                              {`${row?.cadence_average?.toFixed(0) ?? "-"} rpm`}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {`${row?.slope_average?.toFixed(0) ?? "-"} %`}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const metricsToStatisticsMap = (
  metrics: {
    barometer_inclination: number;
    bike_power: number;
    cadence: number;
    created_at: string;
    current_recording_time: number;
    custom_position_id: number;
    default_position_id: number;
    device_altitude: number;
    distance: number;
    heart_rate: number;
    id: number;
    latitude: number;
    longitude: number;
    pedal_balance_left: number;
    session_id: string;
    speed: number;
    temperature: number;
    timestamp: string;
    torso_degrees: number;
  }[],
  positions: {
    default_degrees_high_offset: number;
    default_degrees_low_offset: number;
    drag_coefficient: number;
    id: number;
    primary_name: string;
    secondary_name: string;
    bike_type?: number;
    bike_id?: number;
  }[],
) => {
  const statisticsMap = new Map<
    number,
    Statistics & {
      position: {
        default_degrees_high_offset: number;
        default_degrees_low_offset: number;
        drag_coefficient: number;
        id: number;
        primary_name: string;
        secondary_name: string;
        bike_type?: number;
        bike_id?: number;
      };
    }
  >();
  for (const metric of metrics) {
    const isDefault =
      metric.default_position_id !== null &&
      metric.default_position_id !== undefined;

    const position = isDefault
      ? positions.filter(
          (position) => position.id === metric.default_position_id,
        )[0]
      : positions.filter(
          (position) => position.id === metric.custom_position_id,
        )[0];

    const metricId = isDefault
      ? metric.default_position_id
      : metric.custom_position_id;

    const prevStatistic = statisticsMap.get(metricId) ?? {
      positionType: null,
      cadenceAverage: null,
      cadenceCount: 0,
      cadenceSum: 0,
      heartRateAverage: null,
      heartRateCount: 0,
      heartRateSum: 0,
      heartRateMax: 0,
      positionPercentage: 0,
      powerAverage: null,
      powerCount: 0,
      powerSum: 0,
      slopeAverage: 0,
      slopeCount: 0,
      slopeSum: 0,
      speedAverage: null,
      speedCount: 0,
      speedSum: 0,
      totalPositionPercentage: 0,
    };

    statisticsMap.set(metricId, {
      positionType:
        position.primary_name +
        (position.secondary_name ? ` - ${position.secondary_name}` : ""),
      cadenceAverage: null,
      cadenceCount: prevStatistic.cadenceCount + 1,
      cadenceSum: prevStatistic.cadenceSum + (metric.cadence ?? 0),
      heartRateAverage: null,
      heartRateCount: prevStatistic.heartRateCount + 1,
      heartRateSum: prevStatistic.heartRateSum + (metric.heart_rate ?? 0),
      heartRateMax: Math.max(
        prevStatistic.heartRateMax,
        metric.heart_rate ?? 0,
      ),
      positionPercentage: null,
      powerAverage: null,
      powerCount: prevStatistic.powerCount + 1,
      powerSum: prevStatistic.powerSum + (metric.bike_power ?? 0),
      slopeAverage: 0,
      slopeCount: prevStatistic.slopeCount + 1,
      slopeSum: prevStatistic.slopeSum + (metric.barometer_inclination ?? 0), //slope
      speedAverage: null,
      speedCount: prevStatistic.speedCount + 1,
      speedSum: prevStatistic.speedSum + (metric.speed ?? 0),
      totalPositionPercentage: null,
      position: { ...position },
    });
  }

  return statisticsMap;
};

export const statisticsToFinal = (
  statisticsMap: Map<
    number,
    Statistics & {
      position: {
        default_degrees_high_offset: number;
        default_degrees_low_offset: number;
        drag_coefficient: number;
        id: number;
        primary_name: string;
        secondary_name: string;
        bike_type?: number;
        bike_id?: number;
      };
    }
  >,
  has_custom: boolean | null,
  metricsLength: number,
) => {
  return Array.from(statisticsMap.values()).map((stat) => ({
    positionType: stat.positionType,
    slope_average: stat.slopeSum / stat.slopeCount,
    heart_rate_average: stat.heartRateSum / stat.heartRateCount,
    heart_rate_max: stat.heartRateMax,
    power_average: stat.powerSum / stat.powerCount,
    speed_average: stat.speedSum / stat.speedCount,
    cadence_average: stat.cadenceSum / stat.cadenceCount,
    position_percentage: stat.speedCount / metricsLength,
  }));
};
