import { Box, Grid, Tab, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MyPlansView from "./MyPlansView";
import { Outlet, useNavigate } from "react-router-dom";

export default function TrainingPlansView(props) {
  const navigate = useNavigate();
  const [value, setValue] = useState("available");
  const handleChange = useCallback((_, newValue) => {
    navigate(`/dashboard/library/training-plans/${newValue}`);
  }, []);
  useEffect(() => {
    setValue(props.tab);
  }, [props?.tab]);
  return (
    <Box
      sx={{
        width: "98%",
        padding: "1.5em",
        background: "#201B20",
        borderRadius: "10px",
      }}
    >
      <TabContext value={value}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TabList onChange={handleChange} variant="scrollable">
            <Tab
              value={"available"}
              label={<Typography variant="h5">AVAILABLE</Typography>}
            />
            <Tab
              value={"my-plans"}
              label={<Typography variant="h5">MY PLANS</Typography>}
            />
          </TabList>
        </Box>
        <TabPanel value="available">
          <Typography sx={{ marginTop: "10px" }}>No plans found.</Typography>
          {/* <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
            <Grid item>
              <TrainingPlanComponent />
            </Grid>
            <Grid item>
              <TrainingPlanComponent />
            </Grid>
            <Grid item>
              <TrainingPlanComponent />
            </Grid>
            <Grid item>
              <TrainingPlanComponent />
            </Grid>
          </Grid> */}
        </TabPanel>
        <TabPanel sx={{ padding: "5px" }} value="my-plans">
          <Outlet />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
