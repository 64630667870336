import { PropsWithChildren, useMemo } from "react";
import { useUser } from ".";
import { TableRow, WithCache } from "src/contexts/CacheContext";
import { User } from "@supabase/supabase-js";
import { UnitsContext } from "./CyclistAuthenticated";


export default function CoachWrapper(props: PropsWithChildren<{}>) {
  const user = useUser();
  return (
    <WithCache
      tables={["coach"]}
      component={(cached) => (
        <CoachAuthenticated
          coach={cached.coach[0]}
          user={user}
        >
          {props.children}
        </CoachAuthenticated>
        )}
    />
  );
}

type CoachProps = {
  coach: TableRow<"coach">;
  user: User;
};

function CoachAuthenticated(props: PropsWithChildren<CoachProps>) {
  const units = useMemo(
    () => (props.coach.units ? "imperial" : "metric"),
    [props.coach.units],
  );

  const isCoach = useMemo(
    () => props.user?.user_metadata?.accountType === "coach" ?? false,
    [props.user],
  );
  return (
    <>
      {isCoach ? (
        <UnitsContext.Provider value={units}>
            {props.children}
        </UnitsContext.Provider>
      ) : (
        <></>
      )}
    </>
  );
}