import {
  Checkbox,
  FormControlLabel,
  Grid,
  Slider,
  Stack,
  Typography,
  Tabs,
  Tab,
  Box,
  useTheme,
  Button,
  useMediaQuery,
  ListItemText,
  Popover,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  IconButton,
  type SelectChangeEvent,
  checkboxClasses,
} from "@mui/material";
import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import {
  allBikePositions,
  allColors,
  BikePosition,
  timeFormatter,
  withReference,
} from "src/utils/common";
import Chart from "react-apexcharts";
import type { ApexOptions } from "apexcharts";
import ApexCharts from "apexcharts";
import im from "immutable";
import Map from "../../../customComponents/MapComponent";
import { match, P } from "ts-pattern";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import convert from "convert-units";

import { TableRow } from "src/contexts/CacheContext";
import {
  useMetrics,
  useRideSession,
  useSpeciments,
} from "../AthletesSessions/CoachSessionViewPreload";
import {
  useRideSession as RideSession,
  useSpeciments as Specimentss,
  useMetrics as Metrics,
} from "../../Sessions/CoachSessionViewsPreload";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";

import CoachNotesComponent from "../CoachNotesComponent";

type PlaybackState =
  | {
      kind: "not-started";
    }
  | {
      kind: "playing" | "paused";
      index: number;
    };

export type Speciments = {
  timestamp: number;
  distance: number;
  power: number;
  heartrate: number;
  elevation: number;
  position: BikePosition;
  speed: number;
  longitude: number;
  latitude: number;
  cadence: number;
  torso_degrees: number;
};

export type PlaybackLabels = {
  position: boolean;
  heartRate: boolean;
  power: boolean;
  speed: boolean;
  cadence: boolean;
  bodyAngle: boolean;
  cda:boolean;
};

export type CoachAnalyticsViewProps = {
  defaultPositions: Array<TableRow<"default_positions">>;
  customPositions: Array<TableRow<"custom_positions">>;
};

export default function CoachAnalyticsView(props: CoachAnalyticsViewProps) {
  const theme = useTheme();
  const units = useUnits();
  const isMobileScreen = useMediaQuery("(max-width:550px)");

  const metrics =
    location.pathname.startsWith("/coach/my_athletes") &&
    !location.pathname.startsWith("/coach/my_athletes/session")
      ? useMetrics()
      : Metrics();
  const allSpeciments =
    location.pathname.startsWith("/coach/my_athletes") &&
    !location.pathname.startsWith("/coach/my_athletes/session")
      ? useSpeciments()
      : Specimentss();
  const session =
    location.pathname.startsWith("/coach/my_athletes") &&
    !location.pathname.startsWith("/coach/my_athletes/session")
      ? useRideSession()
      : RideSession();

  const allCharts = [
    "Body Angle",
    "Speed",
    "Elevation",
    "Heart Rate",
    "Power",
    "Cadence",
  ];

  //Visibility
  const [openPopover, setOpenPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [charts, setCharts] = useState(
    session.ride_types.name === "Indoor"
      ? allCharts.filter((col) => col !== "Body Angle")
      : allCharts.filter((col) => col !== "Cadence" && col !== "Body Angle"),
  );

  const [disabledCharts, setDisabledCharts] = useState<Array<string>>([]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenPopover(false);
  };

  const hasElevation = useMemo(
    () =>
      !allSpeciments
        .map((spec) => spec.elevation)
        .every((elevation) => !elevation),
    [allSpeciments],
  );

  const hasHeartRate = useMemo(
    () =>
      !allSpeciments
        .map((spec) => spec.heartrate)
        .every((heartrate) => !heartrate),
    [allSpeciments],
  );

  const hasPower = useMemo(
    () => !allSpeciments.map((spec) => spec.power).every((power) => !power),
    [allSpeciments],
  );

  const hasCadence = useMemo(
    () =>
      !allSpeciments.map((spec) => spec.cadence).every((cadence) => !cadence),
    [allSpeciments],
  );

  const hasSpeed = useMemo(
    () => !allSpeciments.map((spec) => spec.speed).every((speed) => !speed),
    [allSpeciments],
  );

  const hasBodyAngle = useMemo(
    () =>
      !allSpeciments
        .map((spec) => spec.torso_degrees)
        .every((torso_degrees) => !torso_degrees),
    [allSpeciments],
  );

  useEffect(() => {
    const disabledCharts = [];

    if (!hasElevation) {
      disabledCharts.push("Elevation");
    }
    if (!hasHeartRate) {
      disabledCharts.push("Heart Rate");
    }
    if (!hasPower) {
      disabledCharts.push("Power");
    }
    if (!hasCadence) {
      disabledCharts.push("Cadence");
    }
    if (!hasSpeed) {
      disabledCharts.push("Speed");
    }
    if (!hasBodyAngle) {
      disabledCharts.push("Body Angle");
    }

    setDisabledCharts(disabledCharts);
  }, [
    hasElevation,
    hasHeartRate,
    hasPower,
    hasCadence,
    hasSpeed,
    hasBodyAngle,
  ]);

  const bikePositions = useMemo(() => {
    if (session.session_statistics.length === 0) {
      return [];
    }
    return Array.from(
      new Set(
        metrics.flatMap((session) => {
          const positions =
            session.default_position_id !== null
              ? props.defaultPositions.find(
                  (pos) => pos.id === session.default_position_id,
                )
              : props.customPositions.find(
                  (pos) => pos.id === session.custom_position_id,
                );
          return (
            positions &&
            positions.primary_name +
              (positions.secondary_name ? ` - ${positions.secondary_name}` : "")
          );
        }),
      ),
    ).sort(
      (a, b) =>
        allBikePositions.indexOf(
          a as
            | "Standing"
            | "Comfortable - Not Ideal"
            | "Comfortable - Ideal"
            | "Aggressive - Not Ideal"
            | "Aggressive - Ideal"
            | "TT - Not Ideal"
            | "TT - Ideal"
            | "Lower than TT",
        ) -
        allBikePositions.indexOf(
          b as
            | "Standing"
            | "Comfortable - Not Ideal"
            | "Comfortable - Ideal"
            | "Aggressive - Not Ideal"
            | "Aggressive - Ideal"
            | "TT - Not Ideal"
            | "TT - Ideal"
            | "Lower than TT",
        ),
    );
  }, [metrics, session, props.defaultPositions]);

  const rideType = useMemo(
    () =>
      session.ride_types.name === "Indoor"
        ? "indoor"
        : session.ride_types.name === "Outdoor"
        ? "outdoor"
        : "virtual",
    [session],
  );

  const [selectedPositions] = useState<im.Set<BikePosition>>(im.Set());

  const [currentTab, setCurrentTab] = useState<"distance" | "time">("time");

  const positionRanges: Array<{
    position: BikePosition;
    open: number;
    close: number;
  }> = useMemo(() => {
    if (allSpeciments?.length > 0) {
      let currentPosition = allSpeciments[0].position;
      let open = allSpeciments[0].timestamp;
      // biome-ignore lint/style/useConst: <explanation>
      let rval = [];

      for (let i = 1; i < metrics.length; i++) {
        if (
          allSpeciments[i].position !== currentPosition ||
          i === metrics.length - 1
        ) {
          rval.push({
            position: currentPosition,
            open,
            close: allSpeciments[i].timestamp,
          });
          currentPosition = allSpeciments[i].position;
          open = allSpeciments[i].timestamp;
        }
      }

      return rval;
    }
    return [];
  }, [metrics, allSpeciments]);

  const positionDistanceRanges: Array<{
    position: BikePosition;
    open: number;
    close: number;
  }> = useMemo(() => {
    if (allSpeciments?.length > 0) {
      let currentPosition = allSpeciments[0].position;
      let open = allSpeciments[0].distance;
      // biome-ignore lint/style/useConst: <explanation>
      let rval = [];

      for (let i = 1; i < metrics.length; i++) {
        if (
          allSpeciments[i].position !== currentPosition ||
          i === metrics.length - 1
        ) {
          rval.push({
            position: currentPosition,
            open,
            close: allSpeciments[i].distance,
          });
          currentPosition = allSpeciments[i].position;
          open = allSpeciments[i].distance;
        }
      }

      return rval;
    }
    return [];
  }, [metrics, allSpeciments]);

  const [positionValue, setPositionValue] = useState("");

  const handleChangeSelect = useCallback((event: SelectChangeEvent) => {
    setPositionValue(event.target.value as string);
  }, []);

  useEffect(() => {
    ApexCharts.exec("positions", "updateOptions", {
      annotations: {
        position: "back",
        xaxis: withReference(
          currentTab === "time"
            ? positionRanges
            : units === "metric"
            ? positionDistanceRanges
            : positionDistanceRanges.map((range) => ({
                ...range,
                open: convert(range.open).from("km").to("mi"),
                close: convert(range.close).from("km").to("mi"),
              })),
          (arr) =>
            arr.map((positionRange) => ({
              x: positionRange.open,
              x2: positionRange.close,
              fillColor: allColors[positionRange.position],
              opacity:
                positionRange.position === positionValue
                  ? 1
                  : positionValue === ""
                  ? 1
                  : 0.1,
              strokeDashArray: 0,
              borderColor: allColors[positionRange.position],
            })),
        ),
      },
    });
    if (!selectedPositions.isEmpty()) {
      if (rideType !== "indoor") {
        ApexCharts.exec("elevation", "updateOptions", {
          annotations: {
            points: allSpeciments
              .filter((spec) => selectedPositions.has(spec.position))
              .map((spec) => ({
                x:
                  currentTab === "time"
                    ? spec.timestamp
                    : units === "metric"
                    ? spec.distance
                    : convert(spec.distance).from("km").to("mi"),
                y:
                  units === "metric"
                    ? spec.elevation
                    : convert(spec.elevation).from("m").to("ft"),
                marker: {
                  fillColor: allColors[spec.position],
                  strokeColor: allColors[spec.position],
                  size: 1,
                },
              })),
          },
        });
      } else {
        ApexCharts.exec("speed", "updateOptions", {
          annotations: {
            points: allSpeciments
              .filter((spec) => selectedPositions.has(spec.position))
              .map((spec) => ({
                x:
                  currentTab === "time"
                    ? spec.timestamp
                    : units === "metric"
                    ? spec.distance
                    : convert(spec.distance).from("km").to("mi"),
                y: units === "metric" ? spec.speed : spec.speed * 0.6213711922,
                marker: {
                  fillColor: allColors[spec.position],
                  strokeColor: allColors[spec.position],
                  size: 1,
                },
              })),
          },
        });
      }
      ApexCharts.exec("heartrate", "updateOptions", {
        annotations: {
          position: "back",
          xaxis: withReference(
            currentTab === "time"
              ? positionRanges
              : units === "metric"
              ? positionDistanceRanges
              : positionDistanceRanges.map((range) => ({
                  ...range,
                  open: convert(range.open).from("km").to("mi"),
                  close: convert(range.close).from("km").to("mi"),
                })),
            (arr) =>
              arr
                .filter((range) => selectedPositions.has(range.position))
                .map((positionRange) => ({
                  x: positionRange.open,
                  x2: positionRange.close,
                  fillColor: allColors[positionRange.position],
                  opacity: 1,
                  strokeDashArray: 0,
                  borderColor: allColors[positionRange.position],
                })),
          ),
        },
      });
      ApexCharts.exec("power", "updateOptions", {
        annotations: {
          position: "back",
          xaxis: withReference(
            currentTab === "time"
              ? positionRanges
              : positionDistanceRanges.map((range) => ({
                  ...range,
                  open: convert(range.open).from("km").to("mi"),
                  close: convert(range.close).from("km").to("mi"),
                })),
            (arr) =>
              arr
                .filter((range) => selectedPositions.has(range.position))
                .map((positionRange) => ({
                  x: positionRange.open,
                  x2: positionRange.close,
                  fillColor: allColors[positionRange.position],
                  opacity: 1,
                  strokeDashArray: 0,
                  borderColor: allColors[positionRange.position],
                })),
          ),
        },
      });
      ApexCharts.exec("cadence", "updateOptions", {
        annotations: {
          position: "back",
          xaxis: withReference(
            currentTab === "time"
              ? positionRanges
              : units === "metric"
              ? positionDistanceRanges
              : positionDistanceRanges.map((range) => ({
                  ...range,
                  open: convert(range.open).from("km").to("mi"),
                  close: convert(range.close).from("km").to("mi"),
                })),
            (arr) =>
              arr
                .filter((range) => selectedPositions.has(range.position))
                .map((positionRange) => ({
                  x: positionRange.open,
                  x2: positionRange.close,
                  fillColor: allColors[positionRange.position],
                  opacity: 1,
                  strokeDashArray: 0,
                  borderColor: allColors[positionRange.position],
                })),
          ),
        },
      });
    } else {
      if (rideType !== "indoor") {
        ApexCharts.exec("elevation", "updateOptions", {
          annotations: {
            points: [],
          },
        });
      } else {
        ApexCharts.exec("speed", "updateOptions", {
          annotations: {
            points: [],
          },
        });
      }
      ApexCharts.exec("heartrate", "updateOptions", {
        annotations: {
          xaxis: [],
        },
      });
      ApexCharts.exec("power", "updateOptions", {
        annotations: {
          xaxis: [],
        },
      });
      ApexCharts.exec("cadence", "updateOptions", {
        annotations: {
          xaxis: [],
        },
      });
    }
  }, [
    selectedPositions,
    currentTab,
    allSpeciments,
    units,
    positionRanges,
    positionDistanceRanges,
    positionValue,
    rideType,
  ]);

  const tabs = useMemo(
    () => [
      { value: "distance", label: "Distance" },
      { value: "time", label: "Time" },
    ],
    [],
  );

  const handleTabsChange = useCallback(
    (_, value: "distance" | "time"): void => {
      setCurrentTab(value);
    },
    [],
  );

  const [hoveredIndex, setHoveredIndex] = useState<number | "none">("none");
  const [zoomed, setZoomed] = useState<[number, number] | "none">("none");

  const createChartOptions = useCallback(
    (
      id: string,
      title: string,
      color: string,
      xaxisFormatter: (_: string) => string,
      yaxisFormatter: (_: number) => string,
      showToolbar: boolean,
    ): ApexOptions => ({
      chart: {
        group: "graphs",
        id,
        animations: {
          enabled: false,
        },
        events: {
          mouseMove: (_, __, e) => {
            setHoveredIndex(e.dataPointIndex > 0 ? e.dataPointIndex : "none");
          },
          mouseLeave: (_) => {
            setHoveredIndex("none");
          },
          click: (_) => {
            setPlayback((playback) =>
              match(playback)
                .with(
                  { kind: "playing", index: P.select("index") },
                  (selected) => ({
                    kind: "paused" as const,
                    index: selected.index,
                  }),
                )
                .otherwise((p) => p),
            );
          },
          beforeZoom: (chartContext, { xaxis }) => ({
            xaxis: {
              min: xaxis.min < 0 ? 0 : xaxis.min,
              max: xaxis.max < 0 ? 1 : xaxis.max,
            },
          }),
          zoomed: (_, props) =>
            !!props.xaxis.min && !!props.xaxis.max
              ? setZoomed([props.xaxis.min, props.xaxis.max])
              : setZoomed("none"),
        },
        background: "transparent",
        toolbar: {
          show: showToolbar,
          offsetX: -20,
          tools: {
            reset: true,
            zoom: "<img/>",
            pan: false,
            selection: false,
            zoomin: false,
            zoomout: false,
            download: false,
          },
        },
      },
      title: {
        text: title,
      },
      stroke: {
        curve: "smooth",
        width: [1, 3],
        dashArray: [0, 5],
        colors: [color],
      },
      fill: {
        opacity: [1, 0.2],
      },
      theme: {
        mode: theme.palette.mode,
      },
      colors: [color],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          formatter: xaxisFormatter,
        },
        tickAmount: 5,
        type: "numeric",
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          maxWidth: 32,
          minWidth: 32,
          formatter: yaxisFormatter,
        },
        tickAmount: 2,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        strokeDashArray: 5,
        borderColor: theme.colors.alpha.black[10],
      },
    }),
    [],
  );

  const elevationTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "elevation",
        `Elevation (${units === "metric" ? "m" : "ft"}) / Time`,
        theme.colors.primary.main,
        timeFormatter,
        (val) => (Number.isNaN(val) ? "-" : val?.toFixed(0)),
        false,
      ),
    [units],
  );

  const elevationTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Elevation",
        data: allSpeciments.map((spec) => [
          spec.timestamp,
          units === "metric"
            ? spec.elevation
            : convert(spec.elevation).from("m").to("ft"),
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments, units],
  );

  const elevationDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "elevation",
        `Elevation (${units === "metric" ? "m" : "ft"}) / Distance (${
          units === "metric" ? "km" : "mi"
        })`,
        theme.colors.primary.main,
        (valStr) =>
          `${Number(valStr)?.toFixed(2)}${units === "metric" ? "km" : "mi"}`,
        (val) => (Number.isNaN(val) ? "-" : val?.toFixed(0)),
        false,
      ),
    [units],
  );

  const elevationDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Elevation",
        data: allSpeciments.map(
          (spec) =>
            units === "metric"
              ? [spec.distance, spec.elevation]
              : [
                  convert(spec.distance).from("km").to("mi"),
                  convert(spec.elevation).from("m").to("ft"),
                ],
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ) as any,
      },
    ],
    [allSpeciments, units],
  );

  const speedTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "speed",
        `Speed (${units === "metric" ? "kph" : "mph"}) / Time`,
        "#E28E54",
        timeFormatter,
        (val) => val?.toFixed(0),
        session.session_statistics.length === 0,
      ),
    [units],
  );

  const speedTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Speed",
        data: allSpeciments.map((spec) => [
          spec.timestamp,
          units === "metric" ? spec.speed : spec.speed * 0.6213711922,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments, units],
  );

  const speedDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "speed",
        `Speed (${units === "metric" ? "kph" : "mph"}) / Distance (${
          units === "metric" ? "km" : "mi"
        })`,
        "#E28E54",
        (valStr) =>
          `${Number(valStr)?.toFixed(2)}${units === "metric" ? "km" : "mi"}`,
        (val) => val?.toFixed(0),
        session.session_statistics.length === 0,
      ),
    [units],
  );

  const speedDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Speed",
        data: allSpeciments.map(
          (spec) =>
            units === "metric"
              ? [spec.distance, spec.speed]
              : [
                  convert(spec.distance).from("km").to("mi"),
                  spec.speed * 0.6213711922,
                ],
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ) as any,
      },
    ],
    [allSpeciments, units],
  );

  const heartRateTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "heartrate",
        "Heart Rate (bpm) / Time",
        theme.colors.error.main,
        timeFormatter,
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (val) => (!val ? (val as any) : val?.toFixed(0)),
        false,
      ),
    [],
  );

  const heartRateTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Heart Rate",
        data: allSpeciments.map((spec) => [
          spec.timestamp,
          spec.heartrate,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments],
  );

  const heartRateDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "heartrate",
        `Heart Rate (bpm) / Distance (${units === "metric" ? "km" : "mi"})`,
        theme.colors.error.main,
        (valStr) =>
          `${Number(valStr)?.toFixed(2)}${units === "metric" ? "km" : "mi"}`,
        (val) => val?.toFixed(0),
        false,
      ),
    [units],
  );

  const heartRateDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Heart Rate",
        data: allSpeciments.map((spec) => [
          units === "metric"
            ? spec.distance
            : convert(spec.distance).from("km").to("mi"),
          spec.heartrate,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments, units],
  );

  const powerTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "power",
        "Power (W) / Time",
        theme.colors.warning.main,
        timeFormatter,
        (val) => val?.toFixed(0),
        false,
      ),
    [],
  );

  const powerTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Power",
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        data: allSpeciments.map((spec) => [spec.timestamp, spec.power]) as any,
      },
    ],
    [allSpeciments],
  );

  const powerDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "power",
        `Power (W) / Distance (${units === "metric" ? "km" : "mi"})`,
        theme.colors.warning.main,
        (valStr) =>
          `${Number(valStr)?.toFixed(2)}${units === "metric" ? "km" : "mi"}`,
        (val) => val?.toFixed(0),
        false,
      ),
    [units],
  );

  const powerDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Power",
        data: allSpeciments.map((spec) => [
          units === "metric"
            ? spec.distance
            : convert(spec.distance).from("km").to("mi"),
          spec.power,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments, units],
  );

  const cadenceTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "cadence",
        "Cadence (rpm) / Time",
        "#00B8D9",
        timeFormatter,
        (val) => val?.toFixed(0),
        false,
      ),
    [],
  );

  const cadenceTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Cadence",
        data: allSpeciments.map((spec) => [
          spec.timestamp,
          spec.cadence,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments],
  );

  const cadenceDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "cadence",
        `Cadence (rpm) / Distance (${units === "metric" ? "km" : "mi"})`,
        "#00B8D9",
        (valStr) =>
          `${Number(valStr)?.toFixed(2)}${units === "metric" ? "km" : "mi"}`,
        (val) => val?.toFixed(0),
        false,
      ),
    [units],
  );

  const cadenceDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Cadence",
        data: allSpeciments.map((spec) => [
          units === "metric"
            ? spec.distance
            : convert(spec.distance).from("km").to("mi"),
          spec.cadence,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments, units],
  );

  const bodyAngleTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "body angle",
        "Body Angle° / Time",
        "#653780",
        timeFormatter,
        (val) => val?.toFixed(1),
        false,
      ),
    [],
  );

  const bodyAngleTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Body Angle",
        data: allSpeciments.map((spec) => [
          spec.timestamp,
          spec.torso_degrees,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments],
  );

  const bodyAngleDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "body angle",
        `Body Angle° / Distance (${units === "metric" ? "km" : "mi"})`,
        "#653780",
        (valStr) =>
          `${Number(valStr)?.toFixed(2)}${units === "metric" ? "km" : "mi"}`,
        (val) => val?.toFixed(1),
        false,
      ),
    [units],
  );

  const bodyAngleDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Body Angle",
        data: allSpeciments.map((spec) => [
          units === "metric"
            ? spec.distance
            : convert(spec.distance).from("km").to("mi"),
          spec.torso_degrees,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments, units],
  );

  const positionBrushChartOptions: ApexOptions = useMemo(
    () => ({
      chart: {
        id: "positions",
        height: 130,
        group: "graphs",
        type: "line",
        animations: {
          enabled: false,
        },
        toolbar: {
          show: true,
          offsetX: -20,
          tools: {
            reset: true,
            zoom: "<img/>",
            pan: false,
            selection: false,
            zoomin: false,
            zoomout: false,
            download: false,
          },
        },
        background: "transparent",
        events: {
          mouseMove: (_, __, e) => {
            setHoveredIndex(e.dataPointIndex > 0 ? e.dataPointIndex : "none");
          },
          mouseLeave: (_) => {
            setHoveredIndex("none");
          },
          beforeZoom: (chartContext, { xaxis }) => ({
            xaxis: {
              min: xaxis.min < 0 ? 0 : xaxis.min,
              max: xaxis.max < 0 ? 1 : xaxis.max,
            },
          }),
          zoomed: (_, props) =>
            !!props.xaxis.min && !!props.xaxis.max
              ? setZoomed([props.xaxis.min, props.xaxis.max])
              : setZoomed("none"),
        },
      },

      legend: {
        show: false,
        position: "bottom",
        markers: {
          fillColors: bikePositions.map((position) => allColors[position]),
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      title: {
        text: "Position",
      },
      theme: {
        mode: theme.palette.mode,
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: [0],
        marker: {
          show: false,
        },
        y: {
          title: {
            formatter: (_) => "Position:",
          },
          formatter: (val) => bikePositions[val],
        },
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        type: "numeric",
        max: allSpeciments?.at(-1)?.timestamp ?? null,
        labels: {
          show: true,
          formatter: timeFormatter,
        },
      },
      fill: {
        opacity: 0,
        colors: ["#E28E54", "#EA366F", "#DD4F4A"],
      },
      stroke: {
        width: 0,
      },
      yaxis: {
        show: true,
        tickAmount: 2,
        labels: {
          show: false,
          maxWidth: 20,
          minWidth: 20,
        },
        max: 100000,
      },
      annotations: {
        position: "back",
        xaxis: positionRanges.map((positionRange) => ({
          x: positionRange.open,
          x2: positionRange.close,
          fillColor: allColors[positionRange.position],
          opacity: 2,
          strokeDashArray: 0,
          borderColor: allColors[positionRange.position],
        })),
      },
    }),
    [positionRanges, bikePositions, allSpeciments],
  );

  const positionBrushDistanceChartOptions: ApexOptions = useMemo(
    () => ({
      chart: {
        id: "positions",
        height: 130,
        group: "graphs",
        type: "line",
        animations: {
          enabled: false,
        },
        toolbar: {
          show: true,
          offsetX: -20,
          tools: {
            reset: true,
            zoom: "<img/>",
            pan: false,
            selection: false,
            zoomin: false,
            zoomout: false,
            download: false,
          },
        },
        background: "transparent",
        events: {
          mouseMove: (_, __, e) => {
            setHoveredIndex(e.dataPointIndex > 0 ? e.dataPointIndex : "none");
          },
          mouseLeave: (_) => {
            setHoveredIndex("none");
          },
        },
        // brush: {
        //   target: "positions",
        //   enabled: true,
        // },
      },
      legend: {
        show: false,
        position: "bottom",
        markers: {
          fillColors: bikePositions.map((position) => allColors[position]),
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      title: {
        text: "Position",
      },
      theme: {
        mode: theme.palette.mode,
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: [0],
        marker: {
          show: false,
        },
        y: {
          title: {
            formatter: (_) => "Position:",
          },
          formatter: (val) => bikePositions[val],
        },
      },
      colors: ["#008FFB"],
      xaxis: {
        type: "numeric",
        max:
          units === "metric"
            ? allSpeciments?.at(-1)?.distance ?? null
            : convert(allSpeciments.at(-1).distance).from("km").to("mi"),
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          formatter: (valStr) =>
            `${Number(valStr)?.toFixed(2)}${units === "metric" ? "km" : "mi"}`,
        },
      },
      fill: {
        opacity: 0,
      },
      stroke: {
        width: 0,
      },
      yaxis: {
        tickAmount: 2,
        labels: {
          show: false,
          maxWidth: 20,
          minWidth: 20,
        },
        max: 100000,
      },
      annotations: {
        position: "back",
        xaxis: positionDistanceRanges.map((positionRange) => ({
          x:
            units === "metric"
              ? positionRange.open
              : convert(positionRange.open).from("km").to("mi"),
          x2:
            units === "metric"
              ? positionRange.close
              : convert(positionRange.close).from("km").to("mi"),
          fillColor: allColors[positionRange.position],
          opacity: 2,
          strokeDashArray: 0,
          borderColor: allColors[positionRange.position],
        })),
      },
    }),
    [
      positionRanges,
      bikePositions,
      units,
      allSpeciments,
      positionDistanceRanges,
      positionRanges,
    ],
  );

  const positionStancesChartSeries: ApexAxisChartSeries = useMemo(
    () =>
      bikePositions.map((position, i) => ({
        name: position,
        data:
          i === 0
            ? (allSpeciments.map((spec) => [
                spec.timestamp,
                bikePositions.findIndex((val) => val === spec.position),
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
              ]) as any)
            : [],
      })),
    [allSpeciments, bikePositions],
  );

  const positionStancesDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () =>
      bikePositions.map((position, i) => ({
        name: position,
        data:
          i === 0
            ? (allSpeciments.map((spec) => [
                units === "metric"
                  ? spec.distance
                  : convert(spec.distance).from("km").to("mi"),
                bikePositions.findIndex((val) => val === spec.position),
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
              ]) as any)
            : [],
      })),
    [allSpeciments, bikePositions, units],
  );

  const [playback, setPlayback] = useState<PlaybackState>({
    kind: "not-started",
  });

  const playbackSpeedRef = useRef<number>(1);

  const handlePlayback = useCallback(() => {
    setPlayback((playback) =>
      match<PlaybackState, PlaybackState>(playback)
        .with({ kind: "not-started" }, () => ({ kind: "playing", index: 0 }))
        .with({ kind: "playing", index: P.select("index") }, (selected) => ({
          kind: "paused",
          index: selected.index,
        }))
        .with({ kind: "paused", index: P.select("index") }, (selected) => ({
          kind: "playing",
          index: selected.index,
        }))
        .run(),
    );
  }, []);

  const incrementPlayback = () => {
    setPlayback((playback) =>
      match<PlaybackState, PlaybackState>(playback)
        .with({ kind: "playing", index: P.select("index") }, (selected) => ({
          kind: "playing",
          index: (selected.index + 1) % allFilteredMarkers.length,
        }))
        .otherwise((playback) => playback),
    );
  };

  useEffect(() => {
    if (incrementIntervalRef.current) {
      clearInterval(incrementIntervalRef.current);
      incrementIntervalRef.current = setInterval(
        () => incrementPlayback(),
        101 - playbackSpeedRef.current,
      );
    }
  }, []);

  const incrementIntervalRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (playback.kind === "playing" && !incrementIntervalRef.current) {
      incrementIntervalRef.current = setInterval(
        () => incrementPlayback(),
        101 - playbackSpeedRef.current,
      );
    } else if (playback.kind === "paused" && !!incrementIntervalRef.current) {
      clearInterval(incrementIntervalRef.current);
      incrementIntervalRef.current = undefined;
    }
  }, [playback, incrementPlayback]);

  useEffect(() => {
    if (playback.kind === "playing" && !!incrementIntervalRef.current) {
      clearInterval(incrementIntervalRef.current);
      incrementIntervalRef.current = setInterval(
        () => incrementPlayback(),
        101 - playbackSpeedRef.current,
      );
    }
  }, [playbackSpeedRef.current, playback.kind]);

  const setPlaybackAnnotations = useCallback(
    (chartId: string, x: number, y: number, label: string) => {
      ApexCharts.exec(chartId, "removeAnnotation", "playback-axis");
      ApexCharts.exec(chartId, "removeAnnotation", "playback-point");
      ApexCharts.exec(chartId, "addXaxisAnnotation", {
        id: "playback-axis",
        x,
        label: {
          text: label,
          orientation: "horizontal",
          style: { background: "black" },
        },
      });
      ApexCharts.exec(chartId, "addPointAnnotation", {
        id: "playback-point",
        x,
        y,
      });
    },
    [],
  );

  useEffect(() => {
    if (playback.kind === "playing") {
      const x =
        currentTab === "time"
          ? allSpeciments[playback.index].timestamp
          : units === "metric"
          ? allSpeciments[playback.index].distance
          : convert(allSpeciments[playback.index].distance).from("km").to("mi");
      setPlaybackAnnotations(
        "elevation",
        x,
        units === "metric"
          ? allSpeciments[playback.index].elevation
          : convert(allSpeciments[playback.index].elevation).from("m").to("ft"),
        `Elevation: ${
          units === "metric"
            ? allSpeciments[playback.index].elevation
            : convert(allSpeciments[playback.index].elevation)
                .from("m")
                .to("ft")
                .toFixed(0)
        }`,
      );
      if (hasHeartRate) {
        setPlaybackAnnotations(
          "heartrate",
          x,
          allSpeciments[playback.index].heartrate,
          `Heart Rate: ${allSpeciments[playback.index].heartrate?.toFixed(0)}`,
        );
      }

      if (hasPower) {
        setPlaybackAnnotations(
          "power",
          x,
          allSpeciments[playback.index].power,
          `Power: ${withReference(
            allSpeciments[playback.index]?.power ??
              allSpeciments[playback.index - 1]?.power,
            (power) => `${power?.toFixed(0)}`,
          )}`,
        );
      }

      if (hasSpeed && !!allSpeciments?.[playback?.index]?.speed?.toFixed(0)) {
        setPlaybackAnnotations(
          "speed",
          x,
          allSpeciments[playback.index].speed,
          `Speed: ${allSpeciments[playback.index].speed?.toFixed(0)}`,
        );
      }
      if (
        hasCadence &&
        !!allSpeciments?.[playback?.index]?.cadence?.toFixed(0)
      ) {
        setPlaybackAnnotations(
          "cadence",
          x,
          allSpeciments[playback.index].cadence,
          `Cadence: ${allSpeciments[playback.index].cadence?.toFixed(0)}`,
        );
      }

      if (
        hasBodyAngle &&
        !!allSpeciments?.[playback?.index]?.torso_degrees?.toFixed(0)
      ) {
        setPlaybackAnnotations(
          "torso degrees",
          x,
          allSpeciments[playback.index].torso_degrees,
          `Body Angle: ${allSpeciments[playback.index].torso_degrees?.toFixed(
            1,
          )}`,
        );
      }

      if (session.session_statistics.length !== 0) {
        setPlaybackAnnotations(
          "positions",
          x,
          0,
          `Position: ${allSpeciments[playback.index].position}`,
        );
      }
    }
  }, [
    playback,
    allSpeciments,
    currentTab,
    setPlaybackAnnotations,
    hasHeartRate,
    hasPower,
    hasCadence,
    session.session_statistics,
  ]);

  const [playbackLabels, setPlaybackLabels] = useState<PlaybackLabels>({
    position: false,
    heartRate: false,
    power: false,
    speed: false,
    cadence: false,
    bodyAngle: false,
    cda:false
  });

  const filteredMarkers = useCallback(
    (markers: [number, number][]) =>
      withReference(
        markers.findIndex((el) => !!el[0] && !!el[1]),

        (index) =>
          index === -1
            ? []
            : markers
                .slice(0, index)
                .map((el, i, arr) => markers[index])
                .concat(markers.slice(index))
                .map((el, i, arr) =>
                  !!el[0] && !!el[1]
                    ? el
                    : arr.slice(0, i).reverse()[
                        arr
                          .slice(0, i)
                          .reverse()
                          .findIndex((el) => !!el[0] && !!el[1])
                      ],
                ),
      ),
    [],
  );

  const allFilteredMarkers = useMemo(
    () =>
      filteredMarkers(
        metrics
          .filter((metric) => metric.longitude !== 0)
          .map((metric) => [metric.latitude, metric.longitude]),
      ),
    [metrics],
  );

  return (
    <>
      <Box
        sx={{
          width: "98%",
          background: "#201B20",
          borderRadius: "6px",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {rideType !== "indoor" && allFilteredMarkers.length !== 0 && (
          <Grid
            item
            position={"relative"}
            sx={{ width: { xs: "100%", md: "50%" } }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "10px",
                top: isMobileScreen ? "460px" : "555px",
                zIndex: 1,
                background: "rgba(197, 187, 198, 0.2)",
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
                border: "1px solid grey",
                borderRadius: "6px",
              }}
            >
              <Grid direction={"row"} container>
                {session.session_statistics.length !== 0 && (
                  <FormControlLabel
                    control={
                      <Checkbox sx={{ color: "#5B7279" }} size="small" />
                    }
                    label={
                      <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                        Position
                      </Typography>
                    }
                    sx={{ margin: 0 }}
                    value={playbackLabels.position}
                    onChange={(_, val) =>
                      setPlaybackLabels((labels) => ({
                        ...labels,
                        position: val,
                      }))
                    }
                  />
                )}
                {hasBodyAngle && (
                  <FormControlLabel
                    control={
                      <Checkbox sx={{ color: "#5B7279" }} size="small" />
                    }
                    label={
                      <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                        Body Angle
                      </Typography>
                    }
                    sx={{ margin: 0 }}
                    value={playbackLabels.bodyAngle}
                    onChange={(_, val) =>
                      setPlaybackLabels((labels) => ({
                        ...labels,
                        bodyAngle: val,
                      }))
                    }
                  />
                )}
                {hasHeartRate && (
                  <FormControlLabel
                    control={
                      <Checkbox sx={{ color: "#5B7279" }} size="small" />
                    }
                    label={
                      <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                        Heart Rate
                      </Typography>
                    }
                    sx={{ margin: 0 }}
                    value={playbackLabels.heartRate}
                    onChange={(_, val) =>
                      setPlaybackLabels((labels) => ({
                        ...labels,
                        heartRate: val,
                      }))
                    }
                  />
                )}
                {hasPower && (
                  <FormControlLabel
                    control={
                      <Checkbox sx={{ color: "#5B7279" }} size="small" />
                    }
                    label={
                      <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                        Power
                      </Typography>
                    }
                    sx={{ margin: 0 }}
                    value={playbackLabels.power}
                    onChange={(_, val) =>
                      setPlaybackLabels((labels) => ({
                        ...labels,
                        power: val,
                      }))
                    }
                  />
                )}
                {hasSpeed && (
                  <FormControlLabel
                    control={
                      <Checkbox sx={{ color: "#5B7279" }} size="small" />
                    }
                    label={
                      <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                        Speed
                      </Typography>
                    }
                    sx={{ margin: 0 }}
                    value={playbackLabels.speed}
                    onChange={(_, val) =>
                      setPlaybackLabels((labels) => ({ ...labels, speed: val }))
                    }
                  />
                )}
                {hasCadence && (
                  <FormControlLabel
                    control={
                      <Checkbox sx={{ color: "#5B7279" }} size="small" />
                    }
                    label={
                      <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                        Cadence
                      </Typography>
                    }
                    sx={{ margin: 0 }}
                    value={playbackLabels.cadence}
                    onChange={(_, val) =>
                      setPlaybackLabels((labels) => ({
                        ...labels,
                        cadence: val,
                      }))
                    }
                  />
                )}
              </Grid>

              <Stack
                spacing={2}
                direction={isMobileScreen ? "column" : "row"}
                alignItems="center"
                padding={"15px"}
              >
                {playback.kind === "playing" ? (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handlePlayback}
                  >
                    <Typography>Stop Ride</Typography>
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handlePlayback}
                  >
                    <Typography>Play Ride</Typography>
                  </Button>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{ color: "#575757", fontWeight: 400 }}
                    variant="subtitle2"
                  >
                    Slow
                  </Typography>
                  <Slider
                    size="small"
                    sx={{ width: "130px", color: "#64605D" }}
                    onChange={(_, val) => {
                      playbackSpeedRef.current = Math.max(Number(val), 1);
                    }}
                  />
                  <Typography
                    sx={{ color: "#575757", fontWeight: 400 }}
                    variant="subtitle2"
                  >
                    Fast
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Map
              playbackLabels={playbackLabels}
              allSpeciments={allSpeciments}
              markers={allFilteredMarkers}
              zoom={
                zoomed === "none"
                  ? null
                  : {
                      xaxis: currentTab,
                      min: zoomed[0],
                      max: zoomed[1],
                    }
              }
              currentHover={
                hoveredIndex === "none"
                  ? null
                  : [
                      allFilteredMarkers[hoveredIndex][0],
                      allFilteredMarkers[hoveredIndex][1],
                    ]
              }
              currentPlayingIndex={
                playback.kind === "not-started" ? null : playback.index
              }
              selectedPositions={selectedPositions}
            />
          </Grid>
        )}
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              md:
                rideType === "indoor"
                  ? "100%"
                  : allFilteredMarkers.length === 0
                  ? "100%"
                  : "50%",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "flex-end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: isMobileScreen ? "column-reverse" : "row",
                }}
              >
                <FormControl
                  disabled={bikePositions.length === 0}
                  sx={{
                    marginTop: ".625rem",
                  }}
                >
                  {positionValue === "" && (
                    <InputLabel
                      shrink={false}
                      sx={{
                        fontSize: "14px",
                        display: "flex",
                      }}
                    >
                      Select Position
                    </InputLabel>
                  )}
                  <Select
                    value={positionValue}
                    onChange={handleChangeSelect}
                    sx={{
                      "& fieldset": {
                        border: "none",
                      },
                      "& .MuiSelect-icon": {
                        color: "#DD4F4A",
                      },
                      width: "13rem",
                    }}
                  >
                    <MenuItem value="">
                      <Typography>All</Typography>
                    </MenuItem>
                    {bikePositions.map((position) => (
                      <MenuItem key={position} value={position}>
                        {position}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box sx={{ display: "flex" }}>
                  <Tabs
                    onChange={handleTabsChange}
                    value={currentTab}
                    scrollButtons="auto"
                    textColor="inherit"
                    indicatorColor="primary"
                    TabIndicatorProps={{
                      style: { display: "none" },
                    }}
                    sx={{
                      "& .MuiTab-root": {
                        border: "1px solid #DD4F4A",
                        background: "#DD4F4A",
                        color: "#F7F3F0",
                        fontSize: "12px",
                        minHeight: "1.875rem",
                        marginTop: ".625rem",
                        padding: ".4375rem .625rem",
                        minWidth: "4.625rem",
                        borderRadius: ".375rem",
                        marginRight: ".625rem",
                      },
                      "& .Mui-selected": {
                        color: "#DD4F4A",
                        background: "#EFD1B6",
                        fontSize: "12px",
                        border: "1px solid #E57B77",
                        minHeight: "1.875rem",
                        minWidth: "4.625rem",
                        borderRadius: ".375rem",
                        marginRight: ".625rem",
                      },
                      paddingRight: ".9375rem",
                    }}
                  >
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
                  <IconButton
                    sx={{ color: "#DD4F4A", p: 0, mr: 3 }}
                    onClick={handlePopoverOpen}
                  >
                    <VisibilityOutlinedIcon />
                  </IconButton>
                  <Popover
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <ShowHideColumnsComponent
                      allColumns={allCharts.map((col) => col)}
                      defaultSelectedColumns={charts.map((col) => col)}
                      disabledCharts={disabledCharts}
                      onChange={(cols) =>
                        setCharts(allCharts.filter((col) => cols.includes(col)))
                      }
                    />
                  </Popover>
                </Box>
              </Box>
            </Box>

            {currentTab === "time" ? (
              <div key="time">
                {session.session_statistics.length !== 0 && (
                  <Chart
                    options={positionBrushChartOptions}
                    series={positionStancesChartSeries}
                    type="line"
                    height={113}
                    // ref={measuredRef}
                  />
                )}
                {charts.includes("Body Angle") && hasBodyAngle && (
                  <Chart
                    options={bodyAngleTimeChartOptions}
                    series={bodyAngleTimeChartSeries}
                    type="line"
                    height={113}
                  />
                )}
                {session.session_statistics.length !== 0
                  ? charts.includes("Speed") &&
                    hasSpeed && (
                      <Chart
                        options={speedTimeChartOptions}
                        series={speedTimeChartSeries}
                        type="line"
                        height={113}
                      />
                    )
                  : hasSpeed && (
                      <Chart
                        options={speedTimeChartOptions}
                        series={speedTimeChartSeries}
                        type="line"
                        height={113}
                      />
                    )}

                {charts.includes("Elevation") && hasElevation && (
                  <Box>
                    <Chart
                      options={elevationTimeChartOptions}
                      series={elevationTimeChartSeries}
                      type="line"
                      height={113}
                    />
                  </Box>
                )}
                {charts.includes("Heart Rate") && hasHeartRate && (
                  <Chart
                    options={heartRateTimeChartOptions}
                    series={heartRateTimeChartSeries}
                    type="line"
                    height={113}
                  />
                )}
                {charts.includes("Power") && hasPower && (
                  <Chart
                    options={powerTimeChartOptions}
                    series={powerTimeChartSeries}
                    type="line"
                    height={113}
                  />
                )}
                {charts.includes("Cadence") && hasCadence && (
                  <Chart
                    options={cadenceTimeChartOptions}
                    series={cadenceTimeChartSeries}
                    type="line"
                    height={113}
                  />
                )}
              </div>
            ) : (
              <div key="distance">
                {session.session_statistics.length !== 0 && (
                  <Chart
                    options={positionBrushDistanceChartOptions}
                    series={positionStancesDistanceChartSeries}
                    type="line"
                    height={113}
                  />
                )}
                {charts.includes("Body Angle") && hasBodyAngle && (
                  <Chart
                    options={bodyAngleDistanceChartOptions}
                    series={bodyAngleDistanceChartSeries}
                    type="line"
                    height={113}
                  />
                )}
                {session.session_statistics.length !== 0
                  ? charts.includes("Speed") &&
                    hasSpeed && (
                      <Chart
                        options={speedDistanceChartOptions}
                        series={speedDistanceChartSeries}
                        type="line"
                        height={113}
                      />
                    )
                  : hasSpeed && (
                      <Chart
                        options={speedDistanceChartOptions}
                        series={speedDistanceChartSeries}
                        type="line"
                        height={113}
                      />
                    )}
                {charts.includes("Elevation") && hasElevation && (
                  <>
                    <Chart
                      options={elevationDistanceChartOptions}
                      series={elevationDistanceChartSeries}
                      type="line"
                      height={113}
                    />
                  </>
                )}

                {charts.includes("Heart Rate") && hasHeartRate && (
                  <Chart
                    options={heartRateDistanceChartOptions}
                    series={heartRateDistanceChartSeries}
                    type="line"
                    height={113}
                  />
                )}
                {charts.includes("Power") && hasPower && (
                  <Chart
                    options={powerDistanceChartOptions}
                    series={powerDistanceChartSeries}
                    type="line"
                    height={113}
                  />
                )}

                {charts.includes("Cadence") && hasCadence && (
                  <Chart
                    options={cadenceDistanceChartOptions}
                    series={cadenceDistanceChartSeries}
                    type="line"
                    height={113}
                  />
                )}
              </div>
            )}
          </Box>
          {isMobileScreen && (
            <Box sx={{ display: "flex", justifyContent: "center", padding: 1 }}>
              <ScreenRotationIcon fontSize="large" />
            </Box>
          )}
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <CoachNotesComponent sessionId={session.id}/>
      </Box>
    </>
  );
}

export type ShowHideColumnsComponentProps = {
  allColumns: Array<string>;
  defaultSelectedColumns: Array<string>;
  disabledCharts: Array<string>;
  onChange: (selectedColumns: Array<string>) => void;
};

function ShowHideColumnsComponent(props: ShowHideColumnsComponentProps) {
  const [selectedColumns, setSelectedColumns] = useState(
    props.defaultSelectedColumns,
  );

  useEffect(() => {
    props.onChange(selectedColumns);
  }, [selectedColumns]);

  return (
    <Box sx={{ p: 1, background: "#201B20" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: "10px",
          mt: "16px",
        }}
      >
        {props.allColumns.map((option, index) => {
          const isDisabled = props.disabledCharts.includes(option);
          const isChecked = selectedColumns.includes(option);
          return (
            <Box
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                // cursor: "pointer",
                width: "20.625rem",
              }}
              onClick={(_) =>
                selectedColumns.includes(option)
                  ? setSelectedColumns(
                      selectedColumns.filter((op) => op !== option),
                    )
                  : setSelectedColumns(selectedColumns.concat([option]))
              }
            >
              <Checkbox
                checked={isChecked && !isDisabled}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: "#DD4F4A",
                  },
                }}
                disabled={isDisabled}
              />

              <ListItemText primary={option} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
