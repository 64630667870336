import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import CyclistPreload from "src/utils/CyclistPreload";
import { useUser } from ".";
import { TableRow, WithCache } from "src/contexts/CacheContext";
import { User } from "@supabase/supabase-js";

export const UnitsContext = createContext<"metric" | "imperial">(undefined);

export const useUnits = () => useContext(UnitsContext);

export default function CyclistWrapper(props: PropsWithChildren<{}>) {
  const user = useUser();
  return (
    <WithCache
      tables={["athlete", "notifications"]}
      component={(cached) => (
        <CyclistAuthenticated
          athlete={cached.athlete[0]}
          user={user}
          notifications={cached.notifications}
        >
          {props.children}
        </CyclistAuthenticated>
      )}
    />
  );
}

type CyclistProps = {
  athlete: TableRow<"athlete">;
  notifications: Array<TableRow<"notifications">>;
  user: User;
};

function CyclistAuthenticated(props: PropsWithChildren<CyclistProps>) {
  const units = useMemo(
    () => (props.athlete.units ? "imperial" : "metric"),
    [props.athlete.units],
  );

  const isCyclist = useMemo(
    () => props.user?.user_metadata?.accountType === "cyclist" ?? false,
    [props.user],
  );

  return (
    <>
      {isCyclist ? (
        <UnitsContext.Provider value={units}>
          <CyclistPreload notifications={props.notifications}>
            {props.children}
          </CyclistPreload>
        </UnitsContext.Provider>
      ) : (
        <></>
      )}
    </>
  );
}
