import { Box, Typography, Button, useTheme } from "@mui/material";
import Check from "../../../assets/img/views/settings/checkfeature.svg";

export type SubscriptionComponentProps = {
  sub_type: string;
  features: string[];
  features_info: string;
  pricing: string;
  pricing_info?: string;
  buttonText: string;
};

export default function SubscriptionComponent(
  props: SubscriptionComponentProps,
) {
  const theme = useTheme();
  const breakpoint = theme.breakpoints.down("md");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#342C35",
        borderRadius: "6px",
        paddingX: "3.5em",
        paddingY: "2em",
        marginTop: "1rem",
        [breakpoint]: {
          width: "85%",
          margin: "1rem auto 0",
        },
      }}
    >
      <Typography
        sx={{
          color: "#BC6D29",
          fontSize: "1.25rem",
          fontWeight: 700,
          marginBottom: "20px",
        }}
      >
        {props.sub_type}
      </Typography>
      {props.features.map((feature, index) => (
        <Box
          key={index}
          sx={{ display: "flex", gap: 1, marginBottom: ".5rem" }}
        >
          <img src={Check} alt="checked-icon" />
          <Typography variant="h4">{feature}</Typography>
        </Box>
      ))}
      <Typography
        variant="body2"
        marginBottom={".6875rem"}
        marginTop={props.sub_type === "Basic" ? "1.125rem" : "2.7rem"}
        sx={{
          textDecoration: "underline",
          alignSelf: "center",
          color: "white",
        }}
        component={"a"}
        rel="noreferrer"
        target="_blank"
        href={"https://darefore.com/plans"}
      >
        {props.features_info}
      </Typography>
      <Typography
        variant="h2"
        marginBottom={"1rem"}
        sx={{ fontWeight: "400", alignSelf: "center" }}
      >
        {props.pricing}
      </Typography>
      <Typography
        variant="subtitle2"
        marginBottom={props.sub_type === "Premium" ? "1rem" : "2rem"}
        textAlign={"center"}
      >
        {props.pricing_info}
      </Typography>
      <Button
        variant={props.sub_type === "Premium" ? "contained" : "customDisabled"}
        disabled={props.sub_type === "Premium" ? false : true}
      >
        {props.buttonText}
      </Button>
    </Box>
  );
}
