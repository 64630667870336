import { useCallback, useEffect, useMemo, useState } from "react";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import type { ApexOptions } from "apexcharts";
import {
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { withReference } from "src/utils/common";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import { Database } from "src/utils/DatabaseDefinitions";
import PreloadComponent from "src/utils/PreloadComponent";
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";

type Month =
  | "Jan"
  | "Feb"
  | "Mar"
  | "Apr"
  | "May"
  | "Jun"
  | "Jul"
  | "Aug"
  | "Sep"
  | "Oct"
  | "Nov"
  | "Dec";

type Day = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";

const days: Array<Day> = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const months: Array<Month> = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

type Week = { [K in Day]: { [P in Averages]: number } };

export const averages = [
  "Speed",
  "Power",
  "Heart Rate",
  "Cadence",
  "Duration",
  "Distance",
] as const;

export type Averages = typeof averages[number];

export type CoachPerformanceProps = {
  sessions: Pick<
    Database["public"]["Tables"]["session"]["Row"],
    | "total_distance"
    | "average_speed"
    | "average_power"
    | "average_cadence"
    | "average_heart_rate"
    | "total_time"
    | "date"
  >[];
};

export type PreloadProps = {
  userId: string;
};

export default function CoachPerformanceCall(props: PreloadProps) {
  return (
    <>
      <PreloadComponent<{
        session: Pick<
          Database["public"]["Tables"]["session"]["Row"],
          | "total_distance"
          | "average_speed"
          | "average_power"
          | "average_cadence"
          | "average_heart_rate"
          | "total_time"
          | "date"
        >[];
      }>
        promises={{
          session: async (supabase) =>
            supabase
              .from("session")
              .select(
                "total_distance , average_speed , average_power , average_cadence , average_heart_rate , total_time  , date",
              )
              .order("date", { ascending: false })
              .eq("athlete_id", props.userId)
              .then((res) => res.data),
        }}
        component={(props) => (
          <>
            <CoachPerformanceChart sessions={props.session} />
          </>
        )}
      />
    </>
  );
}

function CoachPerformanceChart(props: CoachPerformanceProps) {
  const theme = useTheme();
  const units = useUnits();
  const [currentPeriod, setCurrentPeriod] = useState<
    "7 Days" | "4 Weeks" | "6 Months" | "1 Year"
  >("1 Year");

  const updateIfNotZeroOrNull = useCallback(
    (sumObject, countObject, month, property: string, value: number) => {
      if (value !== 0 && value !== null) {
        sumObject[month][property] += value;
        countObject[month][property] += 1;
      }
    },
    [],
  );

  const timeFormatter = (valStr) => {
    const val = Number(valStr);
    const hours = Math.floor(Math.floor(val / 60) / 60);
    const minutes = Math.trunc((val % 3600) / 60);
    const seconds = Math.trunc(val % 60);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const [rangeHigh, setRangeHigh] = useState(dayjs());

  // Calculation for our rangeLow Date
  const rangeLow = useMemo(
    () =>
      withReference(
        currentPeriod === "4 Weeks"
          ? ([27, "days"] as const)
          : currentPeriod === "7 Days"
          ? ([6, "days"] as const)
          : currentPeriod === "1 Year"
          ? ([11, "month"] as const)
          : ([5, "month"] as const),
        (args) => rangeHigh.subtract(args[0], args[1]),
      ),
    [rangeHigh, currentPeriod],
  );

  const periods = useMemo(
    () => [
      {
        value: "7 Days",
        label: "7 Days",
      },
      {
        value: "4 Weeks",
        label: "4 Weeks",
      },
      {
        value: "6 Months",
        label: "6 Months",
      },
      {
        value: "1 Year",
        label: "1 Year",
      },
    ],
    [],
  );

  const handleTabsPeriodChange = useCallback(
    (_, value: "7 Days" | "4 Weeks" | "6 Months" | "1 Year"): void => {
      setCurrentPeriod(value);
    },
    [],
  );
  const handleCursor = useCallback(
    (opt: "prev" | "next") =>
      setRangeHigh((hi) =>
        withReference(
          currentPeriod === "4 Weeks"
            ? ([27, "days"] as const)
            : currentPeriod === "7 Days"
            ? ([1, "week"] as const)
            : currentPeriod === "1 Year"
            ? ([11, "month"] as const)
            : ([5, "month"] as const),
          (args) => hi[opt === "prev" ? "subtract" : "add"](args[0], args[1]),
        ),
      ),
    [currentPeriod],
  );

  const filterSessions = useMemo(
    () =>
      props.sessions.filter((session) =>
        currentPeriod === "1 Year" || currentPeriod === "6 Months"
          ? rangeLow.isSameOrBefore(new Date(session.date), "month") &&
            rangeHigh.isSameOrAfter(new Date(session.date), "month")
          : rangeLow.isSameOrBefore(new Date(session.date), "day") &&
            rangeHigh.isSameOrAfter(new Date(session.date), "day"),
      ),
    // .filter(
    //   (session) =>
    //     !!session.statistics && Object.keys(session.statistics).length > 2,
    // ),

    [rangeLow, rangeHigh],
  );

  //Chart labels. Finding the correct starting month
  const categories = useMemo(() => {
    if (currentPeriod == "1 Year" || currentPeriod == "6 Months") {
      const indexOfMonth = rangeLow.month();
      const months: Array<Month> = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return currentPeriod == "1 Year"
        ? [...months.slice(indexOfMonth), ...months.slice(0, indexOfMonth)]
        : [
            ...months.slice(indexOfMonth),
            ...months.slice(0, indexOfMonth),
          ].slice(0, 6);
    } else if (currentPeriod == "7 Days" || currentPeriod == "4 Weeks") {
      const indexOfDay = rangeLow.day();

      const days: Array<Day> = [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
      ];
      return [...days.slice(indexOfDay), ...days.slice(0, indexOfDay)];
    } else return [];
  }, [rangeLow, currentPeriod]);

  const buckets: { [K in Month]: { [P in Averages]: number } } = useMemo(() => {
    if (currentPeriod == "1 Year" || currentPeriod == "6 Months") {
      const sumObject = Object.fromEntries(
        categories.map((cat) => [
          cat,
          Object.fromEntries(averages.map((pos: Averages) => [pos, 0])),
        ]),
      ) as { [K in Month]: { [P in Averages]: number } };
      const countObject = Object.fromEntries(
        categories.map((cat) => [
          cat,
          Object.fromEntries(averages.map((pos: Averages) => [pos, 0])),
        ]),
      ) as { [K in Month]: { [P in Averages]: number } };

      for (const session of filterSessions) {
        const month = months[new Date(session.date).getMonth()];

        updateIfNotZeroOrNull(
          sumObject,
          countObject,
          month,
          "Speed",
          session.average_speed,
        );
        updateIfNotZeroOrNull(
          sumObject,
          countObject,
          month,
          "Power",
          session.average_power,
        );
        updateIfNotZeroOrNull(
          sumObject,
          countObject,
          month,
          "Heart Rate",
          session.average_heart_rate,
        );
        updateIfNotZeroOrNull(
          sumObject,
          countObject,
          month,
          "Cadence",
          session.average_cadence,
        );
        updateIfNotZeroOrNull(
          sumObject,
          countObject,
          month,
          "Duration",
          session.total_time,
        );
        updateIfNotZeroOrNull(
          sumObject,
          countObject,
          month,
          "Distance",
          session.total_distance,
        );
      }

      return Object.fromEntries(
        Object.entries(sumObject).map(([month, val]) => [
          month,
          Object.fromEntries(
            Object.entries(val).map(([property, val]) => [
              property,
              val === 0 ? val : (val / countObject[month][property]).toFixed(1),
            ]),
          ),
        ]),
      ) as { [K in Month]: { [P in Averages]: number } };
    } else {
      return {} as { [K in Month]: { [P in Averages]: number } };
    }
  }, [categories, filterSessions]);

  const day_buckets: { [K in Day]: { [P in Averages]: number } } =
    useMemo(() => {
      if (currentPeriod == "7 Days") {
        const dayObject = Object.fromEntries(
          categories.map((day) => [
            day,
            Object.fromEntries(averages.map((pos: Averages) => [pos, 0])),
          ]),
        ) as { [K in Day]: { [P in Averages]: number } };

        const countObject = Object.fromEntries(
          categories.map((day) => [
            day,
            Object.fromEntries(averages.map((pos: Averages) => [pos, 0])),
          ]),
        ) as { [K in Day]: { [P in Averages]: number } };

        for (const session of filterSessions) {
          const day = days[new Date(session.date).getDay()];

          updateIfNotZeroOrNull(
            dayObject,
            countObject,
            day,
            "Speed",
            session.average_speed,
          );
          updateIfNotZeroOrNull(
            dayObject,
            countObject,
            day,
            "Power",
            session.average_power,
          );
          updateIfNotZeroOrNull(
            dayObject,
            countObject,
            day,
            "Heart Rate",
            session.average_heart_rate,
          );
          updateIfNotZeroOrNull(
            dayObject,
            countObject,
            day,
            "Cadence",
            session.average_cadence,
          );
          updateIfNotZeroOrNull(
            dayObject,
            countObject,
            day,
            "Duration",
            session.total_time,
          );
          updateIfNotZeroOrNull(
            dayObject,
            countObject,
            day,
            "Distance",
            session.total_distance,
          );
        }
        return Object.fromEntries(
          Object.entries(dayObject).map(([day, val]) => [
            day,
            Object.fromEntries(
              Object.entries(val).map(([property, val]) => [
                property,
                val === 0 ? val : (val / countObject[day][property]).toFixed(1),
              ]),
            ),
          ]),
        ) as { [K in Day]: { [P in Averages]: number } };
      } else return {} as { [K in Day]: { [P in Averages]: number } };
    }, [categories, filterSessions]);

  const week_buckets: { [W in 1 | 2 | 3 | 4]: Week } = useMemo(() => {
    if (currentPeriod == "4 Weeks") {
      const emptyDayObject = Object.fromEntries(
        categories.map((day) => [
          day,
          Object.fromEntries(averages.map((pos: Averages) => [pos, 0])),
        ]),
      ) as { [K in Day]: { [P in Averages]: number } };

      const week_buckets: { [W in 1 | 2 | 3 | 4]: Week } = {
        1: { ...emptyDayObject },
        2: { ...emptyDayObject },
        3: { ...emptyDayObject },
        4: { ...emptyDayObject },
      };

      for (let i = 1; i < 5; i++) {
        const date = rangeLow.add((i - 1) * 7, "day");
        // Filtering Sessions for Each Week
        const weekSession = filterSessions.filter(
          (session) =>
            rangeLow
              .add((i - 1) * 7, "day")
              .isSameOrBefore(new Date(session.date), "day") &&
            rangeLow
              .add(i * 7 - 1, "day")
              .isSameOrAfter(new Date(session.date), "day"),
        );

        const dayObject = Object.fromEntries(
          categories.map((day) => [
            day,
            Object.fromEntries(averages.map((pos: Averages) => [pos, 0])),
          ]),
        ) as { [K in Day]: { [P in Averages]: number } };

        const countObject = Object.fromEntries(
          categories.map((day) => [
            day,
            Object.fromEntries(averages.map((pos: Averages) => [pos, 0])),
          ]),
        ) as { [K in Day]: { [P in Averages]: number } };

        for (const session of weekSession) {
          const day = days[new Date(session.date).getDay()];

          updateIfNotZeroOrNull(
            dayObject,
            countObject,
            day,
            "Speed",
            session.average_speed,
          );
          updateIfNotZeroOrNull(
            dayObject,
            countObject,
            day,
            "Power",
            session.average_power,
          );
          updateIfNotZeroOrNull(
            dayObject,
            countObject,
            day,
            "Heart Rate",
            session.average_heart_rate,
          );
          updateIfNotZeroOrNull(
            dayObject,
            countObject,
            day,
            "Cadence",
            session.average_cadence,
          );
          updateIfNotZeroOrNull(
            dayObject,
            countObject,
            day,
            "Duration",
            session.total_time,
          );
          updateIfNotZeroOrNull(
            dayObject,
            countObject,
            day,
            "Distance",
            session.total_distance,
          );
        }

        const lastObject = Object.fromEntries(
          Object.entries(dayObject).map(([day, val]) => [
            day,
            Object.fromEntries(
              Object.entries(val).map(([property, val]) => [
                property,
                val === 0 ? val : (val / countObject[day][property]).toFixed(1),
              ]),
            ),
          ]),
        ) as { [K in Day]: { [P in Averages]: number } };

        week_buckets[i] = lastObject;
        delete Object.assign(week_buckets, {
          [date.format("DD/MM")]: week_buckets[i],
        })[i];
      }

      return week_buckets;
    } else return {} as { [W in 1 | 2 | 3 | 4]: Week };
  }, [categories, filterSessions]);

  useEffect(() => {
    if (currentPeriod !== "4 Weeks") {
      ApexCharts.exec("AveragesChart", "updateOptions", {
        xaxis: {
          categories,
          tickAmount: undefined,
          overwriteCategories: undefined,
        },
      });
    } else {
      ApexCharts.exec("AveragesChart", "updateOptions", {
        xaxis: {
          categories: [],
          tickAmount: 3,
          overwriteCategories: Object.keys(week_buckets).reduce((arr, key) => {
            return arr.concat(key, "", "", "", "", "", "", "");
          }, []),
        },
      });
    }
  }, [currentPeriod, categories]);

  const createChartOptions = useCallback(
    (id: string): ApexOptions => ({
      chart: {
        id,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        background: "transparent",
      },
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#FF5630",
          fontSize: "14px",
          fontFamily: undefined,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      theme: {
        mode: theme.palette.mode,
      },
      fill: {
        opacity: 0,
        type: "solid",
      },
      markers: {
        hover: {
          sizeOffset: 2,
        },
        shape: "circle",
        size: 3,
        strokeWidth: 3,
        strokeOpacity: 1,
      },
      tooltip: {
        // shared: true,
        // y: [
        //   {
        //     formatter: function (value: number) {
        //       if (typeof value !== "undefined") {
        //         return value.toFixed(1) + " kph";
        //       }
        //       return value;
        //     },
        //   },
        //   {
        //     formatter: function (value: number) {
        //       if (typeof value !== "undefined") {
        //         return value.toFixed(0) + " W";
        //       }
        //       return value;
        //     },
        //   },
        //   {
        //     formatter: function (value: number) {
        //       if (typeof value !== "undefined") {
        //         return value.toFixed(0) + " bpm";
        //       }
        //       return value;
        //     },
        //   },
        //   {
        //     formatter: function (value: number) {
        //       if (typeof value !== "undefined") {
        //         return value.toFixed(0) + " rpm";
        //       }
        //       return value;
        //     },
        //   },
        //   {
        //     formatter: function (value: number) {
        //       return timeFormatter(value / 1000);
        //     },
        //   },
        //   {
        //     formatter: function (value: number) {
        //       if (typeof value !== "undefined") {
        //         return value.toFixed(1) + " km";
        //       }
        //       return value;
        //     },
        //   },
        // ],
        x: {
          show: false,
        },
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
          const hoverIndexes = w.globals.seriesX.map((seriesX) => {
            return seriesX.findIndex((xData) => xData === hoverXaxis);
          });

          let hoverList = "";
          hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
            if (hoverIndex >= 0) {
              if (series[seriesEachIndex][hoverIndex] !== undefined)
                hoverList += `
                        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                            <span class="apexcharts-tooltip-marker" style="background-color: ${
                              w.globals.markers.colors[seriesEachIndex]
                            };"></span>
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                <div class="apexcharts-tooltip-y-group">
                                    <span class="apexcharts-tooltip-text-y-label">${
                                      w.globals.seriesNames[seriesEachIndex]
                                    }: </span>
                                    <span class="apexcharts-tooltip-text-y-value">${w.globals.yLabelFormatters[
                                      seriesEachIndex
                                    ](
                                      series[seriesEachIndex][hoverIndex],
                                    )}</span>
                                </div>
                            </div>
                        </div>`;
            }
          });
          return `${hoverList}`;
        },
      },
      legend: {
        labels: {
          colors: "#ffffff",
        },
        horizontalAlign: "left",
        itemMargin: {
          horizontal: 20,
        },
        offsetY: 10,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        row: {
          opacity: 0.1,
        },
        show: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#64605D",
          },
        },
        tooltip: {
          enabled: false, // Hide the small tooltip for categories
        },
      },
      yaxis: [
        {
          //   min: 0,
          //   max: (val) => Math.ceil(val),
          show: false,
          labels: {
            formatter: function (value: number) {
              if (typeof value !== "undefined") {
                return value.toFixed(1) + " kph";
              }
              return value;
            },
          },
        },
        {
          // min: 75,
          show: false,
          labels: {
            formatter: function (value: number) {
              if (typeof value !== "undefined") {
                return value.toFixed(0) + " W";
              }
              return value;
            },
          },
        },
        {
          // min: 100,
          show: false,
          labels: {
            formatter: function (value: number) {
              if (typeof value !== "undefined") {
                return value.toFixed(0) + " bpm";
              }
              return value;
            },
          },
        },
        {
          // min: 50,
          show: false,
          labels: {
            formatter: function (value: number) {
              if (typeof value !== "undefined") {
                return value.toFixed(0) + " rpm";
              }
              return value;
            },
          },
        },
        {
          show: false,
          labels: {
            formatter: (val) => timeFormatter(val / 1000), //Duration
          },
        },
        {
          show: false,
          labels: {
            formatter: function (value: number) {
              if (typeof value !== "undefined") {
                return units === "metric"
                  ? `${value.toFixed(2) ?? "0"} km`
                  : `${
                      value
                        ? convert(value)
                            .from("km")
                            .to("mi")
                            .toFixed(2)
                        : "0"
                    } mi`
              }
              return value;
            },
          },
        },
      ],
      colors: [
        "#BC6D29",
        "#D9D9D9",
        "#DD4F4A",
        "#B1A4B3",
        "#E28E54",
        "#797979",
      ],
    }),
    [averages],
  );

  const chartOptions = useMemo(
    () => createChartOptions("AveragesChart"),
    [day_buckets, buckets, filterSessions],
  );

  const chartSeries: ApexAxisChartSeries = useMemo(() => {
    if (currentPeriod == "1 Year" || currentPeriod == "6 Months") {
      const properties = Object.keys(buckets[categories[0]]);

      const series = properties.map((property) => {
        const data = categories.map((category) => {
          return buckets[category][property];
        });
        return {
          name: property,
          data,
        };
      });
      return series;
    } else if (currentPeriod == "7 Days") {
      const properties = Object.keys(day_buckets[categories[0]]);
      const series = properties.map((property) => {
        const data = categories.map((category) => {
          return day_buckets[category][property];
        });
        return {
          name: property,
          data,
        };
      });
      return series;
    } else if (currentPeriod == "4 Weeks") {
      const weeksArray = Object.values(week_buckets);
      const combinedWeeks = weeksArray.reduce((acc, week) => {
        Object.keys(week).forEach((day) => {
          Object.keys(week[day]).forEach((property) => {
            if (!acc[property]) {
              acc[property] = [];
            }
            acc[property].push(week[day][property]);
          });
        });
        return acc;
      }, {});
      const series = Object.entries(combinedWeeks).map(([property, val]) => {
        return {
          name: property,
          data: val as any,
        };
      });
      return series;
    } else return [];
  }, [day_buckets, buckets, filterSessions]);

  return (
    <Box
      sx={{
        background: "#201B20",
        borderRadius: ".625rem",
        width: "98%",
        marginTop: "2rem",
        padding: "1em",
      }}
    >
      <Grid container justifyContent={"space-between"}>
        <Grid item alignSelf={"flex-end"}>
          <Typography>PERFORMANCE PROGRESS</Typography>
          <Grid item>
            <Typography sx={{ opacity: 0.5 }}>
              {currentPeriod == "1 Year"
                ? "Average metrics over the last year"
                : currentPeriod == "6 Months"
                ? "Average metrics over the last six months"
                : currentPeriod == "4 Weeks"
                ? "Average metrics over the last four weeks"
                : currentPeriod == "7 Days"
                ? "Average metrics over the last seven days"
                : ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ display: "flex" }}>
          <IconButton
            onClick={(_) => handleCursor("prev")}
            sx={{ color: "#DD4F4A" }}
          >
            <ArrowBackTwoToneIcon fontSize="small" />
          </IconButton>
          <Typography sx={{ opacity: 0.5, alignSelf: "center" }}>
            {currentPeriod === "1 Year" || currentPeriod === "6 Months"
              ? `${rangeLow.format("MMM YYYY")} - ${rangeHigh.format(
                  "MMM YYYY",
                )}`
              : `${rangeLow.format("DD MMM YYYY")} - ${rangeHigh.format(
                  "DD MMM YYYY",
                )}`}
          </Typography>
          <IconButton
            color="primary"
            onClick={(_) => handleCursor("next")}
            sx={{ color: "#DD4F4A" }}
          >
            <ArrowForwardTwoToneIcon fontSize="small" />
          </IconButton>
        </Grid>

        <Grid item>
          <Tabs
            onChange={handleTabsPeriodChange}
            value={currentPeriod}
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            sx={{
              "& .MuiTab-root": {
                border: ".0625rem solid #64605D",
                color: "#64605D",
                fontSize: ".75rem",
                borderRadius: "6px",
                minHeight: "30px",
                marginTop: "10px",
                padding: "7px 10px",
                minWidth: "50px",
              },
              "& .Mui-selected": {
                color: "#DD4F4A !important",
                fontSize: ".75rem",
                border: ".0625rem solid #DD4F4A",
                borderRadius: "6px",
                minHeight: "30px",
              },
            }}
          >
            {periods.map((period) => (
              <Tab
                key={period.value}
                label={period.label}
                value={period.value}
                sx={{ marginLeft: "10px" }}
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>

      <Box sx={{ height: { xl: "750px", xs: "500px" } }}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="area"
          height={"88%"}
        />
      </Box>
    </Box>
  );
}