import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  Select,
  styled,
  Slider,
  FormGroup,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useCallback, useEffect, useMemo } from "react";
import {
  heartRateZonesFromMaxHeartRate,
  powerZonesFromFTP,
  type SupabaseCall,
  withReference,
} from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import useAsyncState, {
  isFulfilled,
  isPending,
  isRejected,
  isUnloaded,
} from "src/utils/Async";
import { useSnackbar } from "notistack";
import infoicon from "../../../assets/img/views/profile-setup/info-icon.svg";
import slider from "../../../assets/img/views/profile-setup/slider.svg";
import type { TableRow } from "src/contexts/CacheContext";

const DisabledSlider = styled(Slider)(({ theme }) => ({
  color: "#3a8589",
  height: 3,
  padding: ".8125rem 0",
  width: "30rem",
  "& .MuiSlider-thumb": {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    border: ".0625rem solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 .5rem rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: "gray",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

export type AtheleteViewProps = {
  ftp: number;
  lthr: number;
  max_heart_rate: number;
  heart_rate_zones: Array<number>;
  power_zones: Array<number>;
  experience_level: number;
  trainingGoals: Array<number>;
};

export type AthleteViewProps = {
  experienceLevels: Array<TableRow<"athlete_experience_level">>;
  athleteTrainingGoals: Array<TableRow<"athlete_training_goals">>;
  trainingGoals: Array<TableRow<"training_goals">>;
  athlete: TableRow<"athlete">;
};

export default function AtheleteView(props: AthleteViewProps) {
  const supabase = useSupabase();
  const user = useUser();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        ftp: yup
          .number()
          .min(50, "FTP cannot be lower than 50W")
          .max(600, "FTP cannot be greater than 600W")
          .test("FTP is required", "FTP is required", (value) => !!value),
        lthr: yup
          .number()
          .min(60, "LTHR cannot be lower than 60bpm")
          .max(230, "LTHR rate cannot be greater than 230bpm")
          .nullable(),
        // .test("LTHR is required", "LTHR is required", (value) => !!value),
        max_heart_rate: yup
          .number()
          .required("Maximum heart rate is required")
          .min(60, "Max heart rate cannot be lower than 60bpm")
          .max(230, "Max heart rate cannot be greater than 230bpm")
          .test(
            "Maximum heart rate is required",
            "Field is required",
            (value) => !!value,
          ),
      }),
    [],
  );

  const formik = useFormik<AtheleteViewProps>({
    initialValues: {
      max_heart_rate: undefined,
      lthr: undefined,
      ftp: undefined,
      heart_rate_zones: [],
      power_zones: [],
      experience_level: undefined,
      trainingGoals: [],
    },
    validationSchema,
    onSubmit: (values, formikBag) => {
      if ("fire" in saveProfileSettingsCall) {
        saveProfileSettingsCall.fire(async () =>
          saveProfileSettingsPromise(values),
        );
      }

      const startingStateTrainingGoals = props.athleteTrainingGoals.map(
        (res) => res.training_goal_id,
      );

      const toDelete = startingStateTrainingGoals.filter(
        (id) => !values.trainingGoals.includes(id),
      );

      const toAdd = values.trainingGoals.filter(
        (id) => !startingStateTrainingGoals.includes(id),
      );

      if ("fire" in saveAthTrainingGoalsCall) {
        saveAthTrainingGoalsCall.fire(async () =>
          saveAthTrainingGoalsPromise(toAdd, toDelete),
        );
      }
      formikBag.setSubmitting(false);
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      "trainingGoals",
      props.athleteTrainingGoals.map((res) => res.training_goal_id),
    );
  }, [props.athleteTrainingGoals]);

  const saveAthTrainingGoalsPromise = useCallback(
    (toAdd: Array<number>, toDelete: Array<number>) =>
      supabase
        .from("athlete_training_goals")
        .delete()
        .in("training_goal_id", toDelete)
        .throwOnError()
        .then((_) =>
          supabase
            .from("athlete_training_goals")
            .insert(
              toAdd.map((id) => ({
                athlete_id: user.id,
                training_goal_id: id,
              })),
            )
            .throwOnError(),
        ),
    [supabase],
  );

  const saveAthTrainingGoalsCall =
    useAsyncState<SupabaseCall<typeof saveAthTrainingGoalsPromise>>();

  useEffect(() => {
    formik.setFieldValue("max_heart_rate", props.athlete.max_heart_rate);
    formik.validateField("max_heart_rate");

    formik.setFieldValue("lthr", props.athlete.lthr);
    formik.validateField("lthr");

    formik.setFieldValue("ftp", props.athlete.ftp);
    formik.validateField("ftp");

    formik.setFieldValue(
      "heart_rate_zones",
      props.athlete.heart_rate_zones.map((zone) =>
        Number((zone * props.athlete.max_heart_rate).toFixed(0)),
      ),
    );
    // formik.validateField("heart_rate_zones");

    formik.setFieldValue(
      "power_zones",
      props.athlete.power_zones.map((zone) =>
        Number((zone * props.athlete.ftp).toFixed(0)),
      ),
    );
    // formik.validateField("power_zones");

    formik.setFieldValue("experience_level", props.athlete.experience_level);
    // formik.validateField("experience_level");
  }, [props.athlete]);

  const saveProfileSettingsPromise = useCallback(
    (values: AtheleteViewProps) =>
      supabase
        .from("athlete")
        .update({
          max_heart_rate: values.max_heart_rate,
          lthr: !values.lthr ? null : values.lthr,
          ftp: values.ftp,
          heart_rate_zones: values.heart_rate_zones.map(
            (z) => z / values.max_heart_rate,
          ),
          power_zones: values.power_zones.map((z) => z / values.ftp),
          experience_level: values.experience_level,
        })
        .eq("user_id", user.id)
        .then((res) => res.data),
    [supabase],
  );

  const saveProfileSettingsCall =
    useAsyncState<SupabaseCall<typeof saveProfileSettingsPromise>>();

  useEffect(() => {
    if (isRejected(saveProfileSettingsCall)) {
    }
  }, [saveProfileSettingsCall]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isFulfilled(saveProfileSettingsCall)) {
      enqueueSnackbar("Settings saved successfully", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [saveProfileSettingsCall]);

  const heartRateMarks = useMemo(() => {
    if (
      !formik.values.heart_rate_zones ||
      formik.values.max_heart_rate === 0 ||
      (formik.values.max_heart_rate as any) === ""
    ) {
      return undefined;
    }

    let res = [];
    let prev = formik.values.max_heart_rate * 0.5;
    for (const val of formik.values.heart_rate_zones) {
      res.push((prev + val) / 2);
      prev = val;
    }
    res.push((prev + formik.values.max_heart_rate) / 2);
    return res.map((value, i) => ({
      label: `Z${i + 1}`,
      value,
    }));
  }, [formik.values.heart_rate_zones, formik.values.max_heart_rate]);

  const powerMarks = useMemo(() => {
    if (
      !formik.values.power_zones ||
      formik.values.ftp === 0 ||
      (formik.values.ftp as any) === ""
    ) {
      return undefined;
    }

    let res = [];
    let prev = 0;
    for (const val of formik.values.power_zones) {
      res.push((prev + val) / 2);
      prev = val;
    }
    res.push((prev + formik.values.ftp * 1.5) / 2);
    return res.map((value, i) => ({
      label: `Z${i + 1}`,
      value,
    }));
  }, [formik.values.power_zones, formik.values.ftp]);

  const heart_rate_zones_colors = useMemo(
    () =>
      withReference(formik.values.max_heart_rate / 2, (halfHR) =>
        formik.values.heart_rate_zones.map(
          (zone) => ((zone - halfHR) / halfHR) * 100,
        ),
      ),
    [formik.values.heart_rate_zones, formik.values.max_heart_rate],
  );

  const power_zones_colors = useMemo(
    () =>
      formik.values.power_zones.map(
        (zone) => (zone / (formik.values.ftp * 1.5)) * 100,
      ),
    [formik.values.power_zones, formik.values.ftp],
  );

  useEffect(() => {
    if (formik.touched.max_heart_rate && !formik.isSubmitting) {
      formik.setFieldValue(
        "heart_rate_zones",
        heartRateZonesFromMaxHeartRate(formik.values.max_heart_rate),
      );
    }
  }, [formik.values.max_heart_rate, formik.touched.max_heart_rate]);

  useEffect(() => {
    if (formik.touched.ftp && !formik.isSubmitting) {
      formik.setFieldValue("power_zones", powerZonesFromFTP(formik.values.ftp));
    }
  }, [formik.values.ftp, formik.touched.ftp]);

  const theme = useTheme();
  const breakpoint = theme.breakpoints.down("md");
  const isMobileScreen = useMediaQuery("(max-width:480px)");

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            width: "98%",
            background: "#201B20",
            borderRadius: "10px",
            paddingY: "1.5em",
            paddingX: isMobileScreen ? "1rem" : "2rem",
            minHeight: "70vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: ".8125rem" }}>
              ATHLETE INFORMATION
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                padding: "2em",
                [breakpoint]: {
                  flexDirection: "column",
                  gap: 2,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  [breakpoint]: {
                    margin: "0 auto",
                    marginBottom: "20px",
                  },
                }}
              >
                <FormControl>
                  <TextField
                    name="max_heart_rate"
                    label="Max Heart Rate (bpm)"
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    onBlur={formik.handleBlur}
                    value={formik.values.max_heart_rate ?? ""}
                    error={Boolean(
                      formik.touched.max_heart_rate &&
                        formik.errors.max_heart_rate,
                    )}
                    helperText={
                      formik.touched.max_heart_rate &&
                      formik.errors.max_heart_rate
                    }
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    name="lthr"
                    label={"LTHR (BPM)"}
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    onBlur={formik.handleBlur}
                    value={formik.values.lthr ?? ""}
                    error={Boolean(formik.touched.lthr && formik.errors.lthr)}
                    helperText={formik.touched.lthr && formik.errors.lthr}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    name="ftp"
                    label={"FTP (W)"}
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    onBlur={formik.handleBlur}
                    value={formik.values.ftp ?? ""}
                    error={Boolean(formik.touched.ftp && formik.errors.ftp)}
                    helperText={formik.touched.ftp && formik.errors.ftp}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontSize: ".875rem",
                      fontWeight: "400",
                      opacity: 0.5,
                    }}
                  >
                    Training Goal
                  </Typography>

                  <FormGroup>
                    {props.trainingGoals.map((datum) => (
                      <FormControlLabel
                        key={datum.id}
                        control={
                          <Checkbox
                            size="small"
                            name="trainingGoals"
                            value={datum.id}
                            onChange={(_) =>
                              formik.setFieldValue(
                                "trainingGoals",
                                formik.values.trainingGoals.includes(datum.id)
                                  ? formik.values.trainingGoals.filter(
                                      (goal) => goal !== datum.id,
                                    )
                                  : formik.values.trainingGoals.concat([
                                      datum.id,
                                    ]),
                              )
                            }
                            checked={
                              formik.values.trainingGoals?.includes(datum.id) ??
                              false
                            }
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              color: "#BC6D29",
                              fontWeight: 400,
                              fontSize: ".875rem",
                            }}
                          >
                            {datum.goal}
                          </Typography>
                        }
                      />
                    ))}
                  </FormGroup>
                </Box>

                <FormControl>
                  <InputLabel id="experience_level">
                    Level of Experience
                  </InputLabel>

                  <Select
                    id="experience_level"
                    name="experience_level"
                    value={formik.values.experience_level ?? ""}
                    onChange={formik.handleChange}
                    label="Level of Experience"
                  >
                    {props.experienceLevels.map((level) => (
                      <MenuItem key={level.id} value={level.id}>
                        {level.experience_level}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ width: "50%", [breakpoint]: { width: "100%" } }}>
                {withReference(
                  (Boolean(formik.errors.max_heart_rate) &&
                    formik.touched.max_heart_rate) ||
                    !formik.values.max_heart_rate ||
                    formik.values.max_heart_rate === 0 ||
                    (formik.values.max_heart_rate as any) === "",
                  (disabled) => (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            variant="h4"
                            color="text.primary"
                            sx={{ opacity: "0.5" }}
                          >
                            Heart Rate Zones (BPM)
                          </Typography>
                          <Tooltip
                            title="Your HR training zones are determined using the most common method, i.e. on the % of your maximum heart rate. You can always customize your zones."
                            placement="right"
                            arrow
                          >
                            <img
                              src={infoicon}
                              alt="info_icon"
                              width="10"
                              height="11"
                            />
                          </Tooltip>
                        </Box>

                        {disabled ? (
                          <Typography
                            variant="h4"
                            color="text.primary"
                            sx={{ opacity: "0.3" }}
                          >
                            (Set Max Heart Rate to unlock)
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Box>
                      {!disabled ? (
                        <Slider
                          track={false}
                          min={Math.floor(0.5 * formik.values.max_heart_rate)}
                          max={Number(formik.values.max_heart_rate)}
                          disableSwap
                          valueLabelDisplay="on"
                          marks={heartRateMarks}
                          step={1}
                          value={formik.values.heart_rate_zones}
                          sx={{
                            "& .MuiSlider-valueLabel": {
                              backgroundColor: "transparent",
                              color: "#80822B",
                              margin: 0,
                              fontSize: "10px",
                              marginTop: "5px",
                            },
                            marginTop: "1.25rem",
                            height: "9.5296px",
                            "& .MuiSlider-rail": {
                              background: `linear-gradient(to right, #81A9E7 0%, #81A9E7 ${heart_rate_zones_colors[0]}%, #92E3DA ${heart_rate_zones_colors[0]}%, #92E3DA ${heart_rate_zones_colors[1]}%, #C0DE77 ${heart_rate_zones_colors[1]}%, #C0DE77 ${heart_rate_zones_colors[2]}% , #E28E54 ${heart_rate_zones_colors[2]}%, #E28E54 ${heart_rate_zones_colors[3]}%, #DD4F4A ${heart_rate_zones_colors[3]}%, #DD4F4A 100%)`,
                              opacity: 1,
                            },
                            "& .MuiSlider-thumb": {
                              backgroundImage: `url(${slider})`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundColor: "none",
                              height: ".5rem",
                              width: ".5625rem",
                              boxShadow: "none",
                              top: ".625rem",
                            },
                            "& .MuiSlider-mark": {
                              opacity: 0,
                            },
                            "& .MuiSlider-markLabel": {
                              fontSize: "10px",
                              color: "#F7F3F0",
                            },
                          }}
                          name="heart_rate_zones"
                          onChange={(_, values) =>
                            formik.setFieldValue("heart_rate_zones", values)
                          }
                          key={formik.values.max_heart_rate}
                        />
                      ) : (
                        <div style={{ pointerEvents: "none" }}>
                          <DisabledSlider defaultValue={[]} />
                        </div>
                      )}
                    </Box>
                  ),
                )}
                {withReference(
                  (Boolean(formik.errors.ftp) && formik.touched.ftp) ||
                    !formik.values.ftp ||
                    formik.values.ftp === 0 ||
                    (formik.values.ftp as any) === "",
                  (disabled) => (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            variant="h4"
                            color="text.primary"
                            sx={{ opacity: "0.5" }}
                          >
                            Power Zones (W)
                          </Typography>

                          <Tooltip
                            title="Your Power training zones are determined using the most common method, i.e. on the % of your FTP. You can always customize your zones."
                            placement="right"
                            arrow
                          >
                            <img
                              src={infoicon}
                              alt="info_icon"
                              width="10"
                              height="11"
                            />
                          </Tooltip>
                        </Box>
                        {disabled ? (
                          <Typography
                            variant="h4"
                            color="text.primary"
                            sx={{ opacity: "0.3" }}
                          >
                            (Set FTP to unlock)
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Box>
                      {!disabled ? (
                        <Slider
                          key={`pwr-${formik.values.ftp}`}
                          track={false}
                          min={0}
                          max={(formik.values.ftp * 150) / 100}
                          disableSwap
                          valueLabelDisplay="on"
                          marks={powerMarks}
                          step={1}
                          value={formik.values.power_zones}
                          sx={{
                            "& .MuiSlider-valueLabel": {
                              backgroundColor: "transparent",
                              color: "#80822B",
                              margin: 0,
                              fontSize: "10px",
                              marginTop: "5px",
                            },
                            marginTop: "1.25rem",
                            height: "9.5296px",
                            "& .MuiSlider-rail": {
                              background: `linear-gradient(to right, #81A9E7 0%, #81A9E7 ${power_zones_colors[0]}%, #92E3DA ${power_zones_colors[0]}%, #92E3DA ${power_zones_colors[1]}%,#36B37E ${power_zones_colors[1]}%,#36B37E ${power_zones_colors[2]}%, #C0DE77 ${power_zones_colors[2]}%, #C0DE77 ${power_zones_colors[3]}% , #E28E54 ${power_zones_colors[3]}%, #E28E54 ${power_zones_colors[4]}%, #DD4F4A ${power_zones_colors[4]}%, #DD4F4A ${power_zones_colors[5]}%, #EA366F ${power_zones_colors[5]}%, #EA366F 100%)`,
                              opacity: 1,
                            },
                            "& .MuiSlider-thumb": {
                              backgroundImage: `url(${slider})`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundColor: "none",
                              height: ".5rem",
                              width: ".5625rem",
                              boxShadow: "none",
                              top: ".625rem",
                            },
                            "& .MuiSlider-mark": {
                              opacity: 0,
                            },
                            "& .MuiSlider-markLabel": {
                              fontSize: "10px",
                              color: "#F7F3F0",
                            },
                          }}
                          name="power_zones"
                          onChange={(_, values) =>
                            formik.setFieldValue("power_zones", values)
                          }
                        />
                      ) : (
                        <div style={{ pointerEvents: "none" }}>
                          <DisabledSlider defaultValue={[]} />
                        </div>
                      )}
                    </Box>
                  ),
                )}
              </Box>

              {/* <Box sx={{ display: "flex", width: "30%" }}>
              <Box
                sx={{
                  width: "1px",
                  bgcolor: "#64605D",
                  height: "500px",
                  marginTop: "20px",
                  marginRight: "30px",
                }}
              ></Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Typography sx={{ fontSize: ".8125rem" }}>MY COACH</Typography>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    name="coach_email"
                    label={"Coach Email"}
                    type="email"
                    onBlur={formik.handleBlur}
                    value={null}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <Button variant="text" size="small" sx={{ width: "50%" }}>
                  Find Coach
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "#2C262D",
                    borderRadius: "4px",
                    border: "1px solid #BC6D29",
                    padding: "1.5em",
                    gap: 2,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Skeleton variant="circular" width={80} height={80} />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography fontSize={".875rem"}>John Doe</Typography>
                      <Typography fontSize={".75rem"}>Coach</Typography>
                      <Typography fontSize={".625rem"} sx={{ opacity: 0.5 }}>
                        Cyprus,Paphos
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ alignSelf: "flex-end" }}
                  >
                    Select
                  </Button>
                </Box>
              </Box>
            </Box> */}
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              startIcon={
                isPending(saveProfileSettingsCall) ? (
                  <CircularProgress size="1rem" />
                ) : null
              }
              disabled={isPending(saveProfileSettingsCall)}
              type="submit"
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
}
