import { Box, Typography, useMediaQuery } from "@mui/material";
import { PropsWithChildren } from "react";

export type ProfileSetupLayoutProps = {
  counter: number;
};

export default function ProfileSetupLayout(
  props: PropsWithChildren<ProfileSetupLayoutProps>,
) {
  const isMobileScreen = useMediaQuery("(max-width:450px)");
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          paddingTop: "102px",
          "@media (max-width: 640px)": {
            alignItems: isMobileScreen ? "center" : "flex-start",
          },
        }}
      >
        <Box
          sx={{
            background: "#2A252B",
            width: isMobileScreen ? "22rem" : "47.1875rem",
            paddingBottom: "1.875rem",
            borderRadius: "1.25rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignSelf: "flex-end",
              mr: "1rem",
              mt: "1rem",
            }}
          >
            <Typography
              variant="body2"
              lineHeight="14px"
              color="text.secondary"
            >
              {props.counter}/2
            </Typography>
          </Box>
          {props.children}
        </Box>
      </Box>
    </>
  );
}
