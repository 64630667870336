import "../../../assets/scss/layouts/DashboardLayout.scss";
import Logo from "../../../assets/img/layout/Logo_new.svg";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsIcon from "@mui/icons-material/Settings";

import { Card, Skeleton, styled } from "@mui/material";

import {
  Badge,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { SessionContext, useSupabase } from "src/contexts/SupabaseContext";
import { SupabaseCall } from "src/utils/common";
import NotificationPopover from "../../customComponents/dashboard/NotificationPopover";
import { WithCache } from "src/contexts/CacheContext";

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
  height: ${theme.header.height};
  right: 0;
  z-index: 6;
  background-color: #2C262D;
  position: fixed;
  width: 100%;
`,
);

const settings = [
  {
    name: "Settings",
    icon: <SettingsOutlinedIcon />,
    url: "/coach/settings",
  },
  { name: "Sign Out", icon: <LogoutOutlinedIcon /> },
];
export default function CoachNavbar() {
  const navigate = useNavigate();
  const supabase = useSupabase();

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (url: string) => {
    navigate(url);
    setAnchorElUser(null);
  };

  //Sign Out Function

  const logOutCall =
    useAsyncState<SupabaseCall<typeof supabase.auth.signOut>>();

  const session = useContext(SessionContext);

  useEffect(() => {
    if (isFulfilled(logOutCall)) {
      if ("unload" in session) {
        session.unload();
      }
    }
  }, [logOutCall]);

  const handleLogout = useCallback(() => {
    if ("fire" in logOutCall) {
      logOutCall.fire(() => supabase.auth.signOut());
    }
  }, [supabase]);

  //Notification

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <HeaderWrapper
        sx={{
          background: "#2C262D",
          paddingY: "0.5em",
          paddingLeft: "1.5em",
        }}
      >
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <img src={Logo} alt="Logo" className="Logo-dashboard" />
          <Box>
            <Tooltip title="Messages">
              <IconButton size="large">
                <Badge color="error">
                  <EmailOutlinedIcon
                    sx={{
                      color: "#F7F3F0",
                      "&:hover": {
                        color: "#DD4F4A",
                      },
                    }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
            <WithCache
              tables={["notifications"]}
              component={(cached) => (
                <>
                  <Tooltip title="Notifications">
                    <IconButton size="large" onClick={handleClick}>
                      <Badge
                        color="error"
                        badgeContent={
                          cached.notifications?.filter(
                            (noti) => noti.read === false,
                          ).length
                        }
                      >
                        <NotificationsNoneOutlinedIcon
                          sx={{ color: "#F7F3F0" }}
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <NotificationPopover
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    notifications={cached.notifications}
                  />
                </>
              )}
            />
            <Tooltip title="Settings">
              <IconButton size="large" onClick={handleOpenUserMenu}>
                <SettingsIcon
                  sx={{
                    color: "#F7F3F0",
                    "&:hover": {
                      color: "#DD4F4A",
                    },
                  }}
                />
              </IconButton>
            </Tooltip>

            <Menu
              sx={{ mt: "45px" }}
              MenuListProps={{
                style: {
                  background: "#2C262D",
                  borderColor: "#2C262D",
                  padding: 0,
                },
              }}
              PaperProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.name}
                  onClick={
                    setting.name === "Sign Out"
                      ? handleLogout
                      : () => handleMenuItemClick(setting.url)
                  }
                >
                  <ListItemIcon
                    sx={{
                      "&:hover": {
                        color: "#DD4F4A",
                      },
                    }}
                  >
                    {setting.icon}
                  </ListItemIcon>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </HeaderWrapper>
    </>
  );
}