import { Box, Button, TextField, Typography } from "@mui/material";
import { UserResponse } from "@supabase/supabase-js";
import { useFormik } from "formik";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isRejected } from "src/utils/Async";
import * as yup from "yup";
import SuccessResetPasswordView from "./SuccessResetPasswordView";

export default function CreateNewPasswordView() {
  const formik = useFormik<{
    password: string;
    confirmPassword: string;
  }>({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .min(8)
        .max(255)
        .required("The password field is required"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords must match"),
    }),
    onSubmit: ({ password }) => {
      if ("fire" in updatePasswordCall) {
        updatePasswordCall.fire(async () => updatePasswordCallback(password));
      }
    },
  });

  const supabase = useSupabase();

  const updatePasswordCallback = useCallback(
    (password: string) => supabase.auth.updateUser({ password }),
    [supabase],
  );

  const updatePasswordCall = useAsyncState<UserResponse>();

  return (
    <>
      {isFulfilled(updatePasswordCall) && !updatePasswordCall.result.error ? (
        <SuccessResetPasswordView />
      ) : (
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              pt: "17.1875rem",
              "@media (max-width: 400px)": {
                alignItems: "flex-start",
              },
            }}
          >
            <Box
              sx={{
                background: "#2A252B",
                width: "47.1875rem",
                height: "23.375rem",
                borderRadius: "1.25rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h1" color="text.secondary" mt="2.5rem">
                Create New Password
              </Typography>
              <Typography
                variant="body2"
                fontSize={13}
                mt={".8125rem"}
                mb={"3.25rem"}
                textAlign={"center"}
                lineHeight={"20px"}
              >
                Make sure to use a strong password
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "1.5rem",
                }}
              >
                <TextField
                  placeholder="Password"
                  name="password"
                  type="password"
                  error={Boolean(
                    formik.touched.password && formik.errors.password,
                  )}
                  helperText={formik.touched.password && formik.errors.password}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                <TextField
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  error={Boolean(
                    formik.touched.confirmPassword &&
                      formik.errors.confirmPassword,
                  )}
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                />
              </Box>
              {isRejected(updatePasswordCall) && (
                <Typography mt={"10px"} color="#FF5630">
                  {updatePasswordCall.error.message}
                </Typography>
              )}

              <Button
                variant="contained"
                size="small"
                sx={{
                  width: "11.6875rem",
                  mt: "1.5rem",
                  height: "1.875rem",
                }}
                type="submit"
              >
                <Typography variant="h5">Submit</Typography>
              </Button>
              <Typography
                component={Link}
                to={"/account/login"}
                fontSize={12}
                alignSelf={"flex-end"}
                sx={{
                  color: "#BC6D29",
                  mb: "1rem",
                  mr: "1.4375rem",
                }}
                variant="h5"
                lineHeight={"1.5rem"}
              >
                Sign In here
              </Typography>
            </Box>
          </Box>
        </form>
      )}
    </>
  );
}
