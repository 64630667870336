import { useCallback, useEffect, useMemo } from "react";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import type { ApexOptions } from "apexcharts";
import { Box, useTheme } from "@mui/material";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "dayjs/locale/en";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
import type { Database } from "src/utils/DatabaseDefinitions";
import PreloadComponent from "src/utils/PreloadComponent";
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";

type Month =
  | "Jan"
  | "Feb"
  | "Mar"
  | "Apr"
  | "May"
  | "Jun"
  | "Jul"
  | "Aug"
  | "Sep"
  | "Oct"
  | "Nov"
  | "Dec";

const months: Array<Month> = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const averages = [
  "Speed",
  "Power",
  "Heart Rate",
  "Cadence",
  "Duration",
  "Distance",
] as const;

export type Averages = typeof averages[number];

export type PerformanceSessionsProps = {
  sessions: Pick<
    Database["public"]["Tables"]["session"]["Row"],
    | "total_distance"
    | "average_speed"
    | "average_power"
    | "average_cadence"
    | "average_heart_rate"
    | "total_time"
    | "date"
  >[];
};

// export default function PerformanceProgressCall() {
//   return (
//     <>
//       <PreloadComponent<{
//         session: Pick<
//           Database["public"]["Tables"]["session"]["Row"],
//           | "total_distance"
//           | "average_speed"
//           | "average_power"
//           | "average_cadence"
//           | "average_heart_rate"
//           | "total_time"
//           | "date"
//         >[];
//       }>
//         promises={{
//           session: async (supabase) =>
//             supabase
//               .from("session")
//               .select(
//                 "total_distance , average_speed , average_power , average_cadence , average_heart_rate , total_time  , date",
//               )
//               .order("date", { ascending: false })
//               .then((res) => res.data),
//         }}
//         component={(props) => (
//           <>
//             <PerformanceProgress sessions={props.session} />
//           </>
//         )}
//       />
//     </>
//   );
// }

export default function PerformanceProgress(props: PerformanceSessionsProps) {
  const theme = useTheme();
  const units = useUnits();
  const updateIfNotZeroOrNull = useCallback(
    (sumObject, countObject, month, property: string, value: number) => {
      if (value !== 0 && value !== null) {
        sumObject[month][property] += value;
        countObject[month][property] += 1;
      }
    },
    [],
  );

  const timeFormatter = (valStr) => {
    const val = Number(valStr);
    const hours = Math.floor(Math.floor(val / 60) / 60);
    const minutes = Math.trunc((val % 3600) / 60);
    const seconds = Math.trunc(val % 60);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const rangeHigh = dayjs();

  // Calculation for our rangeLow Date
  const rangeLow = rangeHigh.subtract(5, "month");

  const filterSessions = useMemo(
    () =>
      props.sessions.filter(
        (session) =>
          rangeLow.isSameOrBefore(new Date(session.date), "month") &&
          rangeHigh.isSameOrAfter(new Date(session.date), "month"),
      ),

    [rangeLow, rangeHigh],
  );

  //Chart labels. Finding the correct starting month
  const categories = useMemo(() => {
    const indexOfMonth = rangeLow.month();
    const months: Array<Month> = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return [
      ...months.slice(indexOfMonth),
      ...months.slice(0, indexOfMonth),
    ].slice(0, 6);
  }, [rangeLow]);

  const buckets: { [K in Month]: { [P in Averages]: number } } = useMemo(() => {
    const sumObject = Object.fromEntries(
      categories.map((cat) => [
        cat,
        Object.fromEntries(averages.map((pos: Averages) => [pos, 0])),
      ]),
    ) as { [K in Month]: { [P in Averages]: number } };
    const countObject = Object.fromEntries(
      categories.map((cat) => [
        cat,
        Object.fromEntries(averages.map((pos: Averages) => [pos, 0])),
      ]),
    ) as { [K in Month]: { [P in Averages]: number } };

    for (const session of filterSessions) {
      const month = months[new Date(session.date).getMonth()];
      updateIfNotZeroOrNull(
        sumObject,
        countObject,
        month,
        "Speed",
        session.average_speed,
      );
      updateIfNotZeroOrNull(
        sumObject,
        countObject,
        month,
        "Power",
        session.average_power,
      );
      updateIfNotZeroOrNull(
        sumObject,
        countObject,
        month,
        "Heart Rate",
        session.average_heart_rate,
      );
      updateIfNotZeroOrNull(
        sumObject,
        countObject,
        month,
        "Cadence",
        session.average_cadence,
      );
      updateIfNotZeroOrNull(
        sumObject,
        countObject,
        month,
        "Duration",
        session.total_time,
      );
      updateIfNotZeroOrNull(
        sumObject,
        countObject,
        month,
        "Distance",
        session.total_distance,
      );
    }

    return Object.fromEntries(
      Object.entries(sumObject).map(([month, val]) => [
        month,
        Object.fromEntries(
          Object.entries(val).map(([property, val]) => [
            property,
            val === 0
              ? val
              : property === "Distance" || property === "Duration"
              ? val
              : (val / countObject[month][property]).toFixed(1),
          ]),
        ),
      ]),
    ) as { [K in Month]: { [P in Averages]: number } };
  }, [categories, filterSessions]);

  const createChartOptions = useCallback(
    (id: string): ApexOptions => ({
      chart: {
        id,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        background: "transparent",
      },
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#FF5630",
          fontSize: "12px",
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      theme: {
        mode: theme.palette.mode,
      },
      fill: {
        opacity: 0,
        type: "solid",
      },
      markers: {
        hover: {
          sizeOffset: 2,
        },
        shape: "circle",
        size: 3,
        strokeWidth: 3,
        strokeOpacity: 1,
      },
      tooltip: {
        shared: true,
        y: [
          {
            formatter: (value: number) => {
              if (typeof value !== "undefined") {
                return `${value.toFixed(1)} kph`;
              }
              return value;
            },
          },
          {
            formatter: (value: number) => {
              if (typeof value !== "undefined") {
                return `${value.toFixed(0)} W`;
              }
              return value;
            },
          },
          {
            formatter: (value: number) => {
              if (typeof value !== "undefined") {
                return `${value.toFixed(0)} bpm`;
              }
              return value;
            },
          },
          {
            formatter: (value: number) => {
              if (typeof value !== "undefined") {
                return `${value.toFixed(0)} rpm`;
              }
              return value;
            },
          },
          {
            formatter: (value: number) => timeFormatter(value / 1000),
          },
          {
            formatter: (value: number) => {
              if (typeof value !== "undefined") {
                return units === "metric"
                ? `${value.toFixed(2) ?? "0"} km`
                : `${
                    value
                      ? convert(value)
                          .from("km")
                          .to("mi")
                          .toFixed(2)
                      : "0"
                  } mi`
              }
              return value;
            },
          },
        ],
        x: {
          show: false,
        },
      },
      legend: {
        showForNullSeries: true,
        showForZeroSeries: true,
        labels: {
          colors: "#ffffff",
        },
        horizontalAlign: "left",
        offsetY: 10,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        row: {
          opacity: 0.1,
        },
        show: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#64605D",
          },
        },
        tooltip: {
          enabled: false, // Hide the small tooltip for categories
        },
        categories,
        tickAmount: undefined,
        overwriteCategories: undefined,
      },
      yaxis: [
        {
          //   min: 0,
          //   max: (val) => Math.ceil(val),
          show: false,
          labels: {
            formatter: (val) => `${Math.ceil(val)}%`, //Speed
          },
        },
        {
          // min: 75,
          show: false,
          labels: {
            formatter: (val) => `${Math.ceil(val)}%`, // Power
          },
        },
        {
          // min: 100,
          show: false,
          labels: {
            formatter: (val) => `${Math.ceil(val)}%`, // HR
          },
        },
        {
          // min: 50,
          show: false,
          labels: {
            formatter: (val) => `${Math.ceil(val)}%`, // Cadence
          },
        },
        {
          show: false,
          labels: {
            formatter: (val) => timeFormatter(val / 1000), //Duration
          },
        },
        {
          show: false,
          labels: {
            formatter: (val) => `${Math.ceil(val)}%`, // Distance
          },
        },
      ],
      colors: [
        "#BC6D29",
        "#D9D9D9",
        "#DD4F4A",
        "#B1A4B3",
        "#E28E54",
        "#797979",
      ],
    }),
    [averages],
  );

  const chartOptions = useMemo(
    () => createChartOptions("AveragesChart"),
    [buckets, filterSessions],
  );

  const chartSeries: ApexAxisChartSeries = useMemo(() => {
    const properties = Object.keys(buckets[categories[0]]);

    const series = properties.map((property) => {
      const data = categories.map((category) => {
        return buckets[category][property];
      });
      return {
        name: property,
        data,
      };
    });
    return series;
  }, [buckets, filterSessions]);

  useEffect(() => {
    chartSeries.map((series) => {
      const someIsNotZero = series.data.some((item) => item !== 0);
      const isAllZero = !someIsNotZero;
      if (isAllZero)
        ApexCharts.exec("AveragesChart", "hideSeries", [`${series.name}`]);
    });
  }, [chartSeries]);

  return (
    <Box marginBottom={2.5} sx={{ height: { xl: "310px", xs: "233px" } }}>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="area"
        height={"88%"}
      />
    </Box>
  );
}
