import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Tab, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTitleCoach } from "../Dashboard/CoachDashboardLayout";
import { useNavigate } from "react-router-dom";
import PlansDashboard from "../Library/TrainingPlans/PlansDashboard";
import CoachWorkoutsDataGrid from "../Library/Workouts/CoachWorkoutsDataGrid";

type TabValue = "plans" | "workouts";

export type CoachLibraryTabProps = {
  tab?: TabValue;
};

export default function CoachLibraryView(props: CoachLibraryTabProps) {
  const [, setTitle] = useTitleCoach();
  const [value, setValue] = useState<TabValue>("plans");
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery("(max-width:920px)");

  useEffect(() => {
    setTitle("Library");
  }, []);

  useEffect(() => {
    setValue(props.tab);
  }, [props?.tab]);

  const handleChange = useCallback((_, newValue: TabValue) => {
    navigate(`/coach/library/${newValue}`);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "25px",
        paddingBottom: "20px",
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobileScreen ? "column" : "row",
            width: "98%",
            justifyContent: "space-between",
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="Tab list"
            sx={{
              "& .MuiTab-root:not(.Mui-selected)": {
                opacity: 0.5,
              },
              minHeight: "1.5rem",
              "& .Mui-selected": {
                color: "#DD4F4A !important",
              },
              borderBottom: isMobileScreen ? 0 : 1,
              borderColor: "rgba(221, 79, 74, 0.5)",
            }}
            TabIndicatorProps={{
              sx: {
                backgroundColor: "#DD4F4A",
              },
            }}
          >
            <Tab
              label="Training Plans"
              value="plans"
              sx={{
                minHeight: 0,
                padding: "10px",
              }}
            />
            <Tab
              label="Workouts"
              value="workouts"
              sx={{
                minHeight: 0,
                padding: "10px",
              }}
            />
          </TabList>

          <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
            <Button
              variant="coachVariant2"
              // onClick={(_) => navigate("new_workout")}
              onClick={(_) => {
                return value === "plans"
                  ? navigate("new_plan")
                  : navigate("new_workout");
              }}
            >
              +ADD NEW
            </Button>
          </Box>
        </Box>
        <TabPanel
          value="plans"
          sx={{ padding: 0, minHeight: 0, marginTop: "0.6rem" }}
        >
          <PlansDashboard />
        </TabPanel>

        <TabPanel
          value="workouts"
          sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
        >
          <CoachWorkoutsDataGrid />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
