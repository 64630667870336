import { Outlet, RouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import { userState } from "src/utils/common";
import SessionOverviewViewPreload from "src/content/customPages/SessionOverviewViewPreload";
import SessionViewsPreload from "src/content/customPages/SessionViewsPreload";
import CalendarView from "src/content/customPages/Calendar/CalendarView";
import CreateNewPasswordView from "src/content/customViews/CreateNewPasswordView";
import LogInView from "src/content/customViews/LogInView";
import RegisterView from "src/content/customViews/RegisterView";
import ResetPasswordView from "src/content/customViews/ResetPasswordView";
import SuccessMessage from "src/content/customViews/SuccessMessage";
import RoleView from "src/content/customViews/RoleView";
import AuthState from "src/wrappers/AuthState";
import CyclistAuthenticated from "src/components/Authenticated/CyclistAuthenticated";
import Authenticated from "src/components/Authenticated";
import DashboardLayout from "src/content/customLayouts/DashboardLayout";
import DashboardView from "src/content/customViews/DashboardView";
import SessionsView from "src/content/customViews/SessionsView";
import RideInsightsView from "src/content/customViews/RideInsightsView";
import PersonalRecordzView from "src/content/customViews/PersonalRecordzView";
import ProgressView from "src/content/customViews/ProgressView";
import SettingsView from "src/content/customViews/Setttings/SettingsView";
import TrendsView from "src/content/customViews/TrendsView";
import AnalyticsNativePreload from "src/content/customComponents/native/AnalyticsNative";
import ErrorSupportView from "src/content/customViews/ErrorSupportView";
import AdminDashboardLayout from "src/content/customAdminDashboard/AdminDashboardLayout";
import AdminAnalyticsView from "src/content/customAdminDashboard/AdminAnalyticsView";
import AdminDashboard from "src/content/customAdminDashboard/AdminDashboard";
import { WithCache } from "src/contexts/CacheContext";
import CoachDashboardView from "src/content/customCoachDashboard/customViews/CoachDashboardView";
import CoachAthletesView from "src/content/customCoachDashboard/customViews/CoachAthletesView";
import AdminNotificationsView from "src/content/customAdminDashboard/AdminNotificationsView";
import CoachAthleteTabView from "src/content/customCoachDashboard/MyAthletes/CoachAthleteTabView";
import NativeView from "src/content/customViews/NativeView";
import CoachCalendarView from "src/content/customCoachDashboard/customViews/CoachCalendarView";
import CoachLeaderBoardView from "src/content/customCoachDashboard/customViews/CoachLeaderBoardView";
import CoachSessionViewsPreload from "src/content/customCoachDashboard/Sessions/CoachSessionViewsPreload";
import CoachSessionOverviewViewPreload from "src/content/customCoachDashboard/Sessions/CoachSessionOverviewViewPreload";
import CoachAthleteRideInsightsView from "src/content/customCoachDashboard/MyAthletes/CoachRideInsightsView";
import CoachLibraryView from "src/content/customCoachDashboard/customViews/CoachLibaryView";
import CoachDashboardLayout from "src/content/customCoachDashboard/Dashboard/CoachDashboardLayout";
import WorkoutBuilder from "src/content/customCoachDashboard/Library/Workouts/WorkoutBuilder";
import LibraryView from "src/content/customViews/LibraryCyclist/LibraryView";
import ViewPlan from "src/content/customViews/LibraryCyclist/ViewPlan";
import WorkoutBuilderCyclist from "src/content/customViews/LibraryCyclist/WorkoutBuilderCyclist";
import TrainingPlanBuilder from "src/content/customCoachDashboard/Library/TrainingPlans/TrainingPlanBuilder";
import CoachSettingsView from "src/content/customCoachDashboard/customViews/CoachSettingsView";
import CoachSuccessMessage from "src/content/customViews/CoachSuccessMessage";
import CoachAuthenticated from "src/components/Authenticated/CoachAuthenticated";
import AssignedWorkoutBuilder from "src/content/customCoachDashboard/Library/Workouts/AssignedWorkoutBuilder";
import AssignedTrainingPlanViewer from "src/content/customCoachDashboard/Library/TrainingPlans/AssignedTrainingPlanViewer";
import CoachTrainingPlans from "src/content/customCoachDashboard/MyAthletes/TrainingPlans/CoachTrainingPlans";
import AssignedWorkoutView from "src/content/customPages/AssignedWorkoutView";
import TrainingPlansView from "src/content/customViews/LibraryCyclist/TrainingPlansView";
import MyPlansView from "src/content/customViews/LibraryCyclist/MyPlansView";

const router: RouteObject[] = [
  {
    path: "",
    element: <Outlet />,
    children: [
      {
        path: "account/create-new-password",
        element: (
          <>
            <AuthState authState="logged-in-cyclist">
              <CreateNewPasswordView />
            </AuthState>
            <AuthState
              authState={userState.filter(
                (state) =>
                  state !== "logged-in-cyclist" &&
                  state !== "pending" &&
                  state !== "logged-in-admin",
              )}
            >
              <Navigate to="/" />
            </AuthState>
          </>
        ),
      },
      {
        path: "account/profile-setup",
        element: (
          <>
            <AuthState authState="missing-info">
              <WithCache
                tables={["training_goals", "athlete_experience_level"]}
                component={(cached) => (
                  <RoleView
                    trainingGoals={cached.training_goals.map((goal) => [
                      goal.id,
                      goal.goal,
                    ])}
                    experienceLevel={cached.athlete_experience_level.map(
                      (el) => [el.id, el.experience_level],
                    )}
                  />
                )}
              />
            </AuthState>
            <AuthState
              authState={userState.filter(
                (state) => state !== "missing-info" && state !== "pending",
              )}
            >
              <Navigate to="/" />
            </AuthState>
          </>
        ),
      },
      {
        path: "account/profile-complete",
        element: (
          <>
            <AuthState authState="profile-success">
              <SuccessMessage />
            </AuthState>
            <AuthState authState="coach-profile-success">
              <CoachSuccessMessage />
            </AuthState>
            <AuthState
              authState={userState.filter(
                (state) => state !== "profile-success" && state !== "coach-profile-success" && state !== "pending",
              )}
            >
              <Navigate to="/" />
            </AuthState>
          </>
        ),
      },
      {
        path: "account",
        element: (
          <>
            <AuthState authState="anon">
              <Outlet />
            </AuthState>
            <AuthState
              authState={userState.filter(
                (state) => state !== "anon" && state !== "pending",
              )}
            >
              <Navigate to="/" />
            </AuthState>
          </>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="./login" />,
          },
          {
            path: "login",
            element: <LogInView />,
          },
          {
            path: "register",
            element: (
              <WithCache
                tables={["country"]}
                component={(cached) => (
                  <RegisterView countries={cached.country} />
                )}
              />
            ),
          },
          {
            path: "reset-password",
            element: <ResetPasswordView />,
          },
          {
            path: "other-error",
            element: <ErrorSupportView />,
          },
        ],
      },
      {
        path: "dashboard",
        element: (
          <>
            <AuthState authState="logged-in-cyclist">
              <Authenticated>
                <CyclistAuthenticated>
                  <DashboardLayout />
                </CyclistAuthenticated>
              </Authenticated>
            </AuthState>
            <AuthState
              authState={userState.filter(
                (state) =>
                  state !== "logged-in-cyclist" &&
                  state !== "pending",
              )}
            >
              <Navigate to="/" />
            </AuthState>
          </>
        ),
        children: [
          {
            path: "",
            element: <DashboardView />,
          },
          {
            path: "sessions",
            element: <SessionsView />,
          },
          {
            path: "session/:sessionId",
            element: (
              <WithCache
                tables={["default_positions"]}
                component={(cached) => (
                  <SessionViewsPreload
                    defaultPositions={cached.default_positions}
                  />
                )}
              />
            ),
            children: [
              {
                path: "",
                element: <SessionOverviewViewPreload />,
                children: [
                  {
                    path: "",
                    element: <Navigate to="./overview" />,
                  },
                  {
                    path: "overview",
                    element: <RideInsightsView tab="overview" />,
                  },
                  {
                    path: "analytics",
                    element: <RideInsightsView tab="analytics" />,
                  },
                  {
                    path: "zones",
                    element: <RideInsightsView tab="zones" />,
                  },
                  {
                    path: "performance_index",
                    element: <RideInsightsView tab="performance_index" />,
                  },
                ],
              },
            ],
          },
          {
            path: "calendar",
            element: <CalendarView />,
          },
          {
            path: "calendar/assigned-workout/:assignedWorkoutId",
            element: <AssignedWorkoutView />,
          },
          {
            path: "personal-records",
            element: <PersonalRecordzView />,
          },

          {
            path: "trends",
            element: <TrendsView />,
          },
          {
            path: "library",
            children: [
              { path: "", element: <Navigate to="./training-plans" /> },
              { 
                path: "training-plans", 
                element: <LibraryView tab="training-plans"/>,
                children: [
                  {
                    path: "",
                    element: <Navigate to="./available" />,
                  },
                  {
                    path: "available",
                    element: <TrainingPlansView tab="available"/>,
                  },
                  {
                    path: "my-plans",
                    element: <TrainingPlansView tab="my-plans"/>,
                    children: [
                      {
                        path: "",
                        element: <MyPlansView/>,
                      },
                      {
                        path: ":assignedTrainingPlanId",
                        element: <AssignedTrainingPlanViewer />,
                      },
                    ],
                  },
                ]
              },
              { path: "workouts", element: <LibraryView tab="workouts" /> },
              {
                path: "training-plans/view_plan",
                element: <ViewPlan />,
              },
              {
                path: "workouts/add_workout",
                element: <WorkoutBuilderCyclist />,
              },
              {
                path: "workouts/edit_workout/:workoutId",
                element: <WorkoutBuilderCyclist />,
              },
            ],
          },

          {
            path: "progress",
            children: [
              {
                path: "",
                element: <ProgressView tab="performance" />,
              },
              {
                path: "performance",
                element: <ProgressView tab="performance" />,
              },
              {
                path: "positions",
                element: <ProgressView tab="positions" />,
              },
            ],
          },
          {
            path: "settings",
            children: [
              {
                path: "",
                element: <SettingsView tab="my_account" />,
              },
              {
                path: "my_account",
                element: <SettingsView tab="my_account" />,
              },
              {
                path: "athlete",
                element: <SettingsView tab="athlete" />,
              },
              {
                path: "bikes",
                element: <SettingsView tab="bikes" />,
              },
              {
                path: "subscription",
                element: <SettingsView tab="subscription" />,
              },
              {
                path: "connected_apps",
                element: <SettingsView tab="connected_apps" />,
              },
            ],
          },
        ],
      },
      {
        path: "native",
        element: (
          <>
            <AuthState authState="logged-in-cyclist">
              <Authenticated>
                <CyclistAuthenticated>
                  <NativeView />
                </CyclistAuthenticated>
              </Authenticated>
            </AuthState>
            <AuthState
              authState={userState.filter(
                (state) =>
                  state !== "logged-in-cyclist" &&
                  state !== "pending" &&
                  state !== "logged-in-admin",
              )}
            >
              <Navigate to="/" />
            </AuthState>
          </>
        ),
        children: [
          {
            path: "analytics",
            element: (
              <WithCache
                tables={["default_positions"]}
                component={(cached) => (
                  <AnalyticsNativePreload
                    defaultPositions={cached.default_positions}
                  />
                )}
              />
            ),
          },
        ],
      },
      {
        path: "admin",
        element: (
          <>
            <AuthState authState="logged-in-admin">
              <Authenticated>
                <AdminDashboardLayout />
              </Authenticated>
            </AuthState>
            <AuthState
              authState={userState.filter(
                (state) => state !== "pending" && state !== "logged-in-admin",
              )}
            >
              <Navigate to="/" />
            </AuthState>
          </>
        ),
        children: [
          {
            path: "",
            element: <AdminDashboard />,
          },
          {
            path: "analytics",
            element: <AdminAnalyticsView />,
          },
          {
            path: "notifications",
            element: <AdminNotificationsView />,
          },
        ],
      },
      {
        path: "coach",
        element: (
          <>
            <AuthState authState="logged-in-coach">
              <Authenticated>
                <CoachAuthenticated>
                  <CoachDashboardLayout />
                </CoachAuthenticated>
              </Authenticated>
            </AuthState>
            <AuthState
              authState={userState.filter(
                (state) =>
                  state !== "logged-in-coach" &&
                  state !== "pending",
              )}
            >
              <Navigate to="/" />
            </AuthState>
          </>
        ),
        children: [
          {
            path: "",
            element: <CoachDashboardView />,
          },
          {
            path: "my_athletes",
            element: <CoachAthletesView />,
          },
          {
            path: "my_athletes/session/:sessionId",
            element: (
              <WithCache
                tables={["default_positions"]}
                component={(cached) => (
                  <CoachSessionViewsPreload
                    defaultPositions={cached.default_positions}
                  />
                )}
              />
            ),
            children: [
              {
                path: "",
                element: <CoachSessionOverviewViewPreload />,
                children: [
                  {
                    path: "",
                    element: <CoachAthleteRideInsightsView />,
                  },
                ],
              },
            ],
          },
          {
            path: "my_athletes/:userId",
            children: [
              {
                path: "",
                element: <Navigate to="./overview" />,
              },
              {
                path: "overview",
                element: <CoachAthleteTabView tab="overview" />,
              },
              {
                path: "information",
                element: <CoachAthleteTabView tab="information" />,
              },
              {
                path: "calendar",
                element: <CoachAthleteTabView tab="calendar" />,
                children: [
                  {
                    path: "assigned-workout/:assignedWorkoutId",
                    element: <CoachAthleteTabView tab="calendar" />,
                  }
                ]
              },
              {
                path: "training_plans",
                element: <CoachAthleteTabView tab="training_plans" />,
                children: [
                  {
                    path: "",
                    element: <CoachTrainingPlans />,
                  },
                  {
                    path: "assigned-training-plan/:assignedTrainingPlanId",
                    element: <AssignedTrainingPlanViewer />,
                  },
                ]
              },
              {
                path: "trends",
                element: <CoachAthleteTabView tab="trends" />,
              },
            ],
          },
          {
            path: "calendar",
            element: <CoachCalendarView />,
          },
          {
            path: "calendar/session/:sessionId",
            element: (
              <WithCache
                tables={["default_positions"]}
                component={(cached) => (
                  <CoachSessionViewsPreload
                    defaultPositions={cached.default_positions}
                  />
                )}
              />
            ),
            children: [
              {
                path: "",
                element: <CoachSessionOverviewViewPreload />,
                children: [
                  {
                    path: "",
                    element: <CoachAthleteRideInsightsView />,
                  },
                ],
              },
            ],
          },
          {
            path: "calendar/assigned-workout/:assignedWorkoutId",
            element: <AssignedWorkoutBuilder/>,
          },
          {
            path: "library",
            children: [
              { path: "", element: <Navigate to="./plans" /> },
              { path: "plans", element: <CoachLibraryView tab="plans" /> },
              {
                path: "workouts",
                element: <CoachLibraryView tab="workouts" />,
              },
              {
                path: "workouts/new_workout",
                element: <WorkoutBuilder />,
              },
              {
                path: "workouts/edit_workout/:workoutId",
                element: <WorkoutBuilder />,
              },
              {
                path: "plans/new_plan",
                element: <TrainingPlanBuilder />,
              },
              {
                path: "plans/edit_plan/:trainingPlanId",
                element: <TrainingPlanBuilder />,
              },
            ],
          },
          {
            path: "leaderboard",
            children: [
              { path: "", element: <Navigate to="./performance" /> },
              { path: "performance", element: <CoachLeaderBoardView tab="performance"/>},
              { path: "positions", element: <CoachLeaderBoardView tab="positions"/>},
              { path: "compliance", element: <CoachLeaderBoardView tab="compliance" />},
            ]
          },
          {
            path: "settings",
            element: 
              <WithCache
                tables={["coach"]}
                component={(coach) => (
                  <CoachSettingsView coach={coach.coach[0]} />
                )}
              />
            ,
          },
        ],
      },
      {
        path: "",
        element: (
          <>
            <AuthState authState="logged-in-cyclist">
              <Navigate to="/dashboard" />
            </AuthState>
            <AuthState authState="logged-in-coach">
              <Navigate to="/coach" />
            </AuthState>
            <AuthState authState="logged-in-admin">
              <Navigate to="/admin" />
            </AuthState>
            <AuthState authState="anon">
              <Navigate to="/account/login" />
            </AuthState>
            <AuthState authState="missing-info">
              <Navigate to="/account/profile-setup" />
            </AuthState>
            <AuthState authState="profile-success">
              <Navigate to="/account/profile-complete" />
            </AuthState>
            <AuthState authState="coach-profile-success">
              <Navigate to="/account/profile-complete" />
            </AuthState>
          </>
        ),
      },
      {
        path: "*",
        // element: <Status404 />,
      },
    ],
  },
];

export default router;
