import {
  Box,
  Button,
  FormControl,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { expertiseOptions } from "src/utils/common";
import { TableRow } from "src/contexts/CacheContext";
import { FormikProps } from "formik";
import { CoachSettingsAccountType } from "./CoachSettingsView";

export type CoachSettingsAccountProps = {
  coach: TableRow<"coach">;
  formik: FormikProps<CoachSettingsAccountType>;
};

export default function CoachSettingsProfile(props: CoachSettingsAccountProps) {
  return (
    <>
      <Box
        sx={{
          width: "98%",
          padding: "2.5em",
          paddingTop: "1em",
          background: "#201B20",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Typography variant="h5">
            QUALIFICATIONS AND CERTIFICATIONS
          </Typography>
        </Box>
        <TextareaAutosize
          id="qualifications"
          name="qualifications"
          minRows={5}
          placeholder="Type your description here...  "
          value={props.formik.values.qualifications}
          onChange={props.formik.handleChange}
          style={{
            padding: "1em",
            width: "80%",
            height: "120px",
            background: "#201B20",
            borderRadius: "6px",
            color: "#F7F3F0",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            marginTop: "1.5rem",
          }}
        >
          <Typography variant="h5">EXPERTISE</Typography>
          <FormControl>
              <Select
                labelId="expertise"
                name="expertise"
                multiple
                renderValue={(selected) => selected.join(', ')}
                value={props.formik.values.expertise}
                onChange={props.formik.handleChange}
                sx={{
                  color: "#F2F2F2",
                  "& .MuiSvgIcon-root": {
                    color: "#F2F2F2",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#DD4F4A !important",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      border: ".0625rem solid #64605D",
                    },
                    sx: {
                      padding: "0", 
                      "&& .Mui-selected:hover": {backgroundColor: "rgba(221, 79, 74, 0.2)"},
                      "&& .Mui-selected": {backgroundColor: "rgba(221, 79, 74, 0.1)"}
                    },
                  },
                  MenuListProps: {
                    disablePadding: true,
                  },
                }}
              >
                {expertiseOptions.map((option) => (
                  <MenuItem sx={{padding: "0"}} key={option} value={option}>
                    <Radio 
                      sx={{
                        color: "#DD4F4A",
                        '&.Mui-checked': {
                          color: "#DD4F4A",
                        },
                      }}
                      checked={props.formik.values.expertise.indexOf(option) > -1} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            marginTop: "1.5rem",
          }}
        >
          <Typography variant="h5">CONTACT INFORMATION</Typography>
          <TextField
            id="phone"
            name="phone"
            value={props.formik.values.phone}
            onChange={props.formik.handleChange}
            placeholder="Phone"
            sx={{
              border: "1px solid #64605D",
              borderRadius: "4px",
              borderRightColor: "#A19D9A",
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#DD4F4A !important",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            marginTop: "1.5rem",
            marginBottom: "1rem",
          }}
        >
          <Typography variant="h5">OTHER INFO</Typography>
        </Box>
        <TextareaAutosize
          id="other_info"
          name="other_info"
          minRows={5}
          value={props.formik.values.other_info}
          onChange={props.formik.handleChange}
          placeholder="Type your description here...  "
          style={{
            padding: "1em",
            width: "80%",
            height: "100px",
            background: "#201B20",
            borderRadius: "6px",
            color: "#F7F3F0",
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="coachVariant"
            type="submit"
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
}
