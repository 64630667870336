import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import SubscriptionComponent from "./SubscriptionComponent";

export type SubscriptionViewProps = {
  account_type: "PREMIUM" | "BASIC";
  account_exp: Date;
};

export default function SubscriptionView(props: SubscriptionViewProps) {
  const theme = useTheme();
  const breakpoint = theme.breakpoints.down("md");
  const isMobileScreen = useMediaQuery("(max-width:480px)");
  return (
    <Box
      sx={{
        width: "98%",
        background: "#201B20",
        borderRadius: ".625rem",
        paddingY: "1.5em",
        paddingX: isMobileScreen ? "1rem" : "2rem",
        minHeight: "70vh",
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography sx={{ fontSize: "13px" }}>ACCOUNT STATUS</Typography>
        <Typography sx={{ fontSize: "13px", color: "#BC6D29" }}>
          {props.account_type}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 3,
          [breakpoint]: {
            flexDirection: "column",
          },
        }}
      >
        <SubscriptionComponent
          sub_type="Basic"
          features={[
            "Cycling position tracking",
            "Cycling position analysis",
            "Speed estimation",
            "Training calendar",
            "Performance analysis",
            "Integration with 3rd party platforms",
            "Import fit files",
          ]}
          features_info="See all features"
          pricing="FREE"
          buttonText="Select"
        />
        <SubscriptionComponent
          sub_type="Premium"
          features={[
            "Training planning",
            "Performance prediction",
            "Workout library",
            "Enhanced analysis",
            "Set custom positions",
            "Connect to a Coach",
          ]}
          features_info="See all features"
          pricing="€95.99 | Year"
          pricing_info="Per active user / month billed annually* or €9.99 billed monthly"
          buttonText="Subscribed"
        />
      </Box>
    </Box>
  );
}
