import {
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import arrowBack from "../../../assets/img/layout/coach/MyAthletes/arrowBack.svg";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import dayjs from "dayjs";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import CalWidgetCyclist from "./CalWidgetCyclist";
import SensorComponent from "src/content/customCoachDashboard/Library/TrainingPlans/SensorComponent";
import { sensorList } from "src/utils/common";
  
export type FormikProps = {
  name: string;
  sensors: Array<string>;
};
  
  export default function ViewPlan() {
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(0);
    const [tabs, setTabs] = useState([0]);
    const [add, setAdd] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const [assignState, setAssignState] = useState<
      "nothing" | "athlete" | "date"
    >("nothing");
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>();
  
    useEffect(() => {
      if (assignState === "nothing") {
        setStartDate(new Date());
        setEndDate(dayjs().add(tabs.length, "week").subtract(1, "day").toDate());
      }
    }, [assignState]);
  
    // Tabs Logic
    const handleChange = useCallback((event, newValue) => {
      setSelectedTab(newValue);
    }, []);
  
    // Formik
    const validationSchema = useMemo(() => yup.object().shape({}), []);
    const formik = useFormik<FormikProps>({
      initialValues: { name: "Training Plan", sensors: [] },
      validationSchema,
      onSubmit: (values) => {},
    });
  
    const handleSensor = useCallback(
      (id: string) => {
        formik.setFieldValue(
          "sensors",
          formik.values.sensors.includes(id)
            ? formik.values.sensors.filter((sensor) => sensor !== id)
            : formik.values.sensors.concat([id]),
        );
      },
      [formik.values.sensors],
    );
  
    const chartOptions: ApexOptions = {
      chart: {
        type: "bar",
        height: "100%",
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "100%",
        },
      },
      grid: {
        show: false,
      },
      colors: ["#BC6D29"],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Week 1", "Week 2", "Week 3", "Week 4"],
        labels: {
          style: {
            colors: ["#C1C1C1", "#C1C1C1", "#C1C1C1", "#C1C1C1"],
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            return value.toFixed(0);
          },
          style: {
            colors: "#C1C1C1",
          },
        },
      },
      title: {
        text: "Training Hours",
        style: {
          color: "#C1C1C1",
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `<div style="text-align: center; font-size:16px; color: orange; padding: 5px; border-radius: 5px;">${series[seriesIndex][dataPointIndex]}</div>`;
        },
      },
    };
  
    const chartSeries: ApexAxisChartSeries = useMemo(() => {
      return [
        {
          data: [1, 2, 4, 3],
        },
      ];
    }, []);
  
    return (
      <>
        <Box
          sx={{
            width: "96%",
            padding: "1em",
            background: "#201B20",
            borderRadius: ".625rem",
            marginTop: ".625rem",
            marginLeft: "1.5625rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{ padding: ".125rem" }}
              onClick={() => navigate("/dashboard/library")}
            >
              <img src={arrowBack} />
            </IconButton>
            <Typography>Back to Library</Typography>
          </Box>
  
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingX: "1.875rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginBottom: "1rem",
                marginTop: "2rem",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ fontSize: ".9375rem", fontWeight: 700, width: "65%" }}
              >
                Plan Description
              </Typography>
              <Typography
                sx={{
                  fontSize: ".9375rem",
                  fontWeight: 700,
                  width: "35%",
                  alignSelf: "flex-start",
                  paddingLeft: "3rem",
                }}
              >
                By John Doe
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "65%" }}>
                <Typography variant="body2">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Pariatur maxime quas odio, expedita, porro optio provident
                  necessitatibus nihil doloribus ab unde totam iste! Neque ipsum
                  nisi possimus ut officiis non doloremque repellat labore
                  veritatis, iure, dignissimos vel aut distinctio laudantium.
                  Asperiores soluta eaque suscipit quam illum quo in corporis
                  possimus.
                </Typography>
                <Typography
                  sx={{
                    marginBottom: "1rem",
                    marginTop: "2rem",
                    fontSize: ".9375rem",
                    fontWeight: 700,
                  }}
                >
                  Training Plan Sample
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#2C262D",
                    // height: 350,
                    borderRadius: "6px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        paddingX: "1em",
                      }}
                    >
                      <Tabs
                        value={selectedTab}
                        onChange={handleChange}
                        variant="scrollable"
                        sx={{
                          "& .MuiTab-root:not(.Mui-selected)": {
                            opacity: 0.5,
                          },
                          minHeight: "1.5rem",
                          "& .Mui-selected": {
                            color: "#BC6D29 !important",
                          },
                          width: "100%",
                          marginBottom: "0.5rem",
                        }}
                        TabIndicatorProps={{
                          sx: {
                            backgroundColor: "#BC6D29",
                          },
                        }}
                      >
                        {tabs.map((tab, index) => (
                          <Tab
                            label={`Week ${tab + 1}`}
                            key={tab}
                            sx={{ width: "20%" }}
                          />
                        ))}
                      </Tabs>
                      <CalWidgetCyclist
                      // date_session={[]}
                      // startOfWeek={calendarDate}
                      // endOfWeek={calendarDate.endOf("week").add(1, "day")}
                      />
                    </Box>
  
                    <Box
                      sx={{
                        background: "#24211F",
                        borderRadius: "8px",
                        padding: "2em",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        width: "90%",
                        marginBottom: "1rem",
                      }}
                    >
                      <Typography variant="h4" sx={{ color: "#DD4F4A" }}>
                        00:00:00
                      </Typography>
                      <Typography variant="h4" sx={{ color: "#E28E54" }}>
                        00 TSS
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    marginBottom: "1rem",
                    marginTop: "2rem",
                    paddingX: "0.5rem",
                    fontSize: ".9375rem",
                    fontWeight: 700,
                  }}
                >
                  Stats
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#2C262D",
                    height: "310px",
                    borderRadius: "6px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      padding: "2em",
                    }}
                  >
                    <Typography variant="h5">AVERAGE WEEKLY BREAKDOWN</Typography>
                    <Box sx={{ display: "flex", gap: 4 }}>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography variant="h4">Workouts Per Week</Typography>
                        <Typography variant="h4" color={"#BC6D29"}>
                          1-4
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography variant="h4">Weekly Avg.</Typography>
                        <Typography variant="h4" color={"#BC6D29"}>
                          05:30:00
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography variant="h4">Longest Workout</Typography>
                        <Typography variant="h4" color={"#BC6D29"}>
                          02:00:00
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ height: "200px" }}>
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="bar"
                        height={"100%"}
                      />
                    </Box>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    marginBottom: "1rem",
                    marginTop: "2rem",
                    paddingX: "0.5rem",
                    fontSize: ".9375rem",
                    fontWeight: 700,
                  }}
                >
                  Suggested Devices
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#2C262D",
                    padding: "2em",
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  {sensorList.map((sensor, index) => (
                    <SensorComponent
                      key={index}
                      sensor={sensor}
                      formik={formik.values.sensors}
                      handleSensor={handleSensor}
                    />
                  ))}
                </Box>
              </Box>
  
              <Box sx={{ width: "35%", paddingLeft: "3rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginBottom: "1rem",
                  }}
                >
                  <Typography variant="h4">Length</Typography>
                  <Typography variant="h2" color={"#E28E54"}>
                    {tabs.length} {tabs.length === 1 ? "Week" : "Weeks"}
                  </Typography>
                </Box>
                <Typography variant="h1">$23.00</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginY: "1rem",
                  }}
                >
                  <Typography variant="body2">
                    Torem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia
                    nostra, per inceptos himenaeos. Curabitur tempus urna at
                    turpis condimentum lobortis. Ut commodo efficitur neque. Ut
                    diam quam, semper iaculis condimentum ac, vestibulum eu nisl.
                  </Typography>
                </Box>
                <Button variant="contained" fullWidth sx={{ marginY: "2rem" }}>
                  Buy Now
                </Button>
                <Typography variant="subtitle2" color={"#A19D9A"}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor
                  sit autem laborum a, animi esse aperiam numquam non natus sequi
                  minus suscipit cum impedit tenetur. Temporibus eum quaerat
                  voluptates facilis.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }