import "@mui/lab/themeAugmentation";
import { alpha, createTheme, darken } from "@mui/material";
import type {} from "@mui/x-data-grid/themeAugmentation";
import DejaVuSansBold from "../../fonts/DejaVuSans-Bold-webfont.woff";
import DejaVuSans from "../../fonts/DejaVuSans-webfont.woff";
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    customDisabled: true;
    coachVariant: true;
    coachVariant2: true;
  }
}
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    custom: true;
  }
}
declare module "@mui/material/styles" {
  interface TypographyVariants {
    coach400: React.CSSProperties;
    coach700: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    coach400?: React.CSSProperties;
    coach700?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    coach400: true;
    coach700: true;
  }
}

const themeColors = {
  primary: "#F7F3F0", //NEUTRALNEUTRAL LIGHT SHADES/CRYSTAL CLEAR
  secondary: "#434343",
  success: "#57CA22",
  warning: "#FFA319",
  error: "#FF1943",
  info: "#33C2FF",
  black: "#FFFFFF",
  white: "#252525",
  primaryAlt: "#BC6D29", //RudyBrown
  trueWhite: "#ffffff",
};

const colors = {
  gradients: {
    blue1: "linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)",
    blue2: "linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)",
    blue3: "linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)",
    blue4: "linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)",
    blue5: "linear-gradient(135deg, #97ABFF 10%, #123597 100%)",
    orange1: "linear-gradient(135deg, #FCCF31 0%, #F55555 100%)",
    orange2: "linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)",
    orange3: "linear-gradient(120deg, #f6d365 0%, #fda085 100%)",
    purple1: "linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)",
    purple3: "linear-gradient(135deg, #667eea 0%, #764ba2 100%)",
    pink1: "linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)",
    pink2: "linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)",
    green1: "linear-gradient(135deg, #FFF720 0%, #3CD500 100%)",
    green2: "linear-gradient(to bottom, #00b09b, #96c93d)",
    black1: "linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)",
    black2: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
  },
  shadows: {
    success:
      "0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)",
    error:
      "0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)",
    info: "0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)",
    primary:
      "0px 1px 4px rgba(162, 48, 23, 0.25), 0px 3px 12px 2px rgba(162, 48, 23, 0.35)",
    warning:
      "0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)",
    card: "0px 0px 2px #996a6a",
    cardSm: "0px 0px 2px #996a6a",
    cardLg:
      "0 0rem 14rem 0 rgb(255 255 255 / 20%), 0 0.8rem 2.3rem rgb(111 130 156 / 3%), 0 0.2rem 0.7rem rgb(17 29 57 / 15%)",
  },
  layout: {
    general: {
      bodyBg: "#3D343E",
    },
    sidebar: {
      background: themeColors.primary,
      textColor: themeColors.secondary,
      dividerBg: "#1d1818",
      menuItemColor: "#A3A3A3",
      menuItemColorActive: "#ffffff",
      menuItemBg: themeColors.primary,
      menuItemBgActive: "#221e1e",
      menuItemIconColor: themeColors.secondary,
      menuItemIconColorActive: "#ffffff",
      menuItemHeadingColor: darken(themeColors.secondary, 0.3),
    },
  },
  alpha: {
    white: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white,
    },
    trueWhite: {
      5: alpha(themeColors.trueWhite, 0.02),
      10: alpha(themeColors.trueWhite, 0.1),
      30: alpha(themeColors.trueWhite, 0.3),
      50: alpha(themeColors.trueWhite, 0.5),
      70: alpha(themeColors.trueWhite, 0.7),
      100: themeColors.trueWhite,
    },
    black: {
      5: alpha(themeColors.black, 0.02),
      10: alpha(themeColors.black, 0.1),
      30: alpha(themeColors.black, 0.3),
      50: alpha(themeColors.black, 0.5),
      70: alpha(themeColors.black, 0.7),
      100: themeColors.black,
    },
  },
  secondary: {
    lighter: alpha(themeColors.secondary, 0.85),
    light: alpha(themeColors.secondary, 0.6),
    main: themeColors.secondary,
    dark: darken(themeColors.secondary, 0.2),
  },
  primary: {
    lighter: alpha(themeColors.primaryAlt, 0.85),
    light: alpha(themeColors.primaryAlt, 0.3),
    main: themeColors.primaryAlt,
    dark: darken(themeColors.primaryAlt, 0.2),
  },
  success: {
    lighter: alpha(themeColors.success, 0.85),
    light: alpha(themeColors.success, 0.3),
    main: themeColors.success,
    dark: darken(themeColors.success, 0.2),
  },
  warning: {
    lighter: alpha(themeColors.warning, 0.85),
    light: alpha(themeColors.warning, 0.3),
    main: themeColors.warning,
    dark: darken(themeColors.warning, 0.2),
  },
  error: {
    lighter: alpha(themeColors.error, 0.85),
    light: alpha(themeColors.error, 0.3),
    main: themeColors.error,
    dark: darken(themeColors.error, 0.2),
  },
  info: {
    lighter: alpha(themeColors.info, 0.85),
    light: alpha(themeColors.info, 0.3),
    main: themeColors.info,
    dark: darken(themeColors.info, 0.2),
  },
};

export const SmartCycloTheme = createTheme({
  // direction: i18n.dir(),
  colors: {
    gradients: {
      blue1: colors.gradients.blue1,
      blue2: colors.gradients.blue2,
      blue3: colors.gradients.blue3,
      blue4: colors.gradients.blue4,
      blue5: colors.gradients.blue5,
      orange1: colors.gradients.orange1,
      orange2: colors.gradients.orange2,
      orange3: colors.gradients.orange3,
      purple1: colors.gradients.purple1,
      purple3: colors.gradients.purple3,
      pink1: colors.gradients.pink1,
      pink2: colors.gradients.pink2,
      green1: colors.gradients.green1,
      green2: colors.gradients.green2,
      black1: colors.gradients.black1,
      black2: colors.gradients.black2,
    },
    shadows: {
      success: colors.shadows.success,
      error: colors.shadows.error,
      primary: colors.shadows.primary,
      info: colors.shadows.info,
      warning: colors.shadows.warning,
    },
    alpha: {
      white: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white,
      },
      trueWhite: {
        5: alpha(themeColors.trueWhite, 0.02),
        10: alpha(themeColors.trueWhite, 0.1),
        30: alpha(themeColors.trueWhite, 0.3),
        50: alpha(themeColors.trueWhite, 0.5),
        70: alpha(themeColors.trueWhite, 0.7),
        100: themeColors.trueWhite,
      },
      black: {
        5: alpha(themeColors.black, 0.02),
        10: alpha(themeColors.black, 0.1),
        30: alpha(themeColors.black, 0.3),
        50: alpha(themeColors.black, 0.5),
        70: alpha(themeColors.black, 0.7),
        100: themeColors.black,
      },
    },
    secondary: {
      lighter: alpha(themeColors.secondary, 0.1),
      light: alpha(themeColors.secondary, 0.3),
      main: themeColors.secondary,
      dark: darken(themeColors.secondary, 0.2),
    },
    primary: {
      lighter: alpha(themeColors.primary, 0.1),
      light: alpha(themeColors.primary, 0.3),
      main: themeColors.primary,
      dark: darken(themeColors.primary, 0.2),
    },
    success: {
      lighter: alpha(themeColors.success, 0.1),
      light: alpha(themeColors.success, 0.3),
      main: themeColors.success,
      dark: darken(themeColors.success, 0.2),
    },
    warning: {
      lighter: alpha(themeColors.warning, 0.1),
      light: alpha(themeColors.warning, 0.3),
      main: themeColors.warning,
      dark: darken(themeColors.warning, 0.2),
    },
    error: {
      lighter: alpha(themeColors.error, 0.1),
      light: alpha(themeColors.error, 0.3),
      main: themeColors.error,
      dark: darken(themeColors.error, 0.2),
    },
    info: {
      lighter: alpha(themeColors.info, 0.1),
      light: alpha(themeColors.info, 0.3),
      main: themeColors.info,
      dark: darken(themeColors.info, 0.2),
    },
  },
  general: {
    reactFrameworkColor: "#00D8FF",
    borderRadiusSm: "4px",
    borderRadius: "4px",
    borderRadiusLg: "12px",
    borderRadiusXl: "16px",
  },
  sidebar: {
    background: colors.layout.sidebar.background,
    textColor: colors.layout.sidebar.textColor,
    dividerBg: colors.layout.sidebar.dividerBg,
    menuItemColor: colors.layout.sidebar.menuItemColor,
    menuItemColorActive: colors.layout.sidebar.menuItemColorActive,
    menuItemBg: colors.layout.sidebar.menuItemBg,
    menuItemBgActive: colors.layout.sidebar.menuItemBgActive,
    menuItemIconColor: colors.layout.sidebar.menuItemIconColor,
    menuItemIconColorActive: colors.layout.sidebar.menuItemIconColorActive,
    menuItemHeadingColor: colors.layout.sidebar.menuItemHeadingColor,
    boxShadow: "1px 0 0 #413333",
    width: "290px",
  },
  header: {
    height: "78px",
    background: themeColors.primary,
    boxShadow: "0px 1px 0px" + themeColors.primary,
    textColor: colors.secondary.main,
  },
  // m={3} = 3x9
  spacing: 9,
  palette: {
    common: {
      black: colors.alpha.black[100],
      white: colors.alpha.white[100],
    },
    mode: "dark",
    primary: {
      light: colors.primary.light,
      main: colors.primary.main,
      dark: colors.primary.dark,
    },
    secondary: {
      light: colors.secondary.light,
      main: colors.secondary.main,
      dark: colors.secondary.dark,
    },
    error: {
      light: colors.error.light,
      main: colors.error.main,
      dark: colors.error.dark,
      contrastText: themeColors.trueWhite,
    },
    success: {
      light: colors.success.light,
      main: colors.success.main,
      dark: colors.success.dark,
      contrastText: themeColors.trueWhite,
    },
    info: {
      light: colors.info.light,
      main: colors.info.main,
      dark: colors.info.dark,
      contrastText: themeColors.trueWhite,
    },
    warning: {
      light: colors.warning.light,
      main: colors.warning.main,
      dark: colors.warning.dark,
      contrastText: themeColors.trueWhite,
    },
    text: {
      primary: themeColors.primary,
      secondary: themeColors.primaryAlt,
      disabled: colors.alpha.black[50],
    },
    background: {
      paper: colors.alpha.white[100],
      default: colors.layout.general.bodyBg,
    },
    action: {
      active: colors.alpha.black[100],
      // hover: colors.primary.lighter,
      hoverOpacity: 0.1,
      selected: colors.alpha.black[10],
      selectedOpacity: 0.1,
      disabled: colors.alpha.black[50],
      disabledBackground: colors.alpha.black[5],
      disabledOpacity: 0.38,
      focus: colors.alpha.black[10],
      focusOpacity: 0.05,
      activatedOpacity: 0.12,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1840,
      custom: 1375,
    },
  },
  typography: {
    fontFamily: "DejaVu Sans, sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: 28,
      lineHeight: "32.58px",
      "@media (min-width: 1840px)": {
        fontSize: 30,
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: "1.5rem",
      "@media (min-width: 1840px)": {
        fontSize: 20,
      },
    },
    h3: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "1.5rem",
      "@media (min-width: 1840px)": {
        fontSize: 20,
      },
    },
    h4: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "1.5rem",
      "@media (min-width: 1840px)": {
        fontSize: 16,
      },
    },
    h5: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "1rem",
      "@media (min-width: 1840px)": {
        fontSize: 16,
      },
    },
    //default
    body1: {
      fontWeight: 700,
      fontSize: 12,
      "@media (min-width: 1840px)": {
        fontSize: 14,
      },
    },
    body2: {
      fontWeight: 400,
      fontSize: 12,
      "@media (min-width: 1840px)": {
        fontSize: 14,
      },
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: 10,
      lineHeight: "1.5rem",
      "@media (min-width: 1840px)": {
        fontSize: 12,
      },
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 10,
      lineHeight: "1.5rem",
      "@media (min-width: 1840px)": {
        fontSize: 12,
      },
    },
    coach400: {
      fontWeight: 400,
      lineHeight: "1.5rem",
      color: "#DD4F4A",
    },
    coach700: {
      fontWeight: 700,
      lineHeight: "1.5rem",
      color: "#DD4F4A",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'DejaVu Sans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local("DejaVu Sans"), url(${DejaVuSans}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'DejaVu Sans';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local("DejaVu Sans"), url(${DejaVuSansBold}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "div",
          h4: "div",
          h5: "div",
          h6: "div",
          subtitle1: "div",
          subtitle2: "div",
          body1: "div",
          body2: "div",
        },
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: 4,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          paddingLeft: 12,
          paddingRight: 12,
          borderRadius: 6,
          fontSize: 14,
        },
        startIcon: {
          position: "absolute",
          left: "1rem",
        },
        sizeSmall: {
          height: "30px",
        },
        sizeMedium: {
          height: "40px",
        },
        sizeLarge: {
          padding: "11px 24px",
        },
        textSizeSmall: {
          padding: "7px 12px",
        },
        textSizeMedium: {
          padding: "9px 16px",
        },
        textSizeLarge: {
          padding: "12px 16px",
        },
        outlined: {
          borderColor: "#F7F3F0",
          borderRadius: "6px",
          color: "#F7F3F0",
          "&:hover": {
            color: "#E28E54",
          },
          padding: "4px 12px",
        },
        text: {
          border: "1px solid #E28E54",
          bordeRadius: "6px",
          color: "#E28E54",
        },
      },
      variants: [
        {
          props: { variant: "customDisabled" },
          style: {
            border: "1px solid #787471",
            color: "#787471",
          },
        },
        {
          props: { variant: "coachVariant" },
          style: {
            border: "1px solid #DD4F4A",
            backgroundColor: "#DD4F4A",
          },
        },
        {
          props: { variant: "coachVariant2" },
          style: {
            border: "1px solid #DD4F4A",
            backgroundColor: "none",
            color: "#DD4F4A",
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "13px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "20.5rem",
          height: "2.5rem",
          borderRadius: "0.25rem",
          fontWeight: 400,
          fontSize: 14,
          "@media (max-width:480px)": {
            width: "11rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          width: "max-content",
          fontSize: "10px",
          marginLeft: "0px",
          marginTop: "4px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: "20.5rem",
          height: "2.5rem",
          borderRadius: "0.25rem",
          color: "white",
          fontWeight: 400,
          fontSize: 14,
          "@media (max-width:480px)": {
            width: "11rem",
          },
        },
        input: {
          // padding: "17.5px 11px !important",
          boxSizing: "border-box",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-0.6vh",
          color: "#F7F3F0",
          opacity: 0.5,
          "&.MuiInputLabel-shrink": {
            transform: "translate(13px, -2px)",
            fontSize: "9px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popupIndicator: {
          transform: "none",
          color: "#64605D",
        },
        paper: {
          background: "#201B20",
          border: "1px solid #000000",
          borderRadius: "6px",
          boxShadow: "0px 4px 24px -1px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#64605D",
          transform: "none",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          background: "#201B20",
          border: "1px solid #000000",
          boxShadow: "0px 4px 24px -1px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#F7F3F0",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#201B20",
          fontSize: ".625rem",
          lineHeight: ".875rem",
          fontWeight: 400,
          color: "#F7F3F0",
          padding: ".625rem",
          // width: "15.2025rem",
        },
        arrow: {
          color: "#201B20",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
        },
        cell: {
          borderBottom: "none",
        },
        columnHeaders: {
          color: "#BC6D29",
          fontSize: "0.8rem",
          borderBottom: "none",
        },
        columnSeparator: {
          display: "none",
        },
        sortIcon: {
          color: "#BC6D29",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#F7F3F0",
          textTransform: "capitalize",
          fontWeight: 700,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: "15px",
          marginTop: "21px",
        },
      },
    },
  },
  shadows: [
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],
});
