import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
export default function ResetPasswordConfirmationView() {
  return (
    <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            pt: "13.6875rem",
            "@media (max-width: 400px)": {
              alignItems: "flex-start",
            },
          }}
        >
          <Box
            sx={{
              background: "#2A252B",
              width: "47.1875rem",
              height: "14.625remß",
              borderRadius: "1.25rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h1" color="text.secondary" mt="6rem">
              Reset Your Password
            </Typography>
            <Typography
              variant="body2"
              fontSize={13}
              mt={".8125rem"}
              mb={"2.5rem"}
              lineHeight={"20px"}
            >
              Thank you, an email has been sent to you.
            </Typography>
            <Typography
              component={Link}
              to={`/account/login/`}
              fontSize={12}
              sx={{ color: "#BC6D29", textDecoration: "none" }}
              variant="h5"
              mb={"1.0625rem"}
            >
              Sign In here
            </Typography>
          </Box>
        </Box>
    </>
  );
}
