import { createContext, useCallback, useContext } from "react";
import { useSupabase } from "./SupabaseContext";
import { Database } from "src/utils/DatabaseDefinitions";

type NotificationDispatch =
  Database["public"]["Tables"]["notifications"]["Insert"];

const DispatchNotificationContext =
  createContext<(notification: NotificationDispatch) => void>(undefined);

export const useDispatchNotification = () =>
  useContext(DispatchNotificationContext);

export default function NotificationContext(
  props: React.PropsWithChildren<{}>,
) {
  const supabase = useSupabase();
  const notificationPromise = useCallback(
    async (notification: NotificationDispatch) =>
      supabase.from("notifications").insert(notification),
    [supabase],
  );

  return (
    <DispatchNotificationContext.Provider value={notificationPromise}>
      {props.children}
    </DispatchNotificationContext.Provider>
  );
}
