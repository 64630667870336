import { FormElements } from "./FormElements";
import SidebarBtnElement from "./SidebarBtnElement";
import { Grid } from "@mui/material";

export default function DesignerSidebar() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={6} md={3.5} xl={4}>
        <SidebarBtnElement formElement={FormElements.Warmup} />
      </Grid>
      {/* <Grid item xs={3.5} xl={4}>
        <SidebarBtnElement formElement={FormElements.Intervals} />
      </Grid> */}
      <Grid item xs={6} md={3.5} xl={4}>
        <SidebarBtnElement formElement={FormElements.Cooldown} />
      </Grid>
      <Grid item xs={6} md={3.5} xl={4}>
        <SidebarBtnElement formElement={FormElements.Recovery} />
      </Grid>
      <Grid item xs={6} md={3.5} xl={4}>
        <SidebarBtnElement formElement={FormElements.Active} />
      </Grid>
      <Grid item xs={6} md={3.5} xl={4}>
        <SidebarBtnElement formElement={FormElements.Other} />
      </Grid>
    </Grid>
  );
}
