import { useRoutes } from "react-router-dom";
import router from "src/router";

import { SnackbarProvider } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import SupabaseProvider from "./contexts/SupabaseContext";
import Cache from "./contexts/CacheContext";
import NotificationContext from "./contexts/NotificationContext";

function App() {
  const content = useRoutes(router);

  return (
    <SupabaseProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <CssBaseline />
            <Cache>
              <NotificationContext>{content}</NotificationContext>
            </Cache>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </SupabaseProvider>
  );
}
export default App;
