import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography,
} from "@mui/material";

export type CoachOverallTableProps = {
  data: {
    header: string;
    valueAvg: number | string;
    valueMax?: number | string;
  }[];
};

export default function CoachOverallTable(props: CoachOverallTableProps) {
  return (
    <>
      <Grid item xs={12} width={{ xs: "98%", md: "98%" }}>
        <Table
          sx={{
            background: "#201B20",
            borderRadius: "10px",
            minHeight: { xs: "252px", xl: "387px" },
          }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Typography variant="h4" color="#64605D">
                  AVG
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" color="#64605D">
                  MAX
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((props) => (
              <TableRow key={props.header}>
                <TableCell sx={{ border: "none" }} size="small">
                  <Typography variant="h5" color="#64605D">
                    {props.header}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "none" }} size="small">
                  <Typography variant="h4" color="primaryAlt">
                    {props.valueAvg?.toString().charAt(0) === "0" &&
                    props.header !== "Speed"
                      ? "-" + props.valueAvg?.toString().slice(1)
                      : props.valueAvg}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "none" }} size="small">
                  <Typography variant="h4" color="#DD4F4A">
                    {props.valueMax?.toString().charAt(0) === "0" &&
                    props.header !== "Speed"
                      ? "-" + props.valueMax?.toString().slice(1)
                      : props.valueMax}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
}