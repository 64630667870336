import {
    Box,
    Button,
    Checkbox,
    IconButton,
    ListItemText,
    Modal,
    Paper,
    Popover,
    Typography,
    checkboxClasses,
  } from "@mui/material";
  import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
  import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
  import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
  } from "react";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import { SupabaseCall, toHHMMSS, withReference } from "src/utils/common";
import dayjs from "dayjs";
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
  
  export type ModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setSteps: Dispatch<any>;
    steps: any;
    selectedDay: number;
  };
  
  type ShowHideColumnsComponentProps = {
    allColumns: Array<string>;
    defaultSelectedColumns: Array<string>;
    onChange: (selectedColumns: Array<string>) => void;
  };
  
  function ShowHideColumnsComponent(props: ShowHideColumnsComponentProps) {
    const [selectedColumns, setSelectedColumns] = useState(
      props.defaultSelectedColumns,
    );
  
    useEffect(() => {
      props.onChange(selectedColumns);
    }, [selectedColumns]);
  
    return (
      <Box sx={{ p: 1, background: "#201B20" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: "10px",
            mt: "16px",
          }}
        >
          {props.allColumns.map((option, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                width: "20.625rem",
              }}
              onClick={(_) =>
                selectedColumns.includes(option)
                  ? setSelectedColumns(
                      selectedColumns.filter((op) => op !== option),
                    )
                  : setSelectedColumns(selectedColumns.concat([option]))
              }
            >
              <Checkbox
                checked={selectedColumns.includes(option)}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: "#DD4F4A",
                  },
                }}
              />
              <ListItemText primary={option} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
  
  export default function AddNewWorkoutModal(props: ModalProps) {
    const supabase = useSupabase();
    const user = useUser();
    const units = useUnits();
    const handleClose = useCallback(() => {
      props.setOpen(false);
    }, []);
    const allColumns: GridColDef[] = [
      {
        field: "name",
        headerName: "Name",
        headerClassName: "column-headers",
        editable: false,
        width: 200,
        hideable: false,
      },
      {
        field: "workout_type",
        headerName: "Workout Type",
        headerClassName: "column-headers",
        editable: false,
        flex: 1,
        hideable: false,
      },
      {
        field: "duration",
        headerName: "Duration",
        headerClassName: "column-headers",
        editable: false,
        flex: 1,
        hideable: false,
        renderCell: (field) => toHHMMSS(field.value)
      },
      {
        field: "distance",
        headerName: "Distance",
        headerClassName: "column-headers",
        editable: false,
        flex: 1,
        hideable: false,
        valueFormatter: ({value}) => typeof value === "number" ?  
          units === "metric"
          ? `${value.toFixed(2) ?? "0"} km`
          : `${
            value
                ? convert(value)
                    .from("km")
                    .to("mi")
                    .toFixed(2)
                : "0"
            } mi`
        : value
      },
      {
        field: "created_at",
        headerName: "Date Created",
        headerClassName: "column-headers",
        editable: false,
        flex: 1,
        hideable: false,
        renderCell: (field) =>
          withReference(new Date(field.value), (date) => (
            <Box
              sx={{
                width: "100%",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Box>
                <Typography variant="h4">
                  {date.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </Typography>
              </Box>
            </Box>
          )),
      },
      {
        field: "bike_type",
        headerName: "Bike Type",
        headerClassName: "column-headers",
        editable: false,
        flex: 1,
        hideable: false,
      },
      {
        field: "tss",
        headerName: "TSS",
        headerClassName: "column-headers",
        editable: false,
        flex: 1,
        hideable: false,
      },
      {
        field: "position_goal",
        headerName: "Position Goal",
        headerClassName: "column-headers",
        editable: false,
        flex: 1,
        hideable: false,
      },
    ];
  
    //Visibility
  
    const [anchorElMulti, setAnchorElMulti] = useState(null);
  
    const handlePopoverOpenMulti = (event) => {
      setAnchorElMulti(event.currentTarget);
    };
  
    const handlePopoverCloseMulti = () => {
      setAnchorElMulti(null);
    };
  
    const openMulti = Boolean(anchorElMulti);
  
    const [columns, setColumns] = useState<Array<GridColDef>>(
      allColumns.filter((col) => col.headerName),
    );
  
    useEffect(() => {
      setColumns(allColumns.filter((col) => col.headerName));
    }, []);

    const workoutsPromise = useCallback(
      () =>
        supabase
          .from("workout")
          .select("*")
          .eq("user_id", user.id)
      ,[supabase],
    );
  
    const workoutsCall =
    useAsyncState<SupabaseCall<typeof workoutsPromise>>(); // prettier-ignore
  
    useEffect(() => {
      if (isUnloaded(workoutsCall)) {
        workoutsCall.fire(async () => workoutsPromise());
      }
    }, [supabase, workoutsCall]);
  
    const rows = useMemo(
      () => {
        if(isFulfilled(workoutsCall)) {
          return workoutsCall.result.data
        }
        return []
      },
      [workoutsCall],
    );
    
    return (
      <>
        <Modal open={props.open} onClose={() => handleClose()}>
          <Paper sx={{ backgroundColor: "#201B20" }}>
            <Box p={2}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h2">Workouts</Typography>
                <Button variant="coachVariant2" onClick={handleClose}>
                  Close
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "500px",
                }}
              >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  onRowClick={(e) => {
                    props.steps[props.selectedDay] = e.row
                    props.setSteps([...props.steps])
                  }}
                  pageSize={10}
                  autoHeight
                  disableColumnSelector
                  disableDensitySelector
                  sx={{
                    height: "auto",
                    "& .column-headers": {
                      color: "#DD4F4A",
                      border: "none",
                      "&:focus-within": {
                        outline: "0.5px solid #DD4F4A",
                      },
                    },
                    "& .MuiDataGrid-sortIcon": {
                      opacity: 1,
                      color: "#DD4F4A",
                    },
                  }}
                  // filterModel={filterModel}
                  // onFilterModelChange={(newFilterModel) =>
                  //   setFilterModel(newFilterModel)
                  // }
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    columnMenu: {
                      sx: {
                        boxShadow: "none",
                      },
                    },
                    filterPanel: {
                      sx: {
                        width: "1000px",
                        padding: "2em",
                        backgroundColor: "#201C20",
                        placement: "center",
                        borderRadius: "4px",
                      },
                    },
                    panel: {
                      placement: "bottom",
                      sx: {
                        top: "-120px !important",
                        "& .MuiDataGrid-panelWrapper": {
                          bgcolor: "#201C20",
                        },
                        "& .MuiInputBase-root": {
                          width: "200px",
                          marginTop: "0px",
                          padding: "0px",
                        },
                        "& .MuiInput-root:after": {
                          borderBottomColor: "#DD4F4A",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#DD4F4A",
                        },
                        "& .MuiDataGrid-filterFormColumnInput": {
                          width: "200px",
                          margin: "5px",
                        },
                        "& .MuiDataGrid-filterFormOperatorInput": {
                          width: "200px",
                          margin: "5px",
                        },
                        "& .MuiDataGrid-filterFormValueInput": {
                          width: "200px",
                          margin: "5px",
                        },
                        "& .MuiNativeSelect-select": {
                          padding: "0px",
                        },
                      },
                    },
                    toolbar: {
                      showQuickFilter: true,
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      sx: {
                        "& .MuiButton-startIcon": {
                          display: "none",
                        },
                        "& .MuiButton-root": {
                          color: "#DD4F4A",
                          borderColor: "#DD4F4A",
                        },
                        "& .MuiInput-root:after": {
                          borderBottomColor: "#DD4F4A",
                        },
                      },
                    },
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    marginLeft: "100px",
                  }}
                >
                  <IconButton
                    sx={{ color: "#DD4F4A", p: 1.5 }}
                    onClick={handlePopoverOpenMulti}
                  >
                    <VisibilityOutlinedIcon />
                  </IconButton>
                  <Popover
                    open={openMulti}
                    anchorEl={anchorElMulti}
                    onClose={handlePopoverCloseMulti}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <ShowHideColumnsComponent
                      allColumns={allColumns
                        .filter((col) => col.headerName !== "Actions")
                        .map((col) => col.headerName)}
                      defaultSelectedColumns={columns.map(
                        (col) => col.headerName,
                      )}
                      onChange={(cols) =>
                        setColumns(
                          allColumns.filter(
                            (col) =>
                              cols.includes(col.headerName) ||
                              col.headerName === "Actions",
                          ),
                        )
                      }
                    />
                  </Popover>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Modal>
      </>
    );
  }