import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { forwardRef, useCallback, useEffect } from "react";
import { useUser } from "src/components/Authenticated";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isRejected } from "src/utils/Async";
import * as yup from "yup";

export type CoachChangePasswordProps = {
  onClose: () => void;
};

export default forwardRef(function ChangePassword(
  props: CoachChangePasswordProps,
  ref,
) {
  const user = useUser();
  const supabase = useSupabase();
  const formik = useFormik<{
    email: string;
    password: string;
    newPassword: string;
    confirmPassword: string;
  }>({
    initialValues: {
      email: user.email,
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .min(8)
        .max(255)
        .required("The current password is required"),
      newPassword: yup
        .string()
        .min(8)
        .max(255)
        .required("The new password is required"),
      confirmPassword: yup
        .string()
        .required("Confirm new password")
        .oneOf([yup.ref("newPassword")], "Passwords must match"),
    }),
    onSubmit: (values) => {
      if ("fire" in changePasswordCall) {
        changePasswordCall.fire(async () =>
          changePasswordPromise(
            values.email,
            values.password,
            values.newPassword,
          ),
        );
      }
    },
  });

  const changePasswordPromise = useCallback(
    (email: string, currentPassword: string, newPassword: string) =>
      supabase.functions.invoke("change-password", {
        body: JSON.stringify({ email, currentPassword, newPassword }),
      }),
    [supabase],
  );

  const changePasswordCall = useAsyncState<any>();

  //SnackBar
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isFulfilled(changePasswordCall)) {
      if (changePasswordCall.result.data.error === "Invalid login credentials")
        enqueueSnackbar("Incorrect Password", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      else {
        enqueueSnackbar("Password changed successfully", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          variant: "success",
          autoHideDuration: 2000,
        });
        props.onClose();
      }
    }
    if (isRejected(changePasswordCall)) {
      enqueueSnackbar("Something went wrong!", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [changePasswordCall]);

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          background: "#2A252B",
          padding: "3em",
          borderRadius: "1.25rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        ref={ref}
      >
        <Typography variant="h1" color="#DD504A">
          Change Password
        </Typography>
        <Typography
          variant="body2"
          mt={".8125rem"}
          mb={"1.25rem"}
          textAlign={"center"}
          lineHeight={"20px"}
        >
          Your password must be at least 8 characters.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "1.5rem",
          }}
        >
          <TextField
            placeholder="Current Password"
            name="password"
            type="password"
            error={Boolean(formik.touched.password && formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          <TextField
            placeholder="New Password"
            name="newPassword"
            type="password"
            error={Boolean(
              formik.touched.newPassword && formik.errors.newPassword,
            )}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
          />
          <TextField
            placeholder="Confirm New Password"
            name="confirmPassword"
            type="password"
            error={Boolean(
              formik.touched.confirmPassword && formik.errors.confirmPassword,
            )}
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
          />
        </Box>

        <Button
          variant="coachVariant"
          size="small"
          sx={{
            width: "11.6875rem",
            mt: "1.5rem",
            height: "1.875rem",
          }}
          type="submit"
        >
          <Typography variant="h5">Change Password</Typography>
        </Button>
      </Box>
    </form>
  );
});
