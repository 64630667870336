import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useState,
  } from "react";
  import {
    ElementsType,
    FormElementInstance,
    FormElements,
  } from "./FormElements";
  import { nanoid } from "@reduxjs/toolkit";
  
  type DesignerContextType = {
    elements: FormElementInstance[];
    intervalElements: {
      id: string;
      elements: FormElementInstance[];
    }[];
    setIntervalElements: Dispatch<
      SetStateAction<
        {
          id: string;
          elements: FormElementInstance[];
        }[]
      >
    >;
    setElements: Dispatch<SetStateAction<FormElementInstance[]>>;
    addElement: (index: number, element: FormElementInstance) => void;
    addIntervalElement: (
      interval: string,
      element?: FormElementInstance,
      index?: number,
    ) => void;
    addIntervalElements: (
      interval: string,
      elements: FormElementInstance[],
    ) => void;
    removeElement: (id: string) => void;
    updateElement: (id: string, element: FormElementInstance) => void;
    bikeType: string;
    setBikeType: Dispatch<SetStateAction<string>>;
    selectedElement: FormElementInstance | null;
    setSelectedElement: Dispatch<SetStateAction<FormElementInstance | null>>;
    intervalCounter: (id: string, type: string) => void;
  };
  
  export const DesignerContext = createContext<DesignerContextType | null>(null);
  
  export default function DesignerContextProvider({
    children,
  }: {
    children: ReactNode;
  }) {
    const [elements, setElements] = useState<FormElementInstance[]>([]);
    const [selectedElement, setSelectedElement] =
      useState<FormElementInstance | null>(null);
    const [bikeType, setBikeType] = useState<string>("Road");
    const [intervalElements, setIntervalElements] = useState<
      { id: string; elements: FormElementInstance[] }[]
    >([]);
  
    const addElement = (index: number, element: FormElementInstance) => {
      setElements((prev) => {
        const newElements = [...prev];
        newElements.splice(index, 0, element);
        return newElements;
      });
    };
  
    const addIntervalElement = (
      interval: string,
      element?: FormElementInstance,
      index?: number,
    ) => {
      setIntervalElements((prev) => {
        const intervalIndex = prev?.findIndex((item) => item.id === interval);
        // If interval doesn't exist, create a new one
        if (intervalIndex === -1) {
          const activeId = nanoid();
          const recoveryId = nanoid();
          const activeElement =
            FormElements["Active" as ElementsType].construct(activeId);
          const recoveryElement =
            FormElements["Recovery" as ElementsType].construct(recoveryId);
  
          const newIntervalElement = {
            id: interval.toString(),
            elements: [activeElement, recoveryElement],
          };
          return [...prev, newIntervalElement];
        } else {
          // If interval exists, update its elements
          const updatedElements = [...prev];
          const newElements = [...updatedElements[intervalIndex].elements];
          newElements.splice(index, 0, element);
          updatedElements[intervalIndex] = {
            ...updatedElements[intervalIndex],
            elements: newElements,
          };
          return updatedElements;
        }
      });
    };
  
    const addIntervalElements = (
      interval: string,
      elements: FormElementInstance[],
    ) => {
      setIntervalElements((prev) => {
        const newIntervalElement = {
          id: interval,
          elements,
        };
        return [...prev, newIntervalElement];
      });
    };
  
    const removeElement = (id: string) => {
      // Check if element is inside interval
      const isNewIdPresent = intervalElements.some((interval) => {
        return interval.elements.some((element) => {
          return element.id === id;
        });
      });
  
      // If true remove from interval if false remove from outside
      if (!isNewIdPresent) {
        setElements((prev) => prev.filter((element) => element.id !== id));
        setIntervalElements((prev) =>
          prev.filter((element) => element.id !== id),
        );
      } else {
        const indexOfInterval = intervalElements.findIndex((interval) => {
          return interval.elements.some((element) => {
            return element.id === id;
          });
        });
        setIntervalElements((prev) => {
          const updatedIntervals = prev.map((interval, index) => {
            if (index === indexOfInterval) {
              const updatedElements = interval.elements.filter(
                (element) => element.id !== id,
              );
              return { ...interval, elements: updatedElements };
            }
            return interval;
          });
          return updatedIntervals;
        });
      }
    };
  
    const updateElement = (id: string, element: FormElementInstance) => {
      // Check if element is inside interval
      const isNewIdPresent = intervalElements.some((interval) => {
        return interval.elements.some((element) => {
          return element.id === id;
        });
      });
  
      // If true remove from interval if false remove from outside
      if (!isNewIdPresent) {
        setElements((prev) => {
          const newElements = [...prev];
          const index = newElements.findIndex((el) => el.id === id);
          newElements[index] = element;
          return newElements;
        });
      } else {
        const indexOfInterval = intervalElements.findIndex((interval) => {
          return interval.elements.some((element) => {
            return element.id === id;
          });
        });
        setIntervalElements((prev) => {
          const newIntervals = [...prev];
          const intervalToUpdate = { ...newIntervals[indexOfInterval] };
          const updatedElements = intervalToUpdate.elements.map((el) =>
            el.id === id ? element : el,
          );
          intervalToUpdate.elements = updatedElements;
          newIntervals[indexOfInterval] = intervalToUpdate;
          return newIntervals;
        });
      }
    };
  
    const intervalCounter = (id: string, type: string) => {
      setElements((prev) => {
        const newElements = [...prev];
        const intervalIndex = elements.findIndex((el) => el.id === id);
  
        newElements[intervalIndex].intervalCounter =
          type === "plus"
            ? newElements[intervalIndex].intervalCounter + 1
            : newElements[intervalIndex].intervalCounter === 1
            ? newElements[intervalIndex].intervalCounter
            : newElements[intervalIndex].intervalCounter - 1;
        return newElements;
      });
    };
    return (
      <DesignerContext.Provider
        value={{
          elements,
          setElements,
          addElement,
          addIntervalElement,
          addIntervalElements,
          removeElement,
          selectedElement,
          setSelectedElement,
          bikeType,
          setBikeType,
          intervalElements,
          setIntervalElements,
          updateElement,
          intervalCounter,
        }}
      >
        {children}
      </DesignerContext.Provider>
    );
  }