import { Box, Card, CardContent, Typography } from "@mui/material";
export type OverallCardBalanceProps = {
  value1: string | number;
  value2: string | number;
  value3: string | number;
  label: string;
  label2: string;
  icon1: JSX.Element;
  icon2: JSX.Element;
};
export default function OverallCardBalance({
  value1,
  value2,
  value3,
  label,
  label2,
  icon1,
  icon2,
}: OverallCardBalanceProps) {
  return (
    <>
      <Card
        sx={{
          background: "#201B20",
          borderRadius: "10px",
          minHeight: { xs: "131px", xl: "150px" },
        }}
      >
        <CardContent
          sx={{
            padding: "10px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h5"
              color={"#BC6D29"}
              marginTop={1}
              sx={{
                fontSize: {
                  xs: ".875rem",
                  xl: "1.125rem",
                },
              }}
            >
              {value1 === "0%" ? "-%" : value1}L/{" "}
              {value1 === "0%" ? "-%" : value2}R
            </Typography>
            {icon1}
          </Box>
          <Typography variant="body2" color={"#64605D"} marginBottom={1.3}>
            {label}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h5"
              color={"#BC6D29"}
              sx={{
                fontSize: {
                  xs: ".875rem",
                  xl: "1.125rem",
                },
              }}
            >
              {value3}
            </Typography>
            {icon2}
          </Box>
          <Typography variant="body2" color={"#64605D"}>
            {label2}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
