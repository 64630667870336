import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useFormik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useUser } from "src/components/Authenticated";
import { SupabaseCall, imperialToMeters } from "src/utils/common";
import * as yup from "yup";
import convert from "convert-units";

//Date
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../../../assets/scss/Calendar.scss";
import editInput from "../../../assets/img/views/settings/editInput.svg";
import useAsyncState, { isFulfilled, isPending } from "src/utils/Async";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useSnackbar } from "notistack";
import { TableRow } from "src/contexts/CacheContext";
import ChangePassword from "src/content/customComponents/ChangePassword";

export type MyAccountViewType = {
  // email: string;
  name: string;
  dateofbirth: Date;
  gender: "male" | "female";
  weight: number;
  height: number;
  weight_pounds: number;
  height_feet: number;
  height_inches: number;
  units: "metric" | "imperial";
};

export type MyAccountViewProps = {
  athlete: TableRow<"athlete">;
};

export default function MyAccountView(props: MyAccountViewProps) {
  const user = useUser();
  const supabase = useSupabase();
  const isMobileScreen = useMediaQuery("(max-width:480px)");
  const [openDate, setOpenDate] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const buttonRef = useRef(null);
  const inputEmailRef = useRef(null);
  const inputNameRef = useRef(null);
  const inputWeightRef = useRef(null);
  const inputWeightPoundRef = useRef(null);
  const inputHeightRef = useRef(null);
  const inputHeightFeetRef = useRef(null);
  const inputHeightInchRef = useRef(null);

  const formik = useFormik<MyAccountViewType>({
    initialValues: {
      // email: user.email,
      name: undefined,
      dateofbirth: undefined,
      gender: "male",
      weight: undefined,
      height: undefined,
      weight_pounds: undefined,
      height_feet: undefined,
      height_inches: undefined,
      units: "metric",
    },
    validationSchema: yup.object().shape({
      // email: yup
      //   .string()
      //   .email("The email provided should be a valid email address")
      //   .max(255)
      //   .required("Email is required"),
      name: yup.string().required("Name is required"),
      dateofbirth: yup
        .date()
        .max(new Date(Date.now() - 378683112000), "Minimum age is 12 years old")
        .min(
          new Date(Date.now() - 3155692600000.1),
          "Maximum age is 100 years old",
        )
        .required("Date of birth is required"),
      gender: yup
        .string()
        .oneOf(["male", "female"], "Please select a valid gender")
        .required("Please select a valid gender"),
      weight: yup
        .number()
        .test(
          "minimum metric weight",
          "Weight cannot be lower than 30kg",
          (value, { parent }) =>
            !value || parent.units === "imperial" || parent.weight >= 30,
        )
        .test(
          "maximum metric weight",
          "Weight cannot be greater than 250kg",
          (value, { parent }) =>
            !value || parent.units === "imperial" || parent.weight <= 250,
        )
        .test(
          "metric weight is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "imperial",
        )
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      height: yup
        .number()
        .test(
          "minimum metric height",
          "Height cannot be lower than 1m",
          (value, { parent }) =>
            !value || parent.units === "imperial" || parent.height >= 1,
        )
        .test(
          "maximum metric height",
          "Height cannot be greater than 2.3m",
          (value, { parent }) =>
            !value || parent.units === "imperial" || parent.height <= 2.3,
        )
        .test(
          "metric height is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "imeprial",
        )
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      height_inches: yup
        .number()
        .min(0, "Inches should be positive")
        .max(11, "Inches should be less than 11")
        .test(
          "imperial height is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "metric",
        )
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      height_feet: yup
        .number()
        .test(
          "minimum imperial height",
          "Height cannot be lower than 3ft 4in",
          (value, { parent }) =>
            !value && !parent.height_inches && parent.height_inches !== 0
              ? true
              : value +
                  (!!parent.height_inches
                    ? convert(parent.height_inches).from("in").to("ft")
                    : 0) >=
                3 + 1 / 3,
        )
        .test(
          "maximum imperial height",
          "Height cannot be greater than 7ft 6in",
          (value, { parent }) =>
            !value ||
            parent.units === "metric" ||
            imperialToMeters(parent.height_feet, parent.height_inches) <= 2.3,
        )
        .test(
          "imperial height is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "metric",
        )
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      weight_pounds: yup
        .number()
        .test(
          "minimum imperial weight",
          "Weight cannot be lower than 66lbs",
          (value, { parent }) =>
            !value || parent.units === "metric" || parent.weight_pounds >= 66,
        )
        .test(
          "maximum imperial weight",
          "Weight cannot be greater than 551lbs",
          (value, { parent }) =>
            !value || parent.units === "metric" || parent.weight_pounds <= 551,
        )
        .test(
          "imperial weight is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "metric",
        )
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      units: yup
        .string()
        .oneOf(["metric", "imperial"], "Please select a valid unit"),
    }),
    onSubmit: (values) => {
      if ("fire" in saveProfileSettingsCall) {
        saveProfileSettingsCall.fire(async () =>
          saveProfileSettingsPromise(values),
        );
      }
    },
  });

  useEffect(() => {
    formik.validateField("height_feet");
  }, [formik.values.height_inches]);

  useEffect(() => {
    const heightInMeters = props.athlete.height;
    const heightInFeet = convert(heightInMeters).from("m").to("ft");
    const heightInInches = (heightInFeet % 1) * 12;

    formik.setFieldValue("height_feet", Math.floor(heightInFeet));
    formik.setFieldValue("height_inches", Math.round(heightInInches));

    formik.setFieldValue("height", props.athlete.height);
    formik.setFieldValue("weight", props.athlete.weight);
    formik.setFieldValue(
      "weight_pounds",
      (props.athlete.weight * 2.20462).toFixed(0),
    );
    formik.setFieldValue("name", props.athlete.name);
    formik.setFieldValue("dateofbirth", new Date(props.athlete.dob));
    formik.setFieldValue(
      "gender",
      props.athlete.gender === false ? "male" : "female",
    );
    formik.setFieldValue(
      "units",
      props.athlete.units === false ? "metric" : "imperial",
    );
  }, [props.athlete]);

  const saveProfileSettingsPromise = useCallback(
    (values: MyAccountViewType) =>
      supabase
        .from("athlete")
        .update({
          height:
            values.units === "metric"
              ? values.height
              : Number(
                  imperialToMeters(
                    values.height_feet,
                    values.height_inches,
                  ).toFixed(2),
                ),
          weight:
            values.units === "metric"
              ? values.weight
              : Math.round(convert(values.weight_pounds).from("lb").to("kg")),
          name: values.name,
          gender: values.gender === "male" ? false : true,
          units: values.units === "metric" ? false : true,
          dob: values.dateofbirth.toISOString(),
        })
        .eq("user_id", user.id)
        .then((res) => res.data),
    [supabase],
  );

  const saveProfileSettingsCall =
    useAsyncState<SupabaseCall<typeof saveProfileSettingsPromise>>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isFulfilled(saveProfileSettingsCall)) {
      enqueueSnackbar("Settings saved successfully", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [saveProfileSettingsCall]);

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            width: "98%",
            background: "#201B20",
            borderRadius: "10px",
            paddingY: "1em",
            paddingX: isMobileScreen ? "1rem" : "2rem",
            minHeight: "70vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: ".8125rem" }}>
              PERSONAL INFORMATION
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                padding: "2em",
              }}
            >
              {/* <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  border: "1px dashed #64605D",
                  borderRadius: "6px",
                  paddingX: "3em",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <img src={avatar} alt="avatar" />

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                  }}
                >
                  <Button variant="outlined" size="small">
                    Upload
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "1px",
                bgcolor: "#64605D",
                height: "305px",
                marginTop: "70px",
              }}
            ></Box> */}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                {/* <FormControl>
                  <TextField
                    name="email"
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                    inputRef={inputEmailRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() => inputEmailRef.current.focus()}
                        >
                          <IconButton>
                            <img src={editInput} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl> */}

                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={user.email}
                />

                <FormControl>
                  <TextField
                    name="name"
                    onBlur={formik.handleBlur}
                    value={formik.values.name ?? ""}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    inputRef={inputNameRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => inputNameRef.current.focus()}
                          >
                            <img src={editInput} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    name="dateofbirth"
                    error={
                      formik.touched.dateofbirth &&
                      Boolean(formik.errors.dateofbirth)
                    }
                    helperText={
                      formik.touched.dateofbirth && formik.errors.dateofbirth
                    }
                    value={
                      !!formik.values.dateofbirth
                        ? dayjs(formik.values.dateofbirth).format("DD/MM/YYYY")
                        : ""
                    }
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenDate(!openDate)}
                            ref={buttonRef}
                          >
                            <CalendarMonthIcon
                              sx={{ color: "#BC6D29" }}
                              fontSize="small"
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Popover
                  open={openDate}
                  anchorEl={buttonRef.current}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  onClose={(_) => setOpenDate(false)}
                  PaperProps={{
                    style: {
                      background: "none",
                    },
                  }}
                >
                  <Calendar
                    onChange={(date) => {
                      formik.setFieldTouched("dateofbirth");
                      formik.setFieldValue("dateofbirth", date);
                    }}
                    date={new Date(formik.values.dateofbirth)}
                    maxDate={new Date()}
                  />
                </Popover>

                <FormControl>
                  <Select
                    id="gender"
                    name="gender"
                    value={formik.values.gender ?? ""}
                    onChange={formik.handleChange}
                    sx={{
                      "& .MuiSelect-icon": { marginRight: ".4375rem" },
                    }}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                </FormControl>

                {formik.values.units === "metric" ? (
                  <TextField
                    error={
                      formik.touched.weight && Boolean(formik.errors.weight)
                    }
                    helperText={formik.touched.weight && formik.errors.weight}
                    label="Weight (kg)"
                    name="weight"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      if (/^[0-9]*\.?\d{0,2}$/.test(e.target.value)) {
                        formik.handleChange(e);
                      }
                    }}
                    value={formik.values.weight ?? ""}
                    variant="outlined"
                    inputRef={inputWeightRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => inputWeightRef.current.focus()}
                          >
                            <img src={editInput} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <TextField
                    error={
                      formik.touched.weight_pounds &&
                      Boolean(formik.errors.weight_pounds)
                    }
                    helperText={
                      formik.touched.weight_pounds &&
                      formik.errors.weight_pounds
                    }
                    label="Weight (lb)"
                    name="weight_pounds"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      if (/^[0-9]*\.?\d{0,2}$/.test(e.target.value)) {
                        formik.handleChange(e);
                      }
                    }}
                    value={formik.values.weight_pounds ?? ""}
                    variant="outlined"
                    inputRef={inputWeightPoundRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => inputWeightPoundRef.current.focus()}
                          >
                            <img src={editInput} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                {formik.values.units === "metric" ? (
                  <TextField
                    error={
                      formik.touched.height && Boolean(formik.errors.height)
                    }
                    helperText={formik.touched.height && formik.errors.height}
                    name="height"
                    label="Height (m)"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      if (/^[0-9]*\.?\d{0,2}$/.test(e.target.value)) {
                        formik.handleChange(e);
                      }
                    }}
                    value={formik.values.height ?? ""}
                    variant="outlined"
                    inputRef={inputHeightRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => inputHeightRef.current.focus()}
                          >
                            <img src={editInput} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      justifyContent: "space-between",
                      gap: { xs: 1 },
                    }}
                  >
                    <TextField
                      error={
                        formik.touched.height_feet &&
                        Boolean(formik.errors.height_feet)
                      }
                      helperText={
                        formik.touched.height_feet && formik.errors.height_feet
                      }
                      label="Height (ft)"
                      name="height_feet"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        if (/^[0-9]*$/.test(e.target.value)) {
                          formik.handleChange(e);
                        }
                      }}
                      value={formik.values.height_feet ?? ""}
                      variant="outlined"
                      sx={{
                        width: "10rem",
                        "& .MuiInputBase-root": { width: "10rem" },
                        "& .MuiFormHelperText-root": { width: "initial" },
                      }}
                      inputRef={inputHeightFeetRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => inputHeightFeetRef.current.focus()}
                            >
                              <img src={editInput} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label="Height (in)"
                      name="height_inches"
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.height_inches &&
                          formik.errors.height_inches,
                      )}
                      helperText={
                        formik.touched.height_inches &&
                        formik.errors.height_inches
                      }
                      onChange={(e) => {
                        if (/^[0-9]*$/.test(e.target.value)) {
                          formik.handleChange(e);
                        }
                        if (e.target.value === "") {
                          formik.setFieldValue("height_inches", null);
                        }
                      }}
                      value={formik.values.height_inches ?? ""}
                      variant="outlined"
                      sx={{
                        width: "10rem",
                        "& .MuiInputBase-root": { width: "10rem" },
                        "& .MuiFormHelperText-root": { width: "max-content" },
                      }}
                      inputRef={inputHeightInchRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => inputHeightInchRef.current.focus()}
                            >
                              <img src={editInput} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                )}

                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <FormLabel sx={{ alignSelf: "center" }}>
                    <Typography
                      color="text.primary"
                      sx={{
                        opacity: "0.5",
                        marginRight: "1.25rem",
                        fontSize: { xs: "12px", xl: "12px" },
                      }}
                    >
                      Units of Measure
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    row
                    onChange={formik.handleChange}
                    value={formik.values.units}
                    name="units"
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      "& .MuiFormControlLabel-label": { opacity: 0.5 },
                    }}
                  >
                    <FormControlLabel
                      value="metric"
                      control={<Radio color="primary" />}
                      label={<span style={{ fontSize: "12px" }}>Metric</span>}
                    />
                    <FormControlLabel
                      value="imperial"
                      control={<Radio color="primary" />}
                      label={<span style={{ fontSize: "12px" }}>Imperial</span>}
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  variant="text"
                  fullWidth
                  onClick={() => setOpenModal(true)}
                  sx={{ width: { xs: "11rem", sm: "20.5rem" } }}
                >
                  Change Password
                </Button>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              startIcon={
                isPending(saveProfileSettingsCall) ? (
                  <CircularProgress size="1rem" />
                ) : null
              }
              disabled={isPending(saveProfileSettingsCall)}
              type="submit"
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <>
          <ChangePassword onClose={handleClose} />
        </>
      </Modal>
    </>
  );
}
