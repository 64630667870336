import { useCallback, useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  LinearProgress,
  MenuItem,
  Select,
  Tab,
  Typography,
  Tooltip,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useTitleCoach } from "../Dashboard/CoachDashboardLayout";
import { DataGrid, GridColDef, gridClasses } from "@mui/x-data-grid";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useUser } from "src/components/Authenticated";
import useAsyncState, {
  isFulfilled,
  isPending,
  isUnloaded,
} from "src/utils/Async";
import { compliancePercentage, SupabaseCall, toHHMMSS } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import SuspenseLoader from "src/components/SuspenseLoader";
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import dayjs from "dayjs";

type TabValue = "performance" | "positions" | "compliance";

export type LeaderBoardProps = {
  tab?: TabValue;
};

const StyledDataGrid = styled(DataGrid)((theme) => ({
  "& .MuiDataGrid-sortIcon": {
    color: "#DD4F4A",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
    {
      outline: "none",
    },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
    outline: "none",
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "#2C262D",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#201B20",
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    "&:hover": {
      backgroundColor: "#342C35",
    },
  },
}));

export default function CoachLeaderBoardView(props: LeaderBoardProps) {
  const units = useUnits()
  const [, setTitle] = useTitleCoach();
  const supabase = useSupabase();
  const user = useUser();
  const isMobileScreen = useMediaQuery("(max-width:955px)");

  useEffect(() => {
    if (!!props?.tab) {
      setValue(props?.tab);
    }
  }, [props]);

  useEffect(() => {
    setTitle("Leaderboard");
  }, []);

  const [value, setValue] = useState<TabValue>("performance");
  const [numberOfRows, setNumberOfRows] = useState<number>(10);
  const [rows, setRows] = useState([]);
  const [selectedPerformanceFilter, setSelectedPerformanceFilter] = useState(
    "max_avg_speed_session_id",
  );
  const [selectedPositionFilter, setSelectedPositionFilter] =
    useState("max_road_tt_id");

  const handleChange = useCallback((_, newValue: TabValue) => {
    setValue(newValue);
    window.history.replaceState(null, "", `/coach/leaderboard/${newValue}`);
  }, []);

  const handleChangeFilter = (event) => {
    const newValue = event.target.value;
    if (value === "performance") {
      setSelectedPerformanceFilter(newValue);
    } else if (value === "positions") {
      setSelectedPositionFilter(newValue);
    }
  };

  const coachAthletePromise = useCallback(
    () =>
      supabase
        .from("coach_athletes")
        .select(
          "athlete(name,user_id,max_avg_speed_session_id(id,average_speed,date,recording_name),max_avg_power_session_id(id,average_power,date,recording_name),max_avg_heart_rate_session_id(id,average_heart_rate,date,recording_name),max_distance_session_id(id,total_distance,date,recording_name),max_avg_cadence_session_id(id,average_cadence,date,recording_name),max_elevation_gain_session_id(id,elevation_gain,date,recording_name),max_duration_session_id(id,total_time,date,recording_name),max_road_tt_id(position_percentage,session(id,recording_name,date)),max_road_aggressive_id(position_percentage,session(id,recording_name,date)),max_road_comfortable_id(position_percentage,session(id,recording_name,date)),max_road_standing_id(position_percentage,session(id,recording_name,date)),max_tt_tt_id(position_percentage,session(id,recording_name,date)),max_tt_comfortable_id(position_percentage,session(id,recording_name,date)),max_tt_standing_id(position_percentage,session(id,recording_name,date)), session!session_athlete_id_fkey(date,total_time,tss), assigned_workout(*))",
        )
        .eq("coach_id", user.id)
        .gte("athlete.assigned_workout.date", dayjs().subtract(3, "month"))
        .gte("athlete.session.date", dayjs().subtract(3, "month"))
        .order('date', {foreignTable: 'athlete.assigned_workout', ascending: true})
        .order('date', {foreignTable: 'athlete.session', ascending: true})
        .then((res) => res.data),
    [supabase],
  );

  const storedAthleteCall = useAsyncState<SupabaseCall<typeof coachAthletePromise>>();
  useEffect(() => {
    if (isUnloaded(storedAthleteCall)) {
      storedAthleteCall.fire(async () => coachAthletePromise());
    }
  }, [coachAthletePromise, storedAthleteCall]);

  const allPerfomanceColumns: GridColDef[] = [
    {
      field: "rowNumber",
      headerName: "Ranking",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        const NameCellWithTooltip = ({ value }) => {
          const showTooltip = useMediaQuery("(max-width:1000px)");

          return showTooltip ? (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            <span>{value}</span>
          );
        };

        return <NameCellWithTooltip value={params.value} />;
      },
    },
    {
      field: "max_avg_speed_session_id",
      headerName: "Max. Average Speed",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({ value }) =>
        typeof value === "number" ? `${value.toFixed(1)} kph` : value,
    },
    {
      field: "max_avg_power_session_id",
      headerName: "Max. Average Power",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({ value }) =>
        typeof value === "number" ? `${value} watt` : value,
    },
    {
      field: "max_avg_heart_rate_session_id",
      headerName: "Max. Average Heart Rate",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({ value }) =>
        typeof value === "number" ? `${value.toFixed(0)} bpm` : value,
    },
    {
      field: "max_avg_cadence_session_id",
      headerName: "Max. Average Cadence",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({ value }) =>
        typeof value === "number" ? `${value.toFixed(0)} rpm` : value,
    },
    {
      field: "max_distance_session_id",
      headerName: "Max. Distance",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({value}) => typeof value === "number" ?  
        units === "metric"
        ? `${value.toFixed(2) ?? "0"} km`
        : `${
          value
              ? convert(value)
                  .from("km")
                  .to("mi")
                  .toFixed(2)
              : "0"
          } mi`
      : value
    },
    {
      field: "max_duration_session_id",
      headerName: "Max. Duration",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({ value }) =>
        typeof value === "number" ? toHHMMSS(value / 1000) : value,
    },
    {
      field: "max_elevation_gain_session_id",
      headerName: "Max. Elevation Gain",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: ({ value }) =>
        typeof value === "number" ? `${value.toFixed(0)} m` : value,
    },

    {
      field: "date",
      headerName: "Date",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        const NameCellWithTooltip = ({ value }) => {
          const showTooltip = useMediaQuery("(max-width:1000px)");

          return showTooltip ? (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            <span>{value}</span>
          );
        };

        return <NameCellWithTooltip value={params.value} />;
      },
    },
    {
      field: "recording_name",
      headerName: "Session",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        const NameCellWithTooltip = ({ value }) => {
          const showTooltip = useMediaQuery("(max-width:1000px)");

          return showTooltip ? (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            <span>{value}</span>
          );
        };

        return <NameCellWithTooltip value={params.value} />;
      },
    },
  ];

  const [perfomanceColumns, setPerfomanceColumns] = useState<Array<GridColDef>>(
    allPerfomanceColumns.filter(
      (col) =>
        col.field !== "max_avg_power_session_id" &&
        col.field !== "max_avg_heart_rate_session_id" &&
        col.field !== "max_avg_cadence_session_id" &&
        col.field !== "max_distance_session_id" &&
        col.field !== "max_duration_session_id" &&
        col.field !== "max_elevation_gain_session_id",
    ),
  );

  const columnsPositions: GridColDef[] = [
    {
      field: "rowNumber",
      headerName: "Ranking",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        const NameCellWithTooltip = ({ value }) => {
          const showTooltip = useMediaQuery("(max-width:1000px)");

          return showTooltip ? (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            <span>{value}</span>
          );
        };

        return <NameCellWithTooltip value={params.value} />;
      },
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        const NameCellWithTooltip = ({ value }) => {
          const showTooltip = useMediaQuery("(max-width:1000px)");

          return showTooltip ? (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            <span>{value}</span>
          );
        };

        return <NameCellWithTooltip value={params.value} />;
      },
    },
    {
      field: "recording_name",
      headerName: "Session",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        const NameCellWithTooltip = ({ value }) => {
          const showTooltip = useMediaQuery("(max-width:1000px)");

          return showTooltip ? (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            <span>{value}</span>
          );
        };

        return <NameCellWithTooltip value={params.value} />;
      },
    },
    {
      field: "max_road_tt_id",
      headerName: "% of time in TT Ideal - Road Bike",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "max_road_aggressive_id",
      headerName: "% of time in Aggressive Ideal - Road Bike",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "max_road_comfortable_id",
      headerName: "% of time in Comfortable Ideal - Road Bike",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "max_road_standing_id",
      headerName: "% of time in Standing Ideal - Road Bike",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "max_tt_tt_id",
      headerName: "% of time in TT Ideal - TT Bike",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "max_tt_comfortable_id",
      headerName: "% of time in Comfortable Ideal - TT Bike",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "max_tt_standing_id",
      headerName: "% of time in Standing Ideal - TT Bike",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
    },
  ];

  const [positionColumns, setPositionColumns] = useState<Array<GridColDef>>(
    columnsPositions.filter(
      (col) =>
        col.field !== "max_road_aggressive_id" &&
        col.field !== "max_road_comfortable_id" &&
        col.field !== "max_road_standing_id" &&
        col.field !== "max_tt_tt_id" &&
        col.field !== "max_tt_comfortable_id" &&
        col.field !== "max_tt_standing_id",
    ),
  );

  const columnsCompliance: GridColDef[] = [
    {
      field: "rowNumber",
      headerName: "Ranking",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        const NameCellWithTooltip = ({ value }) => {
          const showTooltip = useMediaQuery("(max-width:1000px)");

          return showTooltip ? (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            <span>{value}</span>
          );
        };

        return <NameCellWithTooltip value={params.value} />;
      },
    },
    {
      field: "recording_name",
      headerName: "Session",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        const NameCellWithTooltip = ({ value }) => {
          const showTooltip = useMediaQuery("(max-width:1000px)");

          return showTooltip ? (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            <span>{value}</span>
          );
        };

        return <NameCellWithTooltip value={params.value} />;
      },
    },
    {
      field: "compliance",
      headerName: "Total Compliance (last 3 months)",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title={`${params.value}%`} placement="right" arrow>
          <LinearProgress
            variant="determinate"
            value={params.value}
            sx={{
              width: "100%",
              borderRadius: 4,
              backgroundColor: "#45413F",
              fill: "none",
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#DD4F4A",
              },
            }}
          />
        </Tooltip>
      ),
    },
    {
      field: "workouts_number",
      headerName: "No. of workouts",
      headerClassName: "customGridStyle",
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span>{params.value}</span>
      ),
    },
  ];

  useEffect(() => {
    if (isFulfilled(storedAthleteCall)) {
      switch(value) {
        case 'performance': {
          const rows = storedAthleteCall?.result
            ?.sort((a, b) => {
              const speedA =
                (a.athlete?.max_avg_speed_session_id &&
                  a.athlete.max_avg_speed_session_id["average_speed"]) ||
                0;
              const speedB =
                (b.athlete?.max_avg_speed_session_id &&
                  b.athlete.max_avg_speed_session_id["average_speed"]) ||
                0;

              return speedB - speedA;
            })
            .map((item, index) => ({
              id: item.athlete.user_id,
              rowNumber: index + 1,
              name: item.athlete?.name,
              date: item.athlete?.max_avg_speed_session_id
                ? new Date(
                    item.athlete?.max_avg_speed_session_id["date"],
                  ).toLocaleDateString("en-GB")
                : "-",
              max_avg_speed_session_id: item.athlete?.max_avg_speed_session_id
                ? item.athlete?.max_avg_speed_session_id["average_speed"]
                : "-",
              recording_name: item.athlete?.max_avg_speed_session_id
                ? item.athlete?.max_avg_speed_session_id["recording_name"]
                : "-",
            }));
          setRows(rows);
          break;
        }
        case "positions": {
          const rows = storedAthleteCall.result
            .sort((a, b) => {
              const speedA =
                (a.athlete?.max_road_tt_id &&
                  a.athlete.max_road_tt_id["position_percentage"]) ||
                0;
              const speedB =
                (b.athlete?.max_road_tt_id &&
                  b.athlete.max_road_tt_id["position_percentage"]) ||
                0;

              return speedB - speedA;
            })
            .map((item, index) => ({
              id: item.athlete.user_id,
              rowNumber: index + 1,
              name: item.athlete.name,
              date: item.athlete.max_road_tt_id
                ? new Date(
                    item.athlete?.max_road_tt_id["session"]["date"],
                  ).toLocaleDateString("en-GB")
                : "-",
              max_road_tt_id: item.athlete?.max_road_tt_id
                ? `${(
                    item.athlete?.max_road_tt_id["position_percentage"] * 100
                  )?.toFixed(2)}%`
                : "-",
              recording_name: item.athlete?.max_road_tt_id
                ? item.athlete?.max_road_tt_id["session"]["recording_name"]
                : "-",
              compliance: compliancePercentage(item.athlete.session, item.athlete.assigned_workout)
            }));
          setRows(rows);
          break;
        }
        case "compliance": {
          const rows = storedAthleteCall.result
            .map((item, index) => ({
              id: item.athlete.user_id,
              rowNumber: index + 1,
              name: item.athlete.name,
              recording_name: item.athlete?.max_road_tt_id
                ? item.athlete?.max_road_tt_id["session"]["recording_name"]
                : "-",
              compliance: compliancePercentage(item.athlete.session, item.athlete.assigned_workout),
              workouts_number: item.athlete.assigned_workout.reduce((acc, curr) => dayjs(curr.date).isBefore(dayjs(), 'day') ? acc + 1 : acc, 0)
            }))
            .sort((a, b) => b.compliance - a.compliance)
            .map((item, index) => ({
              ...item,
              rowNumber: index + 1,
            }));
          setRows(rows);
          break;
        }
      }
    }
  }, [storedAthleteCall, value]);

  useEffect(() => {
    if (value === "performance") {
      setPerfomanceColumns(
        allPerfomanceColumns.filter((f) =>
          f.field.includes("max")
            ? f.field === selectedPerformanceFilter
              ? true
              : false
            : true,
        ),
      );
      const currentFilter =
        selectedPerformanceFilter === "max_avg_speed_session_id"
          ? "average_speed"
          : selectedPerformanceFilter === "max_avg_power_session_id"
          ? "average_power"
          : selectedPerformanceFilter === "max_avg_cadence_session_id"
          ? "average_cadence"
          : selectedPerformanceFilter === "max_avg_heart_rate_session_id"
          ? "average_heart_rate"
          : selectedPerformanceFilter === "max_distance_session_id"
          ? "total_distance"
          : selectedPerformanceFilter === "max_duration_session_id"
          ? "total_time"
          : "elevation_gain";
      if (isFulfilled(storedAthleteCall)) {
        const rows = storedAthleteCall.result
          .sort((a, b) =>
            a.athlete[selectedPerformanceFilter] === null
              ? 1
              : b.athlete[selectedPerformanceFilter] === null
              ? -1
              : a.athlete[selectedPerformanceFilter][currentFilter] <
                b.athlete[selectedPerformanceFilter][currentFilter]
              ? 1
              : -1,
          )
          .map((item, index) => ({
            id: item.athlete.user_id,
            rowNumber: index + 1,
            name: item.athlete.name,
            date: item.athlete[selectedPerformanceFilter]
              ? new Date(
                  item.athlete[selectedPerformanceFilter]["date"],
                ).toLocaleDateString("en-GB")
              : "-",
            [selectedPerformanceFilter]: item.athlete[selectedPerformanceFilter]
              ? item.athlete[selectedPerformanceFilter][currentFilter]
              : "-",
            recording_name: item.athlete[selectedPerformanceFilter]
              ? item.athlete[selectedPerformanceFilter]["recording_name"]
              : "-",
          }));
        setRows(rows);
      }
    } else {
      setPositionColumns(
        columnsPositions.filter((f) =>
          f.field.includes("max")
            ? f.field === selectedPositionFilter
              ? true
              : false
            : true,
        ),
      );
      if (isFulfilled(storedAthleteCall)) {
        const rows = storedAthleteCall.result
          .sort((a, b) =>
            a.athlete[selectedPositionFilter] === null
              ? 1
              : b.athlete[selectedPositionFilter] === null
              ? -1
              : a.athlete[selectedPositionFilter]["position_percentage"] <
                b.athlete[selectedPositionFilter]["position_percentage"]
              ? 1
              : -1,
          )
          .map((item, index) => ({
            id: item.athlete.user_id,
            rowNumber: index + 1,
            name: item.athlete.name,
            date: item.athlete[selectedPositionFilter]
              ? new Date(
                  item.athlete[selectedPositionFilter]["session"]["date"],
                ).toLocaleDateString("en-GB")
              : "-",
            [selectedPositionFilter]: item.athlete[selectedPositionFilter]
              ? `${(
                  item.athlete[selectedPositionFilter]["position_percentage"] *
                  100
                )?.toFixed(2)}%`
              : "-",
            recording_name: item.athlete[selectedPositionFilter]
              ? item.athlete[selectedPositionFilter]["session"][
                  "recording_name"
                ]
              : "-"
            }));
        setRows(rows);
      }
    }
  }, [selectedPerformanceFilter, selectedPositionFilter]);

  if (isPending(storedAthleteCall)) {
    return <SuspenseLoader color="#DD4F4A" />;
  }
  return (
    <>
      <Box
        sx={{
          width: "100%",
          paddingTop: "15px",
          paddingLeft: "25px",
          paddingBottom: "20px",
        }}
      >
        <Box>
          <TabContext value={value}>
            <Box
              sx={{
                borderColor: "divider",
                display: "flex",
                paddingRight: "20px",
                justifyContent: "space-between",
              }}
            >
              <TabList
                onChange={handleChange}
                variant="scrollable"
                aria-label="Tab list"
                sx={{
                  "& .MuiTab-root:not(.Mui-selected)": {
                    opacity: 0.5,
                  },
                  minHeight: "1.5rem",
                  "& .Mui-selected": {
                    color: "#DD4F4A !important",
                  },
                }}
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: "#DD4F4A",
                  },
                }}
              >
                <Tab
                  label="Performance"
                  value="performance"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
                <Tab
                  label="Positions"
                  value="positions"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
                <Tab
                  label="Compliance Percentage"
                  value="compliance"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
              </TabList>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: { xl: "20%", lg: "30%", md: "40%" },
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    width: "40%",
                    display: isMobileScreen ? "none" : "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#BFBBB8" }}>View</Typography>
                  <Box
                    onClick={() => setNumberOfRows(10)}
                    sx={{
                      padding: "1em",
                      borderRadius: "4px 0px 0px 4px",
                      background:
                        numberOfRows === 10
                          ? "#DD4F4A"
                          : "rgba(221, 79, 74, 0.5)",
                      marginLeft: "1rem",
                      cursor: "pointer",
                    }}
                  >
                    <Typography>10</Typography>
                  </Box>
                  <Box
                    onClick={() => setNumberOfRows(50)}
                    sx={{
                      padding: "1em",
                      borderRadius: "0px 4px 4px 0px",
                      background:
                        numberOfRows === 50
                          ? "#DD4F4A"
                          : "rgba(221, 79, 74, 0.5)",
                      cursor: "pointer",
                    }}
                  >
                    <Typography>50</Typography>
                  </Box>
                </Box>
                {(value === "performance" || value === "positions") && (
                  <Select
                    value={
                      value === "performance"
                        ? selectedPerformanceFilter
                        : selectedPositionFilter
                    }
                    onChange={handleChangeFilter}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "#DD4F4A",
                      },
                      "&.MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#DD4F4A",
                        },
                        "&:hover fieldset": {
                          borderColor: "#DD4F4A",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#DD4F4A",
                        },
                      },
                      width: "80%",
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          border: "1px solid #DD4F4A",
                        },
                      },
                      sx: {
                        "&& .Mui-selected": {
                          backgroundColor: "rgba(221, 79, 74, 0.5)",
                        },
                      },
                      MenuListProps: {
                        disablePadding: true,
                      },
                    }}
                  >
                    {(value === "performance" && [
                      <MenuItem
                        key="Max_Avg_Speed"
                        value="max_avg_speed_session_id"
                      >
                        Max Avg Speed
                      </MenuItem>,
                      <MenuItem
                        key="Max_Avg_Power"
                        value="max_avg_power_session_id"
                      >
                        Max Avg Power
                      </MenuItem>,
                      <MenuItem
                        key="Max_Avg_HR"
                        value="max_avg_heart_rate_session_id"
                      >
                        Max Avg HR
                      </MenuItem>,
                      <MenuItem
                        key="Max_Avg_Cadence"
                        value="max_avg_cadence_session_id"
                      >
                        Max Avg Cadence
                      </MenuItem>,
                      <MenuItem
                        key="Max_Distance"
                        value="max_distance_session_id"
                      >
                        Max Distance
                      </MenuItem>,
                      <MenuItem
                        key="Max_Duration"
                        value="max_duration_session_id"
                      >
                        Max Duration
                      </MenuItem>,
                      <MenuItem
                        key="Max_Elevation_Gain"
                        value="max_elevation_gain_session_id"
                      >
                        Max Elevation Gain
                      </MenuItem>,
                    ]) ||
                      (value === "positions" && [
                        <MenuItem key="Max_road_tt_id" value="max_road_tt_id">
                          % in TT Ideal - Road Bike
                        </MenuItem>,
                        <MenuItem
                          key="Max_road_aggressive_id"
                          value="max_road_aggressive_id"
                        >
                          % in Aggressive Ideal - Road Bike
                        </MenuItem>,
                        <MenuItem
                          key="Max_road_comfortable_id"
                          value="max_road_comfortable_id"
                        >
                          % in Comfortable Ideal - Road Bike
                        </MenuItem>,
                        <MenuItem
                          key="Max_road_standing_id"
                          value="max_road_standing_id"
                        >
                          % in Standing - Road Bike
                        </MenuItem>,
                        <MenuItem key="Max_tt_tt_id" value="max_tt_tt_id">
                          % in TT Ideal - TT Bike
                        </MenuItem>,
                        <MenuItem
                          key="Max_tt_comfortable_id"
                          value="max_tt_comfortable_id"
                        >
                          % in TT Comfortable - TT Bike
                        </MenuItem>,
                        <MenuItem
                          key="Max_tt_standing_id"
                          value="max_tt_standing_id"
                        >
                          % in TT Standing - TT Bike
                        </MenuItem>,
                      ])}
                  </Select>
                )}
              </Box>
            </Box>
            <TabPanel
              value="performance"
              sx={{ 
                padding: 0,
                minHeight: 0, 
                marginTop: "1rem", 
                "& .customGridStyle": {
                  color: "#DD4F4A",
                  borderColor: "#DD4F4A",
                }
              }}
            >
              <StyledDataGrid
                rows={rows}
                columns={perfomanceColumns}
                pageSize={numberOfRows}
                disableSelectionOnClick
                hideFooterPagination
                hideFooter
                autoHeight
                components={{
                  ColumnSortedAscendingIcon: ExpandLessIcon,
                  ColumnSortedDescendingIcon: ExpandMoreIcon,
                }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </TabPanel>
            <TabPanel
              value="positions"
              sx={{ 
                padding: 0,
                minHeight: 0, 
                marginTop: "1rem", 
                "& .customGridStyle": {
                  color: "#DD4F4A",
                  borderColor: "#DD4F4A",
                }
              }}
            >
              <StyledDataGrid
                rows={rows}
                columns={positionColumns}
                pageSize={numberOfRows}
                disableSelectionOnClick
                hideFooterPagination
                hideFooter
                autoHeight
                components={{
                  ColumnSortedAscendingIcon: ExpandLessIcon,
                  ColumnSortedDescendingIcon: ExpandMoreIcon,
                }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </TabPanel>
            <TabPanel
              value="compliance"
              sx={{ 
                padding: 0,
                minHeight: 0, 
                marginTop: "1rem", 
                "& .customGridStyle": {
                  color: "#DD4F4A",
                  borderColor: "#DD4F4A",
                }
              }}
            >
              <StyledDataGrid
                rows={rows}
                columns={columnsCompliance}
                pageSize={10}
                disableSelectionOnClick
                hideFooterPagination
                hideFooter
                autoHeight
                components={{
                  ColumnSortedAscendingIcon: ExpandLessIcon,
                  ColumnSortedDescendingIcon: ExpandMoreIcon,
                }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
}
