import {
    Box,
    Divider,
    IconButton,
    Tooltip,
    Typography,
    styled,
  } from "@mui/material";
  
  import Dashboard from "../../../assets/img/layout/coach/Dashboard.svg";
  import DashboardIn from "../../../assets/img/layout/coach/DashboardIn.svg";
  import MyAthletes from "../../../assets/img/layout/coach/MyAthletes.svg";
  import MyAthletesIn from "../../../assets/img/layout/coach/MyAthletesIn.svg";
  import Library from "../../../assets/img/layout/coach/Library.svg";
  import LibraryIn from "../../../assets/img/layout/coach/LibraryIn.svg";
  import Calendar from "../../../assets/img/layout/coach/Calendar.svg";
  import CalendarIn from "../../../assets/img/layout/coach/CalendarIn.svg";
  import Leaderboard from "../../../assets/img/layout/coach/Leaderboard.svg";
  import LeaderboardIn from "../../../assets/img/layout/coach/LeaderboardIn.svg";
  
  import { useEffect, useState } from "react";
  import { useLocation, useNavigate } from "react-router-dom";
  import { useUser } from "src/components/Authenticated";
  
  const SidebarWrapper = styled(Box)(
    ({ theme }) => `
          width: 185px;
          min-width: 185px;
          background: #2C262D;
          position: relative;
          z-index: 7;
          height: 100%;
          @media (min-width: ${theme.breakpoints.values.xs}px) {
            height: calc(100% - ${theme.header.height});
            margin-top: ${theme.header.height};
          }
  `,
  );
  
  export default function SidebarCoach() {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useUser();
  
    const [activeIcon, setActiveIcon] = useState("Dashboard");
  
    useEffect(() => {
      return location.pathname.startsWith("/coach/my_athletes")
        ? setActiveIcon("My Athletes")
        : location.pathname.startsWith("/coach/calendar")
        ? setActiveIcon("Calendar")
        : location.pathname.startsWith("/coach/library")
        ? setActiveIcon("Library")
        : location.pathname.startsWith("/coach/leaderboard")
        ? setActiveIcon("Leaderboard")
        : setActiveIcon("Dashboard");
    }, [location]);
  
    const handleIconClick = (icon: string) => {
      setActiveIcon(icon);
      switch (icon) {
        case "My Athletes":
          navigate("/coach/my_athletes");
          break;
        case "Calendar":
          navigate("/coach/calendar");
          break;
        case "Library":
          navigate("/coach/library");
          break;
        case "Leaderboard":
          navigate("/coach/leaderboard");
          break;
        default:
          navigate("/coach");
          break;
      }
    };
  
    const icons = [
      { inactiveIcon: DashboardIn, activeIcon: Dashboard, alt: "Dashboard" },
      { inactiveIcon: MyAthletesIn, activeIcon: MyAthletes, alt: "My Athletes" },
      { inactiveIcon: CalendarIn, activeIcon: Calendar, alt: "Calendar" },
      { inactiveIcon: LibraryIn, activeIcon: Library, alt: "Library" },
      {
        inactiveIcon: LeaderboardIn,
        activeIcon: Leaderboard,
        alt: "Leaderboard",
      },
    ];
  
    return (
      <>
        <SidebarWrapper
          sx={{
            display: "inline-block",
            position: "fixed",
            left: 0,
            top: 0,
          }}
        >
          <Box
            sx={{
              paddingTop: "10px",
              background: "#2C262D",
              display: "flex",
              flexDirection: "column",
              rowGap: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">{user.user_metadata.name}</Typography>
              <Typography
                variant="coach700"
                fontSize={".5rem"}
                letterSpacing={"5.5px"}
              >
                COACH
              </Typography>
            </Box>
            <Divider sx={{ width: "100%", border: "0.5px solid #201B20" }} />
            {icons.map((icon, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "1em",
                }}
              >
                <Tooltip key={icon.alt} title={icon.alt}>
                  <IconButton onClick={() => handleIconClick(icon.alt)}>
                    <img
                      src={
                        activeIcon === icon.alt
                          ? icon.activeIcon
                          : icon.inactiveIcon
                      }
                      alt={icon.alt}
                      width={20}
                    />
                  </IconButton>
                </Tooltip>
                <Typography
                  onClick={() => handleIconClick(icon.alt)}
                  sx={{ cursor: "pointer" }}
                >
                  {icon.alt}
                </Typography>
              </Box>
            ))}
          </Box>
        </SidebarWrapper>
      </>
    );
  }