import { Box, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import arrowBack from "../../../assets/img/layout/coach/MyAthletes/arrowBack.svg";
import CoachOverviewView from "./AthletesSessions/CoachOverviewView";
import CoachAnalyticsView from "./AthletesSessions/CoachAnalyticsView";
import { TableRow, WithCache } from "src/contexts/CacheContext";
import { useLocation, useNavigate } from "react-router-dom";
import CoachZonesView from "./AthletesSessions/CoachZonesView";
import CoachPerformanceIndexView from "./AthletesSessions/CoachPerformanceIndexView";
import PreloadComponent from "src/utils/PreloadComponent";
import { useRideSession } from "./AthletesSessions/CoachSessionViewPreload";
import { useRideSession as RideSession } from ".././Sessions/CoachSessionViewsPreload";
import CoachPositionsView from "./AthletesSessions/CoachPositionsView";

export default function CoachAthleteRideInsightsView() {
  const location = useLocation();
  const navigation = useNavigate();

  const session =
    location.pathname.startsWith("/coach/my_athletes") &&
    !location.pathname.startsWith("/coach/my_athletes/session")
      ? useRideSession()
      : RideSession();

  const [selectedComponent, setSelectedComponent] =
    useState("CoachOverviewView");

  const handleComponentChange = (e) => {
    setSelectedComponent(e.target.value);
  };

  const componentMapping = {
    CoachOverviewView: <CoachOverviewView />,
    CoachAnalyticsView: (
      <PreloadComponent<{
        customPositions: Array<TableRow<"custom_positions">>;
      }>
        promises={{
          customPositions: async (supabase) => {
            if (session?.athlete_bike_id === null) {
              return [];
            }
            const { data } = await supabase
              .from("custom_positions")
              .select("*")
              .eq("bike_id", session?.athlete_bike_id);
            return data;
          },
        }}
        component={(preloadProps) => (
          <>
            <WithCache
              tables={["default_positions"]}
              component={(cached) => (
                <CoachAnalyticsView
                  customPositions={preloadProps.customPositions}
                  defaultPositions={cached.default_positions}
                />
              )}
            />
          </>
        )}
      />
    ),
    CoachZonesView: (
      <PreloadComponent<{
        customPositions: Array<TableRow<"custom_positions">>;
      }>
        promises={{
          customPositions: async (supabase) => {
            if (session?.athlete_bike_id === null) {
              return [];
            }
            const { data } = await supabase
              .from("custom_positions")
              .select("*")
              .eq("bike_id", session?.athlete_bike_id);
            return data;
          },
        }}
        component={(preloadProps) => (
          <>
            <WithCache
              tables={["default_positions"]}
              component={(cached) => (
                <CoachZonesView
                  customPositions={preloadProps.customPositions}
                  defaultPositions={cached.default_positions}
                />
              )}
            />
          </>
        )}
      />
    ),
    CoachPositionsView: (
      <PreloadComponent<{
        customPositions: Array<TableRow<"custom_positions">>;
      }>
        promises={{
          customPositions: async (supabase) => {
            if (session?.athlete_bike_id === null) {
              return [];
            }
            const { data } = await supabase
              .from("custom_positions")
              .select("*")
              .eq("bike_id", session?.athlete_bike_id);
            return data;
          },
        }}
        component={(preloadProps) => (
          <>
            <WithCache
              tables={["default_positions"]}
              component={(cached) => (
                <CoachPositionsView
                  customPositions={preloadProps.customPositions}
                  defaultPositions={cached.default_positions}
                />
              )}
            />
          </>
        )}
      />
    ),
    CoachPerformanceIndex: <CoachPerformanceIndexView />,
  };

  const handleBack = useCallback(() => {
    return location.pathname.startsWith("/coach/my_athletes")
      ? navigation(0)
      : navigation("/coach/calendar");
  }, []);

  return (
    <>
      <Box sx={{ paddingLeft: "25px", paddingTop: "10px" }}>
        <Box
          sx={{
            width: "100%",
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {location.pathname.startsWith("/coach/my_athletes") ? (
              <>
                <IconButton onClick={() => handleBack()}>
                  <img src={arrowBack} />
                </IconButton>
                <Typography>Back To Athlete's Calendar</Typography>
              </>
            ) : (
              <>
                <IconButton onClick={() => handleBack()}>
                  <img src={arrowBack} />
                </IconButton>
                <Typography>Back To My Calendar</Typography>
              </>
            )}
          </Box>

          <Box
            sx={{
              borderColor: "divider",
              display: "flex",
              paddingRight: "20px",
            }}
          >
            <Select
              value={selectedComponent}
              onChange={handleComponentChange}
              sx={{
                color: "#DD4F4A",
                "& .MuiSvgIcon-root": {
                  color: "#DD4F4A",
                },
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#DD4F4A",
                  },
                  "&:hover fieldset": {
                    borderColor: "#DD4F4A",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#DD4F4A",
                  },
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    border: "1px solid #DD4F4A",
                  },
                },
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: "rgba(221, 79, 74, 0.5)",
                  },
                },
                MenuListProps: {
                  disablePadding: true,
                },
              }}
            >
              <MenuItem value="CoachOverviewView">Overview</MenuItem>
              <MenuItem value="CoachAnalyticsView">Analytics</MenuItem>
              <MenuItem value="CoachPositionsView">Positions</MenuItem>
              <MenuItem value="CoachZonesView">Zones</MenuItem>
              <MenuItem value="CoachPerformanceIndex">
                Performance Index
              </MenuItem>
            </Select>
          </Box>
        </Box>
        {componentMapping[selectedComponent]}
      </Box>
    </>
  );
}
