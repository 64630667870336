import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { useCallback, useEffect, useMemo } from "react";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import Chart from "react-apexcharts";
import type { ApexOptions } from "apexcharts";
import ProgressBar from "./CustomProgressBar";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import { PositionStatistics } from "src/utils/types";
import { timeFormatter } from "src/utils/common";

const DotLegend = styled("span")(
  ({ theme }) => `
      border-radius: 1.375rem;
      width: .5rem;
      height: .5rem;
      display: inline-block;
      margin-right: ${theme.spacing(0.5)};
  `,
);

export type StanceInfoCardPropsV3 = {
  statistics: (Omit<PositionStatistics, "positionType"> & {
    positionType: string;
  })[];
  currentTabStat: string;
  totalTime: number;
};

export default function CollapsiblseTable(props: StanceInfoCardPropsV3) {
  const calculatePercentageOfMaximum = useCallback(
    (key: keyof PositionStatistics, statistic: PositionStatistics) =>
      ((statistic[key] as number) * 100) /
      Math.max(...props.statistics.map((stat) => stat[key] as number)),
    [props.statistics],
  );

  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const theme = useTheme();

  const units = useUnits();

  const slopeData = useMemo(
    () => ({
      datasets: [
        {
          backgroundColor: ["#E28E54", "#EA366C", "#726174"],
        },
      ],
      labels: ["Positive Slope", "Negative Slope", "Flat"],
    }),
    [],
  );

  const balanceData = useMemo(
    () => ({
      labels: ["Balance R", "Balance L"],
    }),
    [],
  );

  const filteredStatistics = useMemo(
    () =>
      props.statistics.filter(
        (statistic) => statistic.positionType === props.currentTabStat,
      ),
    [props.currentTabStat],
  );

  const chartOptionsBalance: ApexOptions = useMemo(
    () => ({
      chart: {
        background: "transparent",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "60%",
            labels: {
              show:
                filteredStatistics[0].pedal_balance_left !== null
                  ? false
                  : true,
              total: {
                show: true,
                showAlways: true,
                label: "No Balance",
                fontSize: "16px",
                formatter: (w) => {
                  return "Data";
                },
              },
              value: {
                show: true,
                fontSize: "16px",
              },
            },
          },
        },
      },
      colors:
        filteredStatistics[0].pedal_balance_left !== null
          ? ["#E28E54", "#EA366C"]
          : ["#726174"],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return filteredStatistics[0].pedal_balance_left !== null
            ? (val as number).toFixed(0) + "%"
            : "--";
        },
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          color: theme.colors.alpha.black[50],
          opacity: 0.5,
        },
      },
      fill: {
        opacity: 1,
      },
      labels: balanceData.labels,
      legend: {
        labels: {
          colors: theme.colors.alpha.trueWhite[100],
        },
        show: false,
      },
      stroke: {
        width: 0,
      },
      theme: {
        mode: theme.palette.mode,
      },
      tooltip: {
        enabled:
          filteredStatistics[0].pedal_balance_left !== null ? true : false,
      },
    }),
    [filteredStatistics],
  );

  // const chartOptionsNoBalanceData: ApexOptions = {
  //   chart: {
  //     background: "transparent",
  //     stacked: false,
  //     toolbar: {
  //       show: false,
  //     },
  //   },

  //   plotOptions: {
  //     pie: {
  //       donut: {
  //         size: "60%",
  //         labels: {
  //           show: true,
  //           total: {
  //             show: true,
  //             showAlways: true,
  //             label: "No Balance",
  //             fontSize: "16px",
  //             formatter: (w) => {
  //               return "Data";
  //             },
  //           },
  //           value: {
  //             show: true,
  //             fontSize: "16px",
  //           },
  //         },
  //       },
  //     },
  //   },

  //   colors: ["#726174"],
  //   fill: {
  //     opacity: 0.5,
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   legend: {
  //     labels: {
  //       colors: theme.colors.alpha.trueWhite[100],
  //     },
  //     show: false,
  //   },
  //   tooltip: {
  //     enabled: false,
  //   },
  //   labels: [],
  //   stroke: {
  //     width: 0,
  //   },
  //   theme: {
  //     mode: theme.palette.mode,
  //   },
  // };

  const chartOptions: ApexOptions = useMemo(
    () => ({
      chart: {
        background: "transparent",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "60%",
          },
        },
      },
      colors: ["#E28E54", "#EA366C", "#726174"],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return (val as number)?.toFixed(0) + "%";
        },
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          color: theme.colors.alpha.black[50],
          opacity: 0.5,
        },
      },
      fill: {
        opacity: 1,
      },
      labels: slopeData.labels,
      legend: {
        labels: {
          colors: theme.colors.alpha.trueWhite[100],
        },
        show: false,
      },
      stroke: {
        width: 0,
      },
      theme: {
        mode: theme.palette.mode,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "%";
          },
        },
      },
    }),
    [],
  );
  return (
    <>
      {filteredStatistics.map((statistic, i) => (
        <Box key={i}>
          <Grid
            container
            direction={"row"}
            paddingX={"3.2em"}
            paddingY={"1.5rem"}
            justifyContent={"space-between"}
            gap={2}
          >
            <Grid item>
              <Typography
                sx={{
                  color: "#BC6D29",
                  fontSize: { sm: "15px", xs: "13px" },
                  fontWeight: 700,
                }}
              >{`${
                (statistic.position_percentage * 100)?.toFixed(1) ?? "-"
              }%`}</Typography>
              <Box>
                <Typography variant="subtitle2">% OF TIME</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: "#BC6D29",
                  fontSize: { sm: "15px", xs: "13px" },
                  fontWeight: 700,
                }}
              >
                {`${
                  timeFormatter(
                    (
                      (statistic.position_percentage * props.totalTime) /
                      1000
                    ).toString(),
                  ) ?? "-"
                }`}
              </Typography>
              <Box>
                <Typography variant="subtitle2">TOTAL TIME</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: "#BC6D29",
                  fontSize: { sm: "15px", xs: "13px" },
                  fontWeight: 700,
                }}
              >
                {`${statistic.cda_average?.toFixed(3) ?? "-"}`}
              </Typography>
              <Box>
                <Typography variant="subtitle2">AVG CDA</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: "#BC6D29",
                  fontSize: { sm: "15px", xs: "13px" },
                  fontWeight: 700,
                }}
              >
                {units === "metric"
                  ? `${statistic?.speed_average?.toFixed(1) ?? "-"} kph`
                  : `${
                      (statistic?.speed_average * 0.6213711922)?.toFixed(1) ??
                      "-"
                    } mph`}
              </Typography>

              <Box>
                <Typography variant="subtitle2">AVG SPEED</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: "#BC6D29",
                  fontSize: { sm: "15px", xs: "13px" },
                  fontWeight: 700,
                }}
              >
                {` ${statistic.power_average?.toFixed(0) ?? "-"} W `}
              </Typography>
              <Box>
                <Typography variant="subtitle2">AVG POWER</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: "#BC6D29",
                  fontSize: { sm: "15px", xs: "13px" },
                  fontWeight: 700,
                }}
              >
                {`${statistic.heart_rate_average?.toFixed(0) ?? "-"} bpm`}
              </Typography>
              <Box>
                <Typography variant="subtitle2">AVG HR</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: "#BC6D29",
                  fontSize: { sm: "15px", xs: "13px" },
                  fontWeight: 700,
                }}
              >
                {`${statistic.cadence_average?.toFixed(0) ?? "-"} rpm`}
              </Typography>
              <Box>
                <Typography variant="subtitle2">AVG CAD</Typography>
              </Box>
            </Grid>
          </Grid>

          <Box
            width={"100%"}
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
          >
            <Stack
              sx={{
                width: { xs: "95%", md: "40%" },
              }}
            >
              <Box
                paddingLeft={isMobileScreen ? "2em" : "3.5625rem"}
                paddingTop={isMobileScreen ? "5rem" : "2.25rem"}
              >
                <Box marginBottom={"1.625rem"}>
                  <Typography
                    sx={{
                      color: "#F7F3F0",
                      fontSize: "13px",
                      fontWeight: 400,
                      marginBottom: ".25rem",
                    }}
                  >
                    {units === "metric"
                      ? `${statistic.speed_max?.toFixed(1)} kph`
                      : `${(statistic.speed_max * 0.6213711922)?.toFixed(
                          1,
                        )} mph`}
                  </Typography>
                  <ProgressBar
                    valuePercentage={
                      isNaN(
                        calculatePercentageOfMaximum("speed_max", statistic),
                      )
                        ? 0
                        : calculatePercentageOfMaximum("speed_max", statistic)
                    }
                  />
                  <Box
                    display="flex"
                    sx={{
                      mt: 0.6,
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#F7F3F0",
                        fontSize: "10px",
                        fontWeight: 400,
                        opacity: "0.5",
                      }}
                    >
                      {"Speed Max"}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#F7F3F0",
                        fontSize: "10px",
                        fontWeight: 400,
                        opacity: "0.5",
                      }}
                      variant="subtitle2"
                    >
                      {isNaN(
                        calculatePercentageOfMaximum("speed_max", statistic),
                      )
                        ? "- %"
                        : `${calculatePercentageOfMaximum(
                            "speed_max",
                            statistic,
                          )?.toFixed(0)} %`}
                    </Typography>
                  </Box>
                </Box>
                <Box marginBottom={"1.625rem"}>
                  <Typography
                    sx={{
                      color: "#F7F3F0",
                      fontSize: "13px",
                      fontWeight: 400,
                      marginBottom: ".25rem",
                    }}
                  >
                    {` ${statistic.power_max?.toFixed(0)} W`}
                  </Typography>

                  <ProgressBar
                    valuePercentage={
                      isNaN(
                        calculatePercentageOfMaximum("power_max", statistic),
                      )
                        ? 0
                        : calculatePercentageOfMaximum("power_max", statistic)
                    }
                  />

                  <Box
                    display="flex"
                    sx={{
                      mt: 0.6,
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#F7F3F0",
                        fontSize: "10px",
                        fontWeight: 400,
                        opacity: "0.5",
                      }}
                    >
                      {"Power Max"}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#F7F3F0",
                        fontSize: "10px",
                        fontWeight: 400,
                        opacity: "0.5",
                      }}
                      variant="subtitle2"
                    >
                      {isNaN(
                        calculatePercentageOfMaximum("power_max", statistic),
                      )
                        ? "- %"
                        : `${calculatePercentageOfMaximum(
                            "power_max",
                            statistic,
                          )?.toFixed(0)} %`}
                    </Typography>
                  </Box>
                </Box>
                <Box marginBottom={"1.625rem"}>
                  <Typography
                    sx={{
                      color: "#F7F3F0",
                      fontSize: "13px",
                      fontWeight: 400,
                      marginBottom: ".25rem",
                    }}
                  >
                    {`${statistic.heart_rate_max?.toFixed(0)} bpm`}
                  </Typography>
                  <ProgressBar
                    valuePercentage={
                      isNaN(
                        calculatePercentageOfMaximum(
                          "heart_rate_max",
                          statistic,
                        ),
                      )
                        ? 0
                        : calculatePercentageOfMaximum(
                            "heart_rate_max",
                            statistic,
                          )
                    }
                  />
                  <Box
                    display="flex"
                    sx={{
                      mt: 0.6,
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#F7F3F0",
                        fontSize: "10px",
                        fontWeight: 400,
                        opacity: "0.5",
                      }}
                    >
                      {"HR Max"}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#F7F3F0",
                        fontSize: "10px",
                        fontWeight: 400,
                        opacity: "0.5",
                      }}
                      variant="subtitle2"
                    >
                      {isNaN(
                        calculatePercentageOfMaximum(
                          "heart_rate_max",
                          statistic,
                        ),
                      )
                        ? "- %"
                        : `${calculatePercentageOfMaximum(
                            "heart_rate_max",
                            statistic,
                          )?.toFixed(0)} %`}
                    </Typography>
                  </Box>
                </Box>
                <Box marginBottom={"1.625rem"}>
                  <Typography
                    sx={{
                      color: "#F7F3F0",
                      fontSize: "13px",
                      fontWeight: 400,
                      marginBottom: ".25rem",
                    }}
                  >
                    {`${statistic.cadence_max?.toFixed(0)} rpm`}
                  </Typography>
                  <ProgressBar
                    valuePercentage={
                      isNaN(
                        calculatePercentageOfMaximum("cadence_max", statistic),
                      )
                        ? 0
                        : calculatePercentageOfMaximum("cadence_max", statistic)
                    }
                  />
                  <Box
                    display="flex"
                    sx={{
                      mt: 0.6,
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#F7F3F0",
                        fontSize: "10px",
                        fontWeight: 400,
                        opacity: "0.5",
                      }}
                    >
                      {"Cadence Max"}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#F7F3F0",
                        fontSize: "10px",
                        fontWeight: 400,
                        opacity: "0.5",
                      }}
                      variant="subtitle2"
                    >
                      {isNaN(
                        calculatePercentageOfMaximum("cadence_max", statistic),
                      )
                        ? "- %"
                        : `${calculatePercentageOfMaximum(
                            "cadence_max",
                            statistic,
                          )?.toFixed(0)} %`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Stack>

            <Grid
              container
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sm={statistic.pedal_balance_left !== 0 ? 6 : 12}
                xs={isMobileScreen ? 12 : 6}
              >
                <Chart
                  height={isMobileScreen ? 260 : 290}
                  options={chartOptions}
                  series={[
                    statistic.slope_positive_percentage * 100,
                    statistic.slope_negative_percentage * 100,
                    100 -
                      (statistic.slope_positive_percentage * 100 +
                        statistic.slope_negative_percentage * 100),
                  ].map((num) => Number(num?.toFixed(2)))}
                  type="donut"
                />
                <Box
                  my={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    mx={0.5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <DotLegend
                      style={{
                        background: "#E28E54",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        lineHeight: 1,
                        fontSize: "11px",
                        color: "#F7F3F0",
                      }}
                    >
                      {"Positive Slope"}
                    </Typography>
                  </Box>
                  <Box
                    mx={0.5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <DotLegend
                      style={{
                        background: "#EA366C",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        lineHeight: 1,
                        fontSize: "11px",
                        color: "#F7F3F0",
                      }}
                    >
                      {"Negative Slope"}
                    </Typography>
                  </Box>
                  <Box
                    mx={0.5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <DotLegend
                      style={{
                        background: "#726174",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        lineHeight: 1,
                        fontSize: "11px",
                        color: "#F7F3F0",
                      }}
                    >
                      {"Flat"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {statistic.pedal_balance_left !== 0 && (
                <Grid item xs={isMobileScreen ? 12 : 6}>
                  <Chart
                    height={isMobileScreen ? 260 : 290}
                    // options={
                    //   statistic.pedal_balance_left !== null
                    //     ? chartOptionsBalance
                    //     : chartOptionsNoBalanceData
                    // }
                    options={chartOptionsBalance}
                    series={
                      statistic.pedal_balance_left !== null
                        ? [
                            100 - statistic.pedal_balance_left,
                            statistic.pedal_balance_left,
                          ].map((num) => Number(num?.toFixed(1)))
                        : [1]
                    }
                    type="donut"
                  />
                  <Box
                    my={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      mx={0.5}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <DotLegend
                        style={{
                          background: "#EA366C",
                        }}
                      />
                      <Typography
                        sx={{
                          fontWeight: 400,
                          lineHeight: 1,
                          fontSize: "11px",
                          color: "#F7F3F0",
                        }}
                      >
                        {"Balance L"}
                      </Typography>
                    </Box>
                    <Box
                      mx={0.5}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <DotLegend
                        style={{
                          background: "#E28E54",
                        }}
                      />
                      <Typography
                        sx={{
                          fontWeight: 400,
                          lineHeight: 1,
                          fontSize: "11px",
                          color: "#F7F3F0",
                        }}
                      >
                        {"Balance R"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      ))}
    </>
  );
}
