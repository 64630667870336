import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Slider,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Stack,
} from "@mui/material";
import {
  allBikePositions,
  allColors,
  timeFormatter,
  withReference,
} from "src/utils/common";
import im from "immutable";
import convert from "convert-units";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SuspenseLoader from "src/components/SuspenseLoader";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import type { BikePosition, SupabaseCall } from "src/utils/common";
import type { Database } from "src/utils/DatabaseDefinitions";
import PreloadComponent from "src/utils/PreloadComponent";
import type { ApexOptions } from "apexcharts";
import { match, P } from "ts-pattern";
import Chart from "react-apexcharts";
import MapNative from "./components/MapNative";
import type { TableRow } from "src/contexts/CacheContext";

type PlaybackState =
  | {
      kind: "not-started";
    }
  | {
      kind: "playing" | "paused";
      index: number;
    };

export type Speciments = {
  timestamp: number;
  distance: number;
  power: number;
  heartrate: number;
  elevation: number;
  position: BikePosition;
  speed: number;
  longitude: number;
  latitude: number;
  cadence: number;
  torso_degrees: number;
  cda: number;
};

export type PlaybackLabels = {
  position: boolean;
  heartRate: boolean;
  power: boolean;
  speed: boolean;
  cadence: boolean;
  bodyAngle: boolean;
  cda: boolean;
};

export const MetricsContext = createContext<
  Database["public"]["Tables"]["metrics"]["Row"][]
>([]);

export const SpecimentsContext = createContext<Array<Speciments>>([]);
export const RideSessionContext = createContext<
  Database["public"]["Tables"]["session"]["Row"] & {
    ride_types: { name: string };
    athlete_bikes: { bike_types: { name: string } };
    session_statistics: {
      cadence_max: number;
      created_at: string;
      default_position_id: number;
      heart_rate_average: number;
      heart_rate_max: number;
      id: number;
      pedal_balance_left: number;
      pedal_balance_right: number;
      position_percentage: number;
      power_average: number;
      power_max: number;
      session_id: string;
      slope_average: number;
      slope_negative_percentage: number;
      slope_positive_percentage: number;
      speed_average: number;
      speed_max: number;
      cadence_average: number;
    }[];
  }
>(undefined);

export const useMetrics = () => useContext(MetricsContext);
export const useSpeciments = () => useContext(SpecimentsContext);
export const useRideSession = () => useContext(RideSessionContext);

export type AnalyticsNativePreloadProps = {
  defaultPositions: Array<TableRow<"default_positions">>;
};

export default function AnalyticsNativePreload(
  viewProps: AnalyticsNativePreloadProps,
) {
  // Parse query parameters from the URL
  const urlParams = new URLSearchParams(window.location.search);

  // Get the 'sessionId' parameter
  const sessionId = urlParams.get("sessionId");

  // Now, you can use 'sessionId' in your web page's JavaScript code
  const supabase = useSupabase();

  const sessionPromise = useCallback(
    () =>
      supabase
        .from("session")
        .select(
          "*,ride_types(name),athlete_bikes(bike_types(name)),session_statistics(*)",
        )
        .eq("id", sessionId)
        .then((res) => res.data[0]),
    [supabase],
  );

  const session = useAsyncState<SupabaseCall<typeof sessionPromise>>();

  const lazyLoadPromise = useCallback(
    () =>
      supabase.functions.invoke("fetch-strava-streams", {
        body: JSON.stringify({
          activity_id: sessionId,
        }),
      }),
    [supabase, sessionId],
  );

  const lazyLoad = useAsyncState<SupabaseCall<typeof lazyLoadPromise>>();

  useEffect(() => {
    if (isUnloaded(session)) {
      session.fire(async () => sessionPromise());
    } else if (isFulfilled(session)) {
      if (session?.result?.is_lazy === true) {
        if (isUnloaded(lazyLoad)) {
          lazyLoad.fire(async () => lazyLoadPromise());
        }
      }
    }
  }, [session, lazyLoad]);

  const shouldShow = useMemo(() => {
    if (isFulfilled(session)) {
      if (session?.result?.is_lazy === false) {
        return true;
      }
      if (isFulfilled(lazyLoad)) {
        return true;
      }
    }
    return false;
  }, [session, lazyLoad]);

  return (
    <>
      {shouldShow && isFulfilled(session) ? (
        <PreloadComponent<{
          metrics: Database["public"]["Tables"]["metrics"]["Row"][];
          customPositions: Array<TableRow<"custom_positions">>;
        }>
          promises={{
            metrics: async (supabase) =>
              supabase
                .from("metrics")
                .select("*")
                .eq("session_id", sessionId)
                .order("timestamp", { ascending: true })
                .then((res) =>
                  res.data
                    .filter((el) => el.heart_rate !== 0)
                    .filter((_, i) => i % 5 === 0),
                ),
            customPositions: async (supabase) =>
              supabase
                .from("custom_positions")
                .select("*")
                .eq("bike_id", session.result.athlete_bike_id)
                .then((res) => res.data),
          }}
          component={(props) => {
            const rideType =
              isFulfilled(session) &&
              (session.result.ride_types.name === "Indoor"
                ? "indoor"
                : session.result.ride_types.name === "Outdoor"
                ? "outdoor"
                : "virtual");

            const timestampSpeciment = props.metrics
              .map((datum) => new Date(datum.timestamp).getTime())
              .map(
                (datum) =>
                  datum - new Date(props.metrics[0].timestamp).getTime(),
              )
              .map((datum) => Math.floor(datum / 1000));

            const distanceSpeciment = (() => {
              if (rideType === "indoor") {
                // biome-ignore lint/style/useConst: <explanation>
                let accum = [0];
                for (let i = 1; i < props.metrics.length; i++) {
                  const lastDistance = accum[accum.length - 1];
                  const prevMetric = props.metrics[i - 1];
                  const currMetric = props.metrics[i];
                  const timestampDifferenceMS =
                    new Date(currMetric.timestamp).getTime() -
                    new Date(prevMetric.timestamp).getTime();
                  const timestampDifferenceH =
                    timestampDifferenceMS / 1000 / 3600;
                  const elapsedDistance =
                    timestampDifferenceH * currMetric.speed;
                  accum.push(lastDistance + elapsedDistance);
                }
                return accum;
              }
              return props.metrics.map((datum) => datum.distance);
            })();

            const elevationSpeciment = (() => {
              const min = Math.min(
                ...props.metrics.map((datum) => datum.device_altitude),
              );
              if (min < 0) {
                return props.metrics.map((datum) =>
                  Number((datum.device_altitude - min)?.toFixed(2)),
                );
              }
              return props.metrics.map((datum) =>
                Number(datum.device_altitude?.toFixed(2)),
              );
            })();

            const allSpeciments: Array<Speciments> = (() => {
              if (isFulfilled(session)) {
                // biome-ignore lint/style/useConst: <explanation>
                let rval = [];
                for (let i = 0; i < props.metrics.length; i++) {
                  rval.push({
                    timestamp: timestampSpeciment[i],
                    distance: distanceSpeciment[i],
                    power: props.metrics[i].bike_power,
                    heartrate: props.metrics[i].heart_rate,
                    elevation: elevationSpeciment[i],
                    speed: props.metrics[i].speed,
                    position:
                      session.result.session_statistics.length !== 0
                        ? ((position) =>
                            position.primary_name +
                            (position.secondary_name
                              ? ` - ${position.secondary_name}`
                              : ""))(
                            props.metrics[i].default_position_id !== null
                              ? viewProps.defaultPositions.find(
                                  (pos) =>
                                    pos.id ===
                                    props.metrics[i].default_position_id,
                                )
                              : props.customPositions.find(
                                  (pos) =>
                                    pos.id ===
                                    props.metrics[i].custom_position_id,
                                ),
                          )
                        : null,
                    longitude: props.metrics[i].longitude,
                    latitude: props.metrics[i].latitude,
                    cadence: props.metrics[i].cadence,
                    torso_degrees: props.metrics[i].torso_degrees,
                    cda: props.metrics[i].cda,
                  });
                }
                return rval;
              }
            })();

            return (
              <MetricsContext.Provider value={props.metrics}>
                <SpecimentsContext.Provider value={allSpeciments}>
                  <RideSessionContext.Provider
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    value={isFulfilled(session) && (session.result as any)}
                  >
                    <AnalyticsNative
                      defaultPositions={viewProps.defaultPositions}
                      customPositions={props.customPositions}
                    />
                  </RideSessionContext.Provider>
                </SpecimentsContext.Provider>
              </MetricsContext.Provider>
            );
          }}
        />
      ) : (
        <SuspenseLoader />
      )}
    </>
  );
}

type AnalyticsNativeProps = {
  defaultPositions: Array<TableRow<"default_positions">>;
  customPositions: Array<TableRow<"custom_positions">>;
};

function AnalyticsNative(props: AnalyticsNativeProps) {
  const theme = useTheme();
  const metrics = useMetrics();
  const allSpeciments = useSpeciments();
  const session = useRideSession();

  const rideType = useMemo(
    () =>
      session.ride_types.name === "Indoor"
        ? "indoor"
        : session.ride_types.name === "Outdoor"
        ? "outdoor"
        : "virtual",
    [session],
  );

  const [selectedPositions, setSelectedPositions] = useState<
    im.Set<BikePosition>
  >(im.Set());

  const [currentTab, setCurrentTab] = useState<"distance" | "time">("time");

  const tabs = useMemo(
    () => [
      { value: "distance", label: "Distance" },
      { value: "time", label: "Time" },
    ],
    [],
  );

  const handleTabsChange = useCallback(
    (_, value: "distance" | "time"): void => {
      setCurrentTab(value);
    },
    [],
  );

  const hasElevation = useMemo(
    () =>
      !allSpeciments
        .map((spec) => spec.elevation)
        .every((elevation) => !elevation),
    [allSpeciments],
  );

  const hasHeartRate = useMemo(
    () =>
      !allSpeciments
        .map((spec) => spec.heartrate)
        .every((heartrate) => !heartrate),
    [allSpeciments],
  );

  const hasPower = useMemo(
    () => !allSpeciments.map((spec) => spec.power).every((power) => !power),
    [allSpeciments],
  );

  const hasCadence = useMemo(
    () =>
      !allSpeciments.map((spec) => spec.cadence).every((cadence) => !cadence),
    [allSpeciments],
  );

  const hasSpeed = useMemo(
    () => !allSpeciments.map((spec) => spec.speed).every((speed) => !speed),
    [allSpeciments],
  );

  const hasBodyAngle = useMemo(
    () =>
      !allSpeciments
        .map((spec) => spec.torso_degrees)
        .every((torso_degrees) => !torso_degrees),
    [allSpeciments],
  );

  const hasCda = useMemo(
    () => !allSpeciments.map((spec) => spec.cda).every((cda) => !cda),
    [allSpeciments],
  );
  const bikePositions = useMemo(() => {
    if (session.session_statistics.length === 0) {
      return [];
    }
    return Array.from(
      new Set(
        metrics.flatMap((session) => {
          const positions =
            session.default_position_id !== null
              ? props.defaultPositions.find(
                  (pos) => pos.id === session.default_position_id,
                )
              : props.customPositions.find(
                  (pos) => pos.id === session.custom_position_id,
                );
          return (
            positions &&
            positions.primary_name +
              (positions.secondary_name ? ` - ${positions.secondary_name}` : "")
          );
        }),
      ),
    ).sort(
      (a, b) =>
        allBikePositions.indexOf(
          a as
            | "Standing"
            | "Comfortable - Not Ideal"
            | "Comfortable - Ideal"
            | "Aggressive - Not Ideal"
            | "Aggressive - Ideal"
            | "TT - Not Ideal"
            | "TT - Ideal"
            | "Lower than TT",
        ) -
        allBikePositions.indexOf(
          b as
            | "Standing"
            | "Comfortable - Not Ideal"
            | "Comfortable - Ideal"
            | "Aggressive - Not Ideal"
            | "Aggressive - Ideal"
            | "TT - Not Ideal"
            | "TT - Ideal"
            | "Lower than TT",
        ),
    );
  }, [metrics, session, props.defaultPositions]);

  const positionRanges: Array<{
    position: BikePosition;
    open: number;
    close: number;
  }> = useMemo(() => {
    if (allSpeciments?.length > 0) {
      let currentPosition = allSpeciments[0]?.position;
      let open = allSpeciments[0]?.timestamp;
      // biome-ignore lint/style/useConst: <explanation>
      let rval = [];

      for (let i = 1; i < metrics.length; i++) {
        if (
          allSpeciments[i].position !== currentPosition ||
          i === metrics.length - 1
        ) {
          rval.push({
            position: currentPosition,
            open,
            close: allSpeciments[i].timestamp,
          });
          currentPosition = allSpeciments[i].position;
          open = allSpeciments[i].timestamp;
        }
      }

      return rval;
    }
    return [];
  }, [metrics, allSpeciments]);

  const positionDistanceRanges: Array<{
    position: BikePosition;
    open: number;
    close: number;
  }> = useMemo(() => {
    if (allSpeciments?.length > 0) {
      let currentPosition = allSpeciments[0].position;
      let open = allSpeciments[0].distance;
      // biome-ignore lint/style/useConst: <explanation>
      let rval = [];

      for (let i = 1; i < metrics.length; i++) {
        if (
          allSpeciments[i].position !== currentPosition ||
          i === metrics.length - 1
        ) {
          rval.push({
            position: currentPosition,
            open,
            close: allSpeciments[i].distance,
          });
          currentPosition = allSpeciments[i].position;
          open = allSpeciments[i].distance;
        }
      }

      return rval;
    }
    return [];
  }, [metrics, allSpeciments]);

  useEffect(() => {
    ApexCharts.exec(
      "positions",
      "updateOptions",
      {
        annotations: {
          position: "back",
          xaxis: withReference(
            currentTab === "time" ? positionRanges : positionDistanceRanges,
            (arr) =>
              arr.map((positionRange) => ({
                x: positionRange.open,
                x2: positionRange.close,
                fillColor: allColors[positionRange.position],
                opacity:
                  selectedPositions.isEmpty() ||
                  selectedPositions.has(positionRange.position)
                    ? 2
                    : 0,
                strokeDashArray: 0,
                borderColor: allColors[positionRange.position],
              })),
          ),
        },
      },
      false,
      true,
      false,
    );
    if (!selectedPositions.isEmpty()) {
      if (rideType !== "indoor") {
        ApexCharts.exec(
          "elevation",
          "updateOptions",
          {
            annotations: {
              points: allSpeciments
                .filter((spec) => selectedPositions.has(spec.position))
                .map((spec) => ({
                  x: currentTab === "time" ? spec.timestamp : spec.distance,
                  y: spec.elevation,
                  marker: {
                    fillColor: allColors[spec.position],
                    strokeColor: allColors[spec.position],
                    size: 1,
                  },
                })),
            },
          },
          false,
          true,
          false,
        );
      } else {
        ApexCharts.exec(
          "speed",
          "updateOptions",
          {
            annotations: {
              points: allSpeciments
                .filter((spec) => selectedPositions.has(spec.position))
                .map((spec) => ({
                  x: currentTab === "time" ? spec.timestamp : spec.distance,
                  y: spec.speed,
                  marker: {
                    fillColor: allColors[spec.position],
                    strokeColor: allColors[spec.position],
                    size: 1,
                  },
                })),
            },
          },
          false,
          true,
          false,
        );
      }
      ApexCharts.exec(
        "heartrate",
        "updateOptions",
        {
          annotations: {
            position: "back",
            xaxis: withReference(
              currentTab === "time" ? positionRanges : positionDistanceRanges,
              (arr) =>
                arr
                  .filter((range) => selectedPositions.has(range.position))
                  .map((positionRange) => ({
                    x: positionRange.open,
                    x2: positionRange.close,
                    fillColor: allColors[positionRange.position],
                    opacity: 1,
                    strokeDashArray: 0,
                    borderColor: allColors[positionRange.position],
                  })),
            ),
          },
        },
        false,
        true,
        false,
      );
      ApexCharts.exec(
        "power",
        "updateOptions",
        {
          annotations: {
            position: "back",
            xaxis: withReference(
              currentTab === "time"
                ? positionRanges
                : positionDistanceRanges.map((range) => ({
                    ...range,
                    open: convert(range.open).from("km").to("mi"),
                    close: convert(range.close).from("km").to("mi"),
                  })),
              (arr) =>
                arr
                  .filter((range) => selectedPositions.has(range.position))
                  .map((positionRange) => ({
                    x: positionRange.open,
                    x2: positionRange.close,
                    fillColor: allColors[positionRange.position],
                    opacity: 1,
                    strokeDashArray: 0,
                    borderColor: allColors[positionRange.position],
                  })),
            ),
          },
        },
        false,
        true,
        false,
      );
      ApexCharts.exec(
        "cadence",
        "updateOptions",
        {
          annotations: {
            position: "back",
            xaxis: withReference(
              currentTab === "time" ? positionRanges : positionDistanceRanges,
              (arr) =>
                arr
                  .filter((range) => selectedPositions.has(range.position))
                  .map((positionRange) => ({
                    x: positionRange.open,
                    x2: positionRange.close,
                    fillColor: allColors[positionRange.position],
                    opacity: 1,
                    strokeDashArray: 0,
                    borderColor: allColors[positionRange.position],
                  })),
            ),
          },
        },
        false,
        true,
        false,
      );
      ApexCharts.exec(
        "body angle",
        "updateOptions",
        {
          annotations: {
            position: "back",
            xaxis: withReference(
              currentTab === "time" ? positionRanges : positionDistanceRanges,
              (arr) =>
                arr
                  .filter((range) => selectedPositions.has(range.position))
                  .map((positionRange) => ({
                    x: positionRange.open,
                    x2: positionRange.close,
                    fillColor: allColors[positionRange.position],
                    opacity: 1,
                    strokeDashArray: 0,
                    borderColor: allColors[positionRange.position],
                  })),
            ),
          },
        },
        false,
        true,
        false,
      );
      ApexCharts.exec(
        "cda",
        "updateOptions",
        {
          annotations: {
            position: "back",
            xaxis: withReference(
              currentTab === "time" ? positionRanges : positionDistanceRanges,
              (arr) =>
                arr
                  .filter((range) => selectedPositions.has(range.position))
                  .map((positionRange) => ({
                    x: positionRange.open,
                    x2: positionRange.close,
                    fillColor: allColors[positionRange.position],
                    opacity: 1,
                    strokeDashArray: 0,
                    borderColor: allColors[positionRange.position],
                  })),
            ),
          },
        },
        false,
        true,
        false,
      );
    } else {
      if (rideType !== "indoor") {
        ApexCharts.exec(
          "elevation",
          "updateOptions",
          {
            annotations: {
              points: [],
            },
          },
          false,
          true,
          false,
        );
      } else {
        ApexCharts.exec(
          "speed",
          "updateOptions",
          {
            annotations: {
              points: [],
            },
          },
          false,
          true,
          false,
        );
      }
      ApexCharts.exec(
        "positions",
        "updateOptions",
        {
          annotations: {
            points: [],
          },
        },
        false,
        true,
        false,
      );
      ApexCharts.exec(
        "heartrate",
        "updateOptions",
        {
          annotations: {
            xaxis: [],
          },
        },
        false,
        true,
        false,
      );
      ApexCharts.exec(
        "power",
        "updateOptions",
        {
          annotations: {
            xaxis: [],
          },
        },
        false,
        true,
        false,
      );
      ApexCharts.exec(
        "cadence",
        "updateOptions",
        {
          annotations: {
            xaxis: [],
          },
        },
        false,
        true,
        false,
      );
      ApexCharts.exec(
        "body angle",
        "updateOptions",
        {
          annotations: {
            xaxis: [],
          },
        },
        false,
        true,
        false,
      );
      ApexCharts.exec(
        "cda",
        "updateOptions",
        {
          annotations: {
            xaxis: [],
          },
        },
        false,
        true,
        false,
      );
    }
  }, [
    selectedPositions,
    currentTab,
    allSpeciments,
    positionRanges,
    positionDistanceRanges,
  ]);

  const [hoveredIndex, setHoveredIndex] = useState<number | "none">("none");
  const [zoomed, setZoomed] = useState<[number, number] | "none">("none");

  const createChartOptions = useCallback(
    (
      id: string,
      title: string,
      color: string,
      xaxisFormatter: (_: string) => string,
      yaxisFormatter: (_: number) => string,
      showToolbar: boolean,
    ): ApexOptions => ({
      chart: {
        id,
        group: "graphs",
        animations: {
          enabled: false,
        },
        events: {
          mouseMove: (_, __, e) => {
            setHoveredIndex(e.dataPointIndex > 0 ? e.dataPointIndex : "none");
          },
          mouseLeave: (_) => {
            setHoveredIndex("none");
          },
          click: (_) => {
            setPlayback((playback) =>
              match(playback)
                .with(
                  { kind: "playing", index: P.select("index") },
                  (selected) => ({
                    kind: "paused" as const,
                    index: selected.index,
                  }),
                )
                .otherwise((p) => p),
            );
          },
          zoomed: (_, props) =>
            !!props.xaxis.min && !!props.xaxis.max
              ? setZoomed([props.xaxis.min, props.xaxis.max])
              : setZoomed("none"),
        },
        background: "transparent",
        toolbar: {
          show: showToolbar,
          offsetX: -20,
          tools: {
            reset: true,
            zoom: "<img/>",
            pan: false,
            selection: false,
            zoomin: false,
            zoomout: false,
            download: false,
          },
        },
      },
      title: {
        text: title,
      },
      stroke: {
        curve: "smooth",
        width: [1, 3],
        dashArray: [0, 5],
        colors: [color],
      },
      fill: {
        opacity: [1, 0.2],
      },
      theme: {
        mode: theme.palette.mode,
      },
      colors: [color],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          formatter: xaxisFormatter,
        },
        tickAmount: 5,
        type: "numeric",
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          maxWidth: 20,
          minWidth: 20,
          formatter: yaxisFormatter,
        },

        tickAmount: 2,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        strokeDashArray: 5,
        borderColor: theme.colors.alpha.black[10],
      },
      tooltip: {
        y: {
          formatter: (val) =>
            id === "cda" ? val?.toFixed(3) : val?.toFixed(0),
        },
      },
    }),
    [],
  );

  const elevationTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "elevation",
        "Elevation (m) / Time",
        theme.colors.primary.main,
        timeFormatter,
        (val) => val?.toFixed(0),
        false,
      ),
    [],
  );

  const elevationTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Elevation",
        data: allSpeciments.map((spec) => [
          spec.timestamp,
          spec.elevation,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments],
  );

  const elevationDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "elevation",
        "Elevation (m) / Distance (km)",
        theme.colors.primary.main,
        (valStr) => `${Number(valStr)?.toFixed(2)}km`,
        (val) => val?.toFixed(0),
        true,
      ),
    [],
  );

  const elevationDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Elevation",
        data: allSpeciments.map((spec) => [
          spec.distance,
          spec.elevation,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments],
  );

  const speedTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "speed",
        "Speed (kph) / Time",
        theme.colors.primary.main,
        timeFormatter,
        (val) => val?.toFixed(0),
        session.session_statistics.length === 0,
      ),
    [],
  );

  const speedTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Speed",
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        data: allSpeciments.map((spec) => [spec.timestamp, spec.speed]) as any,
      },
    ],
    [allSpeciments],
  );

  const speedDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "speed",
        "Speed (kph) / Distance (km)",
        theme.colors.primary.main,
        (valStr) => `${Number(valStr)?.toFixed(2)}km`,
        (val) => val?.toFixed(0),
        session.session_statistics.length === 0,
      ),
    [],
  );

  const speedDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Speed",
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        data: allSpeciments.map((spec) => [spec.distance, spec.speed]) as any,
      },
    ],
    [allSpeciments],
  );

  const heartRateTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "heartrate",
        "Heart Rate (bpm) / Time",
        theme.colors.error.main,
        timeFormatter,
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (val) => (!val ? (val as any) : val?.toFixed(0)),
        false,
      ),
    [session],
  );

  const heartRateTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Heart Rate",
        data: allSpeciments.map((spec) => [
          spec.timestamp,
          spec.heartrate,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments],
  );

  const heartRateDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "heartrate",
        "Heart Rate (bpm) / Distance (km)",
        theme.colors.error.main,
        (valStr) => `${Number(valStr)?.toFixed(2)}km`,
        (val) => val?.toFixed(0),
        false,
      ),
    [],
  );

  const heartRateDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Heart Rate",
        data: allSpeciments.map((spec) => [
          spec.distance,
          spec.heartrate,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments],
  );

  const powerTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "power",
        "Power (W) / Time",
        theme.colors.warning.main,
        timeFormatter,
        (val) => val?.toFixed(0),
        false,
      ),
    [],
  );

  const powerTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Power",
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        data: allSpeciments.map((spec) => [spec.timestamp, spec.power]) as any,
      },
    ],
    [allSpeciments],
  );

  const powerDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "power",
        "Power (W) / Distance (km)",
        theme.colors.warning.main,
        (valStr) => `${Number(valStr)?.toFixed(2)}km`,
        (val) => val?.toFixed(0),
        false,
      ),
    [],
  );

  const powerDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Power",
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        data: allSpeciments.map((spec) => [spec.distance, spec.power]) as any,
      },
    ],
    [allSpeciments],
  );

  const cadenceTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "cadence",
        "Cadence (rpm) / Time",
        "#00B8D9",
        timeFormatter,
        (val) => val?.toFixed(0),
        false,
      ),
    [],
  );

  const cadenceTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Cadence",
        data: allSpeciments.map((spec) => [
          spec.timestamp,
          spec.cadence,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments],
  );

  const cadenceDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "cadence",
        "Cadence (rpm) / Distance (km)",
        "#00B8D9",
        (valStr) => `${Number(valStr)?.toFixed(2)}km}`,
        (val) => val?.toFixed(0),
        false,
      ),
    [],
  );

  const cadenceDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Cadence",
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        data: allSpeciments.map((spec) => [spec.distance, spec.cadence]) as any,
      },
    ],
    [allSpeciments],
  );

  const bodyAngleTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "body angle",
        "Body Angle° / Time",
        "#653780",
        timeFormatter,
        (val) => val?.toFixed(0),
        false,
      ),
    [],
  );

  const bodyAngleTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Body Angle",
        data: allSpeciments.map((spec) => [
          spec.timestamp,
          spec.torso_degrees,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments],
  );

  const bodyAngleDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "body angle",
        "Body Angle° / Distance (km)",
        "#653780",
        (valStr) => `${Number(valStr)?.toFixed(2)}km`,
        (val) => val?.toFixed(0),
        false,
      ),
    [],
  );

  const bodyAngleDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "Body Angle",
        data: allSpeciments.map((spec) => [
          spec.distance,
          spec.torso_degrees,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments],
  );

  const cdaTimeChartOptions = useMemo(
    () =>
      createChartOptions(
        "cda",
        "CDA / Time",
        "#55AA55",
        timeFormatter,
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (val) => (!val ? (val as any) : val?.toFixed(0)),
        false,
      ),
    [],
  );

  const cdaTimeChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "CDA",
        data: allSpeciments.map((spec) => [
          spec.timestamp,
          spec.cda,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments],
  );

  const cdaDistanceChartOptions = useMemo(
    () =>
      createChartOptions(
        "cda",
        "CDA / Distance (km)",
        "#55AA55",
        (valStr) => `${Number(valStr)?.toFixed(2)}km`,
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (val) => (!val ? (val as any) : val?.toFixed(0)),
        false,
      ),
    [],
  );

  const cdaDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: "CDA",
        data: allSpeciments.map((spec) => [
          spec.distance,
          spec.cda,
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        ]) as any,
      },
    ],
    [allSpeciments],
  );

  const positionBrushChartOptions: ApexOptions = useMemo(
    () => ({
      chart: {
        id: "positions",
        height: 130,
        group: "graphs",
        type: "line",
        animations: {
          enabled: false,
        },
        toolbar: {
          show: true,
          offsetX: -20,
          tools: {
            reset: true,
            zoom: "<img/>",
            pan: false,
            selection: false,
            zoomin: false,
            zoomout: false,
            download: false,
          },
        },
        background: "transparent",
        events: {
          mouseMove: (_, __, e) => {
            setHoveredIndex(e.dataPointIndex > 0 ? e.dataPointIndex : "none");
          },
          mouseLeave: (_) => {
            setHoveredIndex("none");
          },
          beforeZoom: (chartContext, { xaxis }) => ({
            xaxis: {
              min: xaxis.min < 0 ? 0 : xaxis.min,
              max: xaxis.max < 0 ? 1 : xaxis.max,
            },
          }),
          zoomed: (_, props) =>
            !!props.xaxis.min && !!props.xaxis.max
              ? setZoomed([props.xaxis.min, props.xaxis.max])
              : setZoomed("none"),
        },
        // brush: {
        //   target: "positions",
        //   enabled: true,
        // },
      },

      legend: {
        show: false,
        position: "bottom",
        markers: {
          fillColors: bikePositions.map((position) => allColors[position]),
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      title: {
        text: "Position",
      },
      theme: {
        mode: theme.palette.mode,
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: [0],
        marker: {
          show: false,
        },
        y: {
          title: {
            formatter: (_) => "Position:",
          },
          formatter: (val) => bikePositions[val],
        },
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        type: "numeric",
        max: allSpeciments?.at(-1)?.timestamp ?? null,
        labels: {
          show: true,
          formatter: timeFormatter,
        },
      },
      fill: {
        opacity: 0,
        colors: ["#E28E54", "#EA366F", "#DD4F4A"],
      },
      stroke: {
        width: 0,
      },
      yaxis: {
        show: true,
        tickAmount: 2,
        labels: {
          show: false,
          maxWidth: 20,
          minWidth: 20,
        },
        max: 100000,
      },
      annotations: {
        position: "back",
        xaxis: positionRanges.map((positionRange) => ({
          x: positionRange.open,
          x2: positionRange.close,
          fillColor: allColors[positionRange.position],
          opacity: 2,
          strokeDashArray: 0,
          borderColor: allColors[positionRange.position],
        })),
      },
    }),
    [positionRanges, bikePositions, allSpeciments],
  );

  const positionBrushDistanceChartOptions: ApexOptions = useMemo(
    () => ({
      chart: {
        id: "positions",
        height: 130,
        group: "graphs",
        type: "line",
        animations: {
          enabled: false,
        },
        toolbar: {
          show: true,
          offsetX: -20,
          tools: {
            reset: true,
            zoom: "<img/>",
            pan: false,
            selection: false,
            zoomin: false,
            zoomout: false,
            download: false,
          },
        },
        background: "transparent",
        events: {
          mouseMove: (_, __, e) => {
            setHoveredIndex(e.dataPointIndex > 0 ? e.dataPointIndex : "none");
          },
          mouseLeave: (_) => {
            setHoveredIndex("none");
          },
        },
        // brush: {
        //   target: "positions",
        //   enabled: true,
        // },
      },
      legend: {
        show: false,
        position: "bottom",
        markers: {
          fillColors: bikePositions.map((position) => allColors[position]),
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      title: {
        text: "Position",
      },
      theme: {
        mode: theme.palette.mode,
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: [0],
        marker: {
          show: false,
        },
        y: {
          title: {
            formatter: (_) => "Position:",
          },
          formatter: (val) => bikePositions[val],
        },
      },
      colors: ["#008FFB"],
      xaxis: {
        type: "numeric",
        max: allSpeciments?.at(-1)?.distance ?? null,
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          formatter: (valStr) => `${Number(valStr)?.toFixed(2)}${"km"}`,
        },
      },
      fill: {
        opacity: 0,
      },
      stroke: {
        width: 0,
      },
      yaxis: {
        tickAmount: 2,
        labels: {
          show: false,
          maxWidth: 20,
          minWidth: 20,
        },
        max: 100000,
      },
      annotations: {
        position: "back",
        xaxis: positionDistanceRanges.map((positionRange) => ({
          x: positionRange.open,
          x2: positionRange.close,
          fillColor: allColors[positionRange.position],
          opacity: 2,
          strokeDashArray: 0,
          borderColor: allColors[positionRange.position],
        })),
      },
    }),
    [
      positionRanges,
      bikePositions,
      allSpeciments,
      positionDistanceRanges,
      positionRanges,
    ],
  );

  const positionStancesChartSeries: ApexAxisChartSeries = useMemo(
    () =>
      bikePositions.map((position, i) => ({
        name: position,
        data:
          i === 0
            ? (allSpeciments.map((spec) => [
                spec.timestamp,
                bikePositions.findIndex((val) => val === spec.position),
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
              ]) as any)
            : [],
      })),
    [allSpeciments, bikePositions],
  );

  const positionStancesDistanceChartSeries: ApexAxisChartSeries = useMemo(
    () =>
      bikePositions.map((position, i) => ({
        name: position,
        data:
          i === 0
            ? (allSpeciments.map((spec) => [
                spec.distance,
                bikePositions.findIndex((val) => val === spec.position),
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
              ]) as any)
            : [],
      })),
    [allSpeciments, bikePositions],
  );

  const [playback, setPlayback] = useState<PlaybackState>({
    kind: "not-started",
  });

  const playbackSpeedRef = useRef<number>(1);

  const handlePlayback = useCallback(() => {
    setPlayback((playback) =>
      match<PlaybackState, PlaybackState>(playback)
        .with({ kind: "not-started" }, () => ({ kind: "playing", index: 0 }))
        .with({ kind: "playing", index: P.select("index") }, (selected) => ({
          kind: "paused",
          index: selected.index,
        }))
        .with({ kind: "paused", index: P.select("index") }, (selected) => ({
          kind: "playing",
          index: selected.index,
        }))
        .run(),
    );
  }, []);

  const incrementPlayback = () => {
    setPlayback((playback) =>
      match<PlaybackState, PlaybackState>(playback)
        .with({ kind: "playing", index: P.select("index") }, (selected) => ({
          kind: "playing",
          index: (selected.index + 1) % allFilteredMarkers.length,
        }))
        .otherwise((playback) => playback),
    );
  };

  useEffect(() => {
    if (incrementIntervalRef.current) {
      clearInterval(incrementIntervalRef.current);
      incrementIntervalRef.current = setInterval(
        () => incrementPlayback(),
        101 - playbackSpeedRef.current,
      );
    }
  }, []);

  const incrementIntervalRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (playback.kind === "playing" && !incrementIntervalRef.current) {
      incrementIntervalRef.current = setInterval(
        () => incrementPlayback(),
        101 - playbackSpeedRef.current,
      );
    } else if (playback.kind === "paused" && !!incrementIntervalRef.current) {
      clearInterval(incrementIntervalRef.current);
      incrementIntervalRef.current = undefined;
    }
  }, [playback, incrementPlayback]);

  useEffect(() => {
    if (playback.kind === "playing" && !!incrementIntervalRef.current) {
      clearInterval(incrementIntervalRef.current);
      incrementIntervalRef.current = setInterval(
        () => incrementPlayback(),
        101 - playbackSpeedRef.current,
      );
    }
  }, [playbackSpeedRef.current, playback.kind]);

  const setPlaybackAnnotations = useCallback(
    (chartId: string, x: number, y: number, label: string) => {
      ApexCharts.exec(chartId, "removeAnnotation", "playback-axis");
      ApexCharts.exec(chartId, "removeAnnotation", "playback-point");
      ApexCharts.exec(chartId, "addXaxisAnnotation", {
        id: "playback-axis",
        x,
        label: {
          text: label,
          orientation: "horizontal",
          style: { background: "black" },
        },
      });
      ApexCharts.exec(chartId, "addPointAnnotation", {
        id: "playback-point",
        x,
        y,
      });
    },
    [],
  );

  useEffect(() => {
    if (playback.kind === "playing") {
      const x =
        currentTab === "time"
          ? allSpeciments[playback.index].timestamp
          : allSpeciments[playback.index].distance;

      setPlaybackAnnotations(
        "elevation",
        x,

        allSpeciments[playback.index].elevation,
        `Elevation: ${allSpeciments[playback.index].elevation?.toFixed(1)}`,
      );
      if (hasHeartRate) {
        setPlaybackAnnotations(
          "heartrate",
          x,
          allSpeciments[playback.index].heartrate,
          `Heart Rate: ${allSpeciments[playback.index].heartrate?.toFixed(0)}`,
        );
      }
      if (hasBodyAngle) {
        setPlaybackAnnotations(
          "body angle",
          x,
          allSpeciments[playback.index].torso_degrees,
          `Body Angle: ${allSpeciments[playback.index].torso_degrees?.toFixed(
            0,
          )}`,
        );
      }
      if (hasPower) {
        setPlaybackAnnotations(
          "power",
          x,
          allSpeciments[playback.index].power,
          `Power: ${allSpeciments[playback.index].power?.toFixed(0)}`,
        );
      }
      if (hasCadence) {
        setPlaybackAnnotations(
          "cadence",
          x,
          allSpeciments[playback.index].cadence,
          `Cadence: ${allSpeciments[playback.index].cadence?.toFixed(0)}`,
        );
      }
      if (hasCda) {
        setPlaybackAnnotations(
          "cda",
          x,
          allSpeciments[playback.index].cda,
          `CdA: ${allSpeciments[playback.index].cda?.toFixed(3)}`,
        );
      }
      if (hasSpeed && !!allSpeciments?.[playback?.index]?.speed?.toFixed(0)) {
        setPlaybackAnnotations(
          "speed",
          x,
          allSpeciments[playback.index].speed,
          `Speed: ${allSpeciments[playback.index].speed?.toFixed(0)}`,
        );
      }

      if (session.session_statistics.length !== 0) {
        setPlaybackAnnotations(
          "positions",
          x,
          0,
          `Position: ${allSpeciments[playback.index].position}`,
        );
      }
    }
  }, [
    playback,
    allSpeciments,
    currentTab,
    setPlaybackAnnotations,
    hasHeartRate,
    hasPower,
    hasSpeed,
    hasCda,
    hasBodyAngle,
    session.session_statistics,
  ]);

  const [playbackLabels, setPlaybackLabels] = useState<PlaybackLabels>({
    position: false,
    heartRate: false,
    power: false,
    speed: false,
    cadence: false,
    bodyAngle: false,
    cda: false,
  });

  const filteredMarkers = useCallback(
    (markers: [number, number][]) =>
      withReference(
        markers.findIndex((el) => !!el[0] && !!el[1]),
        (index) =>
          index === -1
            ? []
            : markers
                .slice(0, index)
                .map((el, i, arr) => markers[index])
                .concat(markers.slice(index))
                .map((el, i, arr) =>
                  !!el[0] && !!el[1]
                    ? el
                    : arr.slice(0, i).reverse()[
                        arr
                          .slice(0, i)
                          .reverse()
                          .findIndex((el) => !!el[0] && !!el[1])
                      ],
                ),
      ),
    [],
  );

  const allFilteredMarkers = useMemo(
    () =>
      filteredMarkers(
        metrics
          .filter((metric) => metric.longitude !== 0)
          .map((metric) => [metric.latitude, metric.longitude]),
      ),
    [metrics],
  );

  return (
    <Box
      sx={{
        width: "100%",
        background: "#3D343E",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {rideType !== "indoor" && allFilteredMarkers.length !== 0 && (
        <Grid item position={"relative"}>
          <Box
            sx={{
              position: "absolute",
              left: "30px",
              top: "290px",
              zIndex: 1,
              width: "85%",
              background: "rgba(197, 187, 198, 0.2)",
              display: "flex",
              flexDirection: "column",
              border: "1px solid grey",
              borderRadius: "6px",
              margin: "auto",
            }}
          >
            <Grid direction={"row"} container>
              {session.session_statistics.length !== 0 && (
                <FormControlLabel
                  control={<Checkbox sx={{ color: "#5B7279" }} size="small" />}
                  label={
                    <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                      Position
                    </Typography>
                  }
                  sx={{ margin: 0 }}
                  value={playbackLabels.position}
                  onChange={(_, val) =>
                    setPlaybackLabels((labels) => ({
                      ...labels,
                      position: val,
                    }))
                  }
                />
              )}
              {hasBodyAngle && (
                <FormControlLabel
                  control={<Checkbox sx={{ color: "#5B7279" }} size="small" />}
                  label={
                    <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                      Body Angle
                    </Typography>
                  }
                  sx={{ margin: 0 }}
                  value={playbackLabels.bodyAngle}
                  onChange={(_, val) =>
                    setPlaybackLabels((labels) => ({
                      ...labels,
                      bodyAngle: val,
                    }))
                  }
                />
              )}
              {hasHeartRate && (
                <FormControlLabel
                  control={<Checkbox sx={{ color: "#5B7279" }} size="small" />}
                  label={
                    <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                      Heart Rate
                    </Typography>
                  }
                  sx={{ margin: 0 }}
                  value={playbackLabels.heartRate}
                  onChange={(_, val) =>
                    setPlaybackLabels((labels) => ({
                      ...labels,
                      heartRate: val,
                    }))
                  }
                />
              )}
              {hasPower && (
                <FormControlLabel
                  control={<Checkbox sx={{ color: "#5B7279" }} size="small" />}
                  label={
                    <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                      Power
                    </Typography>
                  }
                  sx={{ margin: 0 }}
                  value={playbackLabels.power}
                  onChange={(_, val) =>
                    setPlaybackLabels((labels) => ({ ...labels, power: val }))
                  }
                />
              )}
              {hasCadence && (
                <FormControlLabel
                  control={<Checkbox sx={{ color: "#5B7279" }} size="small" />}
                  label={
                    <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                      Cadence
                    </Typography>
                  }
                  sx={{ margin: 0 }}
                  value={playbackLabels.cadence}
                  onChange={(_, val) =>
                    setPlaybackLabels((labels) => ({
                      ...labels,
                      cadence: val,
                    }))
                  }
                />
              )}
              {hasCda && (
                <FormControlLabel
                  control={<Checkbox sx={{ color: "#5B7279" }} size="small" />}
                  label={
                    <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                      CdA
                    </Typography>
                  }
                  sx={{ margin: 0 }}
                  value={playbackLabels.cda}
                  onChange={(_, val) =>
                    setPlaybackLabels((labels) => ({
                      ...labels,
                      cda: val,
                    }))
                  }
                />
              )}
              <FormControlLabel
                control={<Checkbox sx={{ color: "#5B7279" }} size="small" />}
                label={
                  <Typography sx={{ color: "#575757", fontWeight: 700 }}>
                    Speed
                  </Typography>
                }
                sx={{ margin: 0 }}
                value={playbackLabels.speed}
                onChange={(_, val) =>
                  setPlaybackLabels((labels) => ({ ...labels, speed: val }))
                }
              />
            </Grid>

            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              padding={"10px"}
            >
              {playback.kind === "playing" ? (
                <Button
                  variant="contained"
                  size="small"
                  sx={{ width: "50%" }}
                  onClick={handlePlayback}
                >
                  <Typography>Stop Ride</Typography>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  sx={{ width: "50%" }}
                  onClick={handlePlayback}
                >
                  <Typography>Play Ride</Typography>
                </Button>
              )}
              <Typography
                sx={{ color: "#575757", fontWeight: 700 }}
                variant="subtitle2"
              >
                Slow
              </Typography>
              <Slider
                size="small"
                sx={{ width: "130px", color: "#64605D" }}
                onChange={(_, val) => {
                  playbackSpeedRef.current = Math.max(Number(val), 1);
                }}
              />
              <Typography
                sx={{ color: "#575757", fontWeight: 700 }}
                variant="subtitle2"
              >
                Fast
              </Typography>
            </Stack>
          </Box>
          <MapNative
            playbackLabels={playbackLabels}
            allSpeciments={allSpeciments}
            markers={allFilteredMarkers}
            zoom={
              zoomed === "none"
                ? null
                : {
                    xaxis: currentTab,
                    min: zoomed[0],
                    max: zoomed[1],
                  }
            }
            currentHover={
              hoveredIndex === "none"
                ? null
                : [
                    allFilteredMarkers[hoveredIndex][0],
                    allFilteredMarkers[hoveredIndex][1],
                  ]
            }
            currentPlayingIndex={
              playback.kind === "not-started" ? null : playback.index
            }
            selectedPositions={selectedPositions}
          />
        </Grid>
      )}
      <Grid
        item
        sx={{
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Tabs
            onChange={handleTabsChange}
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            sx={{
              "& .MuiTab-root": {
                border: ".0625rem solid #BC6D29",
                background: "#BC6D29",
                color: "#F7F3F0",
                fontSize: ".75rem",
                minHeight: "30px",
                marginTop: "10px",
                padding: "7px 10px",
                minWidth: "74px",
                borderRadius: "6px",
                marginRight: "10px",
              },
              "& .Mui-selected": {
                color: "#BC6D29",
                background: "#EFD1B6",
                fontSize: ".75rem",
                border: ".0625rem solid #BC6D29",
                minHeight: "30px",
                minWidth: "74px",
                borderRadius: "6px",
                marginRight: "10px",
              },
              alignSelf: "flex-end",
              paddingRight: "15px",
            }}
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          {currentTab === "time" ? (
            <div key="time">
              {session.session_statistics.length !== 0 && (
                <Chart
                  options={positionBrushChartOptions}
                  series={positionStancesChartSeries}
                  type="line"
                  height={113}
                  // ref={measuredRef}
                />
              )}
              {hasBodyAngle && (
                <Chart
                  options={bodyAngleTimeChartOptions}
                  series={bodyAngleTimeChartSeries}
                  type="line"
                  height={113}
                />
              )}
              {session.session_statistics.length !== 0
                ? hasSpeed && (
                    <Chart
                      options={speedTimeChartOptions}
                      series={speedTimeChartSeries}
                      type="line"
                      height={113}
                    />
                  )
                : hasSpeed && (
                    <Chart
                      options={speedTimeChartOptions}
                      series={speedTimeChartSeries}
                      type="line"
                      height={113}
                    />
                  )}
              {hasElevation && (
                <Chart
                  options={elevationTimeChartOptions}
                  series={elevationTimeChartSeries}
                  type="line"
                  height={113}
                />
              )}
              {hasHeartRate && (
                <Chart
                  options={heartRateTimeChartOptions}
                  series={heartRateTimeChartSeries}
                  type="line"
                  height={113}
                />
              )}
              {hasPower && (
                <Chart
                  options={powerTimeChartOptions}
                  series={powerTimeChartSeries}
                  type="line"
                  height={113}
                />
              )}
              {hasCadence && (
                <Chart
                  options={cadenceTimeChartOptions}
                  series={cadenceTimeChartSeries}
                  type="line"
                  height={113}
                />
              )}
              {/* {hasCda && (
                <Chart
                  options={cdaTimeChartOptions}
                  series={cdaTimeChartSeries}
                  type="line"
                  height={113}
                />
              )} */}
            </div>
          ) : (
            <div key="distance">
              {session.session_statistics.length !== 0 && (
                <Chart
                  options={positionBrushDistanceChartOptions}
                  series={positionStancesDistanceChartSeries}
                  type="line"
                  height={113}
                />
              )}
              {hasBodyAngle && (
                <Chart
                  options={bodyAngleDistanceChartOptions}
                  series={bodyAngleDistanceChartSeries}
                  type="line"
                  height={113}
                />
              )}
              {session.session_statistics.length !== 0
                ? hasSpeed && (
                    <Chart
                      options={speedDistanceChartOptions}
                      series={speedDistanceChartSeries}
                      type="line"
                      height={113}
                    />
                  )
                : hasSpeed && (
                    <Chart
                      options={speedDistanceChartOptions}
                      series={speedDistanceChartSeries}
                      type="line"
                      height={113}
                    />
                  )}
              {hasElevation && (
                <Chart
                  options={elevationDistanceChartOptions}
                  series={elevationDistanceChartSeries}
                  type="line"
                  height={113}
                />
              )}

              {hasHeartRate && (
                <Chart
                  options={heartRateDistanceChartOptions}
                  series={heartRateDistanceChartSeries}
                  type="line"
                  height={113}
                />
              )}
              {hasPower && (
                <Chart
                  options={powerDistanceChartOptions}
                  series={powerDistanceChartSeries}
                  type="line"
                  height={113}
                />
              )}

              {hasCadence && (
                <Chart
                  options={cadenceDistanceChartOptions}
                  series={cadenceDistanceChartSeries}
                  type="line"
                  height={113}
                />
              )}
              {/* {hasCda && (
                <Chart
                  options={cdaDistanceChartOptions}
                  series={cdaDistanceChartSeries}
                  type="line"
                  height={113}
                />
              )} */}
            </div>
          )}
        </Box>
      </Grid>
    </Box>
  );
}
