import { useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import SuspenseLoader from "src/components/SuspenseLoader";
import AssignedTrainingPlanView from "./AssignedTrainingPlanView";
  
  export default function AssignedTrainingPlanViewer() {
    const {assignedTrainingPlanId} = useParams()
    const supabase = useSupabase();
    const assignedTrainingPlanPromise = useCallback(
        () =>
          supabase
            .from("assigned_training_plan")
            .select("*,assigned_workout(*,assigned_workout_step(*))")
            .eq("id", assignedTrainingPlanId)
            .order('date', {ascending: true, foreignTable:"assigned_workout"})
            .single()
        ,[supabase],
      );
    
      const assignedTrainingPlanCall =
      useAsyncState<SupabaseCall<typeof assignedTrainingPlanPromise>>(); // prettier-ignore
    
      useEffect(() => {
        if (isUnloaded(assignedTrainingPlanCall)) {
          assignedTrainingPlanCall.fire(async () => assignedTrainingPlanPromise());
        }
      }, [supabase, assignedTrainingPlanCall]);

    return (
      <div>
        {
            isFulfilled(assignedTrainingPlanCall) 
            ? <AssignedTrainingPlanView assignedTrainingPlan={assignedTrainingPlanCall.result.data}/>
            : <SuspenseLoader color="#DD4F4A"/>
        }
      </div>
    );
  }