import { Box, Button, TextareaAutosize, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "src/components/Authenticated";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";

export default function CoachNotesComponent({sessionId}) {
  const supabase = useSupabase();
  const user = useUser();
  const { enqueueSnackbar } = useSnackbar();
  
  const [notes, setNotes] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = (notes: string, noteId?: number) => {
    if ("fire" in saveSessionNotesCall) {
      saveSessionNotesCall.fire(async () =>
          saveSessionNotesPromise(notes, noteId)
        );
    }
    setIsEditing(false);
  };

  const sessionNotesPromise = useCallback(
    () =>
      supabase
        .from("session_notes")
        .select("*")
        .eq("session_id", sessionId)
        .eq("coach_id", user.id)
        .maybeSingle()
    ,[supabase],
  );

  const sessionNotesCall = useAsyncState<SupabaseCall<typeof sessionNotesPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(sessionNotesCall)) {
      sessionNotesCall.fire(async () => sessionNotesPromise());
    }
    if(isFulfilled(sessionNotesCall)) {
      setNotes(sessionNotesCall.result.data?.notes || '')
    }
  }, [supabase, sessionNotesCall]);
  
  const saveSessionNotesPromise = useCallback(
    (notes, noteId) =>
      supabase
        .from("session_notes")
        .upsert({
          id: noteId,
          notes,
          coach_id: user.id,
          session_id: sessionId
        },
        { onConflict: "id" }
        ).select("id")
    ,[supabase, sessionId, user, sessionNotesCall]
  );
  const saveSessionNotesCall =
  useAsyncState<SupabaseCall<typeof saveSessionNotesPromise>>();

  useEffect(() => {
    if (isFulfilled(saveSessionNotesCall)) {
      if (isFulfilled(sessionNotesCall)) {
          sessionNotesCall.unload();
      }
      enqueueSnackbar("Notes saved successfully", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [saveSessionNotesCall]);

  return (
    <>
      <Box
        sx={{
          background: "#201B20",
          height: "230px",
          width: "98.5%",
          borderRadius: "10px",
          padding: "2em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography fontSize={"14px"} color={"#BDBCBE"} marginBottom={1}>
            Notes
          </Typography>
          {isEditing ? (
            <TextareaAutosize
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              style={{
                width: "90%",
                height: "110px",
                background: "#3D343E",
                color: "#F7F3F0",
                borderRadius: "10px",
                padding: "1em",
              }}
            />
          ) : (
            <Typography fontSize={"12px"} color={"#BDBCBE"}>
              {notes}
            </Typography>
          )}
        </Box>
        <Button
          variant="coachVariant"
          size="small"
          onClick={() => isEditing ? handleSave(notes, isFulfilled(sessionNotesCall) && sessionNotesCall.result.data?.id ): setIsEditing(true)}
          sx={{
            alignSelf: "flex-end",
            marginBottom: 1,
            marginRight: 2,
          }}
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </Box>
    </>
  );
}