import { useCallback, useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import PositionsProgress from "../customComponents/PositionsProgress";
import { useTitle } from "../customLayouts/DashboardLayout";
import PerformanceProgress from "../customComponents/PerformanceProgress";
import { useNavigate } from "react-router-dom";

type TabValue = "performance" | "positions";

export type ProgressViewProps = {
  tab?: TabValue;
};

export default function ProgressView(props: ProgressViewProps) {
  const [, setTitle] = useTitle();

  useEffect(() => {
    setTitle("Progress");
  }, []);

  const [value, setValue] = useState<TabValue>("performance");

  const navigate = useNavigate();

  const handleChange = useCallback((_, newValue: TabValue) => {
    navigate(`/dashboard/progress/${newValue}`);
  }, []);

  useEffect(() => {
    setValue(props.tab);
  }, [props?.tab]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          paddingTop: "15px",
          paddingLeft: "25px",
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="Tab list"
              sx={{
                "& .MuiTab-root:not(.Mui-selected)": {
                  opacity: 0.5,
                },
              }}
            >
              <Tab label="Performance" value="performance" />
              <Tab label="Positions" value="positions" />
            </TabList>
          </Box>
          <TabPanel value="performance" sx={{ padding: 0, marginTop: "-20px" }}>
            <PerformanceProgress />
          </TabPanel>
          <TabPanel value="positions" sx={{ padding: 0, marginTop: "-35px" }}>
            <PositionsProgress />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
