import { Box, Typography } from "@mui/material";
import LockedIcon from "../../assets/img/views/dashboard-personal/locker-icon.svg";

export type PersonalRecordsLockedCardProps = {
  label: string;
};

export default function PersonalRecordsLockedCard(
  props: PersonalRecordsLockedCardProps,
) {
  return (
    <Box
      sx={{
        background: "#2C262D",
        borderRadius: "6px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "1em",
      }}
    >
      <img src={LockedIcon} alt="locked-icon" width={"16px"} />
      <Typography
        sx={{
          color: "#DD4F4A",
          fontSize: ".6875rem",
          fontWeight: 700,
          marginTop: "7px",
          textAlign: "center",
        }}
      >
        {props.label}
      </Typography>
    </Box>
  );
}
