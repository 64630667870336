import { PropsWithChildren } from "react";
import { UserState, useUserAuthState } from "src/utils/common";

export type AuthStateProps = {
  authState: UserState | Array<UserState>;
};

export default function AuthState(props: PropsWithChildren<AuthStateProps>) {
  const userLoginState = useUserAuthState();
  return (
    <>
      {(
        Array.isArray(props.authState)
          ? props.authState.includes(userLoginState)
          : props.authState === userLoginState
      ) ? (
        props.children
      ) : (
        <></>
      )}
    </>
  );
}
