import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  Select,
  Slider,
  styled,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSession, useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, mapAsyncState } from "src/utils/Async";
import {
  defaultHeartRateZones,
  defaultPowerZones,
  expertiseOptions,
  heartRateZonesFromMaxHeartRate,
  powerZonesFromFTP,
  SupabaseCall,
  withReference,
} from "src/utils/common";
import * as yup from "yup";
import infoicon from "../../assets/img/views/profile-setup/info-icon.svg";
import slider from "../../assets/img/views/profile-setup/slider.svg";
import { PersonalSetupInfoType } from "./PersonalSetupInfo";
import { CoachPersonalSetupInfoType } from "./CoachPersonalSetupInfo";

const CustomModal = styled(Modal)(({ theme }) => ({
  width: "36.625rem",
  height: "24.625rem",
  backgroundColor: "#3D343E",
  color: "#F7F3F0",
  border: "1px solid #F7F3F0",
  borderRadius: "1.25rem",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "hidden",
}));

export type CoachSetupInfoType = {
  qualifications: string;
  expertise: Array<any>;
  phone: string;
  other_info: string;
};

export type CoachSetupInfoProps = {
  onChange: (currentCoachSetupInfo: CoachSetupInfoType) => void;
  onBack: () => void;
  initialValues?: CoachSetupInfoType;
  coachPersonalSetupInfo: CoachPersonalSetupInfoType;
};

export default function CoachSetupInfo(props: CoachSetupInfoProps) {
  const formik = useFormik<CoachSetupInfoType>({
    initialValues: props.initialValues ?? {
      qualifications: "",
      expertise: [],
      phone: "",
      other_info: "",
    },
    validationSchema: yup.object().shape({
      qualifications: yup
        .string()
        .nullable(),
      expertise: yup
        .array()
        .nullable(),
      phone: yup
        .string()
        .nullable(),
      other_info: yup
        .string()
        .nullable(),
    }),
    onSubmit: (values) => {
      if ("fire" in submitCall) {
        submitCall.fire(async () => submitPromise(values));
      }
    },
  });

  useEffect(() => {
    props.onChange(formik.values);
  }, [formik.values]);

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const supabase = useSupabase();

  const sessionData = useSession();

  const userMetaData = mapAsyncState(
    sessionData,
    (session) => session?.data?.session?.user,
  );

  const skipForLaterPromise = useCallback(
    () =>
      isFulfilled(userMetaData)
        ? supabase.auth
            .updateUser({
              data: {
                accountType: "coach",
                showSuccess: true,
              },
            })
            .then((_) =>
              supabase.from("coach").insert({
                user_id: userMetaData.result.id,
                name: props.coachPersonalSetupInfo.name,
                email: userMetaData.result.email,
                gender: props.coachPersonalSetupInfo.gender !== "male",
                country_code:
                  userMetaData?.result?.user_metadata?.country ?? "n/a",
                qualifications: "",
                expertise: [],
                phone: "",
                other_info: "",
              }),
            )
            .then((_) =>
              supabase
                .from("user_token")
                .insert({
                  user_id: userMetaData.result.id,
                })
                .throwOnError(),
            )
        : null,
    [supabase, userMetaData, props.coachPersonalSetupInfo],
  );

  const submitPromise = useCallback(
    (values: CoachSetupInfoType) =>
      isFulfilled(userMetaData)
        ? supabase.auth
            .updateUser({
              data: {
                accountType: "coach",
                showSuccess: true,
              },
            })
            .then((_) =>
              supabase
                .from("coach")
                .insert({
                  user_id: userMetaData.result.id,
                  email: userMetaData.result.email,
                  name: props.coachPersonalSetupInfo.name,
                  gender: props.coachPersonalSetupInfo.gender !== "male",
                  country_code:
                    userMetaData?.result?.user_metadata?.country ?? "n/a",
                  ...values
                })
                .throwOnError()
                .then((_) =>
                  supabase
                    .from("user_token")
                    .insert({
                      user_id: userMetaData.result.id,
                    })
                    .throwOnError(),
                ),
            )
        : null,
    [supabase, userMetaData, props.coachPersonalSetupInfo],
  );

  const skipForLaterCall =
    useAsyncState<SupabaseCall<typeof skipForLaterPromise>>();

  const submitCall = useAsyncState<SupabaseCall<typeof submitPromise>>();

  const navigate = useNavigate();

  useEffect(() => {
    if (isFulfilled(skipForLaterCall) && "unload" in sessionData) {
      skipForLaterCall.unload();
      sessionData.unload();
      navigate("/account/profile-complete");
    }
  }, [skipForLaterCall]);

  useEffect(() => {
    if (isFulfilled(submitCall) && "unload" in sessionData) {
      submitCall.unload();
      sessionData.unload();
      navigate("/account/profile-complete");
    }
  }, [submitCall]);

  const isMobileScreen = useMediaQuery("(max-width:450px)");

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box marginTop="40px">
          <Box>
            <Typography
              variant="h1"
              sx={{ mb: ".5rem", color: "#DD4F4A" }}
            >
              Profile Setup
            </Typography>
            <Typography
              variant="body1"
              fontSize={"13px"}
              lineHeight={"15px"}
              pl={".1875rem"}
            >
              Coach Information
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "1.5rem",
              mt: "16px",
            }}
          >
            <TextField
              multiline
              sx={{
                color: "#F2F2F2",
                height:"auto", 
                "& > div": {
                  height: "auto", 
                  padding:"8px 15px",
                },
                "& .Mui-focused.MuiInputLabel-shrink": {
                  color: "#DD4F4A",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#DD4F4A",
                },
              }}
              rows={4}
              error={
                formik.touched.qualifications &&
                Boolean(formik.errors.qualifications)
              }
              label="Qualifications"
              name="qualifications"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.qualifications}
            />
            <FormControl>
              <InputLabel sx={{"&.Mui-focused.MuiInputLabel-shrink":{background:"#2A252B", color:"#DD4F4A"}}} id="expertise">Expertise</InputLabel>
              <Select
                labelId="expertise"
                name="expertise"
                multiple
                placeholder="Expertise"
                input={<OutlinedInput label="Expertise" />}
                renderValue={(selected) => selected.join(', ')}
                value={formik.values.expertise ?? ""}
                onChange={formik.handleChange}
                sx={{
                  color: "#F2F2F2",
                  "& .MuiSvgIcon-root": {
                    color: "#F2F2F2",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#DD4F4A !important",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      border: ".0625rem solid #64605D",
                    },
                    sx: {
                      padding: "0", 
                      "&& .Mui-selected:hover": {backgroundColor: "rgba(221, 79, 74, 0.2)"},
                      "&& .Mui-selected": {backgroundColor: "rgba(221, 79, 74, 0.1)"}
                    },
                  },
                  MenuListProps: {
                    disablePadding: true,
                  },
                }}
              >
                {expertiseOptions.map((option) => (
                  <MenuItem sx={{padding: "0"}} key={option} value={option}>
                    <Radio 
                      sx={{
                        color: "#DD4F4A",
                        '&.Mui-checked': {
                          color: "#DD4F4A",
                        },
                      }}
                      checked={formik.values.expertise.indexOf(option) > -1} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#DD4F4A",
                  },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#DD4F4A",
                },
              }}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              label="Phone"
              name="phone"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.phone ?? ""}
              variant="outlined"
            />
            <TextField
              multiline
              sx={{
                color: "#F2F2F2",
                height:"auto", 
                "& > div": {
                  height: "auto", 
                  padding:"8px 15px",
                },
                "& .Mui-focused.MuiInputLabel-shrink": {
                  color: "#DD4F4A",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#DD4F4A",
                },
              }}
              rows={4}
              error={
                formik.touched.other_info &&
                Boolean(formik.errors.other_info)
              }
              label="Other Info"
              name="other_info"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.other_info}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: "22px",
          }}
        >
          <Button
            onClick={props.onBack}
            size="small"
            variant="outlined"
            sx={{ width: "156px", lineHeight: "16px", "&:hover": {color:"#DD4F4A", backgroundColor: "rgba(221, 79, 74, 0.1)", borderColor: "#DD4F4A"} }}
          >
            Back
          </Button>

          <Button
            variant="coachVariant"
            size="small"
            type="submit"
            sx={{ width: "156px", lineHeight: "16px", ml: "16px" }}
          >
            Submit
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mt: "20px",
              mb: "20px",
              textDecoration: "underline",
              cursor: "pointer",
              color: "#F7F3F0",
            }}
            onClick={handleOpen}
          >
            Skip for later
          </Typography>
          <CustomModal
            sx={{height:"auto"}}
            open={open}
            aria-describedby="Modal-description"
            disablePortal
          >
            <>
              <Box
                sx={{
                  padding: "3em",
                }}
              >
                <Typography
                  id="modal-modal-description"
                  variant="h4"
                  textAlign={"center"}
                >
                  Are you sure? You can always change your profile on the settings page
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleClose}
                  variant="text"
                  sx={{ width: "100px", height: "38px", padding: "22px", color: "#DD4F4A", borderColor: "#DD4F4A" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="coachVariant"
                  size="small"
                  sx={{
                    width: "100px",
                    height: "38px",
                    padding: "22px",
                    ml: "1rem",
                  }}
                  onClick={(_) =>
                    "fire" in skipForLaterCall &&
                    skipForLaterCall.fire(async () => skipForLaterPromise())
                  }
                >
                  Confirm
                </Button>
              </Box>
            </>
          </CustomModal>
        </Box>
      </form>
    </>
  );
}
