import type { ElementType, ReactNode } from "react";
import { format } from "date-fns";
import {
  Button,
  Grid,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useMediaQuery,
} from "@mui/material";

import CalendarViewMonthTwoToneIcon from "@mui/icons-material/CalendarViewMonthTwoTone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ViewWeekTwoToneIcon from "@mui/icons-material/ViewWeekTwoTone";
import type { View } from "src/models/calendar";
import TodayTwoToneIcon from "@mui/icons-material/TodayTwoTone";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import moment from "moment";

//Styles

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 70,
  },
});

export type ActionsProps = {
  children?: ReactNode;
  className?: string;
  date: Date;
  onNext?: () => void;
  onPrevious?: () => void;
  onToday?: () => void;
  handleCreateEvent?: () => void;
  changeView?: (view: View) => void;
  view: View;
};

type ViewOption = {
  label: string;
  value: View;
  icon: ElementType;
};

const viewOptions: ViewOption[] = [
  {
    label: "Week",
    value: "dayGridWeek",
    icon: ViewWeekTwoToneIcon,
  },
  {
    label: "Month",
    value: "dayGridMonth",
    icon: CalendarMonthIcon,
  },
  {
    label: "Year",
    value: "multiMonthYear",
    icon: CalendarViewMonthTwoToneIcon,
  },
];

export default function Actions(props: ActionsProps) {
  const { date, onNext, onPrevious, onToday, changeView, view } = props;
  const isMobileScreen = useMediaQuery("(max-width:573px)");

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      justifyContent={isMobileScreen ? "center" : "space-between"}
    >
      <Grid item>
        <CustomWidthTooltip
          arrow
          placement="top"
          title="Previous"
          sx={{ textAlign: "center" }}
        >
          <IconButton
            color="primary"
            onClick={onPrevious}
            sx={{ color: "#DD4F4A" }}
          >
            <ArrowBackTwoToneIcon />
          </IconButton>
        </CustomWidthTooltip>
        <CustomWidthTooltip
          arrow
          placement="top"
          title="Next"
          sx={{ textAlign: "center" }}
        >
          <IconButton
            color="primary"
            onClick={onNext}
            sx={{ color: "#DD4F4A" }}
          >
            <ArrowForwardTwoToneIcon />
          </IconButton>
        </CustomWidthTooltip>
        <CustomWidthTooltip
          arrow
          placement="top"
          title="Today"
          sx={{ textAlign: "center" }}
        >
          <Button
            sx={{
              mx: 1,
            }}
            size="small"
            onClick={onToday}
            variant="coachVariant"
          >
            TODAY
          </Button>
        </CustomWidthTooltip>
      </Grid>
      <Grid
        item
        sx={{
          display: { xs: "none", sm: "inline-block" },
        }}
      >
        {view === "dayGridWeek" ? (
          <Typography variant="h3" color="text.primary">
            {`${moment(date).format("MMM DD")} - ${moment(date)
              .endOf("week")
              .format("MMM DD, YYYY")}`}
          </Typography>
        ) : view === "multiMonthYear" ? (
          <Typography variant="h3" color="text.primary">
            {format(date, "yyyy")}
          </Typography>
        ) : (
          <Typography variant="h3" color="text.primary">
            {format(date, "MMMM yyyy")}
          </Typography>
        )}
      </Grid>
      {!isMobileScreen && (
        <Grid
          item
          sx={{
            display: { sm: "inline-block" },
          }}
        >
          {viewOptions.map((viewOption) => {
            const Icon = viewOption.icon;
            const isViewOptionSelected = viewOption.value === view;

            return (
              <CustomWidthTooltip
                key={viewOption.value}
                arrow
                placement="top"
                title={viewOption.label}
                sx={{ textAlign: "center" }}
              >
                <IconButton
                  sx={{
                    color: isViewOptionSelected ? "#DD4F4A" : "#64605D",
                  }}
                  onClick={() => changeView(viewOption.value)}
                >
                  <Icon />
                </IconButton>
              </CustomWidthTooltip>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
}
