import {
    ElementsType,
    FormElementInstance,
    FormElements,
  } from "./FormElements";
  import intervalsStep from "../../../../assets/img/views/dashboard-library/intervalStep.svg";
  import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
    IconButtonProps,
    Modal,
    Stack,
    Tooltip,
    Typography,
    styled,
  } from "@mui/material";
  import { useCallback, useEffect, useMemo, useState } from "react";
  import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
  import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
  import {
    DragEndEvent,
    DraggableAttributes,
    useDndMonitor,
    useDraggable,
    useDroppable,
  } from "@dnd-kit/core";
  import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
  import { nanoid } from "@reduxjs/toolkit";
  import useDesigner from "./useDesigner";
  import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
  import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
  
  export const createIntervalElement = (type: ElementsType) => {
    const intervalCounter = 2;
    const icon = <img src={intervalsStep} alt="intervals" />;
    return {
      type,
      construct: (id: string) => ({
        id,
        type,
        intervalCounter,
      }),
      designerBtnElement: {
        icon,
        title: type,
      },
      designerComponent: IntervalsContainer,
    };
  };
  interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  
  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  
  export default function IntervalsContainer({
    elementInstance,
    attributes,
    listeners,
  }: {
    elementInstance: FormElementInstance;
    attributes: DraggableAttributes;
    listeners: SyntheticListenerMap;
  }) {
    const {
      setSelectedElement,
      intervalElements,
      addIntervalElement,
      removeElement,
      selectedElement,
      elements,
      intervalCounter,
    } = useDesigner();
  
    const [expanded, setExpanded] = useState(false);
    const [open, setOpen] = useState(false);
  
    const handleModal = useCallback(() => {
      setOpen((prev) => !prev);
    }, []);
    const handleExpandClick = useCallback(() => {
      setExpanded((prev) => !prev);
    }, []);
  
    const droppable = useDroppable({
      id: elementInstance.id + "interval-drop-area",
      data: {
        isDesignerDropArea: true,
      },
    });
  
    const currentIntervalElements = useMemo(() => {
      return intervalElements.find((element) => element.id === elementInstance.id)
        ?.elements;
    }, [elementInstance.id, intervalElements]);
  
    useDndMonitor({
      onDragEnd: (event: DragEndEvent) => {
        const { active, over } = event;
        if (!active || !over) return;
  
        //Check if the new id is inside an interval
        const lastIndex = over.id.toString().lastIndexOf("-");
        const newId =
          lastIndex !== -1 ? over.id.toString().substring(0, lastIndex) : over.id;
        const isNewIdPresent = intervalElements.some((interval) => {
          return interval.elements.some((element) => {
            return element.id === newId;
          });
        });
        const intervalDropArea = over.id
          .toString()
          .includes("interval-drop-area");
        const checkIfDropOrInterval = isNewIdPresent || intervalDropArea;
  
        if (
          checkIfDropOrInterval &&
          selectedElement.id === elementInstance.id &&
          active.data.current["type"] !== "Intervals"
        ) {
          const isDesignerBtnElement = active.data?.current?.isDesignerBtnElement;
          const isDroppingOverDesignerDropArea =
            over.data?.current?.isDesignerDropArea;
  
          // First Scenario
          const droppingSidebarBtnOverDesignerDropArea =
            isDesignerBtnElement && isDroppingOverDesignerDropArea;
          if (droppingSidebarBtnOverDesignerDropArea) {
            const type = active.data?.current?.type;
            const id = nanoid();
            const newElement = FormElements[type as ElementsType].construct(id);
            addIntervalElement(
              elementInstance.id,
              newElement,
              currentIntervalElements.length,
            );
            return;
          }
  
          // Second Scenario
          const isDroppingOverDesignerElementTopHalf =
            over.data?.current?.isTopHalfDesignerElement;
          const isDroppingOverDesignerElementBottomHalf =
            over.data?.current?.isBottomHalfDesignerElement;
          const isDroppingOverDesignerElement =
            isDroppingOverDesignerElementTopHalf ||
            isDroppingOverDesignerElementBottomHalf;
  
          const droppingSidebarBtnOverDesignerElement =
            isDesignerBtnElement && isDroppingOverDesignerElement;
          if (droppingSidebarBtnOverDesignerElement) {
            const type = active.data?.current?.type;
            const id = nanoid();
            const newElement = FormElements[type as ElementsType].construct(id);
            const overId = over.data?.current?.elementId;
            const overElementIndex = currentIntervalElements?.findIndex(
              (el) => el.id === overId,
            );
  
            let indexForNewElement = overElementIndex;
            if (isDroppingOverDesignerElementBottomHalf) {
              indexForNewElement = overElementIndex + 1;
            }
            addIntervalElement(
              elementInstance.id,
              newElement,
              indexForNewElement,
            );
            return;
          }
  
          // Third Scenario
          const isDraggingDesignerElement =
            active.data?.current?.isDesignerElement;
          const draggingDesignerElementOverAnotherDesignerElement =
            isDroppingOverDesignerElement && isDraggingDesignerElement;
  
          if (draggingDesignerElementOverAnotherDesignerElement) {
            const activeId = active.data?.current?.elementId;
            const overId = over.data?.current?.elementId;
  
            const activeElementIndex = currentIntervalElements.findIndex(
              (el) => el.id === activeId,
            );
  
            const overElementIndex = currentIntervalElements.findIndex(
              (el) => el.id === overId,
            );
            const intervalIndex = elements.findIndex((el) => el.id === activeId);
  
            const flag =
              activeElementIndex === -1 || overElementIndex === -1 ? 1 : 0;
            removeElement(activeId);
  
            const activeElement =
              flag === 1
                ? {
                    ...elements[intervalIndex],
                  }
                : {
                    ...currentIntervalElements[activeElementIndex],
                  };
  
            let indexForNewElement = overElementIndex; // i assume i'm on top-half
            if (isDroppingOverDesignerElementBottomHalf) {
              indexForNewElement = overElementIndex + 1;
            }
  
            addIntervalElement(
              elementInstance.id,
              activeElement,
              indexForNewElement,
            );
            return;
          }
  
          // Fourth Scenario dropping from elements to empty interval
          const intervalIndex = elements.findIndex(
            (el) => el.id === active.data?.current?.elementId,
          );
          if (intervalIndex !== -1) {
            const activeElement = {
              ...elements[intervalIndex],
            };
            removeElement(active.data?.current?.elementId);
            addIntervalElement(
              elementInstance.id,
              activeElement,
              currentIntervalElements.length,
            );
            return;
          }
        }
      },
    });
  
    return (
      <>
        <Box
          onMouseOver={(e) => {
            e.stopPropagation();
            setSelectedElement(elementInstance);
          }}
          sx={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
        >
          <Accordion expanded={expanded}>
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                paddingLeft: "0.5%",
                paddingRight: "0px",
                margin: "0px",
                background: expanded
                  ? `linear-gradient(90deg, #726174 0.5%, #3D343E 0.5%)`
                  : `linear-gradient(90deg, #726174 0.5%, #2C262D 0.5%)`,
                borderRadius: "6px",
                borderBottomLeftRadius: expanded ? "0px" : "6px",
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                  cursor: "default",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  {/* Type */}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="Drag & Drop" placement="top" arrow>
                      <IconButton
                        // ref={draggable.setActivatorNodeRef}
                        {...listeners}
                        {...attributes}
                      >
                        <DragIndicatorIcon />
                      </IconButton>
                    </Tooltip>
                    <Typography variant="h4">
                      Intervals x{elementInstance.intervalCounter}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      onClick={() => {
                        intervalCounter(elementInstance.id, "minus");
                      }}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        intervalCounter(elementInstance.id, "plus");
                      }}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
  
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleModal();
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                borderEndStartRadius: "6px",
                borderEndEndRadius: "6px",
                padding: "0px",
                borderRight: "1px solid #3D343E",
                borderBottom: "1px solid #3D343E",
                background: `linear-gradient(90deg,  #726174 0.5%, #201B20 0.5%)`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 2,
                  // height: "200px",
                  maxHeight: { xl: "850px", md: "520px" },
                  // height: { xl: "820px", md: "520px" },
                }}
              >
                {expanded && (
                  <Box
                    ref={droppable.setNodeRef}
                    sx={{
                      flex: 1,
                      border: droppable.isOver
                        ? "2px solid white"
                        : "0px solid black",
                      marginX: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      maxHeight: { xl: "850px", md: "520px" },
                      overflowY: "auto",
                      marginTop: ".3125rem",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      {/* When i have 0 elements */}
                      {!droppable.isOver && currentIntervalElements.length === 0 && (
                        <Box
                          sx={{
                            border: ".0625rem dashed #64605D",
                            background: "#2C262D",
                            borderRadius: ".375rem",
                            height: "200px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Typography>Drag and drop step here</Typography>
                        </Box>
                      )}
                      {/* While adding the first one */}
                      {droppable.isOver && currentIntervalElements.length === 0 && (
                        <Box
                          sx={{
                            background: "white",
                            width: "100%",
                            opacity: "0.25",
                            height: "100px",
                          }}
                        />
                      )}
                      {/* My elements */}
                      {currentIntervalElements.length > 0 && (
                        <Box>
                          {currentIntervalElements.map((element) => (
                            <DesignerElementWrapper
                              key={element.id}
                              element={element}
                            />
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Modal
          open={open}
          onClose={handleModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "212px",
              width: "500px",
              border: "2px solid #BC6D29",
              boxShadow: "24px",
              backgroundColor: "#2A252B",
              padding: "1.5em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography color={"#BC6D29"} variant="h1">
              Remove Step
            </Typography>
            <Typography
              color={"#F7F3F0"}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              Are you sure you want to remove this Step?
            </Typography>
            <Stack flexDirection={"row"} marginTop={"1rem"} gap={2}>
              <Button variant="outlined" onClick={handleModal} size="small">
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => removeElement(elementInstance.id)}
              >
                Delete
              </Button>
            </Stack>
          </Box>
        </Modal>
      </>
    );
  }
  
  function DesignerElementWrapper({ element }: { element: FormElementInstance }) {
    const DesignerElement = FormElements[element.type].designerComponent;
    const topHalf = useDroppable({
      id: element.id + "-top",
      data: {
        type: element.type,
        elementId: element.id,
        isTopHalfDesignerElement: true,
      },
    });
  
    const bottomHalf = useDroppable({
      id: element.id + "-bottom",
      data: {
        type: element.type,
        elementId: element.id,
        isBottomHalfDesignerElement: true,
      },
    });
  
    const draggable = useDraggable({
      id: element.id + "-drag-handler",
      data: {
        type: element.type,
        elementId: element.id,
        isDesignerElement: true,
      },
    });
  
    if (draggable.isDragging) return null; // temporary remove the element from designer
  
    return (
      <Box
        ref={draggable.setNodeRef}
        {...draggable.listeners}
        {...draggable.attributes}
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100%",
          marginX: "10px",
          touchAction:"none",
          WebkitTouchCallout:"none",
          WebkitUserSelect:"none",
          msUserSelect:"none",
          userSelect:"none"
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "50%",
            borderRadius: "4px",
            opacity: 0.2,
          }}
          ref={topHalf.setNodeRef}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "50%",
            borderRadius: "4px",
            opacity: 0.2,
          }}
          ref={bottomHalf.setNodeRef}
        />
  
        {topHalf.isOver && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              width: "100%",
              borderRadius: "6px",
              height: "7px",
              backgroundColor: "#DD4F4A",
              borderBottomLeftRadius: "none",
              borderBottomRightRadius: "none",
              display: topHalf.isOver ? "block" : "none",
              zIndex: 100,
            }}
          />
        )}
        <Box sx={{ flex: 1, width: "100%" }}>
          <DesignerElement
            elementInstance={element}
            listeners={draggable.listeners}
            attributes={draggable.attributes}
          />
        </Box>
        {bottomHalf.isOver && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              borderRadius: "6px",
              height: "7px",
              backgroundColor: "#DD4F4A",
              borderBottomLeftRadius: "none",
              borderBottomRightRadius: "none",
              display: bottomHalf.isOver ? "block" : "none",
            }}
          />
        )}
      </Box>
    );
  }