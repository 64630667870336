import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useCallback, useEffect, useState } from "react";
import "../../../assets/scss/MapComponent.scss";
import AntPath from "../AntPath";
import { withReference } from "src/utils/common";

export type MapProps = {
  markers?: Array<[number, number]>;
};

export default function Map(props: MapProps) {
  const [mapFitted, setMapFitted] = useState(false);

  // const filteredMarkers = useCallback(
  //   (markers: [number, number][]) =>
  //     withReference(
  //       markers.findIndex((el) => !!el[0] && !!el[1]),

  //       (index) =>
  //         index === -1
  //           ? []
  //           : markers
  //               .slice(0, index)
  //               .map((el, i, arr) => markers[index])
  //               .concat(markers.slice(index))
  //               .map((el, i, arr) =>
  //                 !!el[0] && !!el[1]
  //                   ? el
  //                   : arr.slice(0, i).reverse()[
  //                       arr
  //                         .slice(0, i)
  //                         .reverse()
  //                         .findIndex((el) => !!el[0] && !!el[1])
  //                     ],
  //               ),
  //     ),
  //   [],
  // );

  return (
    <MapContainer
      zoom={13}
      scrollWheelZoom={false}
      zoomControl={false}
      attributionControl={false}
      dragging={false}
      style={{
        height: "214px",
        zIndex: 0,
        width: "95%",
        borderRadius: "6px 0px 0px 6px",
      }}
    >
      <Inner
        markers={props.markers}
        fitted={mapFitted}
        setFitted={() => setMapFitted(true)}
      />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <AntPath
        positions={props.markers}
        options={{
          dashArray: [1, 1],
          weight: 3,
          color: "white",
          pulseColor: "#141C22",
          paused: true,
          reverse: false,
          opacity: 1,
        }}
      />
    </MapContainer>
  );
}

type InnerProps = {
  markers: Array<[number, number]>;
  fitted: boolean;
  setFitted: () => void;
};

function Inner(props: InnerProps) {
  const map = useMap();

  useEffect(() => {
    if (!props.fitted && !!props.markers && props.markers.length > 0) {
      props.setFitted();
      map.fitBounds(L.latLngBounds(props.markers));
    }
  }, [props.markers, props.fitted, props.setFitted]);

  return <></>;
}
