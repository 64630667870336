import { Box, IconButton, Tooltip, styled } from "@mui/material";

import Dashboard from "../../assets/img/layout/Dashboard.svg";
import DashboardIn from "../../assets/img/layout/DashboardIn.svg";
import Progress from "../../assets/img/layout/progress.svg";
import ProgressAct from "../../assets/img/layout/progressact.svg";
import AdminNotification from "../../assets/img/layout/AdminNotification.svg";
import AdminNotificationIn from "../../assets/img/layout/AdminNotificationIn.svg";

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: 75px;
        min-width: 75px;
        background: #434244;
        position: relative;
        z-index: 7;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.xs}px) {
          height: calc(100% - ${theme.header.height});
          margin-top: ${theme.header.height};
        }
`,
);

export default function SidebarAdmin() {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeIcon, setActiveIcon] = useState("Dashboard");

  useEffect(() => {
    switch (location.pathname) {
      case "/admin/analytics":
        setActiveIcon("Analytics");
        break;
      case "/admin/notifications":
        setActiveIcon("Notifications");
        break;
      default:
        setActiveIcon("Dashboard");
        break;
    }
  }, [location]);

  const handleIconClick = (icon: string) => {
    setActiveIcon(icon);

    switch (icon) {
      case "Analytics":
        navigate("/admin/analytics");
        break;
      case "Notifications":
        navigate("/admin/notifications");
        break;
      default:
        navigate("/admin");
        break;
    }
  };

  const icons = [
    { inactiveIcon: DashboardIn, activeIcon: Dashboard, alt: "Dashboard" },
    { inactiveIcon: Progress, activeIcon: ProgressAct, alt: "Analytics" },
    {
      inactiveIcon: AdminNotificationIn,
      activeIcon: AdminNotification,
      alt: "Notifications",
    },
  ];

  return (
    <>
      <SidebarWrapper
        sx={{
          display: "inline-block",
          position: "fixed",
          left: 0,
          top: 0,
        }}
      >
        <Box
          sx={{
            paddingTop: "25px",
            background: "#434244",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: "25px",
          }}
        >
          {icons.map((icon) => (
            <Tooltip key={icon.alt} title={icon.alt}>
              <IconButton
                sx={{
                  "&::after": {
                    content: '""',
                    display: activeIcon === icon.alt ? "block" : "none",
                    position: "absolute",
                    bottom: "0px",
                    width: "50%",
                    height: "18px",
                    backgroundSize: "100% 20px",
                  },
                }}
                onClick={() => handleIconClick(icon.alt)}
              >
                <img
                  src={
                    activeIcon === icon.alt
                      ? icon.activeIcon
                      : icon.inactiveIcon
                  }
                  alt={icon.alt}
                  width={20}
                />
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      </SidebarWrapper>
    </>
  );
}
