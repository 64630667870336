import { useCallback, useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, useMediaQuery } from "@mui/material";
import MyAccountView from "./MyAccountView";
import ConnectedAppsView from "src/content/customViews/Setttings/ConnectedAppsView";
import AthleteView from "./AthleteView";
import SubscriptionView from "./SubscriptionView";
import BikeInfoView from "./BikeInfoView";

import { useTitle } from "src/content/customLayouts/DashboardLayout";
import { useNavigate } from "react-router-dom";
import { WithCache } from "src/contexts/CacheContext";
import { useUser } from "src/components/Authenticated";

type TabValue =
  | "my_account"
  | "athlete"
  | "bikes"
  | "subscription"
  | "connected_apps";

export type SettingsViewProps = {
  tab?: TabValue;
};

export default function SettingsView(props: SettingsViewProps) {
  const isMobileScreen = useMediaQuery("(max-width:480px)");
  const [, setTitle] = useTitle();

  useEffect(() => {
    setTitle("Settings");
  }, []);

  const [value, setValue] = useState<TabValue>("my_account");

  const navigate = useNavigate();

  const handleChange = useCallback((_, newValue: TabValue) => {
    navigate(`/dashboard/settings/${newValue}`);
  }, []);

  useEffect(() => {
    setValue(props.tab);
  }, [props?.tab]);
  const user = useUser();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          paddingTop: "1.5rem",
          paddingLeft: "1.5625rem",
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="Tab list"
              variant="scrollable"
              sx={{
                "& .MuiTab-root:not(.Mui-selected)": {
                  opacity: 0.5,
                },
                minHeight: "1.5rem",
              }}
            >
              <Tab
                label="My account"
                value="my_account"
                sx={{ minHeight: 0, padding: "10px" }}
              />
              <Tab
                label="Athlete"
                value="athlete"
                sx={{ minHeight: 0, padding: "10px" }}
              />
              <Tab
                label="Bikes"
                value="bikes"
                sx={{ minHeight: 0, padding: "10px" }}
              />
              <Tab
                label="Subscription"
                value="subscription"
                sx={{ minHeight: 0, padding: "10px" }}
              />
              <Tab
                label="Connected Apps"
                value="connected_apps"
                sx={{ minHeight: 0, padding: "10px" }}
              />
            </TabList>
          </Box>
          <TabPanel
            value="my_account"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <WithCache
              tables={["athlete"]}
              component={(athlete) => (
                <MyAccountView athlete={athlete.athlete[0]} />
              )}
            />
          </TabPanel>
          <TabPanel
            value="athlete"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <WithCache
              tables={[
                "athlete",
                "training_goals",
                "athlete_training_goals",
                "athlete_experience_level",
              ]}
              component={(full) => (
                <AthleteView
                  athlete={full.athlete[0]}
                  athleteTrainingGoals={full.athlete_training_goals}
                  experienceLevels={full.athlete_experience_level}
                  trainingGoals={full.training_goals}
                />
              )}
            />
          </TabPanel>
          <TabPanel value="bikes" sx={{ padding: 0, marginTop: "1rem" }}>
            <WithCache
              tables={["athlete_bikes", "bike_types"]}
              component={(cached) => (
                <BikeInfoView
                  athleteBikes={cached.athlete_bikes.filter(
                    (bike) => bike.athlete_id === user.id && !bike.soft_deleted,
                  )}
                  bikeTypes={cached.bike_types}
                />
              )}
            />
          </TabPanel>
          <TabPanel value="subscription" sx={{ padding: 0, marginTop: "1rem" }}>
            <SubscriptionView account_exp={null} account_type="PREMIUM" />
          </TabPanel>
          <TabPanel
            value="connected_apps"
            sx={{ padding: 0, marginTop: "1rem" }}
          >
            <WithCache
              tables={["athlete_bikes"]}
              component={(cached) => (
                <ConnectedAppsView
                  athleteBikes={cached.athlete_bikes.filter(
                    (bike) => bike.athlete_id === user.id && !bike.soft_deleted,
                  )}
                />
              )}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
