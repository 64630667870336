import { Box, IconButton, Tooltip, styled } from "@mui/material";
import Calendar from "../../../assets/img/layout/Calendar.svg";
import Dashboard from "../../../assets/img/layout/Dashboard.svg";
import Divider from "../../../assets/img/layout/Divider.svg";
import Progress from "../../../assets/img/layout/progress.svg";
import PersonalRec from "../../../assets/img/layout/PersonalRec.svg";
import Sessions from "../../../assets/img/layout/Sessions.svg";
import CalendarAct from "../../../assets/img/layout/Calendaract.svg";
import DashboardIn from "../../../assets/img/layout/DashboardIn.svg";
import ProgressAct from "../../../assets/img/layout/progressact.svg";
import PersonalRecAct from "../../../assets/img/layout/personalrecact.svg";
import SessionsAct from "../../../assets/img/layout/sessionsact.svg";
import TrendsAct from "../../../assets/img/layout/Trends.svg";
import TrendsIn from "../../../assets/img/layout/TrendsIn.svg";
import Library from "../../../assets/img/views/dashboard-library/Library.svg";
import LibraryIn from "../../../assets/img/views/dashboard-library/LibraryIn.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: 75px;
        min-width: 75px;
        background: #2C262D;
        position: relative;
        z-index: 7;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.xs}px) {
          height: calc(100% - ${theme.header.height});
          margin-top: ${theme.header.height};
        }
`,
);

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeIcon, setActiveIcon] = useState("Dashboard");
  useEffect(() => {
    return location.pathname.startsWith("/dashboard/session")
      ? setActiveIcon("Sessions")
      : location.pathname.startsWith("/dashboard/calendar")
      ? setActiveIcon("Calendar")
      : location.pathname.startsWith("/dashboard/personal-records")
      ? setActiveIcon("Personal Records")
      : location.pathname.startsWith("/dashboard/progress")
      ? setActiveIcon("Progress")
      : location.pathname.startsWith("/dashboard/library")
      ? setActiveIcon("Library")
      : location.pathname.startsWith("/dashboard/trends")
      ? setActiveIcon("Trends")
      : setActiveIcon("Dashboard");
  }, [location]);

  const handleIconClick = (icon: string) => {
    setActiveIcon(icon);
    switch (icon) {
      case "Sessions":
        navigate("/dashboard/sessions");
        break;
      case "Calendar":
        navigate("/dashboard/calendar");
        break;
      case "Personal Records":
        navigate("/dashboard/personal-records");
        break;
      case "Trends":
        navigate("/dashboard/trends");
        break;
      case "Progress":
        navigate("/dashboard/progress");
        break;
      case "Library":
        navigate("/dashboard/library");
        break;
      default:
        navigate("/dashboard");
        break;
    }
  };

  const icons = [
    { inactiveIcon: DashboardIn, activeIcon: Dashboard, alt: "Dashboard" },
    { inactiveIcon: Sessions, activeIcon: SessionsAct, alt: "Sessions" },

    { inactiveIcon: Progress, activeIcon: ProgressAct, alt: "Progress" },
    { inactiveIcon: TrendsIn, activeIcon: TrendsAct, alt: "Trends" },

    {
      inactiveIcon: PersonalRec,
      activeIcon: PersonalRecAct,
      alt: "Personal Records",
    },
    { inactiveIcon: Calendar, activeIcon: CalendarAct, alt: "Calendar" },
    { inactiveIcon: LibraryIn, activeIcon: Library, alt: "Library" }
  ];
  return (
    <>
      <SidebarWrapper
        sx={{
          display: "inline-block",
          position: "fixed",
          left: 0,
          top: 0,
        }}
      >
        <Box
          sx={{
            paddingTop: "25px",
            background: "#2C262D",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: "25px",
          }}
        >
          {icons.map((icon) => (
            <Tooltip key={icon.alt} title={icon.alt}>
              <IconButton
                sx={{
                  "&::after": {
                    content: '""',
                    display: activeIcon === icon.alt ? "block" : "none",
                    position: "absolute",
                    bottom: "0px",
                    width: "50%",
                    height: "18px",
                    backgroundSize: "100% 20px",
                    backgroundImage: `url(${Divider})`,
                  },
                }}
                onClick={() => handleIconClick(icon.alt)}
              >
                <img
                  src={
                    activeIcon === icon.alt
                      ? icon.activeIcon
                      : icon.inactiveIcon
                  }
                  alt={icon.alt}
                  width={20}
                />
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      </SidebarWrapper>
    </>
  );
}
