import { ElementsType, FormElementInstance } from "./FormElements";
import warmupStep from "../../../../assets/img/views/dashboard-library/warmupStep.svg";
import cooldownStep from "../../../../assets/img/views/dashboard-library/cooldownStep.svg";
import intervalsStep from "../../../../assets/img/views/dashboard-library/intervalStep.svg";
import recoverStep from "../../../../assets/img/views/dashboard-library/recoverStep.svg";
import activeStep from "../../../../assets/img/views/dashboard-library/activeStep.svg";
import otherStep from "../../../../assets/img/layout/coach/Library/otherStep.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  IconButtonProps,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import useDesigner from "./useDesigner";
import { useFormik } from "formik";
import * as yup from "yup";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import StepNotesComponent from "./StepNotesComponent";
import { getSecondsFromHHMMSS, toHHMMSS } from "src/utils/common";
import { Tables } from "src/utils/DatabaseDefinitions";
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";

const timeFormatter = (valStr) => {
  const val = Number(valStr);
  const hours = Math.floor(Math.floor(val / 60) / 60);
  const minutes = Math.trunc((val % 3600) / 60);
  const seconds = Math.trunc(val % 60);
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

const generateExtraAttributes = (
  type: ElementsType,
): Omit<
  Tables<"workout_step">,
  "id" | "workout_id" | "created_at" | "order"
> => {
  // You can customize the logic for each type here
  if (type === "Warmup") {
    return {
      type: "Warmup",
      duration_type: "Time",
      duration: 1200,
      primary_target_type: "Power",
      primary_target_unit: "ftp",
      primary_target: [40, 70],
      secondary_target_type: "None",
      secondary_target_unit: "",
      secondary_target: [0, 0],
      position: "Free",
      notes: "",
    };
  } else if (type === "Cooldown") {
    return {
      type: "Cooldown",
      duration_type: "Time",
      duration: 600,
      primary_target_type: "Power",
      primary_target_unit: "ftp",
      primary_target: [30, 60],
      secondary_target_type: "None",
      secondary_target_unit: "",
      secondary_target: [0, 0],
      position: "Free",
      notes: "",
    };
  } else if (type === "Recovery") {
    return {
      type: "Recovery",
      duration_type: "Time",
      duration: 300,
      primary_target_type: "None",
      primary_target_unit: "",
      primary_target: [0, 0],
      secondary_target_type: "None",
      secondary_target_unit: "",
      secondary_target: [0, 0],
      position: "Free",
      notes: "",
    };
  } else if (type === "Active") {
    return {
      type: "Active",
      duration_type: "Time",
      duration: 1200,
      primary_target_type: "None",
      primary_target_unit: "",
      primary_target: [0, 0],
      secondary_target_type: "None",
      secondary_target_unit: "",
      secondary_target: [0, 0],
      position: "Free",
      notes: "",
    };
  } else {
    return {
      type: "Other",
      duration_type: "Time",
      duration: 0,
      primary_target_type: "None",
      primary_target_unit: "",
      primary_target: [0, 0],
      secondary_target_type: "None",
      secondary_target_unit: "",
      secondary_target: [0, 0],
      position: "Free",
      notes: "",
    };
  }
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const createFormElement = (type: ElementsType) => {
  const extraAttributes = generateExtraAttributes(type);
  const icon =
    type === "Warmup" ? (
      <img src={warmupStep} alt="warm-up" />
    ) : type === "Intervals" ? (
      <img src={intervalsStep} alt="intervals" />
    ) : type === "Cooldown" ? (
      <img src={cooldownStep} alt="cooldown" />
    ) : type === "Recovery" ? (
      <img src={recoverStep} alt="recovery" />
    ) : type === "Active" ? (
      <img src={activeStep} alt="active" />
    ) : (
      <img src={otherStep} alt="other" />
    );

  return {
    type,
    construct: (id: string) => ({
      id,
      type,
      extraAttributes,
    }),
    designerBtnElement: {
      icon,
      title: type,
    },
    designerComponent: DesignerComponent,
  };
};

export type FormikProps = Omit<
  Tables<"workout_step">,
  "id" | "workout_id" | "created_at" | "order"
>;

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function DesignerComponent({
  elementInstance,
  attributes,
  listeners,
}: {
  elementInstance: FormElementInstance;
  attributes: DraggableAttributes;
  listeners: SyntheticListenerMap;
}) {
  const { removeElement, setSelectedElement, bikeType, updateElement } =
    useDesigner();
  const units = useUnits();
  // Formik
  const validationSchema = useMemo(() => yup.object().shape({}), []);
  const formik = useFormik<FormikProps>({
    initialValues: {
      type: elementInstance.extraAttributes.type,
      duration_type: elementInstance.extraAttributes.duration_type,
      duration: units === 'metric' ? elementInstance.extraAttributes.duration : Number(convert(elementInstance.extraAttributes.duration).from('km').to('mi').toFixed(2)),
      primary_target_type: elementInstance.extraAttributes.primary_target_type,
      primary_target_unit: elementInstance.extraAttributes.primary_target_unit,
      primary_target: elementInstance.extraAttributes.primary_target,
      secondary_target_type:
        elementInstance.extraAttributes.secondary_target_type,
      secondary_target_unit:
        elementInstance.extraAttributes.secondary_target_unit,
      secondary_target: elementInstance.extraAttributes.secondary_target,
      position: elementInstance.extraAttributes.position,
      notes: elementInstance.extraAttributes.notes,
    },
    validationSchema,

    onSubmit: (values) => {},
  });

  const isTabletScreen = useMediaQuery("(max-width:1160px)");
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const handleModal = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);
  const handleExpandClick = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (bikeType === "TT" && formik.values.position === "Aggressive")
      formik.setFieldValue("position", "Free");
  }, [bikeType]);

  const [time, setTime] = useState(
    toHHMMSS(elementInstance.extraAttributes?.duration),
  );

  const onChange = (event) => {
    setTime(event.target.value);
  };

  const onBlur = (event) => {
    const value = event.target.value;
    const seconds = Math.max(0, getSecondsFromHHMMSS(value));

    const time = toHHMMSS(seconds);
    setTime(time);
  };

  useEffect(() => {
    if (formik.values.duration_type === "Time") {
      formik.setFieldValue("duration", getSecondsFromHHMMSS(time));
    }
  }, [time]);

  useEffect(() => {
    if(formik.initialValues.primary_target_type !== formik.values.primary_target_type) {
      formik.setFieldValue("primary_target", [0,0])
    }
  }, [formik.values.primary_target_type])

  useEffect(() => {
    if(formik.initialValues.secondary_target_type !== formik.values.secondary_target_type) {
      formik.setFieldValue("secondary_target", [0,0])
    }
  }, [formik.values.secondary_target_type])

  useEffect(() => {
    updateElement(elementInstance.id, {
      id: elementInstance.id,
      type: formik.values.type as ElementsType,
      extraAttributes: {
        type: formik.values.type,
        duration_type: formik.values.duration_type,
        duration: units === 'metric' || formik.values.duration_type === 'Time' ? Number(formik.values.duration) : Number(convert(formik.values.duration).from('mi').to('km').toFixed(2)),
        primary_target_type: formik.values.primary_target_type,
        primary_target_unit: formik.values.primary_target_unit,
        primary_target: formik.values.primary_target,
        secondary_target_type: formik.values.secondary_target_type,
        secondary_target_unit: formik.values.secondary_target_unit,
        secondary_target: formik.values.secondary_target,
        position: formik.values.position,
        notes: formik.values.notes,
      },
    });
  }, [formik.values]);
  return (
    <>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          setSelectedElement(elementInstance);
        }}
        sx={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
      >
        <Accordion expanded={expanded}>
          <AccordionSummary
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              paddingLeft: "0.5%",
              paddingRight: "0px",
              margin: expanded ? "0px" : "0px",
              background: expanded
                ? `linear-gradient(90deg, ${
                    formik.values.type === "Warmup"
                      ? "#E28E54"
                      : formik.values.type === "Recovery"
                      ? "#00B8D9"
                      : formik.values.type === "Active"
                      ? "#ffcf40"
                      : formik.values.type === "Cooldown"
                      ? "#EFC09F"
                      : "#726174"
                  } 0.5%, #3D343E 0.5%)`
                : `linear-gradient(90deg, ${
                    formik.values.type === "Warmup"
                      ? "#E28E54"
                      : formik.values.type === "Recovery"
                      ? "#00B8D9"
                      : formik.values.type === "Active"
                      ? "#ffcf40"
                      : formik.values.type === "Cooldown"
                      ? "#EFC09F"
                      : "#726174"
                  } 0.5%, #2C262D 0.5%)`,
              borderRadius: "6px",
              borderBottomLeftRadius: expanded ? "0px" : "6px",
              "& .MuiAccordionSummary-content": {
                margin: 0,
                cursor: "default",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  justifyContent: "space-between",
                  display: "flex",
                  width: "100%",
                }}
              >
                {/* Type */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="Drag & Drop" placement="top" arrow>
                    <IconButton
                      // ref={draggable.setActivatorNodeRef}
                      {...listeners}
                      {...attributes}
                    >
                      <DragIndicatorIcon />
                    </IconButton>
                  </Tooltip>
                  <Typography variant="h4">
                    {formik.values.type === "Cooldown"
                      ? "Cool Down"
                      : formik.values.type[0].toUpperCase() +
                        formik.values.type.slice(1)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleModal();
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: isTabletScreen ? 1 : 5,
                  width: "100%",
                  paddingX: "1rem",
                  paddingTop: "1rem",
                  paddingBottom: "0.5rem",
                  background: "#2C262D",
                  borderEndStartRadius: expanded ? "0px" : "6px",
                  borderEndEndRadius: expanded ? "0px" : "6px",
                }}
              >
                <Box sx={{ width: { md: "80px", xl: "100px" } }}>
                  <Typography
                    variant={isTabletScreen ? "subtitle1" : "h5"}
                    color={"#BC6D29"}
                  >
                    {formik.values.duration_type === "Time"
                      ? isNaN(formik.values.duration)
                        ? "00:00:00"
                        : timeFormatter(formik.values.duration)
                      : formik.values.duration_type === "Distance"
                      ? formik.values.duration + (units === 'metric' ? " km" : " mi")
                      : "LAP"}
                  </Typography>
                  <Typography variant="subtitle2">
                    {formik.values.duration_type === "Time"
                      ? "Total Time"
                      : formik.values.duration_type === "Distance"
                      ? "Total Distance"
                      : ""}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant={isTabletScreen ? "subtitle1" : "h5"}
                    color={"#BC6D29"}
                  >
                    {formik.values.primary_target_type.toUpperCase()}
                  </Typography>
                  <Typography variant="subtitle2">Primary Target</Typography>
                </Box>
                <Box sx={{ width: "60px" }}>
                  <Typography
                    variant={isTabletScreen ? "subtitle1" : "h5"}
                    color={"#BC6D29"}
                  >
                    {formik.values.position.toUpperCase()}
                  </Typography>
                  <Typography variant="subtitle2">Position</Typography>
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "0px",
              background: `linear-gradient(90deg, ${
                formik.values.type === "Warmup"
                  ? "#E28E54"
                  : formik.values.type === "Recovery"
                  ? "#00B8D9"
                  : formik.values.type === "Active"
                  ? "#ffcf40"
                  : formik.values.type === "Cooldown"
                  ? "#EFC09F"
                  : "#726174"
              } 0.5%, #2C262D 0.5%)`,
              borderEndStartRadius: "6px",
              borderEndEndRadius: "6px",
            }}
          >
            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: "1.5rem",
              }}
            >
              {/* Type */}
              <Box>
                <Typography variant="h4">Type</Typography>
                <Select
                  id="type"
                  name="type"
                  value={formik.values.type ?? ""}
                  onChange={formik.handleChange}
                  // onBlur={(e) => handleBlur(e)}
                  sx={{
                    width: isTabletScreen ? 210 : 340,
                    color: "#F2F2F2",
                    "& .MuiSvgIcon-root": {
                      color: "#F2F2F2",
                    },
                    "&.MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#64605D",
                      },
                      "&:hover fieldset": {
                        borderColor: "#64605D",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#64605D",
                      },
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        border: "1px solid #64605D",
                      },
                      sx: {
                        "&& .Mui-selected": {
                          backgroundColor: "#64605D",
                        },
                      },
                    },
                    MenuListProps: {
                      disablePadding: true,
                    },
                  }}
                >
                  <MenuItem value="Warmup">Warmup</MenuItem>
                  <MenuItem value="Recovery">Recovery</MenuItem>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Cooldown">Cool Down</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </Box>
              {/* Duration  */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h4">Duration</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isTabletScreen ? "column" : "row",
                    justifyContent: { md: "space-between", xl: "flex-start" },
                    gap: { md: 2, xl: 4 },
                  }}
                >
                  <Select
                    id="duration_type"
                    name="duration_type"
                    value={formik.values.duration_type ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue("duration", 0);
                      setTime("0:00");
                      formik.handleChange(e);
                    }}
                    sx={{
                      width: isTabletScreen ? 210 : 340,
                      color: "#F2F2F2",
                      "& .MuiSvgIcon-root": {
                        color: "#F2F2F2",
                      },
                      "&.MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#64605D",
                        },
                        "&:hover fieldset": {
                          borderColor: "#64605D",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#64605D",
                        },
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          border: "1px solid #64605D",
                        },
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: "#64605D",
                          },
                        },
                      },
                      MenuListProps: {
                        disablePadding: true,
                      },
                    }}
                  >
                    <MenuItem value="Time">Time</MenuItem>
                    <MenuItem value="Distance">Distance</MenuItem>
                    <MenuItem value="Lap">Lap</MenuItem>
                  </Select>

                  {/* If Time & Distance , if lap none */}
                  {(formik.values.duration_type === "Time" ||
                    formik.values.duration_type === "Distance") && (
                    <Box sx={{ display: "flex" }}>
                      <FormControl
                        sx={{
                          "& .MuiFormControl-root": {
                            width: isTabletScreen ? 120 : 140,
                          },
                        }}
                      >
                        <TextField
                          name="duration"
                          onBlur={(e) =>
                            formik.values.duration_type === "Time"
                              ? onBlur(e)
                              : null
                          }
                          value={
                            formik.values.duration_type === "Time"
                              ? time
                              : formik.values.duration
                          }
                          // parseFloat(e.target.value).toFixed(1))
                          error={
                            formik.touched.duration &&
                            Boolean(formik.errors.duration)
                          }
                          helperText={
                            formik.touched.duration && formik.errors.duration
                          }
                          onChange={(e) =>
                            formik.values.duration_type === "Time"
                              ? onChange(e)
                              : formik.handleChange(e)
                          }
                          placeholder={
                            formik.values.duration_type === "Time"
                              ? "00:00:00"
                              : "0"
                          }
                          sx={{
                            border: "1px solid #64605D",
                            borderRadius: "4px",
                            borderTopRightRadius: "0px",
                            borderBottomRightRadius: "0px",
                            borderRightColor: "#A19D9A",
                            height: "39px",
                            textAlignLast: "center",
                            textAlign: "center",
                            "& .MuiInputBase-root": {
                              width: isTabletScreen ? 120 : 140,
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#BC6D29 !important",
                            },
                          }}
                        />
                      </FormControl>
                      <Box
                        sx={{
                          border: "1px solid #64605D",
                          borderRadius: "4px",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          borderLeftColor: "#A19D9A",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "39px",
                          background: "#A19D9A",
                          width: isTabletScreen ? 90 : 120,
                        }}
                      >
                        <Typography sx={{ color: "#24211F", opacity: "0.5" }}>
                          {formik.values.duration_type === "Time"
                            ? "hh:mm:ss"
                            : units === 'metric' ? "km" : "mi"}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              {/* Primary Target */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { md: "space-between", xl: "flex-start" },
                    gap: { xl: 29 },
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ marginBottom: "0.5rem", marginRight: "0.5rem" }}
                  >
                    Primary Target
                  </Typography>
                  {!isTabletScreen &&
                    formik.values.primary_target_type === "Power" && (
                      <Typography
                        variant="h4"
                        sx={{
                          marginBottom: "0.5rem",
                          display: "flex",
                          width: isTabletScreen ? 120 : 260,
                        }}
                      >
                        Units
                      </Typography>
                    )}
                  {!isTabletScreen &&
                    formik.values.primary_target_type === "Heart Rate" && (
                      <Typography
                        variant="h4"
                        sx={{
                          marginBottom: "0.5rem",
                          display: "flex",
                          width: 260,
                        }}
                      >
                        Target
                      </Typography>
                    )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isTabletScreen ? "column" : "row",
                    justifyContent: { md: "space-between", xl: "flex-start" },
                    gap: { md: 2, xl: 4 },
                  }}
                >
                  <Select
                    id="primary_target_type"
                    name="primary_target_type"
                    value={formik.values.primary_target_type ?? ""}
                    onChange={formik.handleChange}
                    sx={{
                      width: isTabletScreen ? 210 : 340,
                      color: "#F2F2F2",
                      "& .MuiSvgIcon-root": {
                        color: "#F2F2F2",
                      },
                      "&.MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#64605D",
                        },
                        "&:hover fieldset": {
                          borderColor: "#64605D",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#64605D",
                        },
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          border: "1px solid #64605D",
                        },
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: "#64605D",
                          },
                        },
                      },
                      MenuListProps: {
                        disablePadding: true,
                      },
                    }}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="Speed">Speed</MenuItem>
                    <MenuItem value="Power Zones">Power Zones</MenuItem>
                    <MenuItem value="Power">Power</MenuItem>
                    <MenuItem value="Cadence">Cadence</MenuItem>
                    <MenuItem value="Heart Rate Zones">
                      Heart Rate Zones
                    </MenuItem>
                    <MenuItem value="Heart Rate">Heart Rate</MenuItem>
                  </Select>
                  {(formik.values.primary_target_type === "Speed" ||
                    formik.values.primary_target_type === "Cadence") && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FormControl
                        sx={{
                          "& .MuiFormControl-root": {
                            width: isTabletScreen ? 60 : 70,
                          },
                        }}
                      >
                        <TextField
                          name="primary_target[0]"
                          onBlur={formik.handleBlur}
                          value={formik.values.primary_target?.[0]}
                          error={
                            formik.touched.primary_target?.[0] &&
                            Boolean(formik.errors.primary_target?.[0])
                          }
                          helperText={
                            formik.touched.primary_target?.[0] &&
                            formik.errors.primary_target?.[0]
                          }
                          onChange={formik.handleChange}
                          sx={{
                            border: "1px solid #64605D",
                            borderRadius: "4px",
                            borderRightColor: "#A19D9A",
                            height: "38px",
                            "& .MuiInputBase-root": {
                              width: isTabletScreen ? 60 : 70,
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#BC6D29",
                            },
                          }}
                        />
                      </FormControl>
                      <Typography
                        sx={{
                          opacity: "0.5",
                          width: isTabletScreen ? 40 : 60,
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        to
                      </Typography>
                      <FormControl
                        sx={{
                          "& .MuiFormControl-root": {
                            width: isTabletScreen ? 60 : 70,
                          },
                        }}
                      >
                        <TextField
                          name="primary_target[1]"
                          onBlur={formik.handleBlur}
                          value={formik.values.primary_target?.[1]}
                          error={
                            formik.touched.primary_target?.[1] &&
                            Boolean(formik.errors.primary_target?.[1])
                          }
                          helperText={
                            formik.touched.primary_target?.[1] &&
                            formik.errors.primary_target?.[1]
                          }
                          onChange={formik.handleChange}
                          sx={{
                            border: "1px solid #64605D",
                            borderRadius: "4px",
                            borderRightColor: "#A19D9A",
                            height: "38px",
                            "& .MuiInputBase-root": {
                              width: isTabletScreen ? 60 : 70,
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#BC6D29",
                            },
                          }}
                        />
                      </FormControl>
                      <Box
                        sx={{
                          width: 60,
                          border: "1px solid #64605D",
                          borderRadius: "4px",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          borderLeftColor: "#A19D9A",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "38px",
                          paddingX: "1em",
                          background: "#A19D9A",
                        }}
                      >
                        <Typography sx={{ color: "#24211F", opacity: "0.5" }}>
                          {formik.values.primary_target_type === "Speed"
                            ? "kph "
                            : "rpm"}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {formik.values.primary_target_type === "Power Zones" && (
                    <Select
                      name="primary_target[0]"
                      value={formik.values.primary_target?.[0]}
                      onChange={formik.handleChange}
                      sx={{
                        width: isTabletScreen ? 210 : 260,
                        color: "#F2F2F2",
                        "& .MuiSvgIcon-root": {
                          color: "#F2F2F2",
                        },
                        "&.MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#64605D",
                          },
                          "&:hover fieldset": {
                            borderColor: "#64605D",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#64605D",
                          },
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            border: "1px solid #64605D",
                          },
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "#64605D",
                            },
                          },
                        },
                        MenuListProps: {
                          disablePadding: true,
                        },
                      }}
                    >
                      <MenuItem value="0">Power Zone 1</MenuItem>
                      <MenuItem value="1">Power Zone 2</MenuItem>
                      <MenuItem value="2">Power Zone 3</MenuItem>
                      <MenuItem value="3">Power Zone 4</MenuItem>
                      <MenuItem value="4">Power Zone 5</MenuItem>
                      <MenuItem value="5">Power Zone 6</MenuItem>
                      <MenuItem value="6">Power Zone 7</MenuItem>
                    </Select>
                  )}
                  {formik.values.primary_target_type === "Power" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      {isTabletScreen &&
                        formik.values.primary_target_type === "Power" && (
                          <Typography
                            variant="h4"
                            sx={{
                              marginBottom: "0.5rem",
                              display: "flex",
                              width: isTabletScreen ? 120 : 260,
                            }}
                          >
                            Units
                          </Typography>
                        )}
                      <Select
                        name="primary_target_unit"
                        value={formik.values.primary_target_unit}
                        onChange={formik.handleChange}
                        sx={{
                          width: isTabletScreen ? 210 : 260,
                          color: "#F2F2F2",
                          "& .MuiSvgIcon-root": {
                            color: "#F2F2F2",
                          },
                          "&.MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#64605D",
                            },
                            "&:hover fieldset": {
                              borderColor: "#64605D",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#64605D",
                            },
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              border: "1px solid #64605D",
                            },
                            sx: {
                              "&& .Mui-selected": {
                                backgroundColor: "#64605D",
                              },
                            },
                          },
                          MenuListProps: {
                            disablePadding: true,
                          },
                        }}
                      >
                        <MenuItem value="watts">Watts</MenuItem>
                        <MenuItem value="ftp">% FTP</MenuItem>
                      </Select>
                      <Typography variant="h4">Power Target</Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FormControl
                          sx={{
                            "& .MuiFormControl-root": {
                              width: isTabletScreen ? 60 : 70,
                            },
                          }}
                        >
                          <TextField
                            name="primary_target[0]"
                            onBlur={formik.handleBlur}
                            value={formik.values.primary_target?.[0]}
                            error={
                              formik.touched.primary_target?.[0] &&
                              Boolean(formik.errors.primary_target?.[0])
                            }
                            helperText={
                              formik.touched.primary_target?.[0] &&
                              formik.errors.primary_target?.[0]
                            }
                            onChange={formik.handleChange}
                            sx={{
                              border: "1px solid #64605D",
                              borderRadius: "4px",
                              borderRightColor: "#A19D9A",
                              height: "38px",
                              "& .MuiInputBase-root": {
                                width: isTabletScreen ? 60 : 70,
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#BC6D29",
                                },
                            }}
                          />
                        </FormControl>
                        <Typography
                          sx={{
                            opacity: "0.5",
                            width: isTabletScreen ? 50 : 60,
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          to
                        </Typography>
                        <FormControl
                          sx={{
                            "& .MuiFormControl-root": {
                              width: isTabletScreen ? 60 : 70,
                            },
                          }}
                        >
                          <TextField
                            name="primary_target[1]"
                            onBlur={formik.handleBlur}
                            value={formik.values.primary_target?.[1]}
                            error={
                              formik.touched.primary_target?.[1] &&
                              Boolean(formik.errors.primary_target?.[1])
                            }
                            helperText={
                              formik.touched.primary_target?.[1] &&
                              formik.errors.primary_target?.[1]
                            }
                            onChange={formik.handleChange}
                            sx={{
                              border: "1px solid #64605D",
                              borderRadius: "4px",
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                              borderRightColor: "#A19D9A",
                              height: "38px",
                              "& .MuiInputBase-root": {
                                width: isTabletScreen ? 60 : 70,
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#BC6D29",
                                },
                            }}
                          />
                        </FormControl>
                        <Box
                          sx={{
                            width: isTabletScreen ? 40 : 60,
                            border: "1px solid #64605D",
                            borderRadius: "4px",
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                            borderLeftColor: "#A19D9A",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "38px",
                            paddingX: "1em",
                            background: "#A19D9A",
                          }}
                        >
                          <Typography sx={{ color: "#24211F", opacity: "0.5" }}>
                            {formik.values.primary_target_unit === "watts"
                              ? "W "
                              : "%FTP"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {formik.values.primary_target_type === "Heart Rate Zones" && (
                    <Select
                      name="primary_target[0]"
                      value={formik.values.primary_target?.[0]}
                      onChange={formik.handleChange}
                      sx={{
                        width: isTabletScreen ? 210 : 260,
                        color: "#F2F2F2",
                        "& .MuiSvgIcon-root": {
                          color: "#F2F2F2",
                        },
                        "&.MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#64605D",
                          },
                          "&:hover fieldset": {
                            borderColor: "#64605D",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#64605D",
                          },
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            border: "1px solid #64605D",
                          },
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "#64605D",
                            },
                          },
                        },
                        MenuListProps: {
                          disablePadding: true,
                        },
                      }}
                    >
                      <MenuItem value="0">Heart Rate Zone 1</MenuItem>
                      <MenuItem value="1">Heart Rate Zone 2</MenuItem>
                      <MenuItem value="2">Heart Rate Zone 3</MenuItem>
                      <MenuItem value="3">Heart Rate Zone 4</MenuItem>
                      <MenuItem value="4">Heart Rate Zone 5</MenuItem>
                    </Select>
                  )}
                  {formik.values.primary_target_type === "Heart Rate" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      {isTabletScreen && (
                        <Typography
                          variant="h4"
                          sx={{
                            display: "flex",
                            width: 260,
                          }}
                        >
                          Target
                        </Typography>
                      )}
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FormControl
                          sx={{
                            "& .MuiFormControl-root": {
                              width: isTabletScreen ? 60 : 70,
                            },
                          }}
                        >
                          <TextField
                            name="primary_target[0]"
                            onBlur={formik.handleBlur}
                            value={formik.values.primary_target?.[0]}
                            error={
                              formik.touched.primary_target?.[0] &&
                              Boolean(formik.errors.primary_target?.[0])
                            }
                            helperText={
                              formik.touched.primary_target?.[0] &&
                              formik.errors.primary_target?.[0]
                            }
                            onChange={formik.handleChange}
                            sx={{
                              border: "1px solid #64605D",
                              borderRadius: "4px",
                              borderRightColor: "#A19D9A",
                              height: "38px",
                              "& .MuiInputBase-root": {
                                width: isTabletScreen ? 60 : 70,
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#BC6D29",
                                },
                            }}
                          />
                        </FormControl>
                        <Typography
                          sx={{
                            opacity: "0.5",
                            width: isTabletScreen ? 40 : 60,
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          to
                        </Typography>
                        <FormControl
                          sx={{
                            "& .MuiFormControl-root": {
                              width: isTabletScreen ? 60 : 70,
                            },
                          }}
                        >
                          <TextField
                            name="primary_target[1]"
                            onBlur={formik.handleBlur}
                            value={formik.values.primary_target?.[1]}
                            error={
                              formik.touched.primary_target?.[1] &&
                              Boolean(formik.errors.primary_target?.[1])
                            }
                            helperText={
                              formik.touched.primary_target?.[1] &&
                              formik.errors.primary_target?.[1]
                            }
                            onChange={formik.handleChange}
                            sx={{
                              border: "1px solid #64605D",
                              borderRadius: "4px",
                              borderRightColor: "#A19D9A",
                              height: "38px",
                              "& .MuiInputBase-root": {
                                width: isTabletScreen ? 60 : 70,
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#BC6D29",
                                },
                            }}
                          />
                        </FormControl>
                        <Box
                          sx={{
                            width: 60,
                            border: "1px solid #64605D",
                            borderRadius: "4px",
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                            borderLeftColor: "#A19D9A",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "38px",
                            paddingX: "1em",
                            background: "#A19D9A",
                          }}
                        >
                          <Typography sx={{ color: "#24211F", opacity: "0.5" }}>
                            bpm
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              {/* Secondary Target */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { md: "space-between", xl: "flex-start" },
                    gap: { xl: 26.5 },
                  }}
                >
                  <Typography variant="h4" sx={{ marginBottom: "0.5rem" }}>
                    Secondary Target
                  </Typography>
                  {!isTabletScreen &&
                    formik.values.secondary_target_type === "Power" && (
                      <Typography
                        variant="h4"
                        sx={{
                          marginBottom: "0.5rem",
                          display: "flex",
                          width: isTabletScreen ? 120 : 260,
                        }}
                      >
                        Units
                      </Typography>
                    )}
                  {!isTabletScreen &&
                    formik.values.secondary_target_type === "Heart Rate" && (
                      <Typography
                        variant="h4"
                        sx={{
                          marginBottom: "0.5rem",
                          display: "flex",
                          width: 260,
                        }}
                      >
                        Target
                      </Typography>
                    )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isTabletScreen ? "column" : "row",
                    justifyContent: { md: "space-between", xl: "flex-start" },
                    gap: { md: 2, xl: 4 },
                  }}
                >
                  <Select
                    id="secondary_target_type"
                    name="secondary_target_type"
                    value={formik.values.secondary_target_type}
                    onChange={formik.handleChange}
                    disabled={formik.values.primary_target_type === "None"}
                    sx={{
                      width: isTabletScreen ? 210 : 340,
                      color: "#F2F2F2",
                      "& .MuiSvgIcon-root": {
                        color: "#F2F2F2",
                      },
                      "&.MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#64605D",
                        },
                        "&:hover fieldset": {
                          borderColor: "#64605D",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#64605D",
                        },
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          border: "1px solid #64605D",
                        },
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: "#64605D",
                          },
                        },
                      },
                      MenuListProps: {
                        disablePadding: true,
                      },
                    }}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="Speed">Speed</MenuItem>
                    <MenuItem value="Power Zones">Power Zones</MenuItem>
                    <MenuItem value="Power">Power</MenuItem>
                    <MenuItem value="Cadence">Cadence</MenuItem>
                    <MenuItem value="Heart Rate Zones">
                      Heart Rate Zones
                    </MenuItem>
                    <MenuItem value="Heart Rate">Heart Rate</MenuItem>
                  </Select>
                  {(formik.values.secondary_target_type === "Speed" ||
                    formik.values.secondary_target_type === "Cadence") && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FormControl
                        sx={{
                          "& .MuiFormControl-root": {
                            width: isTabletScreen ? 60 : 70,
                          },
                        }}
                      >
                        <TextField
                          name="secondary_target[0]"
                          onBlur={formik.handleBlur}
                          value={formik.values.secondary_target?.[0]}
                          error={
                            formik.touched.secondary_target?.[0] &&
                            Boolean(formik.errors.secondary_target?.[0])
                          }
                          helperText={
                            formik.touched.secondary_target?.[0] &&
                            formik.errors.secondary_target?.[0]
                          }
                          onChange={formik.handleChange}
                          sx={{
                            border: "1px solid #64605D",
                            borderRadius: "4px",
                            borderRightColor: "#A19D9A",
                            height: "38px",
                            "& .MuiInputBase-root": {
                              width: isTabletScreen ? 60 : 70,
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#BC6D29",
                            },
                          }}
                        />
                      </FormControl>
                      <Typography
                        sx={{
                          opacity: "0.5",
                          width: isTabletScreen ? 40 : 60,
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        to
                      </Typography>
                      <FormControl
                        sx={{
                          "& .MuiFormControl-root": {
                            width: isTabletScreen ? 60 : 70,
                          },
                        }}
                      >
                        <TextField
                          name="secondary_target[1]"
                          onBlur={formik.handleBlur}
                          value={formik.values.secondary_target?.[1]}
                          error={
                            formik.touched.secondary_target?.[1] &&
                            Boolean(formik.errors.secondary_target?.[1])
                          }
                          helperText={
                            formik.touched.secondary_target?.[1] &&
                            formik.errors.secondary_target?.[1]
                          }
                          onChange={formik.handleChange}
                          sx={{
                            border: "1px solid #64605D",
                            borderRadius: "4px",
                            borderRightColor: "#A19D9A",
                            height: "38px",
                            "& .MuiInputBase-root": {
                              width: isTabletScreen ? 60 : 70,
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#BC6D29",
                            },
                          }}
                        />
                      </FormControl>
                      <Box
                        sx={{
                          width: 60,
                          border: "1px solid #64605D",
                          borderRadius: "4px",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          borderLeftColor: "#A19D9A",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "38px",
                          paddingX: "1em",
                          background: "#A19D9A",
                        }}
                      >
                        <Typography sx={{ color: "#24211F", opacity: "0.5" }}>
                          {formik.values.secondary_target_type === "Speed"
                            ? "kph "
                            : "rpm"}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {formik.values.secondary_target_type === "Power Zones" && (
                    <Select
                      name="secondary_target[0]"
                      value={formik.values.secondary_target?.[0]}
                      onChange={formik.handleChange}
                      sx={{
                        width: isTabletScreen ? 210 : 260,
                        color: "#F2F2F2",
                        "& .MuiSvgIcon-root": {
                          color: "#F2F2F2",
                        },
                        "&.MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#64605D",
                          },
                          "&:hover fieldset": {
                            borderColor: "#64605D",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#64605D",
                          },
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            border: "1px solid #64605D",
                          },
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "#64605D",
                            },
                          },
                        },
                        MenuListProps: {
                          disablePadding: true,
                        },
                      }}
                    >
                      <MenuItem value="0">Power Zone 1</MenuItem>
                      <MenuItem value="1">Power Zone 2</MenuItem>
                      <MenuItem value="2">Power Zone 3</MenuItem>
                      <MenuItem value="3">Power Zone 4</MenuItem>
                      <MenuItem value="4">Power Zone 5</MenuItem>
                      <MenuItem value="5">Power Zone 6</MenuItem>
                      <MenuItem value="6">Power Zone 7</MenuItem>
                    </Select>
                  )}
                  {formik.values.secondary_target_type === "Power" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      {isTabletScreen &&
                        formik.values.secondary_target_type === "Power" && (
                          <Typography
                            variant="h4"
                            sx={{
                              marginBottom: "0.5rem",
                              display: "flex",
                              width: isTabletScreen ? 120 : 260,
                            }}
                          >
                            Units
                          </Typography>
                        )}
                      <Select
                        name="secondary_target_unit"
                        value={formik.values.secondary_target_unit}
                        onChange={formik.handleChange}
                        sx={{
                          width: isTabletScreen ? 210 : 260,
                          color: "#F2F2F2",
                          "& .MuiSvgIcon-root": {
                            color: "#F2F2F2",
                          },
                          "&.MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#64605D",
                            },
                            "&:hover fieldset": {
                              borderColor: "#64605D",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#64605D",
                            },
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              border: "1px solid #64605D",
                            },
                            sx: {
                              "&& .Mui-selected": {
                                backgroundColor: "#64605D",
                              },
                            },
                          },
                          MenuListProps: {
                            disablePadding: true,
                          },
                        }}
                      >
                        <MenuItem value="watts">Watts</MenuItem>
                        <MenuItem value="ftp">% FTP</MenuItem>
                      </Select>
                      <Typography variant="h4">Power Target</Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FormControl
                          sx={{
                            "& .MuiFormControl-root": {
                              width: isTabletScreen ? 60 : 70,
                            },
                          }}
                        >
                          <TextField
                            name="secondary_target[0]"
                            onBlur={formik.handleBlur}
                            value={formik.values.secondary_target?.[0]}
                            error={
                              formik.touched.secondary_target?.[0] &&
                              Boolean(formik.errors.secondary_target?.[0])
                            }
                            helperText={
                              formik.touched.secondary_target?.[0] &&
                              formik.errors.secondary_target?.[0]
                            }
                            onChange={formik.handleChange}
                            sx={{
                              border: "1px solid #64605D",
                              borderRadius: "4px",
                              borderRightColor: "#A19D9A",
                              height: "38px",
                              "& .MuiInputBase-root": {
                                width: isTabletScreen ? 60 : 70,
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#BC6D29",
                                },
                            }}
                          />
                        </FormControl>
                        <Typography
                          sx={{
                            opacity: "0.5",
                            width: isTabletScreen ? 50 : 60,
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          to
                        </Typography>
                        <FormControl
                          sx={{
                            "& .MuiFormControl-root": {
                              width: isTabletScreen ? 60 : 70,
                            },
                          }}
                        >
                          <TextField
                            name="secondary_target[1]"
                            onBlur={formik.handleBlur}
                            value={formik.values.secondary_target?.[1]}
                            error={
                              formik.touched.secondary_target?.[1] &&
                              Boolean(formik.errors.secondary_target?.[1])
                            }
                            helperText={
                              formik.touched.secondary_target?.[1] &&
                              formik.errors.secondary_target?.[1]
                            }
                            onChange={formik.handleChange}
                            sx={{
                              border: "1px solid #64605D",
                              borderRadius: "4px",
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                              borderRightColor: "#A19D9A",
                              height: "38px",
                              "& .MuiInputBase-root": {
                                width: isTabletScreen ? 60 : 70,
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#BC6D29",
                                },
                            }}
                          />
                        </FormControl>
                        <Box
                          sx={{
                            width: isTabletScreen ? 40 : 60,
                            border: "1px solid #64605D",
                            borderRadius: "4px",
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                            borderLeftColor: "#A19D9A",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "38px",
                            paddingX: "1em",
                            background: "#A19D9A",
                          }}
                        >
                          <Typography sx={{ color: "#24211F", opacity: "0.5" }}>
                            {formik.values.secondary_target_unit === "watts"
                              ? "W "
                              : "%FTP"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {formik.values.secondary_target_type ===
                    "Heart Rate Zones" && (
                    <Select
                      name="secondary_target[0]"
                      value={formik.values.secondary_target?.[0]}
                      onChange={formik.handleChange}
                      sx={{
                        width: isTabletScreen ? 210 : 260,
                        color: "#F2F2F2",
                        "& .MuiSvgIcon-root": {
                          color: "#F2F2F2",
                        },
                        "&.MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#64605D",
                          },
                          "&:hover fieldset": {
                            borderColor: "#64605D",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#64605D",
                          },
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            border: "1px solid #64605D",
                          },
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "#64605D",
                            },
                          },
                        },
                        MenuListProps: {
                          disablePadding: true,
                        },
                      }}
                    >
                      <MenuItem value="0">Heart Rate Zone 1</MenuItem>
                      <MenuItem value="1">Heart Rate Zone 2</MenuItem>
                      <MenuItem value="2">Heart Rate Zone 3</MenuItem>
                      <MenuItem value="3">Heart Rate Zone 4</MenuItem>
                      <MenuItem value="4">Heart Rate Zone 5</MenuItem>
                    </Select>
                  )}
                  {isTabletScreen &&
                    formik.values.secondary_target_type === "Heart Rate" && (
                      <Typography
                        variant="h4"
                        sx={{
                          marginBottom: "0.5rem",
                          display: "flex",
                          width: 260,
                        }}
                      >
                        Target
                      </Typography>
                    )}
                  {formik.values.secondary_target_type === "Heart Rate" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FormControl
                          sx={{
                            "& .MuiFormControl-root": {
                              width: isTabletScreen ? 60 : 70,
                            },
                          }}
                        >
                          <TextField
                            name="secondary_target[0]"
                            onBlur={formik.handleBlur}
                            value={formik.values.secondary_target?.[0]}
                            error={
                              formik.touched.secondary_target?.[0] &&
                              Boolean(formik.errors.secondary_target?.[0])
                            }
                            helperText={
                              formik.touched.secondary_target?.[0] &&
                              formik.errors.secondary_target?.[0]
                            }
                            onChange={formik.handleChange}
                            sx={{
                              border: "1px solid #64605D",
                              borderRadius: "4px",
                              borderRightColor: "#A19D9A",
                              height: "38px",
                              "& .MuiInputBase-root": {
                                width: isTabletScreen ? 60 : 70,
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#BC6D29",
                                },
                            }}
                          />
                        </FormControl>
                        <Typography
                          sx={{
                            opacity: "0.5",
                            width: isTabletScreen ? 40 : 60,
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          to
                        </Typography>
                        <FormControl
                          sx={{
                            "& .MuiFormControl-root": {
                              width: isTabletScreen ? 60 : 70,
                            },
                          }}
                        >
                          <TextField
                            name="secondary_target[1]"
                            onBlur={formik.handleBlur}
                            value={formik.values.secondary_target?.[1]}
                            error={
                              formik.touched.secondary_target?.[1] &&
                              Boolean(formik.errors.secondary_target?.[1])
                            }
                            helperText={
                              formik.touched.secondary_target?.[1] &&
                              formik.errors.secondary_target?.[1]
                            }
                            onChange={formik.handleChange}
                            sx={{
                              border: "1px solid #64605D",
                              borderRadius: "4px",
                              borderRightColor: "#A19D9A",
                              height: "38px",
                              "& .MuiInputBase-root": {
                                width: isTabletScreen ? 60 : 70,
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#BC6D29",
                                },
                            }}
                          />
                        </FormControl>
                        <Box
                          sx={{
                            width: 60,
                            border: "1px solid #64605D",
                            borderRadius: "4px",
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                            borderLeftColor: "#A19D9A",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "38px",
                            paddingX: "1em",
                            background: "#A19D9A",
                          }}
                        >
                          <Typography sx={{ color: "#24211F", opacity: "0.5" }}>
                            bpm
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              {/* Position */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h4">Position</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Select
                    id="position"
                    name="position"
                    value={formik.values.position ?? ""}
                    onChange={formik.handleChange}
                    sx={{
                      width: 340,
                      color: "#F2F2F2",
                      "& .MuiSvgIcon-root": {
                        color: "#F2F2F2",
                      },
                      "&.MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#64605D",
                        },
                        "&:hover fieldset": {
                          borderColor: "#64605D",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#64605D",
                        },
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          border: "1px solid #64605D",
                        },
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: "#64605D",
                          },
                        },
                      },
                      MenuListProps: {
                        disablePadding: true,
                      },
                    }}
                  >
                    <MenuItem value="Free">Free</MenuItem>
                    <MenuItem value="Comfortable">Comfortable</MenuItem>
                    {bikeType === "Road" && (
                      <MenuItem value="Aggressive">Aggressive</MenuItem>
                    )}
                    <MenuItem value="TT">TT</MenuItem>
                    <MenuItem value="Standing">Standing</MenuItem>
                  </Select>
                </Box>
              </Box>
              <StepNotesComponent formik={formik} />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "212px",
            width: "500px",
            border: "2px solid #BC6D29",
            boxShadow: "24px",
            backgroundColor: "#2A252B",
            padding: "1.5em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography color={"#BC6D29"} variant="h1">
            Remove Step
          </Typography>
          <Typography
            color={"#F7F3F0"}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Are you sure you want to remove this Step?
          </Typography>
          <Stack flexDirection={"row"} marginTop={"1rem"} gap={2}>
            <Button variant="outlined" onClick={handleModal} size="small">
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => removeElement(elementInstance.id)}
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
