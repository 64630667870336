import {
  PropsWithChildren,
  Reducer,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { Database } from "src/utils/DatabaseDefinitions";
import im from "immutable";
import { useSupabase } from "./SupabaseContext";
import SuspenseLoader from "src/components/SuspenseLoader";

export type Table = keyof Database["public"]["Tables"];
export type TableRow<T extends Table> = Database["public"]["Tables"][T]["Row"];

type CacheMemory<Tables extends Table> = {
  [K in Tables]: Array<TableRow<K>>;
};

type AddTableRowsAction<T extends Table> = {
  kind: "add-table-rows";
  tableName: T;
  rows: Array<TableRow<T>>;
};

type DeleteTableRowAction<T extends Table> = {
  kind: "delete-table-row";
  tableName: T;
  row: TableRow<T>;
};

type UpdateTableRowAction<T extends Table> = {
  kind: "update-table-row";
  tableName: T;
  oldRow: TableRow<T>;
  newRow: TableRow<T>;
};

type CacheAction =
  | AddTableRowsAction<Table>
  | DeleteTableRowAction<Table>
  | UpdateTableRowAction<Table>;

type MonitoredTables = im.Map<Table, "fetched" | "pending">;

type TableFetchedAction = {
  kind: "fetched";
  table: Table;
};

type RequestTableAction = {
  kind: "request";
  table: Table;
};

type MonitoredTablesAction = TableFetchedAction | RequestTableAction;

const CacheContext = createContext({} as CacheMemory<Table>);

const MonitoredTablesContext = createContext<
  [MonitoredTables, (_: Table) => void]
>([
  im.Map<Table, "fetched" | "pending">(),
  (t) => {
    console.log("Uncaught context ", t);
  },
]);

export default function Cache(props: PropsWithChildren<{}>) {
  const supabase = useSupabase();

  const monitoredTablesReducer: Reducer<
    MonitoredTables,
    MonitoredTablesAction
  > = useCallback(
    (monitoredTables, action) => {
      switch (action.kind) {
        case "request":
          if (monitoredTables.has(action.table)) {
            return monitoredTables;
          }

          supabase
            .from(action.table)
            .select()
            .then((res) => {
              dispatchCacheAction({
                kind: "add-table-rows",
                tableName: action.table,
                rows: res.data,
              });
              dispatchTableAction({ kind: "fetched", table: action.table });
            });

          supabase
            .channel(action.table)
            .on(
              "postgres_changes",
              { event: "*", schema: "public", table: action.table },
              (payload) => {
                // console.log(action.table, { payload });
                switch (payload.eventType) {
                  case "INSERT":
                    dispatchCacheAction({
                      kind: "add-table-rows",
                      tableName: payload.table as Table,
                      rows: [payload.new] as Array<TableRow<Table>>,
                    });
                    break;
                  case "DELETE":
                    dispatchCacheAction({
                      kind: "delete-table-row",
                      tableName: payload.table as Table,
                      row: payload.old as TableRow<Table>,
                    });
                    break;
                  case "UPDATE":
                    dispatchCacheAction({
                      kind: "update-table-row",
                      tableName: payload.table as Table,
                      oldRow: payload.old as TableRow<Table>,
                      newRow: payload.new as TableRow<Table>,
                    });
                    break;
                }
              },
            )
            .subscribe();

          return monitoredTables.set(action.table, "pending");
        case "fetched":
          return monitoredTables.set(action.table, "fetched");
      }
    },
    [supabase],
  );

  const [monitoredTables, dispatchTableAction] = useReducer(
    monitoredTablesReducer,
    im.Map<Table, "fetched" | "pending">(),
  );

  const cacheReducer: Reducer<CacheMemory<Table>, CacheAction> = useCallback(
    (cache, action) => {
      if (!monitoredTables.has(action.tableName)) {
        return cache;
      }

      switch (action.kind) {
        case "add-table-rows":
          if (!cache[action.tableName]) {
            cache[action.tableName] = [];
          }
          //@ts-ignore
          cache[action.tableName] = cache[action.tableName].concat(action.rows);
          return { ...cache };
        case "delete-table-row":
          //@ts-ignore
          cache[action.tableName] = cache[action.tableName].filter(
            (row) =>
              !Object.keys(action.row).every(
                (key) => row[key] === action.row[key],
              ),
          );
          return { ...cache };
        case "update-table-row":
          //@ts-ignore
          cache[action.tableName] = cache[action.tableName].map((row) =>
            Object.keys(action.oldRow).every(
              (key) => row[key] === action.oldRow[key],
            )
              ? action.newRow
              : row,
          );
          return { ...cache };
      }
    },
    [monitoredTables],
  );

  const [cache, dispatchCacheAction] = useReducer(
    cacheReducer,
    {} as CacheMemory<Table>,
  );

  useEffect(
    () => () => {
      supabase.removeAllChannels();
    },
    [],
  );
  return (
    <MonitoredTablesContext.Provider
      value={[
        monitoredTables,
        (tableName) =>
          dispatchTableAction({ kind: "request", table: tableName }),
      ]}
    >
      <CacheContext.Provider value={cache}>
        {props.children}
      </CacheContext.Provider>
    </MonitoredTablesContext.Provider>
  );
}

export type WithCacheProps<Tables extends Table> = {
  tables: Array<Tables>;
  component: (cached: CacheMemory<Tables>) => JSX.Element;
  pendingComponent?: JSX.Element;
};

export function WithCache<Tables extends Table>(props: WithCacheProps<Tables>) {
  const [monitoredTables, requestTable] = useContext(MonitoredTablesContext);
  const cache = useContext(CacheContext);

  useEffect(() => {
    props.tables.forEach(requestTable);
  }, [props.tables, requestTable]);

  const ready = useMemo(
    () =>
      !monitoredTables
        ? false
        : props.tables.every(
            (table) => monitoredTables.get(table) === "fetched",
          ),
    [monitoredTables, props.tables],
  );

  return ready
    ? props.component(cache)
    : props.pendingComponent ?? <SuspenseLoader />;
}
