import { useEffect, useMemo, useState } from "react";
import { useTitle } from "../customLayouts/DashboardLayout";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import TrendsCard from "../customComponents/TrendsCard";
import TrendUp from "../../assets/img/views/dashboard-widgets/TrendUp.svg";
import TrendDown from "../../assets/img/views/dashboard-widgets/TrendDown.svg";
import NoChange from "../../assets/img/views/dashboard-widgets/NoChange.svg";
import dayjs from "dayjs";
import { Database } from "src/utils/DatabaseDefinitions";
import PreloadComponent from "src/utils/PreloadComponent";
import TrendsGraph from "../customComponents/TrendsGraph";
import { useUser } from "src/components/Authenticated";

type Day = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";

export type Trend =
  | "position_score"
  | "efficiency_factor"
  | "effort_score"
  | "normalized_power"
  | "intensity_factor"
  | "speed_efficiency"
  | "tss"
  | "variability_index"
  | "workload";

export type TrendsProps = {
  sessions: Pick<
    Database["public"]["Tables"]["session"]["Row"],
    | "position_score"
    | "efficiency_factor"
    | "effort_score"
    | "normalized_power"
    | "intensity_factor"
    | "speed_efficiency"
    | "tss"
    | "variability_index"
    | "workload"
    | "date"
  >[];
};

export type TrendsImageProps = {
  trend: string;
  yearBucket: {
    position_score: number;
    efficiency_factor: number;
    effort_score: number;
    normalized_power: number;
    intensity_factor: number;
    speed_efficiency: number;
    tss: number;
    variability_index: number;
    workload: number;
  };
  lastMonthsBucket: {
    position_score: number;
    efficiency_factor: number;
    effort_score: number;
    normalized_power: number;
    intensity_factor: number;
    speed_efficiency: number;
    tss: number;
    variability_index: number;
    workload: number;
  };
};

export default function TrendsPreloadView() {
  const user = useUser();
  const rangeHigh = dayjs();
  const rangeLow = rangeHigh.subtract(365, "days");
  return (
    <>
      <PreloadComponent<{
        session: Pick<
          Database["public"]["Tables"]["session"]["Row"],
          | "position_score"
          | "efficiency_factor"
          | "effort_score"
          | "normalized_power"
          | "intensity_factor"
          | "speed_efficiency"
          | "tss"
          | "variability_index"
          | "workload"
          | "date"
        >[];
      }>
        promises={{
          session: async (supabase) =>
            supabase
              .from("session")
              .select(
                "position_score,efficiency_factor,effort_score,normalized_power,intensity_factor,speed_efficiency,tss,variability_index,workload,date",
              )
              .eq("athlete_id", user.id)
              .order("date", { ascending: false })
              .gte("date", rangeLow.toISOString())
              .lte("date", rangeHigh.toISOString())
              .then((res) => res.data),
        }}
        component={(preloadProps) => (
          <>
            <TrendsView sessions={preloadProps.session} />
          </>
        )}
      />
    </>
  );
}

function TrendImage(props: TrendsImageProps) {
  return props.lastMonthsBucket[props.trend] > props.yearBucket[props.trend] ? (
    <img src={TrendUp} alt="trendup-icon" width={"20px"} />
  ) : props.lastMonthsBucket[props.trend] < props.yearBucket[props.trend] ? (
    <img src={TrendDown} alt="trendDown-icon" width={"20px"} />
  ) : (
    <img src={NoChange} alt="stale-icon" width={"20px"} />
  );
}

function TrendsView(props: TrendsProps) {
  const isMobileScreen = useMediaQuery("(max-width:599px)");
  const allTrends = [
    "position_score",
    "efficiency_factor",
    "effort_score",
    "normalized_power",
    "intensity_factor",
    "speed_efficiency",
    "tss",
    "variability_index",
    "workload",
  ];
  const [, setTitle] = useTitle();
  const rangeHigh = dayjs();
  const rangeLow = rangeHigh.subtract(365, "days");

  // finding how many days is the last week
  const lastWeek = useMemo(() => {
    const indexOfDay = rangeHigh.isoWeekday();
    const days: Array<Day> = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return [...days.slice(0, indexOfDay)];
  }, [rangeHigh]);

  // new range high removed last week
  const newRangeHigh = useMemo(() => {
    return rangeHigh.subtract(lastWeek.length, "day");
  }, [rangeHigh]);

  const newRangeLow = useMemo(() => {
    return newRangeHigh.subtract(11, "week").subtract(6, "day");
  }, [rangeLow]);

  // sessions between new low and new high
  const filterSessions = useMemo(() => {
    return props.sessions.filter(
      (session) =>
        newRangeLow.isSameOrBefore(new Date(session.date), "day") &&
        rangeHigh.isSameOrAfter(new Date(session.date), "day"),
    );
  }, [newRangeLow, rangeHigh]);

  const yearBucket = useMemo(() => {
    const sumObject = Object.fromEntries(
      allTrends.map((trend) => [trend, 0]),
    ) as { [K in Trend]: number };
    const countObject = Object.fromEntries(
      allTrends.map((trend) => [trend, 0]),
    ) as { [K in Trend]: number };
    for (const session of props.sessions) {
      allTrends.map((trend, index) => {
        if (session[trend] !== 0 && session[trend] !== null) {
          sumObject[trend] += session[trend];
          countObject[trend] += 1;
        }
      });
    }
    return Object.fromEntries(
      Object.entries(sumObject).map(([trend, val]) => [
        trend,
        val === 0 ? val : (val / countObject[trend]).toFixed(2),
      ]),
    ) as { [K in Trend]: number };
  }, [props.sessions]);

  const lastMonthsBucket = useMemo(() => {
    const sumObject = Object.fromEntries(
      allTrends.map((trend) => [trend, 0]),
    ) as { [K in Trend]: number };
    const countObject = Object.fromEntries(
      allTrends.map((trend) => [trend, 0]),
    ) as { [K in Trend]: number };
    for (const session of filterSessions) {
      allTrends.map((trend, index) => {
        if (session[trend] !== 0 && session[trend] !== null) {
          sumObject[trend] += session[trend];
          countObject[trend] += 1;
        }
      });
    }
    return Object.fromEntries(
      Object.entries(sumObject).map(([trend, val]) => [
        trend,
        val === 0 ? val : (val / countObject[trend]).toFixed(2),
      ]),
    ) as { [K in Trend]: number };
  }, [filterSessions]);

  useEffect(() => {
    setTitle("Trends");
  }, []);

  const TrendsInfo = useMemo(
    () => [
      {
        index: 1,
        title: "Ideal Positions",
        metric: `${(lastMonthsBucket["position_score"] * 100)?.toFixed(1)} %`,
        description: "Percentage of time in all IDEAL positions.",
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "position_score",
        }),
      },
      {
        index: 2,
        title: "Efficiency Factor",
        metric: `${lastMonthsBucket["efficiency_factor"]}`,
        description:
          "EF compares a cyclist's average power output during a ride to their average heart rate.",
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "efficiency_factor",
        }),
      },
      {
        index: 3,
        title: "Effort Score",
        metric: `${lastMonthsBucket["effort_score"]}`,
        description:
          "It is a subjective toughness rating of the training, based on your HR zones. More points = more effort (harder).",
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "effort_score",
        }),
      },
      {
        index: 4,
        title: "Speed efficiency",
        metric: `${lastMonthsBucket["speed_efficiency"]}`,
        description: `Speed efficiency= Optimal Power needed for the actual average speed / Actual average power.
          Optimal power is the power needed to overcome all forces in an aerodynamic position in the given speed and slope.`,
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "speed_efficiency",
        }),
      },
      {
        index: 5,
        title: "Normalised Power®",
        metric: `${lastMonthsBucket["normalized_power"]}`,
        description:
          "NP is a weighted average of a cyclist's power output, taking into account the variability of their effort throughout the ride. This metric provides a more accurate picture of the actual physiological stress placed on the body than simply looking at average power output.",
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "normalized_power",
        }),
      },
      {
        index: 6,
        title: "TSS®",
        metric: `${Number(lastMonthsBucket["tss"])?.toFixed(1)}`,
        description:
          "TSS is a metric that takes into account the intensity and duration of a ride to estimate the amount of stress placed on the body. TSS = (sec x IF)/ (FTP x 3600) x 100. It provides a more comprehensive view of training load than just looking at distance or time alone.",
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "tss",
        }),
      },
      {
        index: 7,
        title: "Intensity Factor®",
        metric: `${lastMonthsBucket["intensity_factor"]}`,
        description:
          "IF is a metric that compares a cyclist's average power output during a ride to their FTP. IF = AVG_Power/ FTP OR IF = AVH_HR/LTHR. IF provides an indication of how hard the ride was relative to the athlete's capabilities.",
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "intensity_factor",
        }),
      },
      {
        index: 8,
        title: "Variability Index",
        metric: `${lastMonthsBucket["variability_index"]}`,
        description:
          "VI is a measure of how variable a cyclist's power output is during a ride. VI = NP / Average Power. A low VI indicates that the athlete maintained a consistent effort throughout the ride, while a high VI indicates that there were significant fluctuations in effort.",
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "variability_index",
        }),
      },
      {
        index: 9,
        title: "Workload",
        metric: `${Number(lastMonthsBucket["workload"])?.toFixed(1)}`,
        description:
          "It shows a more accurate picture of how hard you are training based on your power zones. Workload = Volume (in minutes) x Intensity (in Power Training Zone).",
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "workload",
        }),
      },
    ],
    [],
  );

  const [activeIndex, setActiveIndex] = useState(TrendsInfo[0]?.index);
  const [activeTitle, setActiveTitle] = useState(TrendsInfo[0]?.title);
  const [activeDescription, setActiveDescription] = useState(
    TrendsInfo[0]?.description,
  );

  const handleCardClick = (index, title, description) => {
    setActiveIndex(index);
    setActiveTitle(title);
    setActiveDescription(description);
  };

  return (
    <>
      <Grid
        sx={{
          padding: "1em",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#201B20",
            width: "98%",
            padding: "1em",
            borderRadius: "10px",
            display: "flex",
            justifyContent: { md: "space-between", sm: "center" },
            flexDirection: { md: "row", sm: "column" },
          }}
        >
          <Grid
            container
            sx={{
              width: { md: "50%", sm: "100%" },
            }}
            gap={2}
          >
            {TrendsInfo.map((cardInfo) => (
              <TrendsCard
                key={cardInfo.index}
                index={cardInfo.index}
                metric={cardInfo.metric}
                title={cardInfo.title}
                description={cardInfo.description}
                icon={cardInfo.icon}
                isActive={cardInfo.index === activeIndex}
                onCardClick={() =>
                  handleCardClick(
                    cardInfo.index,
                    cardInfo.title,
                    cardInfo.description,
                  )
                }
              />
            ))}
            <Typography>
              Normalized Power® (NP®), Intensity Factor® (IF®), and Training
              Stress Score® (TSS®) are registered trademarks of Peaksware, LLC.
            </Typography>
          </Grid>

          {isMobileScreen || (
            <Box
              sx={{
                width: { md: "50%", sm: "95.5%" },
                backgroundColor: "#2C262D",
                borderRadius: "10px",
                marginTop: { sm: "25px", md: 0 },
              }}
            >
              <TrendsGraph
                sessions={props.sessions}
                activeTitle={activeTitle}
                activeDescription={activeDescription}
                weeksAverage={lastMonthsBucket}
                yearAverage={yearBucket}
                rangeHigh={rangeHigh}
                rangeLow={rangeLow}
                newRangeHigh={newRangeHigh}
                lastWeek={lastWeek}
              />
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
}
