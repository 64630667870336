import { Dispatch, SetStateAction } from "react";
import { Grid, Box, Typography, useMediaQuery, Tooltip, IconButton } from "@mui/material";
import cyclistSession from "../../../../assets/img/views/calendar/cyclistIconSession.svg";
import calendarCyclist from "../../../../assets/img/layout/coach/Library/Plans/calendarcyclist.svg";
import "../../../../assets/scss/components/CalendarWidget.scss";
import { toHHMMSS, weekdays } from "src/utils/common";
import CancelIcon from '@mui/icons-material/Cancel';
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";

export type CoachCalendarWidgetProps = {
  steps?: {
    id: number;
    created_at: string;
    workout_type: string;
    bike_type: string;
    duration: number;
    distance: number;
    tss: number;
    position_goal: string;
    user_id: string;
    name: string;
    date: string | Date;
  }[];
  setSteps: Dispatch<any>;
  selectedWeek: number
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  setSelectedDay: Dispatch<SetStateAction<number>>
};

export default function CoachCalendarWidget({
  steps, setSteps,
  selectedWeek,
  setIsModalOpen,
  setSelectedDay,
}: CoachCalendarWidgetProps) {
  const units = useUnits()
  const isTabletScreen = useMediaQuery("(max-width:1160px)");

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {steps
                ?.slice(selectedWeek * 7, selectedWeek * 7 + 7)
                .map((step, i) => {
                  if (!step) {
                    return (
                      <Box
                        key={i}
                        sx={{
                          "&:hover .hovered-area": {
                            color: "white",
                          },
                          "&:hover": {
                            background: "#24211F",
                            borderRadius: "6px",
                          },
                          width: "calc(100%/7)",
                          // paddingX: "1rem",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          className="hovered-area"
                          sx={{
                            color: "#64605D",
                            marginTop: "1rem",
                            textAlign: "center",
                          }}
                        >
                          {weekdays[i]}
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            marginTop: "1.5rem",
                          }}
                        >
                          <img src={calendarCyclist} />
                          {!isTabletScreen && (
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: "#E3DFDC",
                                marginTop: { xs: "10px", xl: "25px" },
                              }}
                            >
                              Intervals
                            </Typography>
                          )}
                          {/* <img src={noData} width={"20px"} /> */}
                          <Box sx={{ cursor: "pointer" }}>
                            {isTabletScreen ? (
                              <Tooltip title="+ Add Workout">
                                <Typography
                                  variant="subtitle2"
                                  onClick={() => {
                                    setSelectedDay(i + selectedWeek * 7);
                                    setIsModalOpen(true);
                                  }}
                                  sx={{
                                    color: "#E3DFDC",
                                    marginTop: { xs: "10px", xl: "25px" },
                                  }}
                                >
                                  +
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography
                                variant="subtitle2"
                                onClick={() => {
                                  setSelectedDay(i + selectedWeek * 7);
                                  setIsModalOpen(true);
                                }}
                                sx={{
                                  color: "#E3DFDC",
                                  marginTop: { xs: "10px", xl: "25px" },
                                }}
                              >
                                + Add Workout
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    );
                  }
                  return (
                    <>
                      <Box
                        key={i}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "calc(100%/7)",
                        }}
                      >
                        {!isTabletScreen && (
                          <Box
                            sx={{
                              width: "100%",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color: "#E3DFDC",
                              fontFamily: "DejaVu Sans",
                              fontSize: "10px",
                            }}
                          >
                            {step.name}
                          </Box>
                        )}

                        <Typography
                          className="hovered-area"
                          sx={{
                            color: "#64605D",
                            marginTop: isTabletScreen ? "1rem" : "",
                          }}
                        >
                          {weekdays[i]}
                        </Typography>

                        <Box>
                          {isTabletScreen ? (
                            <Tooltip
                              title={
                                <Box>
                                  <Typography>{step.name}</Typography>
                                  <Typography>
                                    {toHHMMSS(step.duration)}
                                  </Typography>
                                </Box>
                              }
                              arrow
                            >
                              <img
                                width={22}
                                style={{
                                  marginTop: "1.5rem",
                                  cursor: "pointer",
                                }}
                                src={cyclistSession}
                                alt="Cyclist Session"
                              />
                            </Tooltip>
                          ) : (
                            <img
                              width={22}
                              style={{ marginTop: "1.5rem" }}
                              src={cyclistSession}
                              alt="Cyclist Session"
                            />
                          )}
                        </Box>
                        {!isTabletScreen && (
                          <>
                            <Box sx={{ marginTop: "14px" }}>
                              {toHHMMSS(step.duration)}
                            </Box>

                            <Box>
                          {
                            units === "metric"
                            ? `${step.distance.toFixed(2) ?? "0"} km`
                            : `${
                              step.distance
                                  ? convert(step.distance)
                                      .from("km")
                                      .to("mi")
                                      .toFixed(2)
                                  : "0"
                              } mi`
                          }
                        </Box>
                            <Box>TSS®</Box>
                            <Box>{step.tss}</Box>
                        <IconButton
                          onClick={() => {
                            steps[i + ((selectedWeek * 7))] = undefined
                            setSteps([...steps])
                          }}
                        >
                          <CancelIcon htmlColor="#DD4F4A" sx={{fontSize: 15}}/>
                        </IconButton>
                          </>
                        )}
                      </Box>
                    </>
                  );
                })}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
