import { Box, Button, Modal, Typography } from "@mui/material";

export type RemoveWeekProps = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  weekNumber: number;
};

export default function RemoveWeekModal(props: RemoveWeekProps) {
  const { open, onConfirm, onCancel, weekNumber } = props;
  return (
    <Modal open={open} onClose={onCancel}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#2A252B",
          border: "3px solid #DD4F4A",
          borderRadius: "6px",
          p: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="coach700" component="h2" mt={4}>
          Remove Week {weekNumber}
        </Typography>
        <Typography component="h2" mt={4}>
          Are you sure you want to remove this Week?
        </Typography>
        <Typography component="h2" mt={1}>
          All your data will be lost.
        </Typography>
        <Box sx={{ mt: 6, display: "flex", gap: 2 }}>
          <Button
            onClick={onCancel}
            variant="coachVariant2"
            size="small"
            sx={{ width: "155px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            variant="coachVariant"
            size="small"
            sx={{ width: "155px" }}
            color="error"
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}