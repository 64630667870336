import {
  Box,
  Divider,
  Grid,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useMemo, useState } from "react";
import { PositionStatistics } from "src/utils/types";
import PositionDonut from "../../../customComponents/PositionDonut";
import ZonesComponent from "../../../customComponents/ZonesComponent";

import infoIcon from "../../../../assets/img/layout/coach/MyAthletes/InfoIcon.svg";

// Road Bike Images
import ComfortableRR from "../../../../assets/img/views/position-images/road_bike/RR_comfortable.svg";
import AggressiveRR from "../../../../assets/img/views/position-images/road_bike/RR_aggressive.svg";
import StandingRR from "../../../../assets/img/views/position-images/road_bike/RR_standing.svg";
import TTRR from "../../../../assets/img/views/position-images/road_bike/RR_tt.svg";

import TT_Aero from "../../../../assets/img/views/position-images/tt_bike/tt_aero.svg";
import TT_Comf from "../../../../assets/img/views/position-images/tt_bike/tt_comf.svg";
import TT_Standing from "../../../../assets/img/views/position-images/tt_bike/tt_standing.svg";
import BlurZones from "../../../../assets/img/views/position-images/blueZones.svg";

import { TableRow } from "src/contexts/CacheContext";
import { allColors } from "src/utils/common";
import {
  useRideSession,
  useSpeciments,
} from "../AthletesSessions/CoachSessionViewPreload";
import {
  useRideSession as RideSession,
  useSpeciments as Specimentss,
} from "../../Sessions/CoachSessionViewsPreload";
import {
  useHeartRateZones as HeartZones,
  usePowerZones as PowerZones,
} from "../../Sessions/CoachSessionOverviewViewPreload";

import {
  useHeartRateZones,
  usePowerZones,
} from "./CoachSessionOverviewPreload";
import CoachNotesComponent from "../CoachNotesComponent";

//TT Bike Images

export type CoachZonezViewProps = {
  defaultPositions: Array<TableRow<"default_positions">>;
  customPositions: Array<TableRow<"custom_positions">>;
};

export default function CoachZonezView(props: CoachZonezViewProps) {
  const isMobileScreen = useMediaQuery("(max-width:480px)");

  const allSpeciments =
    location.pathname.startsWith("/coach/my_athletes") &&
    !location.pathname.startsWith("/coach/my_athletes/session")
      ? useSpeciments()
      : Specimentss();
  const session =
    location.pathname.startsWith("/coach/my_athletes") &&
    !location.pathname.startsWith("/coach/my_athletes/session")
      ? useRideSession()
      : RideSession();

  const bikeType = useMemo(() => {
    return session.athlete_bikes?.bike_types?.name;
  }, []);

  const positionStatistics: Array<
    Omit<PositionStatistics, "positionType"> & {
      positionType: string;
    }
  > = useMemo(() => {
    try {
      return session.session_statistics.map((stat) => ({
        ...stat,
        positionType: ((position) =>
          position.primary_name +
          (position.secondary_name ? ` - ${position.secondary_name}` : ""))(
          stat.default_position_id !== null
            ? props.defaultPositions.find(
                (pos) => pos.id === stat.default_position_id,
              )
            : props.customPositions.find(
                (pos) => pos.id === stat.custom_position_id,
              ),
        ),
      }));
    } catch (err) {
      return null;
    }
  }, [props.defaultPositions, props.customPositions]);

  const hasPower = useMemo(
    () => !allSpeciments.map((spec) => spec.power).every((power) => !power),
    [allSpeciments],
  );

  const hasHeartRate = useMemo(
    () =>
      !allSpeciments
        .map((spec) => spec.heartrate)
        .every((heartrate) => !heartrate),
    [allSpeciments],
  );

  const powerZones =
    location.pathname.startsWith("/coach/my_athletes") &&
    !location.pathname.startsWith("/coach/my_athletes/session")
      ? usePowerZones()
      : PowerZones();
  const heartRateZones =
    location.pathname.startsWith("/coach/my_athletes") &&
    !location.pathname.startsWith("/coach/my_athletes/session")
      ? useHeartRateZones()
      : HeartZones();

  const powerTooltip = (
    <>
      <Typography variant="subtitle2">
        <strong>Power Zones for Cycling </strong>
        <br />
        Power zones for cycling assist in comprehending the duration of training
        spent at various intensities throughout a ride. They segment your
        training time into distinct zones determined by the amount of wattage
        generated.
      </Typography>
      <Typography variant="subtitle2">
        <strong>Setting Up Power Zones</strong>
        <br />
        The divisions between zones are established using default percentages of
        your functional threshold power (FTP). You have the option to utilize
        these preset values or modify them according to your specific training
        requirements from your Account settings.
      </Typography>
    </>
  );

  const heartrateTooltip = (
    <>
      <Typography variant="subtitle2">
        <strong>Heart Rate Zones for Cycling </strong>
        <br />
        Heart rate zones help in gauging the intensity of your training based on
        your heart rate during a workout. They divide your training time into
        different zones according to the range of heart rate values.
      </Typography>
      <Typography variant="subtitle2">
        <strong>Setting Up Heart Rate Zones</strong>
        <br />
        The divisions between zones are typically based on default percentages
        of your maximum heart rate. You have the option to utilize these preset
        values or modify them according to your specific training requirements
        from your Account settings.
      </Typography>
    </>
  );

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const positionsTooltip = (
    <>
      <Typography variant="subtitle2">
        <strong>Cycling Position Zones </strong>
        <br />
        Cycling position zones help in understanding and optimizing your riding
        posture during cycling sessions. These zones categorize your riding
        positions based on data gathered from your the Darefore position sensor.
      </Typography>
      <Typography variant="subtitle2">
        <strong>Setting Up Cycling Position Zones</strong>
        <br />
        To determine cycling position zones, you need to utilize a Darefore
        position sensor that captures data related to your riding posture. This
        sensor collects information about your position on the bike real-time.
        The collected data is then used to define distinct zones that reflect
        the different positions adopted during your rides. The boundaries and
        definitions of cycling position zones can be tailored to suit your
        specific needs and preferences. By analyzing the data collected by the
        Darefore position sensor, you can fine-tune the zones based on your
        personal riding style and comfort level.
      </Typography>
      <Typography variant="subtitle2">
        <strong>Optimizing Performance with Cycling Position Zoness</strong>
        <br />
        By leveraging cycling position zones, you can optimize your performance
        by consciously adopting and maintaining specific riding positions during
        different phases of your ride. This can help enhance aerodynamics,
        improve power transfer, reduce fatigue, and ultimately contribute to an
        overall more efficient and enjoyable cycling experience.
      </Typography>
    </>
  );

  const [currentPosition, setCurrentPosition] = useState<string>(
    "Comfortable - Ideal",
  );

  const comfortableStat = useMemo(
    () =>
      positionStatistics?.find(
        (stat) => stat.positionType === "Comfortable - Ideal",
      ),
    [positionStatistics],
  );
  const comfortableNotStat = useMemo(
    () =>
      positionStatistics?.find(
        (stat) => stat.positionType === "Comfortable - Not Ideal",
      ),
    [positionStatistics],
  );

  const ttStat = useMemo(
    () =>
      positionStatistics?.find((stat) => stat.positionType === "TT - Ideal"),
    [positionStatistics],
  );

  const ttNotStat = useMemo(
    () =>
      positionStatistics?.find(
        (stat) => stat.positionType === "TT - Not Ideal",
      ),
    [positionStatistics],
  );

  const standingStat = useMemo(
    () => positionStatistics?.find((stat) => stat.positionType === "Standing"),
    [positionStatistics],
  );

  const aggresiveStat = useMemo(
    () =>
      positionStatistics?.find(
        (stat) => stat.positionType === "Aggressive - Ideal",
      ),
    [positionStatistics],
  );

  const aggresiveNotStat = useMemo(
    () =>
      positionStatistics?.find(
        (stat) => stat.positionType === "Aggressive - Not Ideal",
      ),
    [positionStatistics],
  );

  const lowerThanTtStat = useMemo(
    () =>
      positionStatistics?.find((stat) => stat.positionType === "Lower than TT"),
    [positionStatistics],
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "1.125rem",
          "@media (max-width: 1200px)": {
            flexDirection: "column",
          },
        }}
      >
        {positionStatistics?.length !== 0 ? (
          <Box
            sx={{
              background: "#201B20",
              borderRadius: ".625rem",
              width: "65%",
              "@media (max-width: 1200px)": {
                width: "95%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "2px",
                marginTop: "16px",
                marginLeft: "17px",
              }}
            >
              <Typography sx={{ fontSize: ".8125rem", fontWeight: 400 }}>
                Position Zones
              </Typography>
              <CustomWidthTooltip
                title={positionsTooltip}
                placement="right"
                arrow
              >
                <img src={infoIcon} alt="info_icon" width="10" height="11" />
              </CustomWidthTooltip>
            </Box>
            <Grid container>
              <Grid
                item
                width={"58%"}
                sx={{
                  "@media (max-width: 959px)": {
                    width: "100%",
                  },
                }}
              >
                <>
                  {!!positionStatistics && (
                    <>
                      <PositionDonut
                        positionStatistics={positionStatistics}
                        onPositionHoverChange={setCurrentPosition}
                      />
                    </>
                  )}
                </>
              </Grid>
              <Grid item width={"5%"}>
                <Divider
                  orientation="horizontal"
                  sx={{
                    background: "#64605D",
                    height: { xl: "850px", md: "660px" },
                    width: "1px",
                    marginLeft: "40px",
                    "@media (max-width: 959px)": {
                      display: "none", // Full width on screens with max-width 950px
                    },
                  }}
                />
              </Grid>

              <Grid
                item
                width={"37%"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "@media (max-width: 959px)": {
                    width: "100%",
                  },
                }}
              >
                {currentPosition
                  .toLowerCase()
                  .includes("comfortable - ideal") &&
                  comfortableStat && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        "@media (max-width: 959px)": {
                          marginBottom: "2rem",
                        },
                      }}
                    >
                      <img
                        // session.athlete_bikes.bike_types
                        src={bikeType === "Road" ? ComfortableRR : TT_Comf}
                        width={isMobileScreen ? "221px" : "336px"}
                        height={"400px"}
                      />
                      <Typography
                        sx={{
                          fontSize: { xl: "1.375rem", md: "1.25rem" },
                          color: allColors[currentPosition],
                        }}
                      >
                        {(comfortableStat.position_percentage * 100).toFixed(1)}
                        %
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xl: ".9375rem", md: ".8125rem" } }}
                      >
                        Comfortable Ideal
                      </Typography>
                    </Box>
                  )}
                {currentPosition
                  .toLowerCase()
                  .includes("comfortable - not ideal") &&
                  comfortableNotStat && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        "@media (max-width: 959px)": {
                          marginBottom: "2rem",
                        },
                      }}
                    >
                      <img
                        src={bikeType === "Road" ? ComfortableRR : TT_Comf}
                        width={isMobileScreen ? "221px" : "336px"}
                        height={"400px"}
                      />
                      <Typography
                        sx={{
                          fontSize: { xl: "1.375rem", md: "1.25rem" },
                          color: allColors[currentPosition],
                          fontWeight: 700,
                        }}
                      >
                        {(comfortableNotStat.position_percentage * 100).toFixed(
                          1,
                        )}
                        %
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xl: ".9375rem", md: ".8125rem" } }}
                      >
                        Comfortable - Not Ideal
                      </Typography>
                    </Box>
                  )}
                {currentPosition.toLowerCase().includes("tt - ideal") &&
                  ttStat && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        "@media (max-width: 959px)": {
                          marginBottom: "2rem",
                        },
                      }}
                    >
                      <img
                        src={bikeType === "Road" ? TTRR : TT_Aero}
                        width={isMobileScreen ? "220px" : "336px"}
                        height={"400px"}
                      />
                      <Typography
                        sx={{
                          fontSize: { xl: "1.375rem", md: "1.25rem" },
                          color: allColors[currentPosition],
                          fontWeight: 700,
                        }}
                      >
                        {(ttStat.position_percentage * 100).toFixed(1)}%
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xl: ".9375rem", md: ".8125rem" } }}
                      >
                        TT - Ideal
                      </Typography>
                    </Box>
                  )}
                {currentPosition.toLowerCase().includes("tt - not ideal") &&
                  ttNotStat && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        "@media (max-width: 959px)": {
                          marginBottom: "2rem",
                        },
                      }}
                    >
                      <img
                        src={bikeType === "Road" ? TTRR : TT_Aero}
                        width={isMobileScreen ? "220px" : "336px"}
                        height={"400px"}
                      />
                      <Typography
                        sx={{
                          fontSize: { xl: "1.375rem", md: "1.25rem" },
                          color: allColors[currentPosition],
                          fontWeight: 700,
                        }}
                      >
                        {(ttNotStat.position_percentage * 100).toFixed(1)}%
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xl: ".9375rem", md: ".8125rem" } }}
                      >
                        TT - Not Ideal
                      </Typography>
                    </Box>
                  )}
                {currentPosition.toLowerCase().includes("standing") &&
                  standingStat && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        "@media (max-width: 959px)": {
                          marginBottom: "2rem",
                        },
                      }}
                    >
                      <img
                        src={bikeType === "Road" ? StandingRR : TT_Standing}
                        width={isMobileScreen ? "220px" : "336px"}
                        height={"400px"}
                      />
                      <Typography
                        sx={{
                          fontSize: { xl: "1.375rem", md: "1.25rem" },
                          color: allColors[currentPosition],
                          fontWeight: 700,
                        }}
                      >
                        {(standingStat.position_percentage * 100).toFixed(1)}%
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xl: ".9375rem", md: ".8125rem" } }}
                      >
                        Standing
                      </Typography>
                    </Box>
                  )}

                {currentPosition.toLowerCase().includes("aggressive - ideal") &&
                  aggresiveStat && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        "@media (max-width: 959px)": {
                          marginBottom: "2rem",
                        },
                      }}
                    >
                      <img
                        src={AggressiveRR}
                        width={isMobileScreen ? "220px" : "336px"}
                        height={"400px"}
                      />
                      <Typography
                        sx={{
                          fontSize: { xl: "1.375rem", md: "1.25rem" },
                          color: allColors[currentPosition],
                          fontWeight: 700,
                        }}
                      >
                        {(aggresiveStat.position_percentage * 100).toFixed(1)}%
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xl: ".9375rem", md: ".8125rem" } }}
                      >
                        Aggresive - Ideal
                      </Typography>
                    </Box>
                  )}
                {currentPosition
                  .toLowerCase()
                  .includes("aggressive - not ideal") &&
                  aggresiveNotStat && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        "@media (max-width: 959px)": {
                          marginBottom: "2rem",
                        },
                      }}
                    >
                      <img
                        src={AggressiveRR}
                        width={isMobileScreen ? "220px" : "336px"}
                        height={"400px"}
                      />
                      <Typography
                        sx={{
                          fontSize: { xl: "1.375rem", md: "1.25rem" },
                          color: allColors[currentPosition],
                          fontWeight: 700,
                        }}
                      >
                        {(aggresiveNotStat.position_percentage * 100).toFixed(
                          1,
                        )}
                        %
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xl: ".9375rem", md: ".8125rem" } }}
                      >
                        Aggresive - Not Ideal
                      </Typography>
                    </Box>
                  )}
                {currentPosition.toLowerCase().includes("lower than tt") &&
                  lowerThanTtStat && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        "@media (max-width: 959px)": {
                          marginBottom: "2rem",
                        },
                      }}
                    >
                      <img
                        src={bikeType === "Road" ? TTRR : TT_Aero}
                        width={isMobileScreen ? "220px" : "336px"}
                        height={"400px"}
                      />
                      <Typography
                        sx={{
                          fontSize: { xl: "1.375rem", md: "1.25rem" },
                          color: allColors[currentPosition],
                          fontWeight: 700,
                        }}
                      >
                        {(lowerThanTtStat.position_percentage * 100).toFixed(1)}
                        %
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xl: ".9375rem", md: ".8125rem" } }}
                      >
                        Lower Than TT
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              width: { xs: "95%", lg: "65%" },
              display: { xs: "none", md: "flex" },
              overflow: "hidden",
            }}
          >
            <Box>
              <img src={BlurZones} style={{ width: "100%" }} />
            </Box>
          </Box>
        )}

        <Box
          sx={{
            background: "#201B20",
            borderRadius: ".625rem",
            width: "30%",
            "@media (max-width: 1200px)": {
              width: "95%",
            },
          }}
        >
          <Box
            sx={{
              marginLeft: "17px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "2px",
                marginTop: "16px",
                marginBottom: "10px",
              }}
            >
              <Typography sx={{ fontSize: ".8125rem", fontWeight: 400 }}>
                Power Zones (W)
              </Typography>
              <CustomWidthTooltip title={powerTooltip} placement="right" arrow>
                <img src={infoIcon} alt="info_icon" width="10" height="11" />
              </CustomWidthTooltip>
            </Box>
            {powerZones.length > 0 && hasPower === true ? (
              <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
                <ZonesComponent zones={powerZones} units="W" />
              </Box>
            ) : (
              <Box>
                <Typography sx={{ textAlign: "center" }}>
                  No power zones data
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
                  <ZonesComponent zones={powerZones} units="W" />
                </Box>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                gap: "2px",
                marginTop: {
                  lg: hasHeartRate === true ? "45px" : "20px",
                  xl: "10px",
                  sm: "20px",
                  xs: "30px",
                },
              }}
            >
              <Typography sx={{ fontSize: ".8125rem", fontWeight: 400 }}>
                Heart Rate Zones (BPM)
              </Typography>
              <CustomWidthTooltip
                title={heartrateTooltip}
                placement="right"
                arrow
              >
                <img src={infoIcon} alt="info_icon" width="10" height="11" />
              </CustomWidthTooltip>
            </Box>
            {heartRateZones.length > 0 && hasHeartRate === true ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  marginBottom: "10px",
                }}
              >
                <ZonesComponent zones={heartRateZones} units="bpm" />
              </Box>
            ) : (
              <Box>
                <Typography sx={{ textAlign: "center" }}>
                  No heart rate zones data
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    marginBottom: "10px",
                  }}
                >
                  <ZonesComponent zones={heartRateZones} units="bpm" />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <CoachNotesComponent sessionId={session.id}/>
      </Box>
    </>
  );
}
