import { Grid } from "@mui/material";
import SessionsDataGrid from "../customComponents/SessionsDataGrid";

export default function SessionsView() {
  return (
    <>
      <Grid item xs={12} padding={"25px"}>
        <SessionsDataGrid />
      </Grid>
    </>
  );
}
