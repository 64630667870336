import {
    DndContext,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
  } from "@dnd-kit/core";
  import DragOverlayWrapper from "./DnD/DragOverlayWrapper";
  import DesignerContextProvider from "./DnD/DesignerContext";
import { useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import SuspenseLoader from "src/components/SuspenseLoader";
import AssignedDesigner from "./DnD/AssignedDesigner";
  
  export default function AssignedWorkoutBuilder() {
    const {assignedWorkoutId} = useParams()
    const supabase = useSupabase();
    const mouseSensor = useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10, // Enable sort function when dragging 10px   💡 here!!!
      },
    });
    const touchSensor = useSensor(TouchSensor,{
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    });    //const keyboardSensor = useSensor(KeyboardSensor);
    const sensors = useSensors(mouseSensor, touchSensor);
  
    const assignedWorkoutPromise = useCallback(
      () =>
        supabase
          .from("assigned_workout")
          .select("*,assigned_workout_step(*)")
          .eq("id", assignedWorkoutId)
          .order('order', {ascending: true, foreignTable:"assigned_workout_step"})
          .single()
      ,[supabase],
    );
  
    const assignedWorkoutCall = useAsyncState<SupabaseCall<typeof assignedWorkoutPromise>>(); // prettier-ignore
  
    useEffect(() => {
      if (isUnloaded(assignedWorkoutCall)) {
        assignedWorkoutCall.fire(async () => assignedWorkoutPromise());
      }
    }, [supabase, assignedWorkoutCall]);
    
    return (
      <div>
        <DesignerContextProvider>
          <DndContext sensors={sensors}>
              {
                isFulfilled(assignedWorkoutCall) 
                ? <AssignedDesigner assignedWorkout={assignedWorkoutCall.result?.data}/>
                : <SuspenseLoader color="#DD4F4A"/>
              }
            <DragOverlayWrapper />
          </DndContext>
        </DesignerContextProvider>
      </div>
    );
  }