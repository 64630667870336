import { Box, Button, TextareaAutosize, Typography } from "@mui/material";
import { useState } from "react";

export default function CoachNotesComponent({formik}) {
  const [isEditing, setIsEditing] = useState(false);

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid #64605D",
          height: "120px",
          width: "100%",
          borderRadius: "4px",
          padding: "1em",
        }}
      >
        <Box>
          <Typography fontSize={"14px"} color={"#BDBCBE"} marginBottom={1}>
            Notes
          </Typography>

          {isEditing ? (
            <TextareaAutosize
              name="notes"
              autoFocus
              value={formik.values.notes}
              onBlur={handleBlur}
              onChange={formik.handleChange}
              style={{
                width: "98%",
                height: "60px",
                background: "#3D343E",
                color: "#F7F3F0",
                borderRadius: "10px",
                paddingLeft: "1em",
                paddingTop: "0.5em",
              }}
            />
          ) : (
            <Typography onClick={() => setIsEditing(true)} color={"#BDBCBE"}>
              {formik.values.notes || "Click here to add notes..."}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}