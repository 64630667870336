import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAsyncState, {
  AsyncState,
  isFulfilled,
  isRejected,
  isUnloaded,
} from "./Async";
import im from "immutable";
import SuspenseLoader from "src/components/SuspenseLoader";
import { useSupabase } from "src/contexts/SupabaseContext";

type AsyncStateMap = im.Map<string, AsyncState<any>>;

export const AsyncStatesContext =
  createContext<React.Dispatch<React.SetStateAction<AsyncStateMap>>>(undefined);

const LoaderComponent = (props: {
  name: string;
  promise: (supabase: ReturnType<typeof useSupabase>) => PromiseLike<any>;
}) => {
  const setAllAsyncStatesMap = useContext(AsyncStatesContext);
  const supabase = useSupabase();
  const asyncState = useAsyncState<any>();

  useEffect(() => {
    if (isUnloaded(asyncState)) {
      asyncState.fire(async () => props.promise(supabase));
    }
  }, [asyncState, props.promise]);

  useEffect(() => {
    setAllAsyncStatesMap((asyncStateMap) =>
      asyncStateMap.set(props.name, asyncState),
    );
  }, [asyncState]);

  return <></>;
};

export type PreloadComponentProps<T extends Object> = {
  promises: {
    [K in keyof T]: (
      supabase: ReturnType<typeof useSupabase>,
    ) => PromiseLike<T[keyof T]>;
  };
  component: (fulfilledProps: T) => JSX.Element;
  rejectComponent?: (error: any) => JSX.Element;
};

const PreloadComponent = <T extends Object>(
  props: PreloadComponentProps<T>,
) => {
  const [asyncStateMap, setAsyncStateMap] = useState(
    im.Map<string, AsyncState<T[keyof T]>>(),
  );

  const promiseEntries = useMemo(
    () => Object.entries(props.promises),
    [props.promises],
  );

  const allFulfilled = useMemo(
    () =>
      asyncStateMap.size > 0 &&
      asyncStateMap.every((asyncState) => isFulfilled(asyncState)),
    [asyncStateMap],
  );

  const someRejected: false | Error = useMemo(() => {
    const rejected = asyncStateMap.find((asyncState) => isRejected(asyncState));
    if (!!rejected) {
      return isRejected(rejected) && rejected.error;
    }
    return false;
  }, [asyncStateMap]);

  return (
    <AsyncStatesContext.Provider value={setAsyncStateMap}>
      {allFulfilled ? (
        props.component(
          Object.fromEntries(
            asyncStateMap
              .toArray()
              .map(([name, asyncState]) => [
                name,
                isFulfilled(asyncState) && asyncState.result,
              ]),
          ) as unknown as T,
        )
      ) : someRejected !== false ? (
        !!props.rejectComponent ? (
          props.rejectComponent(someRejected)
        ) : (
          <></>
        )
      ) : (
        <>
          {promiseEntries.map(([name, promise]) => (
            <LoaderComponent name={name} promise={promise} key={name} />
          ))}
          <SuspenseLoader />
        </>
      )}
    </AsyncStatesContext.Provider>
  );
};

export default PreloadComponent;
