import { Grid, Typography } from "@mui/material";
import NoChange from "../../../assets/img/views/dashboard-widgets/NoChange.svg";

export default function TrendsWidget() {
  const data = [
    {
      title: "Position Score",
      value: `- %`,
      icon: <img src={NoChange} alt="trendup-icon" width={"20px"} />,
    },
    {
      title: "Efficiency Factor",
      value: "-",
      icon: <img src={NoChange} alt="trendup-icon" width={"20px"} />,
    },
    {
      title: "Effort Score",
      value: "-",
      icon: <img src={NoChange} alt="trendup-icon" width={"20px"} />,
    },
    {
      title: "Speed efficiency",
      value: "-",
      icon: <img src={NoChange} alt="trendup-icon" width={"20px"} />,
    },
    {
      title: "Normalised W®",
      value: `- W`,
      icon: <img src={NoChange} alt="trendup-icon" width={"20px"} />,
    },
    {
      title: "TSS®",
      value: "-",
      icon: <img src={NoChange} alt="trendup-icon" width={"20px"} />,
    },
  ];
  return (
    <Grid
      container
      spacing={2}
      marginTop={1}
      marginBottom={3}
      height={{ xl: "270px", sm: "221px", xs: "350px" }}
    >
      {data.map((item, index) => (
        <Grid item  xs={6} sm={6} key={index}>
          <Grid container spacing={2} alignItems="center" marginBottom={2}>
            <Grid item>{item.icon}</Grid>
            <Grid
              item
              xs={12}
              sm
              container
              direction="column"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography fontSize={{ xs: ".6875rem", xl: ".8125rem" }}>
                  {item.title}
                </Typography>
              </Grid>
              <Grid item fontSize={".9375rem"} color={"#BC6D29"}>
                <Typography> </Typography>
                {item.value}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
