import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, MenuItem, Select, Tab } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import CoachPerformanceChart from "./CoachPerformanceChart";
import CoachPositionsCharts from "./CoachPositionsCharts";
import CoachPersonalRecordsView from "./CoachPersonalRecordsView";
import CoachTrendsView from "./CoachTrendsView";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import CoachAthleteSettingsPreload from "./CoachAthleteSettingsView";
import { WithCache } from "src/contexts/CacheContext";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import CoachCalendar from "./CoachCalendar/CoachMyAthletesCalendar.tsx";
import { useTitleCoach } from "../Dashboard/CoachDashboardLayout";
import AssignedWorkoutBuilder from "../Library/Workouts/AssignedWorkoutBuilder";

type TabValue =
  | "overview"
  | "information"
  | "calendar"
  | "trends"
  | "training_plans";

export type CoachAthleteTabViewProps = {
  tab?: TabValue;
};

export default function CoachAthleteTabView(props: CoachAthleteTabViewProps) {
  const params = useParams<{ userId: string; assignedWorkoutId: string }>();
  const navigate = useNavigate();
  const supabase = useSupabase();

  const athletePromise = useCallback(
    () =>
      supabase
        .from("athlete")
        .select("name")
        .eq("user_id", params.userId)
        .then((res) => res.data[0]),
    [supabase],
  );

  const storedAthleteCall =
    useAsyncState<SupabaseCall<typeof athletePromise>>();

  useEffect(() => {
    if (isUnloaded(storedAthleteCall)) {
      storedAthleteCall.fire(async () => athletePromise());
    }
  }, [storedAthleteCall, athletePromise]);

  const handleChange = useCallback(
    (_, newValue: TabValue) => {
      navigate(`/coach/my_athletes/${params.userId}/${newValue}`);
    },
    [params],
  );

  //Overview Component Swapping
  const [selectedComponent, setSelectedComponent] = useState(
    "PerformanceProgress",
  );

  const handleComponentChange = (e) => {
    setSelectedComponent(e.target.value);
  };

  const componentMapping = {
    PerformanceProgress: <CoachPerformanceChart userId={params.userId} />,
    PositionsProgress: <CoachPositionsCharts userId={params.userId} />,
    PersonalRecordzView: <CoachPersonalRecordsView userId={params.userId} />,
  };

  const [, setTitle] = useTitleCoach();
  useEffect(() => {
    if (isFulfilled(storedAthleteCall)) setTitle(storedAthleteCall.result.name);
  }, [storedAthleteCall]);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          paddingTop: "10px",
          paddingLeft: "25px",
          paddingBottom: "20px",
        }}
      >
        <TabContext value={props.tab}>
          <Box
            sx={{
              borderColor: "divider",
              display: "flex",
              paddingRight: "20px",
              justifyContent: "space-between",
            }}
          >
            <TabList
              onChange={handleChange}
              variant="scrollable"
              sx={{
                "& .MuiTab-root:not(.Mui-selected)": {
                  opacity: 0.5,
                },
                minHeight: "1.5rem",
                "& .Mui-selected": {
                  color: "#DD4F4A !important",
                },
              }}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "#DD4F4A",
                },
              }}
            >
              <Tab
                label="Overview"
                value="overview"
                sx={{
                  minHeight: 0,
                  padding: "10px",
                }}
              />
              <Tab
                label="Information"
                value="information"
                sx={{ minHeight: 0, padding: "10px" }}
              />
              <Tab
                label="Calendar"
                value="calendar"
                sx={{ minHeight: 0, padding: "10px" }}
              />
              <Tab
                label="Training Plans"
                value="training_plans"
                sx={{ minHeight: 0, padding: "10px" }}
              />
              <Tab
                label="Trends"
                value="trends"
                sx={{ minHeight: 0, padding: "10px" }}
              />
            </TabList>
            {props.tab === "overview" && (
              <Select
                value={selectedComponent}
                onChange={handleComponentChange}
                sx={{
                  color: "#DD4F4A",
                  "& .MuiSvgIcon-root": {
                    color: "#DD4F4A",
                  },
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#DD4F4A",
                    },
                    "&:hover fieldset": {
                      borderColor: "#DD4F4A",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#DD4F4A",
                    },
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      border: "1px solid #DD4F4A",
                    },
                  },
                  sx: {
                    "&& .Mui-selected": {
                      backgroundColor: "rgba(221, 79, 74, 0.5)",
                    },
                  },
                  MenuListProps: {
                    disablePadding: true,
                  },
                }}
              >
                <MenuItem value="PerformanceProgress">
                  Performance Chart
                </MenuItem>
                <MenuItem value="PositionsProgress">Positions Charts</MenuItem>
                <MenuItem value="PersonalRecordzView">
                  Personal Records
                </MenuItem>
              </Select>
            )}
          </Box>
          <TabPanel
            value="overview"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            {componentMapping[selectedComponent]}
          </TabPanel>

          <TabPanel
            value="information"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <WithCache
              tables={[
                "athlete",
                "training_goals",
                "athlete_training_goals",
                "athlete_experience_level",
              ]}
              component={(full) => (
                <CoachAthleteSettingsPreload
                  userId={params.userId}
                  experienceLevels={full.athlete_experience_level}
                  trainingGoals={full.training_goals}
                />
              )}
            />
          </TabPanel>
          <TabPanel
            value="calendar"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            {params.assignedWorkoutId ? (
              <AssignedWorkoutBuilder />
            ) : (
              <CoachCalendar userId={params.userId} />
            )}
          </TabPanel>
          <TabPanel
            value="training_plans"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <Outlet />
          </TabPanel>
          <TabPanel
            value="trends"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <CoachTrendsView userId={params.userId} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
