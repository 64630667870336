import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled } from "src/utils/Async";
import * as yup from "yup";
import ResetPasswordConfirmationView from "./ResetPasswordConfirmationView";

export default function ResetPasswordView() {
  const supabase = useSupabase();

  const formik = useFormik<{
    email: string;
  }>({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().email("Please enter a valid email address"),
    }),
    onSubmit: (values) => {
      if ("fire" in resetPasswordCall) {
        resetPasswordCall.fire(async () => resetPasswordPromise(values.email));
      }
    },
  });

  const resetPasswordPromise = useCallback(
    (email: string) =>
      supabase.functions.invoke("reset-password", {
        body: JSON.stringify({ email }),
      }),
    [supabase],
  );

  const resetPasswordCall = useAsyncState<any>();

  return (
    <>
      {isFulfilled(resetPasswordCall) ? (
        <ResetPasswordConfirmationView />
      ) : (
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              pt: "13.6875rem",
              "@media (max-width: 400px)": {
                alignItems: "flex-start",
              },
            }}
          >
            <Box
              sx={{
                background: "#2A252B",
                width: "47.1875rem",
                height: "24.5625rem",
                borderRadius: "1.25rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h1" color="text.secondary" mt="6rem">
                Reset Your Password
              </Typography>
              <Typography
                variant="body2"
                fontSize={14}
                mt={".8125rem"}
                mb={".9375rem"}
                textAlign={"center"}
                lineHeight={"20px"}
              >
                Enter the email address associated with your <br /> account to
                receive a password reset link.
              </Typography>
              <TextField
                placeholder="Email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Button
                variant="contained"
                size="small"
                sx={{
                  width: "11.6875rem",
                  mt: "1.5rem",
                  mb: "1.5rem",
                  height: "1.875rem",
                }}
                type="submit"
              >
                <Typography variant="h5">Submit</Typography>
              </Button>
              <Typography
                component={Link}
                to={`/account/login/`}
                fontSize={12}
                sx={{ color: "#BC6D29" }}
                variant="h5"
              >
                Sign In here
              </Typography>
            </Box>
          </Box>
        </form>
      )}
    </>
  );
}
