import { Box, Button, Typography } from "@mui/material";

import PlanCover1 from "../../../../assets/img/layout/coach/Library/Plans/PlanCover1.svg";
import { useNavigate } from "react-router-dom";

export default function PlanComponent({trainingPlan}) {
  const navigate = useNavigate();
  const totalWeeks = Math.ceil((trainingPlan.training_plan_step[trainingPlan.training_plan_step.length - 1]?.day ?? 0) / 7);
  const workoutsPerWeek = (trainingPlan.training_plan_step.length / totalWeeks) || 0;
  const imageBackground = trainingPlan.image !== "" ? `${process.env.REACT_APP_SUPABASE_DOMAIN}/storage/v1/object/public/training-plan-images/${trainingPlan.image}` : `${PlanCover1}`;

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: { xl: 350, xs: 260 },
          height: { xl: 280, xs: 170 },
          padding: "1em",
          borderRadius: "6px",
          backgroundImage: `url('${imageBackground}')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginBottom: "0.5rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body2">{/* ${trainingPlan.price} */}</Typography>
          <Typography variant="body2">{trainingPlan.ride_type}</Typography>
        </Box>
        <Box>
          <Typography variant="h5">{trainingPlan.name}</Typography>
          <Typography variant="subtitle2">{trainingPlan.difficulty}</Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body2" color={"#DD4F4A"}>
          {totalWeeks} Weeks
        </Typography>
        <Typography variant="body2" color={"#DD4F4A"}>
          {workoutsPerWeek.toFixed(1)} Workouts/Week
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginY: "1rem",
        }}
      >
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ textDecoration: "underline", lineHeight: 1 }}
          >
            Required
          </Typography>
          <Typography sx={{textTransform: "capitalize"}} variant="subtitle2">
            {
              trainingPlan.sensors.toString()
            }
          </Typography>
        </Box>
        <Button onClick={() => navigate(`edit_plan/${trainingPlan.id}`)} variant="coachVariant2" sx={{ width: "80px", height: "30px" }}>
          <Typography>MANAGE</Typography>
        </Button>
      </Box>
    </Box>
  );
}