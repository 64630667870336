import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
  } from "@dnd-kit/core";
  import Designer from "./DnD/Designer";
  import DragOverlayWrapper from "./DnD/DragOverlayWrapper";
  import DesignerContextProvider from "./DnD/DesignerContext";
import { useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import useAsyncState, { isFulfilled, isSettled, isUnloaded } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import SuspenseLoader from "src/components/SuspenseLoader";
  
  export default function WorkoutBuilder() {
    const {workoutId} = useParams()
    const supabase = useSupabase();
    const mouseSensor = useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10, // Enable sort function when dragging 10px   💡 here!!!
      },
    });
    const touchSensor = useSensor(TouchSensor,{
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    });
    //const keyboardSensor = useSensor(KeyboardSensor);
    const sensors = useSensors(mouseSensor, touchSensor);
  
    const workoutsPromise = useCallback(
      () =>
        workoutId && supabase
          .from("workout")
          .select("*,workout_step(*)")
          .eq("id", workoutId)
          .order('order', {ascending: true, foreignTable:"workout_step"})
          .single()
      ,[supabase],
    );
  
    const workoutsCall =
    useAsyncState<SupabaseCall<typeof workoutsPromise>>(); // prettier-ignore
  
    useEffect(() => {
      if (isUnloaded(workoutsCall)) {
        workoutsCall.fire(async () => workoutsPromise());
      }
    }, [supabase, workoutsCall]);
    
    return (
      <div>
        <DesignerContextProvider>
          <DndContext sensors={sensors}>
              {
                isFulfilled(workoutsCall) 
                ? <Designer workout={workoutsCall.result?.data}/>
                : <SuspenseLoader color="#DD4F4A"/>
              }
            <DragOverlayWrapper />
          </DndContext>
        </DesignerContextProvider>
      </div>
    );
  }