import { useState, useRef, useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import { Grid, Box, styled } from "@mui/material";

import type { View } from "src/models/calendar";
import { Database } from "src/utils/DatabaseDefinitions";
import cyclistSession from "../../../assets/img/views/calendar/cyclistIconSession.svg";
import noData from "../../../assets/img/views/calendar/noData.svg";
import "../../../assets/scss/components/CalendarWidget.scss";

import moment from "moment";
import dayjs from "dayjs";
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
const FullCalendarWrapper = styled(Box)(
  ({ theme }) => `

  padding-bottom : ${theme.spacing(1)};

  & .fc-license-message {
    display: none;
  }
  .fc {

    --fc-page-bg-color: none;

    .fc-col-header-cell {
      padding: ${theme.spacing(1)};
      background: none;
      border: none !important;
    }

    .fc-scrollgrid {
      border:none !important;
    }

    .fc-scroller {
      overflow-y: hidden !important;
   }

    .fc-scrollgrid-section-header {
      border: none;
    }

    &.fc-theme-standard td, 
    &.fc-theme-standard th,
    &.fc-theme-standard .fc-list {
      border : none !important
    }

    .fc-col-header {
      background : #201B20;
    }

    .fc-daygrid-day.fc-day-other {
      opacity: 0.5;
    }

    .fc-daygrid-day-frame {
      border: 1px solid #64605D;
      height: 170px;
    }

    .fc-daygrid-day-top {
      display : flex;
      flex-direction : row !important;
      justify-content: center;
      align-items: center;
    }

    .fc-cell-shaded,
    .fc-list-day-cushion {
      background: ${theme.colors.alpha.black[5]};
    }

    .fc-list-event-graphic {
      padding-right: ${theme.spacing(1)};
    }

    .fc-event {
      cursor : pointer;
    }

    .fc-event-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #E3DFDC;
      font-family: 'DejaVu Sans';
      font-size: 10px;
      margin-bottom:10px;
    }

    .fc-popover,
    .fc-more-popover {
      background-color : #201B20 !important;
      box-shadow : none !important;
    }

    .fc-list-event:hover td,
    td.fc-daygrid-day.fc-day-today {
      background-color: ${theme.colors.primary.lighter};
    }

    td.fc-daygrid-day:hover,
    .fc-highlight {
      background: ${theme.colors.alpha.black[10]};
    }
    
    .fc-daygrid-dot-event:hover, 
    .fc-daygrid-dot-event.fc-event-mirror {
      background: ${theme.colors.primary.lighter};
    }

    .fc-daygrid-day-number {
      padding: ${theme.spacing(1)};
      font-weight: bold;
    }

    .fc-list-sticky .fc-list-day > * {
      background: ${theme.colors.alpha.black[5]} !important;
    }

    .fc-cell-shaded, 
    .fc-list-day-cushion {
      background: ${theme.colors.alpha.black[10]} !important;
      color: ${theme.colors.alpha.black[70]} !important;
    }

    .fc-col-header-cell-cushion {
      color : #64605D;
    }

    .fc-more-link {
      display: none !important;
    }
`,
);

export type Dates = {
  date_session: Pick<
    Database["public"]["Tables"]["session"]["Row"],
    "date" | "recording_name" | "total_time" | "total_distance" | "tss"
  >[];
  startOfWeek: dayjs.Dayjs;
  endOfWeek: dayjs.Dayjs;
};

export default function CalendarWidget(props: Dates) {
  const calendarRef = useRef<FullCalendar | null>(null);
  const [date, setDate] = useState<Date>(new Date());
  const [view, setView] = useState<View>("dayGridWeek");
  const [currentDate, setCurrentDay] = useState(props.startOfWeek);
  const [mySessions, setSessions] = useState({});
  const units = useUnits();
  const filterSessions: {
    date: Date;
    total_distance: number;
    total_time: number;
    tss: number;
  }[] = useMemo(() => {
    props.date_session.forEach((session) => {
      if (dayjs(session.date).isSame(currentDate, "day")) {
        if (!mySessions[currentDate.format("YYYY-MM-DD")]) {
          // If not, initialize it with the current session data
          mySessions[currentDate.format("YYYY-MM-DD")] = {
            date: currentDate.format("YYYY-MM-DD"),
            total_time: session.total_time,
            total_distance: session.total_distance,
            tss: session.tss,
          };
        } else {
          // If it exists, add the current session's values to the existing aggregated data
          mySessions[currentDate.format("YYYY-MM-DD")].total_time +=
            session.total_time;
          mySessions[currentDate.format("YYYY-MM-DD")].total_distance +=
            session.total_distance;
          mySessions[currentDate.format("YYYY-MM-DD")].tss += session.tss;
        }
      }
    });
    const aggregatedSessionsArray = Object.values(mySessions);
    if (currentDate.diff(props.endOfWeek, "day") !== 0)
      setCurrentDay(currentDate.add(1, "day"));
    return aggregatedSessionsArray;
  }, [currentDate]) as any;

  const eventDates = useMemo(
    () =>
      filterSessions.map((data) => {
        return {
          date: new Date(data.date),
          extendedProps: {
            distance: data.total_distance ?? 0,
            total_time: data.total_time ?? 0,
            tss: data.tss?.toFixed(0) ?? 0,
          },
        };
      }),
    [filterSessions],
  );

  function formatSecondsToHHMM(seconds) {
    const val = Number(seconds);
    const hours = Math.floor(Math.floor(val / 60) / 60);
    const minutes = Math.trunc((val % 3600) / 60);
    const formattedTime = `${hours.toString().padStart(2, "0")}h${minutes
      .toString()
      .padStart(2, "0")}’`;
    return formattedTime;
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Grid item xs={12} sx={{ marginX: "10px" }}>
            <FullCalendarWrapper height={{ xs: "280px", xl: "355px" }}>
              <FullCalendar
                allDayMaintainDuration
                initialDate={date}
                initialView={view}
                eventDisplay="block"
                eventColor="transparent"
                events={eventDates}
                dayHeaderFormat={({ date }) => {
                  return moment(date).format("ddd");
                }}
                dayCellContent={(arg) => {
                  if (arg.view.type === "dayGridWeek") {
                    const dayOfMonth = arg.date.getDate();
                    const dayOfWeek = arg.date.getDay(); // 0 (Sunday) to 6 (Saturday)
                    const monthName = arg.date

                      .toLocaleString("default", { month: "short" })
                      .slice(0, 3);
                    const dayStyles = {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor:
                        dayjs(date).day() === 0
                          ? "#36B37E"
                          : 0 < dayOfWeek && dayOfWeek <= dayjs(date).day()
                          ? "#36B37E"
                          : "#DD4F4A",
                    };

                    // Check if the day has events
                    const hasEvents = eventDates.some((event) => {
                      const eventDate = new Date(event.date);
                      return (
                        eventDate.getDate() === arg.date.getDate() &&
                        eventDate.getMonth() === arg.date.getMonth() &&
                        eventDate.getFullYear() === arg.date.getFullYear()
                      );
                    });

                    if (!hasEvents) {
                      return (
                        <>
                          <Box style={dayStyles}>
                            <Box className="day-number">{dayOfMonth}</Box>
                          </Box>
                          <Box style={{ paddingTop: "10px" }}>
                            <Box className="month-name">{monthName}</Box>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              marginTop: "27px",
                            }}
                          >
                            <img src={noData} width={"30px"} />
                            <Box
                              sx={{
                                color: "#E3DFDC",
                                fontSize: "8px",
                                marginTop: { xs: "18px", xl: "40px" },
                              }}
                            >
                              ----
                            </Box>
                            <Box sx={{ color: "#E3DFDC", fontSize: "8px" }}>
                              ----
                            </Box>
                            <Box
                              sx={{
                                marginTop: { xs: "15px", xl: "40px" },
                                color: "#64605D",
                                fontSize: { xs: "10px", xl: "15px" },
                              }}
                            >
                              TSS®
                            </Box>
                            <Box
                              sx={{
                                color: "#64605D",
                                fontSize: { xs: "10px", xl: "15px" },
                              }}
                            >
                              -
                            </Box>
                          </Box>
                        </>
                      );
                    }
                    return (
                      <>
                        <Box style={dayStyles}>
                          <Box className="day-number">{dayOfMonth}</Box>
                        </Box>
                        <Box
                          className="month-name"
                          style={{ paddingTop: "10px" }}
                        >
                          {monthName}
                        </Box>
                      </>
                    );
                  }
                }}
                eventDidMount={(info) => {
                  const eventTitle = info.el.querySelector(
                    ".fc-event-title",
                  ) as HTMLElement;

                  if (eventTitle) {
                    eventTitle.style.display = "flex";
                    eventTitle.style.flexDirection = "column";
                    eventTitle.style.alignItems = "center";
                    eventTitle.style.justifyContent = "center";

                    const iconElement = document.createElement("img");
                    iconElement.alt = "Cyclist Icon";
                    iconElement.src = cyclistSession;
                    iconElement.style.width = "30px";
                    iconElement.style.marginBottom = "10px";

                    const totalTime = document.createElement("div");
                    totalTime.textContent = formatSecondsToHHMM(
                      info.event.extendedProps.total_time / 1000,
                    );
                    totalTime.classList.add("total-time");

                    const distance = document.createElement("div");
                    distance.textContent = units === "metric"
                    ? `${info.event.extendedProps.distance.toFixed(2) ?? "0"} km`
                    : `${
                      info.event.extendedProps.distance
                          ? convert(info.event.extendedProps.distance)
                              .from("km")
                              .to("mi")
                              .toFixed(2)
                          : "0"
                      } mi`
                    distance.classList.add("distance");

                    const tssLabel = document.createElement("div");
                    tssLabel.textContent = "TSS®";
                    tssLabel.classList.add("tss-label");

                    const tssValue = document.createElement("div");
                    tssValue.textContent = info.event.extendedProps.tss;
                    tssValue.classList.add("tss-value");

                    eventTitle.appendChild(iconElement);
                    eventTitle.appendChild(totalTime);
                    eventTitle.appendChild(distance);
                    eventTitle.appendChild(tssLabel);
                    eventTitle.appendChild(tssValue);
                  }
                }}
                dayMaxEvents={1}
                fixedWeekCount={false}
                displayEventTime={false}
                headerToolbar={false}
                height={"100%"}
                ref={calendarRef}
                rerenderDelay={10}
                weekends
                weekNumberCalculation={"ISO"}
                plugins={[dayGridPlugin]}
              />
            </FullCalendarWrapper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
