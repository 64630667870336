import { useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import SuspenseLoader from "src/components/SuspenseLoader";
import TrainingPlanDesigner from "./TrainingPlanDesigner";
  
  export default function TrainingPlanBuilder() {
    const {trainingPlanId} = useParams()
    const supabase = useSupabase();

    const trainingPlanPromise = useCallback(
        () =>
          trainingPlanId && supabase
            .from("training_plan")
            .select("*,training_plan_step(*, workout(*,workout_step(*)))")
            .eq("id", trainingPlanId)
            .order('day', {ascending: true, foreignTable:"training_plan_step"})
            .single()
        ,[supabase],
      );
    
      const trainingPlanCall =
      useAsyncState<SupabaseCall<typeof trainingPlanPromise>>(); // prettier-ignore
    
      useEffect(() => {
        if (isUnloaded(trainingPlanCall)) {
          trainingPlanCall.fire(async () => trainingPlanPromise());
        }
      }, [supabase, trainingPlanCall]);

    return (
      <div>
        {
            isFulfilled(trainingPlanCall) 
            ? <TrainingPlanDesigner trainingPlan={trainingPlanCall.result?.data}/>
            : <SuspenseLoader color="#DD4F4A"/>
        }
      </div>
    );
  }