import {
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  Typography,
} from "@mui/material";
import { DataGrid, GridValueFormatterParams, type GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar } from "react-date-range";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { timeFormatter, type SupabaseCall } from "src/utils/common";

export type AdminStatsCardProps = {
  label: string;
  icon: JSX.Element;
  promiseName:
    | "active users"
    | "active athletes"
    | "active darefore"
    | "new users"
    | "total logins"
    | "total sessions";
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  allUsers: any;
};

export default function AdminStatsCard({
  label,
  icon,
  promiseName,
  allUsers,
}: AdminStatsCardProps) {
  const supabase = useSupabase();

  const [date, setDate] = useState(dayjs());
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [results, setResults] = useState([]);
  const [open, setOpen] = useState(false);

  const activePromise = useCallback(
    (date: dayjs.Dayjs) =>
      promiseName === "active athletes"
        ? supabase
            .from("session")
            .select("athlete_id")
            .gte("created_at", date.toISOString().split("T")[0])
            .order("athlete_id", { ascending: true })
            .then((res) => {
              const uniqueObjects: Array<{ athlete_id: string }> = [];
              const uniqueAthleteIds = new Set<string>();
              for (const row of res.data) {
                if (!uniqueAthleteIds.has(row.athlete_id)) {
                  uniqueAthleteIds.add(row.athlete_id);
                  uniqueObjects.push(row);
                }
              }
              return uniqueObjects;
            })
        : promiseName === "total sessions"
        ? supabase
            .from("session")
            .select(
              "id,athlete_id,recording_name,date,total_distance,total_time,average_power,average_speed,type_of_ride_id,provider,average_cadence,elevation_gain,athlete_bikes(bike_types(name)),athlete:athlete_id(name)",
            )
            .gte("created_at", date.toISOString().split("T")[0])
            .order("id", { ascending: true })
            .then((res) => res.data)
        : supabase
            .from("session")
            .select("athlete_id,session_statistics(*)")
            .gte("created_at", date.toISOString().split("T")[0])
            .order("athlete_id", { ascending: true })
            .then((res) => {
              const uniqueObjects: Array<{ athlete_id: string }> = [];
              const uniqueAthleteIds = new Set<string>();
              for (const row of res.data) {
                if (
                  !uniqueAthleteIds.has(row.athlete_id) &&
                  row.session_statistics.length > 0
                ) {
                  uniqueAthleteIds.add(row.athlete_id);
                  uniqueObjects.push(row);
                }
              }
              return uniqueObjects;
            }),
    [supabase],
  );

  const activeCall = useAsyncState<SupabaseCall<typeof activePromise>>();

  useEffect(() => {
    if (isFulfilled(activeCall)) {
      if (
        promiseName === "active athletes" ||
        promiseName === "active darefore"
      ) {
        const athleteIdsSet = new Set(
          activeCall.result.map((athlete) => athlete.athlete_id),
        );
        const filteredUsers = allUsers.filter((user) =>
          athleteIdsSet.has(user.id),
        );
        setResults(filteredUsers);
      } else {
        setResults(activeCall.result);
      }
      activeCall.unload();
    }
  }, [activeCall]);

  useEffect(() => {
    if (
      (promiseName === "active athletes" ||
        promiseName === "total sessions" ||
        promiseName === "active darefore") &&
      isUnloaded(activeCall)
    ) {
      activeCall.fire(async () => activePromise(date));
    } else if (promiseName === "active users") {
      setResults(
        allUsers?.filter((user) => user.last_sign_in_at > date.toISOString()),
      );
    } else if (promiseName === "new users") {
      setResults(
        allUsers?.filter((user) => user.created_at > date.toISOString()),
      );
    }
  }, [date]);

  // Users DataGrid
  const columnsUsers: GridColDef[] = [
    {
      field: "name",
      headerName: "Full Name",
      editable: false,
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      width: 300,
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "last_login",
      headerName: "Last Login",
      editable: false,
      width: 200,
      getApplyQuickFilterFn: undefined,
      type: "dateTime",
    },
  ];

  // Sessions DataGrid
  const columnsSessions: GridColDef[] = [
    {
      field: "athlete_name",
      headerName: "Athlete Name",
      editable: false,
      width: 200,
    },
    {
      field: "recording_name",
      headerName: "Recording Name",
      editable: false,
      width: 200,
    },
    {
      field: "date",
      headerName: "Date",
      editable: false,
      width: 200,
      type: "dateTime",
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      valueFormatter: (params: GridValueFormatterParams<any>) =>
        dayjs(params.value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      field: "total_distance",
      headerName: "Distance",
      editable: false,
      width: 200,
    },
    {
      field: "total_time",
      headerName: "Duration",
      editable: false,
      width: 200,
    },
    {
      field: "average_power",
      headerName: "Avg Power",
      editable: false,
      width: 200,
    },
    {
      field: "average_speed",
      headerName: "Avg Speed",
      editable: false,
      width: 200,
    },
    {
      field: "average_cadence",
      headerName: "Avg Cadence",
      editable: false,
      width: 200,
    },
    {
      field: "elevation_gain",
      headerName: "Elevation Gain",
      editable: false,
      width: 200,
    },
    {
      field: "provider",
      headerName: "Provider",
      editable: false,
      width: 200,
    },
    {
      field: "type_of_ride_id",
      headerName: "Type of Ride",
      editable: false,
      width: 200,
    },
    {
      field: "athlete_bikes",
      headerName: "Bike Type",
      editable: false,
      width: 200,
    },
  ];

  const typeOfRideMap = {
    1: "Indoor",
    2: "Outdoor",
    3: "Virtual",
  };

  const rows = useMemo(() => {
    return promiseName === "total sessions"
      ? results.map((res, index) => {
          return {
            id: index + 1,
            athlete_name: res.athlete.name,
            athlete_id: res.athlete_id,
            recording_name: res.recording_name,
            total_distance: `${res.total_distance?.toFixed(0) ?? "-"} km`,
            total_time: timeFormatter((res?.total_time/1000).toString()),
            date: new Date(res.date).toISOString(),
            average_power: `${res.average_power?.toFixed(0) ?? "-"} W`,
            average_speed: `${res.average_speed?.toFixed(0) ?? "-"} km/h`,
            average_cadence: `${res.average_cadence?.toFixed(0) ?? "-"} rpm`,
            elevation_gain: `${res.elevation_gain?.toFixed(0) ?? "-"} m`,
            provider: res.provider,
            type_of_ride_id: typeOfRideMap[res.type_of_ride_id],
            athlete_bikes: res?.athlete_bikes?.bike_types?.name ?? "-",
          };
        })
      : results.map((res, index) => {
          return {
            id: index + 1,
            name: res.user_metadata?.name || ("Unknown" as string),
            email: res?.email as string,
            last_login: new Date(res?.last_sign_in_at) as Date,
          };
        });
  }, [results]);

  return (
    <>
      <Card
        sx={{
          background: "#201B20",
          borderRadius: "10px",
          minHeight: { xs: "100px", xl: "150px" },
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5" sx={{ color: "#BC6D29" }}>
            {label}
          </Typography>
          <Box mt={2}>{icon}</Box>
          <Box mt={2}>
            <Typography
              variant="h5"
              sx={{
                color: "#BC6D29",
                fontSize: {
                  xs: ".875rem",
                  xl: "1.125rem",
                },
                textAlign: "center",
              }}
            >
              {results?.toString().includes("-") ? "-" : results?.length}
            </Typography>
            <Typography variant="body2" sx={{ color: "#64605D", mt: 1 }}>
              {`${label} since ${date.format("DD/MM/YYYY")}`}
            </Typography>
          </Box>
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setShowCalendar((prev) => !prev);
              }}
            >
              {showCalendar ? "Close Calendar" : "Select Start Date"}
            </Button>
            {showCalendar && (
              <Calendar
                onChange={(date) => {
                  setDate(dayjs(date));
                  setShowCalendar(false);
                }}
                date={date.toDate()}
                maxDate={new Date()}
              />
            )}
          </Box>

          <>
            <Button sx={{ mt: 2 }} onClick={() => setOpen(true)}>
              View More
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
              {results.length > 0 ? (
                <DataGrid
                  rows={rows}
                  columns={
                    promiseName === "total sessions"
                      ? columnsSessions
                      : columnsUsers
                  }
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  autoHeight
                  disableSelectionOnClick
                  sx={{
                    width: promiseName === "total sessions" ? "100%" : "1000px",
                    background: "#201C20",
                    margin: "0 auto",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "1px solid #000",
                    p: 4,
                  }}
                >
                  <Typography>No results found.</Typography>
                </Box>
              )}
            </Modal>
          </>
        </CardContent>
      </Card>
    </>
  );
}
