import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "../../../assets/img/layout/coach/Library/EditIcon.svg";

import { useUser } from "src/components/Authenticated";
import { TableRow } from "src/contexts/CacheContext";
import { useCallback, useEffect, useRef, useState } from "react";
import CoachChangePassword from "../customComponents/CoachChangePassword";
import { CoachSettingsAccountType } from "./CoachSettingsView";
import { FormikProps } from "formik";

export type CoachSettingsAccountProps = {
  coach: TableRow<"coach">;
  formik: FormikProps<CoachSettingsAccountType>;
};

export default function CoachSettingsAccount(props: CoachSettingsAccountProps) {
  const user = useUser();
  const inputNameRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "98%",
          padding: "1.5em",
          background: "#201B20",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            width: "98%",
            background: "#201B20",
            borderRadius: "10px",
            paddingY: "1em",
            minHeight: "70vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: ".8125rem" }}>
              PERSONAL INFORMATION
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                padding: "2em",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={user.email}
                  sx={{
                    border: "1px solid #64605D",
                    borderRadius: "4px",
                    borderRightColor: "#A19D9A",
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#DD4F4A !important",
                    },
                  }}
                />

                <FormControl>
                  <TextField
                    name="name"
                    onBlur={props.formik.handleBlur}
                    value={props.formik.values.name}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.name && Boolean(props.formik.errors.name)}
                    helperText={props.formik.touched.name && props.formik.errors.name}
                    inputRef={inputNameRef}
                    sx={{
                      borderRadius: "4px",
                      borderRightColor: "#A19D9A",
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#DD4F4A !important",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => inputNameRef.current.focus()}
                          >
                            <img src={EditIcon} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <FormControl>
                  <Select
                    id="gender"
                    name="gender"
                    value={props.formik.values.gender}
                    onChange={props.formik.handleChange}
                    sx={{
                      color: "#F2F2F2",
                      "& .MuiSvgIcon-root": {
                        color: "#DD4F4A",
                      },
                      "&.MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#64605D",
                        },
                        "&:hover fieldset": {
                          borderColor: "#64605D",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#64605D",
                        },
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          border: ".0625rem solid #64605D",
                        },
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: "#64605D",
                          },
                        },
                      },
                      MenuListProps: {
                        disablePadding: true,
                      },
                    }}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <RadioGroup
                    row
                    onChange={props.formik.handleChange}
                    value={props.formik.values.units}
                    name="units"
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FormControlLabel
                      value="metric"
                      control={<Radio sx={{color: '#DD4F4A !important'}} />}
                      label={<span style={{ fontSize: "12px" }}>Metric</span>}
                    />
                    <FormControlLabel
                      value="imperial"
                      control={<Radio sx={{color: '#DD4F4A !important'}} />}
                      label={<span style={{ fontSize: "12px" }}>Imperial</span>}
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  variant="coachVariant2"
                  fullWidth
                  onClick={() => setOpenModal(true)}
                  sx={{ width: { xs: "11rem", sm: "20.5rem" } }}
                >
                  Change Password
                </Button>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="coachVariant"
              type="submit"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <>
          <CoachChangePassword onClose={handleClose} />
        </>
      </Modal>
    </>
  );
}
