import {
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useCallback, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, {
  isFulfilled,
  isPending,
  isRejected,
  isUnloaded,
} from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import StravaLogo from "../../../assets/img/views/settings/strava.svg";
import GarminLogo from "../../../assets/img/views/settings/garminIcon.svg";
import ConnectedAppComponent from "../../customComponents/ConnectAppComponent";
import { useStravaToken } from "src/utils/CyclistPreload";
import { useUser } from "src/components/Authenticated";
import { TableRow } from "src/contexts/CacheContext";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export type BikeInfoViewProps = {
  athleteBikes: Array<TableRow<"athlete_bikes">>;
};

export default function ConnectedAppsView(props: BikeInfoViewProps) {
  const [queryParameters, setQueryParameters] = useSearchParams();
  const supabase = useSupabase();
  const isMobileScreen = useMediaQuery("(max-width:480px)");
  const user = useUser();
  const [uniqueId, setUniqueId] = useState("");
  const [open, setOpen] = useState(false);

  const currentBikes = useMemo(
    () => props.athleteBikes.filter((bike) => !bike.soft_deleted),
    [props.athleteBikes],
  );

  const stravaTokens = useStravaToken();

  const code = useMemo(() => queryParameters.get("code"), [queryParameters]);

  const navigate = useNavigate();

  const garminStatusPromise = useCallback(
    () =>
      supabase.functions.invoke("garmin-status", {
        body: undefined,
      }),
    [supabase],
  );

  const garminStatusCall =
    useAsyncState<SupabaseCall<typeof garminStatusPromise>>();

  useEffect(() => {
    if (isUnloaded(garminStatusCall)) {
      garminStatusCall.fire(async () => garminStatusPromise());
    }
  }, [garminStatusCall]);

  const linkStravaPromise = useCallback(
    () =>
      supabase.functions.invoke("get-strava-token", {
        body: {
          code,
        },
      }),
    [supabase],
  );

  const linkStravaCall =
    useAsyncState<SupabaseCall<typeof linkStravaPromise>>();

  useEffect(() => {
    if (
      isUnloaded(linkStravaCall) &&
      !!code &&
      isFulfilled(stravaTokens) &&
      stravaTokens.result === "not-integrated"
    ) {
      linkStravaCall.fire(async () => linkStravaPromise());
      queryParameters.delete("code");
      setQueryParameters(queryParameters);
    }
  }, [code, linkStravaCall, linkStravaPromise, stravaTokens, queryParameters]);

  useEffect(() => {
    if (
      isFulfilled(linkStravaCall) &&
      isFulfilled(stravaTokens) &&
      stravaTokens.result === "not-integrated"
    ) {
      linkStravaCall.unload();
      stravaTokens.unload();
    }
  }, [stravaTokens, linkStravaCall]);

  const onRevoke = useCallback(
    () =>
      supabase.functions.invoke("revoke-strava-token").then((_) => {
        if ("unload" in stravaTokens) {
          stravaTokens.unload();
        }
      }),
    [supabase, stravaTokens],
  );

  useEffect(() => {
    if (
      !!code &&
      isFulfilled(stravaTokens) &&
      stravaTokens.result !== "not-integrated"
    ) {
      navigate("/dashboard/apps");
    }
  }, [stravaTokens, code, location]);

  const linkGarminCallback = useCallback(() => {
    supabase.functions.invoke("generate-garmin-token").then((res) => {
      const { oauth_token } = res.data;
      window.open(
        `https://connect.garmin.com/oauthConfirm?oauth_token=${oauth_token}&oauth_callback=${window.location.href}`,
        "_self",
      );
    });
  }, [supabase]);

  const verifier = useMemo(
    () => queryParameters.get("oauth_verifier"),
    [queryParameters],
  );

  const saveGarminVerifierPromise = useCallback(
    () =>
      supabase.functions.invoke("save-garmin-verifier", {
        body: { oauth_verifier: verifier },
      }),
    [supabase, verifier],
  );

  const saveGarminVerifierCall =
    useAsyncState<SupabaseCall<typeof saveGarminVerifierPromise>>();

  useEffect(() => {
    if (!!verifier && isUnloaded(saveGarminVerifierCall)) {
      saveGarminVerifierCall.fire(async () => saveGarminVerifierPromise());
    }
  }, [saveGarminVerifierCall, verifier, queryParameters]);

  useEffect(() => {
    if (isFulfilled(saveGarminVerifierCall)) {
      queryParameters.delete("oauth_verifier");
      setQueryParameters(queryParameters);

      if ("unload" in garminStatusCall) {
        garminStatusCall.unload();
      }
      saveGarminVerifierCall.unload();
    }
  }, [saveGarminVerifierCall, garminStatusCall, queryParameters]);

  const revokeGarminTokenPromise = useCallback(
    () => supabase.functions.invoke("revoke-garmin-token"),
    [supabase],
  );

  const revokeGarminTokenCall =
    useAsyncState<SupabaseCall<typeof revokeGarminTokenPromise>>();

  const onRevokeGarmin = useCallback(() => {
    if (isUnloaded(revokeGarminTokenCall)) {
      revokeGarminTokenCall.fire(async () => revokeGarminTokenPromise());
    }
  }, [supabase]);

  useEffect(() => {
    if (isFulfilled(revokeGarminTokenCall)) {
      revokeGarminTokenCall.unload();
      if (isFulfilled(garminStatusCall)) {
        garminStatusCall.unload();
      }
    }
  }, [revokeGarminTokenCall, garminStatusCall]);

  const stravaAuthorizeURL = useMemo(
    () =>
      `https://www.strava.com/oauth/authorize?client_id=98467&redirect_uri=${window.location.origin}/dashboard/settings/connected_apps/&response_type=code&scope=read_all,activity:read_all`,
    [],
  );

  // Fetch unique token of user
  const tokenPromise = useCallback(
    () =>
      supabase
        .from("user_token")
        .select("token")
        .eq("user_id", user.id)
        .throwOnError(),
    [supabase],
  );

  const tokenCall = useAsyncState<SupabaseCall<typeof tokenPromise>>();

  useEffect(() => {
    if (isUnloaded(tokenCall)) {
      tokenCall.fire(async () => tokenPromise());
    }
  }, [tokenCall, supabase]);

  useEffect(() => {
    if (isFulfilled(tokenCall)) {
      setUniqueId(tokenCall.result?.data[0]?.token);
    }
  }, [tokenCall]);

  const handleCopy = () => {
    navigator.clipboard.writeText(uniqueId ?? "-");
    setOpen(true);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          width: "98%",
          background: "#201B20",
          borderRadius: "10px",
          minHeight: "70vh",
          padding: "1.5em",
        }}
      >
        {isRejected(stravaTokens) && (
          <a href={stravaAuthorizeURL}>
            <Button variant="contained">Connect Strava Account</Button>
          </a>
        )}
        <Typography sx={{ fontSize: ".8125rem" }}>
          Manage Your Connected Accounts
        </Typography>

        <Box>
          <ConnectedAppComponent
            title="Strava"
            // description="Strava is an app for fitness"
            image={
              <img
                alt="Strava"
                src={StravaLogo}
                style={{ height: 53, width: 33 }}
              />
            }
            connected={
              isFulfilled(stravaTokens) &&
              stravaTokens.result !== "not-integrated"
            }
            isLoading={isPending(stravaTokens)}
            connectComponent={
              <a href={stravaAuthorizeURL}>
                <Button variant="contained" size="small">
                  Connect
                </Button>
              </a>
            }
            revokeComponent={
              <Button variant="contained" size="small" onClick={onRevoke}>
                Revoke
              </Button>
            }
          />
          <ConnectedAppComponent
            title="Garmin"
            // description="Garmin is an app for fitness"
            image={
              <img
                alt="Garmin"
                src={GarminLogo}
                style={{ height: 40, width: 40 }}
              />
            }
            connected={
              isFulfilled(garminStatusCall) &&
              garminStatusCall.result.data?.garmin === "connected"
            }
            isLoading={isPending(garminStatusCall)}
            connectComponent={
              <Button
                variant="contained"
                size="small"
                onClick={linkGarminCallback}
              >
                Connect
              </Button>
            }
            revokeComponent={
              <Button variant="contained" size="small" onClick={onRevokeGarmin}>
                Revoke
              </Button>
            }
          />

          <Grid
            container
            sx={{
              paddingTop: "2em",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              md={6}
              sm={8}
              xs={12}
              sx={{
                border: "1px solid #787471",
                borderRadius: "6px",
                paddingY: "8px",
                paddingX: "16px",
              }}
            >
              {currentBikes.length === 0 ? (
                <Typography>
                  You have to create at least one Bike entity to be able to use
                  the Garmin Connect IQ Darefore datafield.
                </Typography>
              ) : (
                <>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography>Your Unique ID: {uniqueId ?? "-"}</Typography>
                    <IconButton sx={{ padding: 1 }} onClick={handleCopy}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    {!isMobileScreen && (
                      <Snackbar
                        open={open}
                        autoHideDuration={3000}
                        onClose={handleClose}
                        message="Copied to clipboard"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        sx={{ width: "150px", margin: "0 auto" }}
                      />
                    )}
                  </Box>
                  <Typography sx={{ fontStyle: "italic", mt: 2 }}>
                    Use this ID to your account on Garmin Connect IQ datafield.
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
