import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import emailVerification from "../../assets/img/views/signup/email-verification.svg";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useCallback, useEffect } from "react";
import useAsyncState, { isFulfilled, isRejected } from "src/utils/Async";
import { useSnackbar } from "notistack";

export type VerifyEmailViewProps = {
  email: string;
};

export default function VerifyEmailView(props: VerifyEmailViewProps) {
  const supabase = useSupabase();
  const { enqueueSnackbar } = useSnackbar();
  const isMobileScreen = useMediaQuery("(max-width:450px)");

  const resendEmail = useCallback(
    (email: string) =>
      supabase.functions.invoke("resend-verification-email", {
        body: JSON.stringify({ email }),
      }),
    [supabase],
  );

  const resendEmailCall = useAsyncState<any>();

  useEffect(() => {
    if (isFulfilled(resendEmailCall)) {
      enqueueSnackbar("Email Sent", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "success",
        autoHideDuration: 3000,
      });
    }
    if (isRejected(resendEmailCall)) {
      enqueueSnackbar("Oops something went wrong", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [resendEmailCall]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "6.375rem",
          "@media (max-width: 640px)": {
            alignItems: isMobileScreen ? "center" : "flex-start",
          },
        }}
      >
        <Box
          sx={{
            background: "#2A252B",
            width: isMobileScreen ? "20rem" : "47.1875rem",
            height: isMobileScreen ? "48rem" : "39.3125rem",
            borderRadius: "1.25rem",
            paddingTop: "4rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: isMobileScreen ? "2em" : "",
          }}
        >
          <Typography variant="h1" color="text.secondary">
            Verify Your Email
          </Typography>
          <Typography variant="h3" mt={".8125rem"} mb={"2.75rem"}>
            To complete your registration, please verify your email address.
          </Typography>

          <img src={emailVerification} alt="Email verification image" />

          <Typography variant="h2" mt={"2.75rem"}>
            Thanks for joining!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mt: ".875rem",
            }}
          >
            <Typography variant="h4" textAlign={"center"}>
              A verification email has been sent to {props.email}. Please check
              your inbox (and <br /> your spam folder) and follow the
              instructions in the email to verify your account.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: isMobileScreen ? "column" : "",
              }}
            >
              <Typography variant="subtitle2">
                If you didn't receive the email, click the
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                px={".1875rem"}
              >
                "Resend Email"
              </Typography>
              <Typography variant="subtitle2">button below.</Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            size="small"
            sx={{ width: "11.6875rem", mt: "2.4375rem" }}
            onClick={() => {
              if ("fire" in resendEmailCall) {
                resendEmailCall.fire(async () => resendEmail(props.email));
              }
            }}
          >
            <Typography>Resend email</Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
}
