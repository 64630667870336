import { FormElement } from "./FormElements";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useDraggable } from "@dnd-kit/core";

export default function SidebarBtnElement({
  formElement,
}: {
  formElement: FormElement;
}) {
  const { title, icon } = formElement.designerBtnElement;
  const draggable = useDraggable({
    id: `designer-btn-${formElement.type}`,
    data: {
      type: formElement.type,
      isDesignerBtnElement: true,
    },
  });
  return (
    <Tooltip title={title} placement="top-start">
      <IconButton
        ref={draggable.setNodeRef}
        {...draggable.listeners}
        {...draggable.attributes}
        sx={{
          padding: "0px",
          cursor: "pointer",
          opacity: draggable.isDragging ? 0.5 : 1,
        }}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
}

export function SidebarBtnElementDragOverlay({
  formElement,
}: {
  formElement: FormElement;
}) {
  const { icon } = formElement.designerBtnElement;

  return (
    <Box
      sx={{
        width: "64px",
        height: "64px",
        all: "unset",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        opacity: 0.5,
      }}
    >
      {icon}
    </Box>
  );
}