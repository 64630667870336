import {
  Box,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";

import TooltipZonesC from "../../../../assets/img/layout/coach/MyAthletes/TooltipZonesC.svg";

export type PerformanceIndexCardProps = {
  index: number;
  title: string;
  description: string;
  metric: string | number;
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export default function CoachPerformanceIndexCard(
  props: PerformanceIndexCardProps,
) {
  const isMobileScreen = useMediaQuery("(max-width:480px)");

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#2C262D",
          width: {
            xs: "90%",
            sm: props.index === 9 ? "82.5%" : "40%",
            md: "30%",
          },
          padding: "2em",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, marginBottom: 1 }}>
          <Typography
            variant={isMobileScreen ? "h4" : "h3"}
            sx={{
              color: "#DD4F4A",
              fontWeight: 700,
            }}
          >
            {props.title}
          </Typography>
          <CustomWidthTooltip title={props.description} placement="right" arrow>
            <img src={TooltipZonesC} alt="info_icon" width="15" height="15" />
          </CustomWidthTooltip>
        </Box>
        <Typography
          variant="h2"
          sx={{
            color: "#A19D9A",
          }}
        >
          {props.metric || "-"}{" "}
          {props.title === "Normalised Power®"
            ? "W"
            : props.title === "Ideal Positions"
            ? "%"
            : ""}
        </Typography>
      </Box>
    </>
  );
}
