import {
  Box,
  FormControl,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Button,
  CircularProgress,
  useTheme,
  Tooltip,
  styled,
} from "@mui/material";
import { isPending } from "@reduxjs/toolkit";
import { useFormik } from "formik";
import { useMemo, useCallback, useEffect } from "react";
import useAsyncState from "src/utils/Async";
import {
  type SupabaseCall,
  heartRateZonesFromMaxHeartRate,
  powerZonesFromFTP,
  withReference,
} from "src/utils/common";
import type { AtheleteViewProps } from "../customViews/Setttings/AthleteView";
import * as yup from "yup";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import infoicon from "../../assets/img/views/profile-setup/info-icon.svg";
import slider from "../../assets/img/views/profile-setup/slider.svg";

const DisabledSlider = styled(Slider)(({ theme }) => ({
  color: "#3a8589",
  height: 3,
  padding: ".8125rem 0",
  width: "30rem",
  "& .MuiSlider-thumb": {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    border: ".0625rem solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 .5rem rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: "gray",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

export type AthleteProps = {
  ftp: number;
  lthr: number;
  max_heart_rate: number;
  heart_rate_zones: Array<number>;
  power_zones: Array<number>;
  experience_level: number;
  trainingGoals: Array<number>;
};

export type AthleteDetailsProps = {
  filteredAthlete: {
    age: number;
    country_code: string;
    dob: string;
    experience_level: number;
    firebase_id: string;
    ftp: number;
    gender: boolean;
    heart_rate_zones: number[];
    height: number;
    lthr: number;
    max_avg_cadence_session_id: string;
    max_avg_heart_rate_session_id: string;
    max_avg_power_session_id: string;
    max_avg_speed_session_id: string;
    max_distance_session_id: string;
    max_duration_session_id: string;
    max_elevation_gain_session_id: string;
    max_heart_rate: number;
    max_heart_rate_session_id: string;
    max_power_session_id: string;
    max_speed_session_id: string;
    min_avg_cadence_session_id: string;
    name: string;
    power_zones: number[];
    road_bike_name: string;
    road_bike_weight: number;
    tt_bike_name: string;
    tt_bike_weight: number;
    units: boolean;
    user_id: string;
    weight: number;
  }[];
  trainingGoals: {
    created_at: string;
    goal: string;
    id: number;
  }[];
  experienceLevel: {
    created_at: string;
    experience_level: string;
    id: number;
  }[];
  athleteTrainingsGoals: {
    athlete_id: string;
    created_at: string;
    id: number;
    training_goal_id: number;
  }[];
};

export default function AdminDashboardAthleteDetails(
  props: AthleteDetailsProps,
) {
  const supabase = useSupabase();
  const user = useUser();
  const theme = useTheme();
  const breakpoint = theme.breakpoints.down("md");

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        ftp: yup
          .number()
          .min(50, "FTP cannot be lower than 50W")
          .max(600, "FTP cannot be greater than 600W")
          .test("FTP is required", "FTP is required", (value) => !!value),
        lthr: yup
          .number()
          .min(60, "LTHR cannot be lower than 60bpm")
          .max(230, "LTHR rate cannot be greater than 230bpm")
          .test("LTHR is required", "LTHR is required", (value) => !!value),
        max_heart_rate: yup
          .number()
          .required("Maximum heart rate is required")
          .min(60, "Max heart rate cannot be lower than 60bpm")
          .max(230, "Max heart rate cannot be greater than 230bpm")
          .test(
            "Maximum heart rate is required",
            "Field is required",
            (value) => !!value,
          ),
        // experience_level: yup.number().oneOf(
        //   (isFulfilled(storedexpLevelCall)
        //     ? storedexpLevelCall.result
        //     : []
        //   ).map((type) => type.id),
        //   "Please select a valid level of experience",
        // ),
        // trainingGoals: yup.array().test(
        //   "training goals are valid",
        //   "Please select valid goals",
        //   (values) =>
        //     values.every((val) => trainingGoals.some((goal) => goal[0] === val)),
        // ),
      }),
    [],
  );

  const athleteFormik = useFormik<AtheleteViewProps>({
    initialValues: {
      max_heart_rate: undefined,
      lthr: undefined,
      ftp: undefined,
      heart_rate_zones: [],
      power_zones: [],
      experience_level: undefined,
      trainingGoals: [],
    },
    validationSchema,
    onSubmit: (values) => {
      if ("fire" in saveProfileSettingsCall) {
        saveProfileSettingsCall.fire(async () =>
          saveProfileSettingsPromise(values),
        );
      }

      const startingStateTrainingGoals = props.athleteTrainingsGoals.map(
        (res) => res.training_goal_id,
      );

      const toDelete = startingStateTrainingGoals.filter(
        (id) => !values.trainingGoals.includes(id),
      );

      const toAdd = values.trainingGoals.filter(
        (id) => !startingStateTrainingGoals.includes(id),
      );

      if ("fire" in saveAthTrainingGoalsCall) {
        saveAthTrainingGoalsCall.fire(async () =>
          saveAthTrainingGoalsPromise(toAdd, toDelete),
        );
      }
    },
  });

  const saveProfileSettingsPromise = useCallback(
    (values: AtheleteViewProps) =>
      supabase
        .from("athlete")
        .update({
          max_heart_rate: values.max_heart_rate,
          lthr: values.lthr,
          ftp: values.ftp,
          heart_rate_zones: values.heart_rate_zones.map(
            (z) => z / values.max_heart_rate,
          ),
          power_zones: values.power_zones.map((z) => z / values.ftp),
          experience_level: values.experience_level,
        })
        .eq("user_id", user.id)
        .then((res) => res.data),
    [supabase],
  );

  const saveProfileSettingsCall =
    useAsyncState<SupabaseCall<typeof saveProfileSettingsPromise>>();

  const saveAthTrainingGoalsPromise = useCallback(
    (toAdd: Array<number>, toDelete: Array<number>) =>
      supabase
        .from("athlete_training_goals")
        .delete()
        .in("training_goal_id", toDelete)
        .throwOnError()
        .then((_) =>
          supabase
            .from("athlete_training_goals")
            .insert(
              toAdd.map((id) => ({
                athlete_id: user.id,
                training_goal_id: id,
              })),
            )
            .throwOnError(),
        ),
    [supabase],
  );

  const saveAthTrainingGoalsCall =
    useAsyncState<SupabaseCall<typeof saveAthTrainingGoalsPromise>>();

  useEffect(() => {
    athleteFormik.setFieldValue(
      "max_heart_rate",
      props.filteredAthlete[0]?.max_heart_rate,
    );
    athleteFormik.validateField("max_heart_rate");

    athleteFormik.setFieldValue("lthr", props.filteredAthlete[0]?.lthr);
    athleteFormik.validateField("lthr");

    athleteFormik.setFieldValue("ftp", props.filteredAthlete[0]?.ftp);
    athleteFormik.validateField("ftp");

    athleteFormik.setFieldValue(
      "heart_rate_zones",
      props.filteredAthlete[0]?.heart_rate_zones.map((zone) =>
        Number((zone * props.filteredAthlete[0]?.max_heart_rate).toFixed(0)),
      ),
    );

    athleteFormik.setFieldValue(
      "power_zones",
      props.filteredAthlete[0]?.power_zones.map((zone) =>
        Number((zone * props.filteredAthlete[0]?.ftp).toFixed(0)),
      ),
    );

    athleteFormik.setFieldValue(
      "experience_level",
      props.filteredAthlete[0]?.experience_level,
    );
  }, [props.filteredAthlete]);

  const heartRateMarks = useMemo(() => {
    if (
      !athleteFormik.values.heart_rate_zones ||
      athleteFormik.values.max_heart_rate === 0 ||
      (athleteFormik.values.max_heart_rate as any) === ""
    ) {
      return undefined;
    }

    let res = [];
    let prev = athleteFormik.values.max_heart_rate * 0.5;
    for (const val of athleteFormik.values.heart_rate_zones) {
      res.push((prev + val) / 2);
      prev = val;
    }
    res.push((prev + athleteFormik.values.max_heart_rate) / 2);
    return res.map((value, i) => ({
      label: `Z${i + 1}`,
      value,
    }));
  }, [
    athleteFormik.values.heart_rate_zones,
    athleteFormik.values.max_heart_rate,
  ]);

  const powerMarks = useMemo(() => {
    if (
      !athleteFormik.values.power_zones ||
      athleteFormik.values.ftp === 0 ||
      (athleteFormik.values.ftp as any) === ""
    ) {
      return undefined;
    }

    let res = [];
    let prev = 0;
    for (const val of athleteFormik.values.power_zones) {
      res.push((prev + val) / 2);
      prev = val;
    }
    res.push((prev + athleteFormik.values.ftp * 1.5) / 2);
    return res.map((value, i) => ({
      label: `Z${i + 1}`,
      value,
    }));
  }, [athleteFormik.values.power_zones, athleteFormik.values.ftp]);

  const heart_rate_zones_colors = useMemo(
    () =>
      withReference(athleteFormik.values.max_heart_rate / 2, (halfHR) =>
        athleteFormik.values.heart_rate_zones.map(
          (zone) => ((zone - halfHR) / halfHR) * 100,
        ),
      ),
    [
      athleteFormik.values.heart_rate_zones,
      athleteFormik.values.max_heart_rate,
    ],
  );

  const power_zones_colors = useMemo(
    () =>
      athleteFormik.values.power_zones.map(
        (zone) => (zone / (athleteFormik.values.ftp * 1.5)) * 100,
      ),
    [athleteFormik.values.power_zones, athleteFormik.values.ftp],
  );

  useEffect(() => {
    if (athleteFormik.touched.max_heart_rate && !athleteFormik.isSubmitting) {
      athleteFormik.setFieldValue(
        "heart_rate_zones",
        heartRateZonesFromMaxHeartRate(athleteFormik.values.max_heart_rate),
      );
    }
  }, [
    athleteFormik.values.max_heart_rate,
    athleteFormik.touched.max_heart_rate,
  ]);

  useEffect(() => {
    if (athleteFormik.touched.ftp && !athleteFormik.isSubmitting) {
      athleteFormik.setFieldValue(
        "power_zones",
        powerZonesFromFTP(athleteFormik.values.ftp),
      );
    }
  }, [athleteFormik.values.ftp, athleteFormik.touched.ftp]);

  useEffect(() => {
    athleteFormik.setFieldValue(
      "trainingGoals",
      props.athleteTrainingsGoals.map((res) => res.training_goal_id),
    );
  }, [props.athleteTrainingsGoals]);

  return (
    <form noValidate onSubmit={athleteFormik.handleSubmit}>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <FormControl>
            <TextField
              name="max_heart_rate"
              label="Max Heart Rate (bpm)"
              InputLabelProps={{ shrink: true }}
              type="number"
              onBlur={athleteFormik.handleBlur}
              value={athleteFormik.values.max_heart_rate ?? ""}
              error={Boolean(
                athleteFormik.touched.max_heart_rate &&
                  athleteFormik.errors.max_heart_rate,
              )}
              helperText={
                athleteFormik.touched.max_heart_rate &&
                athleteFormik.errors.max_heart_rate
              }
              onChange={athleteFormik.handleChange}
            />
          </FormControl>
          <FormControl>
            <TextField
              name="lthr"
              label={"LTHR (BPM)"}
              InputLabelProps={{ shrink: true }}
              type="number"
              onBlur={athleteFormik.handleBlur}
              value={athleteFormik.values.lthr ?? ""}
              error={Boolean(
                athleteFormik.touched.lthr && athleteFormik.errors.lthr,
              )}
              helperText={
                athleteFormik.touched.lthr && athleteFormik.errors.lthr
              }
              onChange={athleteFormik.handleChange}
            />
          </FormControl>
          <FormControl>
            <TextField
              name="ftp"
              label={"FTP (W)"}
              InputLabelProps={{ shrink: true }}
              type="number"
              onBlur={athleteFormik.handleBlur}
              value={athleteFormik.values.ftp ?? ""}
              error={Boolean(
                athleteFormik.touched.ftp && athleteFormik.errors.ftp,
              )}
              helperText={athleteFormik.touched.ftp && athleteFormik.errors.ftp}
              onChange={athleteFormik.handleChange}
            />
          </FormControl>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontSize: ".875rem",
                fontWeight: "400",
                opacity: 0.5,
              }}
            >
              Training Goal
            </Typography>

            <FormGroup>
              {props.trainingGoals.map((datum) => (
                <FormControlLabel
                  key={datum.id}
                  control={
                    <Checkbox
                      size="small"
                      name="trainingGoals"
                      value={datum.id}
                      onChange={(_) =>
                        athleteFormik.setFieldValue(
                          "trainingGoals",
                          athleteFormik.values.trainingGoals.includes(datum.id)
                            ? athleteFormik.values.trainingGoals.filter(
                                (goal) => goal !== datum.id,
                              )
                            : athleteFormik.values.trainingGoals.concat([
                                datum.id,
                              ]),
                        )
                      }
                      checked={
                        athleteFormik.values.trainingGoals?.includes(
                          datum.id,
                        ) ?? false
                      }
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        color: "#BC6D29",
                        fontWeight: 400,
                        fontSize: ".875rem",
                      }}
                    >
                      {datum.goal}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </Box>
          <FormControl>
            <InputLabel id="experience_level">Level of Experience</InputLabel>
            <Select
              id="experience_level"
              name="experience_level"
              value={athleteFormik.values.experience_level ?? ""}
              onChange={athleteFormik.handleChange}
              label="Level of Experience"
            >
              {props.experienceLevel.map((level) => (
                <MenuItem key={level.id} value={level.id}>
                  {level.experience_level}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ width: "60%" }}>
          {withReference(
            (Boolean(athleteFormik.errors.max_heart_rate) &&
              athleteFormik.touched.max_heart_rate) ||
              !athleteFormik.values.max_heart_rate ||
              athleteFormik.values.max_heart_rate === 0 ||
              (athleteFormik.values.max_heart_rate as any) === "",
            (disabled) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h4"
                      color="text.primary"
                      sx={{ opacity: "0.5" }}
                    >
                      Heart Rate Zones (BPM)
                    </Typography>
                    <Tooltip
                      title="Your HR training zones are determined using the most common method, i.e. on the % of your maximum heart rate. You can always customize your zones."
                      placement="right"
                      arrow
                    >
                      <img
                        src={infoicon}
                        alt="info_icon"
                        width="10"
                        height="11"
                      />
                    </Tooltip>
                  </Box>

                  {disabled ? (
                    <Typography
                      variant="h4"
                      color="text.primary"
                      sx={{ opacity: "0.3" }}
                    >
                      (Set Max Heart Rate to unlock)
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
                {!disabled ? (
                  <Slider
                    track={false}
                    min={Math.floor(0.5 * athleteFormik.values.max_heart_rate)}
                    max={Number(athleteFormik.values.max_heart_rate)}
                    disableSwap
                    valueLabelDisplay="on"
                    marks={heartRateMarks}
                    step={1}
                    value={athleteFormik.values.heart_rate_zones}
                    sx={{
                      "& .MuiSlider-valueLabel": {
                        backgroundColor: "transparent",
                        color: "#80822B",
                        margin: 0,
                        fontSize: "10px",
                        marginTop: "5px",
                      },
                      marginTop: "1.25rem",
                      height: "9.5296px",
                      "& .MuiSlider-rail": {
                        background: `linear-gradient(to right, #81A9E7 0%, #81A9E7 ${heart_rate_zones_colors[0]}%, #92E3DA ${heart_rate_zones_colors[0]}%, #92E3DA ${heart_rate_zones_colors[1]}%, #C0DE77 ${heart_rate_zones_colors[1]}%, #C0DE77 ${heart_rate_zones_colors[2]}% , #E28E54 ${heart_rate_zones_colors[2]}%, #E28E54 ${heart_rate_zones_colors[3]}%, #DD4F4A ${heart_rate_zones_colors[3]}%, #DD4F4A 100%)`,
                        opacity: 1,
                      },
                      "& .MuiSlider-thumb": {
                        backgroundImage: `url(${slider})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "none",
                        height: ".5rem",
                        width: ".5625rem",
                        boxShadow: "none",
                        top: ".625rem",
                      },
                      "& .MuiSlider-mark": {
                        opacity: 0,
                      },
                      "& .MuiSlider-markLabel": {
                        fontSize: "10px",
                        color: "#F7F3F0",
                      },
                    }}
                    name="heart_rate_zones"
                    onChange={(_, values) =>
                      athleteFormik.setFieldValue("heart_rate_zones", values)
                    }
                    key={athleteFormik.values.max_heart_rate}
                  />
                ) : (
                  <div style={{ pointerEvents: "none" }}>
                    <DisabledSlider defaultValue={[]} />
                  </div>
                )}
              </Box>
            ),
          )}
          {withReference(
            (Boolean(athleteFormik.errors.ftp) && athleteFormik.touched.ftp) ||
              !athleteFormik.values.ftp ||
              athleteFormik.values.ftp === 0 ||
              (athleteFormik.values.ftp as any) === "",
            (disabled) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h4"
                      color="text.primary"
                      sx={{ opacity: "0.5" }}
                    >
                      Power Zones (W)
                    </Typography>

                    <Tooltip
                      title="Your Power training zones are determined using the most common method, i.e. on the % of your FTP. You can always customize your zones."
                      placement="right"
                      arrow
                    >
                      <img
                        src={infoicon}
                        alt="info_icon"
                        width="10"
                        height="11"
                      />
                    </Tooltip>
                  </Box>
                  {disabled ? (
                    <Typography
                      variant="h4"
                      color="text.primary"
                      sx={{ opacity: "0.3" }}
                    >
                      (Set FTP to unlock)
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
                {!disabled ? (
                  <Slider
                    key={`pwr-${athleteFormik.values.ftp}`}
                    track={false}
                    min={0}
                    max={(athleteFormik.values.ftp * 150) / 100}
                    disableSwap
                    valueLabelDisplay="on"
                    marks={powerMarks}
                    step={1}
                    value={athleteFormik.values.power_zones}
                    sx={{
                      "& .MuiSlider-valueLabel": {
                        backgroundColor: "transparent",
                        color: "#80822B",
                        margin: 0,
                        fontSize: "10px",
                        marginTop: "5px",
                      },
                      marginTop: "1.25rem",
                      height: "9.5296px",
                      "& .MuiSlider-rail": {
                        background: `linear-gradient(to right, #81A9E7 0%, #81A9E7 ${power_zones_colors[0]}%, #92E3DA ${power_zones_colors[0]}%, #92E3DA ${power_zones_colors[1]}%,#36B37E ${power_zones_colors[1]}%,#36B37E ${power_zones_colors[2]}%, #C0DE77 ${power_zones_colors[2]}%, #C0DE77 ${power_zones_colors[3]}% , #E28E54 ${power_zones_colors[3]}%, #E28E54 ${power_zones_colors[4]}%, #DD4F4A ${power_zones_colors[4]}%, #DD4F4A ${power_zones_colors[5]}%, #EA366F ${power_zones_colors[5]}%, #EA366F 100%)`,
                        opacity: 1,
                      },
                      "& .MuiSlider-thumb": {
                        backgroundImage: `url(${slider})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "none",
                        height: ".5rem",
                        width: ".5625rem",
                        boxShadow: "none",
                        top: ".625rem",
                      },
                      "& .MuiSlider-mark": {
                        opacity: 0,
                      },
                      "& .MuiSlider-markLabel": {
                        fontSize: "10px",
                        color: "#F7F3F0",
                      },
                    }}
                    name="power_zones"
                    onChange={(_, values) =>
                      athleteFormik.setFieldValue("power_zones", values)
                    }
                  />
                ) : (
                  <div style={{ pointerEvents: "none" }}>
                    <DisabledSlider defaultValue={[]} />
                  </div>
                )}
              </Box>
            ),
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          startIcon={
            isPending(saveProfileSettingsCall) ? (
              <CircularProgress size="1rem" />
            ) : null
          }
          disabled={isPending(saveProfileSettingsCall)}
          type="submit"
        >
          Save
        </Button>
      </Box>
    </form>
  );
}
