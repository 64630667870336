import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import PlanComponent from "./PlanComponent";

type TabValue = "active" | "scheduled" | "completed";

export type MyPlansViewProps = {
  tab?: TabValue;
};

export default function MyPlansView(props: MyPlansViewProps) {
  const [value, setValue] = useState<TabValue>("active");

  const handleChange = useCallback((_, newValue: TabValue) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    if (!!props?.tab) {
      setValue(props?.tab);
    }
  }, [props]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#201B20",
          borderRadius: "10px",
        }}
      >
        <TabContext value={value}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                aria-label="Tab list"
                sx={{
                  "& .MuiTab-root:not(.Mui-selected)": {
                    opacity: 0.5,
                  },
                  minHeight: "1.5rem",

                }}
              >
                <Tab
                  label="Active"
                  value="active"
                  sx={{
                    minHeight: 0,
                    padding: "10px",
                  }}
                />
                <Tab
                  label="Scheduled"
                  value="scheduled"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
                <Tab
                  label="Completed"
                  value="completed"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
              </TabList>
          </Box>
          <TabPanel
            value="active"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <PlanComponent tab={"active"}/>
          </TabPanel>
          <TabPanel
            value="scheduled"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <PlanComponent tab={"scheduled"}/>
          </TabPanel>
          <TabPanel
            value="completed"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          >
            <PlanComponent tab={"completed"}/>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
