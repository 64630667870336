import { Box, Card, CardContent, Typography } from "@mui/material";

export type AdminAnalyticsTitlesProps = {
  label: string;
};

export default function AdminAnalyticsTitles({
  label,
}: AdminAnalyticsTitlesProps) {
  return (
    <>
      <Box
        sx={{
          width: "95%",
          background: "#988699",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          padding: "2em",
          marginBottom: "2rem",
        }}
      >
        <Typography variant="h2" textTransform={"uppercase"}>
          {label}
        </Typography>
      </Box>
    </>
  );
}
