import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import { Link } from "react-router-dom";
import convert from "convert-units";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isUnloaded, isFulfilled } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import PersonalRecordsLockedCard from "../../customComponents/PersonalRecordsLockedCard";
import SuspenseLoader from "src/components/SuspenseLoader";
import { WithCache } from "src/contexts/CacheContext";
import CoachSessionViewPreload from "./AthletesSessions/CoachSessionViewPreload";
import RoadBikeIcon from "../../../assets/img/views/dashboard-personal/roadbikeIcon.svg";
import TTBikeIcon from "../../../assets/img/views/dashboard-personal/ttbikeIcon.svg";

export type CoachPersonalRecordsCardProps = {
  session_id: string;
  value: number;
  date: Date;
  label: string;
};

export type PreloadProps = {
  userId: string;
};

export default function CoachPersonalRecordsCard(props: PreloadProps) {
  const isMobileScreen = useMediaQuery("(max-width:599px)");
  const timeFormatter = useCallback((valStr) => {
    const val = Number(valStr);
    const hours = Math.floor(Math.floor(val / 60) / 60);
    const minutes = Math.trunc((val % 3600) / 60);
    const seconds = Math.trunc(val % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  }, []);
  const [insights, setInsights] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  const units = useUnits();

  const supabase = useSupabase();

  const supabaseRecordsPromise = useCallback(
    () =>
      supabase
        .from("athlete")
        .select(
          "max_speed_session_id (id,max_speed,date),max_avg_speed_session_id(id,average_speed,date),max_avg_power_session_id (id,average_power,date),max_heart_rate_session_id (id,max_heartrate,date),max_avg_heart_rate_session_id(id,average_heart_rate,date),max_distance_session_id(id,total_distance,date),max_avg_cadence_session_id(id,average_cadence,date),max_elevation_gain_session_id(id,elevation_gain,date),max_duration_session_id(id,total_time,date),max_power_session_id (id,max_power,date),max_road_tt_id(position_percentage,session(id,recording_name,date,ride_types(name))),max_road_aggressive_id(position_percentage,session(id,recording_name,date,ride_types(name))),max_road_comfortable_id(position_percentage,session(id,recording_name,date,ride_types(name))),max_road_standing_id(position_percentage,session(id,recording_name,date,ride_types(name))),max_tt_tt_id(position_percentage,session(id,recording_name,date,ride_types(name))),max_tt_comfortable_id(position_percentage,session(id,recording_name,date,ride_types(name))),max_tt_standing_id(position_percentage,session(id,recording_name,date,ride_types(name))) ",
        )
        .eq("user_id", props.userId)
        .then((res) => {
          const isNullish = res.data.every((obj) =>
            Object.values(obj).every((value) => value === null),
          );
          if (!isNullish) {
            return {
              max_speed:
                res.data[0].max_speed_session_id !== null
                  ? {
                      session_id: res.data[0]?.max_speed_session_id["id"],
                      value: res.data[0]?.max_speed_session_id["max_speed"],
                      date: res.data[0]?.max_speed_session_id["date"],
                      label: "Max Speed",
                    }
                  : null,
              max_avg_speed:
                res.data[0].max_avg_speed_session_id !== null
                  ? {
                      session_id: res.data[0]?.max_avg_speed_session_id["id"],
                      value:
                        res.data[0]?.max_avg_speed_session_id["average_speed"],
                      date: res.data[0]?.max_avg_speed_session_id["date"],
                      label: "Max Avg Speed",
                    }
                  : null,
              max_avg_power:
                res.data[0].max_avg_power_session_id !== null
                  ? {
                      session_id: res.data[0]?.max_avg_power_session_id["id"],
                      value:
                        res.data[0]?.max_avg_power_session_id["average_power"],
                      date: res.data[0]?.max_avg_power_session_id["date"],
                      label: "Max Avg Power",
                    }
                  : null,
              max_heart_rate:
                res.data[0].max_heart_rate_session_id !== null
                  ? {
                      session_id: res.data[0]?.max_heart_rate_session_id["id"],
                      value:
                        res.data[0]?.max_heart_rate_session_id["max_heartrate"],
                      date: res.data[0]?.max_heart_rate_session_id["date"],
                      label: "Max Heart Rate",
                    }
                  : null,
              max_avg_heart_rate:
                res.data[0].max_avg_heart_rate_session_id !== null
                  ? {
                      session_id:
                        res.data[0]?.max_avg_heart_rate_session_id["id"],
                      value:
                        res.data[0]?.max_avg_heart_rate_session_id[
                          "average_heart_rate"
                        ],
                      date: res.data[0]?.max_avg_heart_rate_session_id["date"],
                      label: "Max Avg Heart Rate",
                    }
                  : null,
              max_distance:
                res.data[0].max_distance_session_id !== null
                  ? {
                      session_id: res.data[0]?.max_distance_session_id["id"],
                      value:
                        res.data[0]?.max_distance_session_id["total_distance"],
                      date: res.data[0]?.max_distance_session_id["date"],
                      label: "Max Distance",
                    }
                  : null,
              max_avg_cadence:
                res.data[0].max_avg_cadence_session_id !== null
                  ? {
                      session_id: res.data[0]?.max_avg_cadence_session_id["id"],
                      value:
                        res.data[0]?.max_avg_cadence_session_id[
                          "average_cadence"
                        ],
                      date: res.data[0]?.max_avg_cadence_session_id["date"],
                      label: "Max Avg Cadence",
                    }
                  : null,
              max_elevation_gain:
                res.data[0].max_elevation_gain_session_id !== null
                  ? {
                      session_id:
                        res.data[0]?.max_elevation_gain_session_id["id"],

                      value:
                        res.data[0]?.max_elevation_gain_session_id[
                          "elevation_gain"
                        ],
                      date: res.data[0]?.max_elevation_gain_session_id["date"],
                      label: "Max Elevation Gain",
                    }
                  : null,
              max_duration:
                res.data[0].max_duration_session_id !== null
                  ? {
                      session_id: res.data[0]?.max_duration_session_id["id"],
                      value: res.data[0]?.max_duration_session_id["total_time"],
                      date: res.data[0]?.max_duration_session_id["date"],
                      label: "Max Duration",
                    }
                  : null,
              max_power:
                res.data[0].max_power_session_id !== null
                  ? {
                      session_id: res.data[0]?.max_power_session_id["id"],
                      value: res.data[0]?.max_power_session_id["max_power"],
                      date: res.data[0]?.max_power_session_id["date"],
                      label: "Max Power",
                    }
                  : null,
              max_road_tt:
                res.data[0].max_road_tt_id !== null
                  ? {
                      session_id: res.data[0]?.max_road_tt_id["session"]["id"],
                      value: res.data[0]?.max_road_tt_id["position_percentage"],
                      date: res.data[0]?.max_road_tt_id["session"]["date"],
                      name: res.data[0]?.max_road_tt_id["session"][
                        "recording_name"
                      ],
                      type: res.data[0]?.max_road_tt_id["session"][
                        "ride_types"
                      ]["name"],
                    }
                  : null,
              max_road_aggressive:
                res.data[0].max_road_aggressive_id !== null
                  ? {
                      session_id:
                        res.data[0]?.max_road_aggressive_id["session"]["id"],
                      value:
                        res.data[0]?.max_road_aggressive_id[
                          "position_percentage"
                        ],
                      date: res.data[0]?.max_road_aggressive_id["session"][
                        "date"
                      ],
                      name: res.data[0]?.max_road_aggressive_id["session"][
                        "recording_name"
                      ],
                      type: res.data[0]?.max_road_aggressive_id["session"][
                        "ride_types"
                      ]["name"],
                    }
                  : null,
              max_road_comfortable:
                res.data[0].max_road_comfortable_id !== null
                  ? {
                      session_id:
                        res.data[0]?.max_road_comfortable_id["session"]["id"],
                      value:
                        res.data[0]?.max_road_comfortable_id[
                          "position_percentage"
                        ],
                      date: res.data[0]?.max_road_comfortable_id["session"][
                        "date"
                      ],
                      name: res.data[0]?.max_road_comfortable_id["session"][
                        "recording_name"
                      ],
                      type: res.data[0]?.max_road_comfortable_id["session"][
                        "ride_types"
                      ]["name"],
                    }
                  : null,
              max_road_standing:
                res.data[0].max_road_standing_id !== null
                  ? {
                      session_id:
                        res.data[0]?.max_road_standing_id["session"]["id"],
                      value:
                        res.data[0]?.max_road_standing_id[
                          "position_percentage"
                        ],
                      date: res.data[0]?.max_road_standing_id["session"][
                        "date"
                      ],
                      name: res.data[0]?.max_road_standing_id["session"][
                        "recording_name"
                      ],
                      type: res.data[0]?.max_road_standing_id["session"][
                        "ride_types"
                      ]["name"],
                    }
                  : null,
              max_tt_tt:
                res.data[0].max_tt_tt_id !== null
                  ? {
                      session_id: res.data[0]?.max_tt_tt_id["session"]["id"],
                      value: res.data[0]?.max_tt_tt_id["position_percentage"],
                      date: res.data[0]?.max_tt_tt_id["session"]["date"],
                      name: res.data[0]?.max_tt_tt_id["session"][
                        "recording_name"
                      ],
                      type: res.data[0]?.max_tt_tt_id["session"]["ride_types"][
                        "name"
                      ],
                    }
                  : null,
              max_tt_comfortable:
                res.data[0].max_tt_comfortable_id !== null
                  ? {
                      session_id:
                        res.data[0]?.max_tt_comfortable_id["session"]["id"],
                      value:
                        res.data[0]?.max_tt_comfortable_id[
                          "position_percentage"
                        ],
                      date: res.data[0]?.max_tt_comfortable_id["session"][
                        "date"
                      ],
                      name: res.data[0]?.max_tt_comfortable_id["session"][
                        "recording_name"
                      ],
                      type: res.data[0]?.max_tt_comfortable_id["session"][
                        "ride_types"
                      ]["name"],
                    }
                  : null,
              max_tt_standing:
                res.data[0].max_tt_standing_id !== null
                  ? {
                      session_id:
                        res.data[0]?.max_tt_standing_id["session"]["id"],
                      value:
                        res.data[0]?.max_tt_standing_id["position_percentage"],
                      date: res.data[0]?.max_tt_standing_id["session"]["date"],
                      name: res.data[0]?.max_tt_standing_id["session"][
                        "recording_name"
                      ],
                      type: res.data[0]?.max_tt_standing_id["session"][
                        "ride_types"
                      ]["name"],
                    }
                  : null,
              noData: {
                value: false,
              },
            };
          } else {
            return {
              noData: {
                value: true,
              },
            };
          }
        }),
    [supabase],
  );

  const personal_records =
    useAsyncState<SupabaseCall<typeof supabaseRecordsPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(personal_records)) {
      personal_records.fire(async () => supabaseRecordsPromise());
    }
  }, [supabase, personal_records]);

  const handleBack = useCallback(() => {
    setId("");
    setInsights(false);
  }, []);

  useEffect(() => {
    setId("");
    setInsights(false);
  }, []);

  return insights ? (
    <WithCache
      tables={["default_positions"]}
      component={(cached) => (
        <CoachSessionViewPreload
          sessionId={id}
          defaultPositions={cached.default_positions}
        />
      )}
    />
  ) : (
    <>
      {isFulfilled(personal_records) ? (
        <Box
          sx={{
            background: "#201B20",
            borderRadius: "10px",
            width: "98%",
            margin: "0 auto",
            paddingX: "3em",
            height: {
              xl:
                personal_records.result.noData.value === true
                  ? "470px"
                  : "370px",
            },
            paddingBottom: "2em",
          }}
        >
          <Grid container>
            <Typography
              variant="h3"
              marginTop={"15px"}
              color={"#DD4F4A"}
              marginBottom={"10px"}
            >
              Performance Records
            </Typography>

            {personal_records.result.noData?.value === false ? (
              <>
                <Grid
                  item
                  container
                  spacing={3}
                  lg={12}
                  xs={12}
                  justifyContent={"center"}
                >
                  {/* Max Speed */}
                  {personal_records?.result?.max_speed !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setInsights(true);
                        setId(personal_records.result.max_speed.session_id);
                      }}
                    >
                      <Box
                        sx={{
                          background: "#2C262D",
                          borderRadius: "6px",
                          height: "82px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          textDecoration: "none",
                        }}
                      >
                        <Box p={"14px"}>
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {units === "metric"
                              ? `${personal_records.result.max_speed.value?.toFixed(
                                  1,
                                )} kph`
                              : `${(
                                  personal_records.result.max_speed.value *
                                  0.6213711922
                                ).toFixed(1)} mph`}
                          </Typography>
                          <Typography variant="body2" color={"#F7F3F0"}>
                            {personal_records.result.max_speed.label}
                          </Typography>
                          <Typography variant="subtitle2" color={"#A19D9A"}>
                            {new Date(
                              personal_records.result.max_speed.date,
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}
                    >
                      <PersonalRecordsLockedCard label="Max Speed" />
                    </Grid>
                  )}
                  {/* Max Avg Speed */}
                  {personal_records?.result?.max_avg_speed !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", xs: "50%" },
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setInsights(true);
                        setId(personal_records.result.max_avg_speed.session_id);
                      }}
                    >
                      <Box
                        sx={{
                          background: "#2C262D",
                          borderRadius: "6px",
                          height: "82px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          textDecoration: "none",
                        }}
                      >
                        <Box p={"14px"}>
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {units === "metric"
                              ? `${personal_records.result.max_avg_speed.value?.toFixed(
                                  1,
                                )} kph`
                              : `${(
                                  personal_records.result.max_avg_speed.value *
                                  0.6213711922
                                ).toFixed(1)} mph`}
                          </Typography>
                          <Typography variant="body2" color={"#F7F3F0"}>
                            {personal_records.result.max_avg_speed.label}
                          </Typography>
                          <Typography variant="subtitle2" color={"#A19D9A"}>
                            {new Date(
                              personal_records.result.max_avg_speed.date,
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}
                    >
                      <PersonalRecordsLockedCard label="Max Avg Speed" />
                    </Grid>
                  )}
                  {/* Max Power */}
                  {personal_records?.result?.max_power !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", xs: "50%" },
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setInsights(true);
                        setId(personal_records.result.max_power.session_id);
                      }}
                    >
                      <Box
                        sx={{
                          background: "#2C262D",
                          borderRadius: "6px",
                          height: "82px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          textDecoration: "none",
                        }}
                      >
                        <Box p={"14px"}>
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {`${personal_records.result.max_power.value.toFixed(
                              0,
                            )} W `}
                          </Typography>
                          <Typography variant="body2" color={"#F7F3F0"}>
                            {personal_records.result.max_power.label}
                          </Typography>
                          <Typography variant="subtitle2" color={"#A19D9A"}>
                            {new Date(
                              personal_records.result.max_power.date,
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}
                    >
                      <PersonalRecordsLockedCard label="Max Power" />
                    </Grid>
                  )}
                  {/* Max Avg Power */}
                  {personal_records?.result?.max_avg_power !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", xs: "50%" },
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setInsights(true);
                        setId(personal_records.result.max_avg_power.session_id);
                      }}
                    >
                      <Box
                        sx={{
                          background: "#2C262D",
                          borderRadius: "6px",
                          height: "82px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          textDecoration: "none",
                        }}
                      >
                        <Box p={"14px"}>
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {`${personal_records.result.max_avg_power.value.toFixed(
                              0,
                            )} W `}
                          </Typography>
                          <Typography variant="body2" color={"#F7F3F0"}>
                            {personal_records.result.max_avg_power.label}
                          </Typography>
                          <Typography variant="subtitle2" color={"#A19D9A"}>
                            {new Date(
                              personal_records.result.max_avg_power.date,
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}
                    >
                      <PersonalRecordsLockedCard label="Max Avg Power" />
                    </Grid>
                  )}
                  {/* Max Heartrate */}
                  {personal_records?.result?.max_heart_rate !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", xs: "50%" },
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setInsights(true);
                        setId(
                          personal_records.result.max_heart_rate.session_id,
                        );
                      }}
                    >
                      <Box
                        sx={{
                          background: "#2C262D",
                          borderRadius: "6px",
                          height: "82px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          textDecoration: "none",
                        }}
                      >
                        <Box p={"14px"}>
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {`${personal_records.result.max_heart_rate.value?.toFixed(
                              0,
                            )} bpm `}
                          </Typography>
                          <Typography variant="body2" color={"#F7F3F0"}>
                            {personal_records.result.max_heart_rate.label}
                          </Typography>
                          <Typography variant="subtitle2" color={"#A19D9A"}>
                            {new Date(
                              personal_records.result.max_heart_rate.date,
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}
                    >
                      <PersonalRecordsLockedCard label="Max Heartrate" />
                    </Grid>
                  )}
                  {/* Max Avg Heartrate */}
                  {personal_records?.result?.max_avg_heart_rate !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", xs: "50%" },
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setInsights(true);
                        setId(
                          personal_records.result.max_avg_heart_rate.session_id,
                        );
                      }}
                    >
                      <Box
                        sx={{
                          background: "#2C262D",
                          borderRadius: "6px",
                          height: "82px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          textDecoration: "none",
                        }}
                      >
                        <Box p={"14px"}>
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {`${personal_records.result.max_avg_heart_rate.value?.toFixed(
                              0,
                            )} bpm `}
                          </Typography>
                          <Typography variant="body2" color={"#F7F3F0"}>
                            {personal_records.result.max_avg_heart_rate.label}
                          </Typography>
                          <Typography variant="subtitle2" color={"#A19D9A"}>
                            {new Date(
                              personal_records.result.max_avg_heart_rate.date,
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}
                    >
                      <PersonalRecordsLockedCard label="Max Avg Heartrate" />
                    </Grid>
                  )}
                  {/* Max Avg Cadence */}
                  {personal_records?.result?.max_avg_cadence !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", xs: "50%" },
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setInsights(true);
                        setId(
                          personal_records.result.max_avg_cadence.session_id,
                        );
                      }}
                    >
                      <Box
                        sx={{
                          background: "#2C262D",
                          borderRadius: "6px",
                          height: "82px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          textDecoration: "none",
                        }}
                      >
                        <Box p={"14px"}>
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {`${personal_records.result.max_avg_cadence.value.toFixed(
                              0,
                            )} rpm`}
                          </Typography>
                          <Typography variant="body2" color={"#F7F3F0"}>
                            {personal_records.result.max_avg_cadence.label}
                          </Typography>
                          <Typography variant="subtitle2" color={"#A19D9A"}>
                            {new Date(
                              personal_records.result.max_avg_cadence.date,
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}
                    >
                      <PersonalRecordsLockedCard label="Max Avg Cadence" />
                    </Grid>
                  )}
                  {/* Max Elevation Gain */}
                  {personal_records?.result?.max_elevation_gain !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", xs: "50%" },
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setInsights(true);
                        setId(
                          personal_records.result.max_elevation_gain.session_id,
                        );
                      }}
                    >
                      <Box
                        sx={{
                          background: "#2C262D",
                          borderRadius: "6px",
                          height: "82px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          textDecoration: "none",
                        }}
                      >
                        <Box p={"14px"}>
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {units === "metric"
                              ? `${personal_records.result.max_elevation_gain.value?.toFixed(
                                  0,
                                )} m `
                              : `${convert(
                                  personal_records.result.max_elevation_gain
                                    .value,
                                )
                                  .from("m")
                                  .to("ft")
                                  .toFixed(0)} ft`}
                          </Typography>
                          <Typography variant="body2" color={"#F7F3F0"}>
                            {personal_records.result.max_elevation_gain.label}
                          </Typography>
                          <Typography variant="subtitle2" color={"#A19D9A"}>
                            {new Date(
                              personal_records.result.max_elevation_gain.date,
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}
                    >
                      <PersonalRecordsLockedCard label="Max Elevation Gain" />
                    </Grid>
                  )}
                  {/* Max Distance */}
                  {personal_records?.result?.max_distance !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", xs: "50%" },
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setInsights(true);
                        setId(personal_records.result.max_distance.session_id);
                      }}
                    >
                      <Box
                        sx={{
                          background: "#2C262D",
                          borderRadius: "6px",
                          height: "82px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          textDecoration: "none",
                        }}
                      >
                        <Box p={"14px"}>
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {units === "metric"
                              ? `${personal_records.result.max_distance.value?.toFixed(
                                  0,
                                )} km `
                              : `${convert(
                                  personal_records.result.max_distance.value,
                                )
                                  .from("km")
                                  .to("mi")
                                  .toFixed(0)} mi`}
                          </Typography>
                          <Typography variant="body2" color={"#F7F3F0"}>
                            {personal_records.result.max_distance.label}
                          </Typography>
                          <Typography variant="subtitle2" color={"#A19D9A"}>
                            {new Date(
                              personal_records.result.max_distance.date,
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}
                    >
                      <PersonalRecordsLockedCard label="Max Distance" />
                    </Grid>
                  )}
                  {/* Max Duration */}
                  {personal_records?.result?.max_duration !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", xs: "50%" },
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setInsights(true);
                        setId(personal_records.result.max_duration.session_id);
                      }}
                    >
                      <Box
                        sx={{
                          background: "#2C262D",
                          borderRadius: "6px",
                          height: "82px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          textDecoration: "none",
                        }}
                      >
                        <Box p={"14px"}>
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {timeFormatter(
                              personal_records.result.max_duration.value / 1000,
                            )}
                          </Typography>
                          <Typography variant="body2" color={"#F7F3F0"}>
                            {personal_records.result.max_duration.label}
                          </Typography>
                          <Typography variant="subtitle2" color={"#A19D9A"}>
                            {new Date(
                              personal_records.result.max_duration.date,
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}
                    >
                      <PersonalRecordsLockedCard label="Max Duration" />
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <Grid container sx={{ paddingTop: "3em" }}>
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    sx={{ color: "#DD4F4A", marginBottom: "7px" }}
                  >
                    No Personal Records Yet
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2" sx={{ marginBottom: "16px" }}>
                    Start tracking your progress today!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" width={"60%"} sx={{}}>
                    Looks like you haven't logged any personal records yet.
                    Don't worry, you can start tracking your progress today and
                    see how much you've improved over time. Simply log your
                    rides and workouts with the Darefore app, and we'll
                    automatically keep track of your best times, distances, and
                    more.
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  spacing={1}
                  lg={12}
                  justifyContent={"center"}
                  marginTop={"24px"}
                >
                  <Grid item width={"20%"}>
                    <PersonalRecordsLockedCard label={"Max Speed"} />
                  </Grid>
                  <Grid item width={"20%"}>
                    <PersonalRecordsLockedCard label={"Max Avg Speed"} />
                  </Grid>
                  <Grid item width={"20%"}>
                    <PersonalRecordsLockedCard label={"Max Power"} />
                  </Grid>
                  <Grid item width={"20%"}>
                    <PersonalRecordsLockedCard label={"Max Avg Power"} />
                  </Grid>
                  <Grid item width={"20%"}>
                    <PersonalRecordsLockedCard label={"Max Heart Rate"} />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  spacing={1}
                  lg={12}
                  //   md={12}
                  justifyContent={"center"}
                  marginTop={"24px"}
                >
                  <Grid
                    item
                    width={"20%"}
                    // sx={{ width: { lg: "20%", md: "50%" } }}
                  >
                    <PersonalRecordsLockedCard label={"Max Avg Heart Rate"} />
                  </Grid>
                  <Grid item width={"20%"}>
                    <PersonalRecordsLockedCard label={"Max Avg Cadence"} />
                  </Grid>
                  <Grid item width={"20%"}>
                    <PersonalRecordsLockedCard label={"Max Elevation Gain"} />
                  </Grid>
                  <Grid item width={"20%"}>
                    <PersonalRecordsLockedCard label={"Max Distance"} />
                  </Grid>
                  <Grid item width={"20%"}>
                    <PersonalRecordsLockedCard label={"Max Duration"} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      ) : (
        <SuspenseLoader />
      )}
      <Box
        sx={{
          background: "#201B20",
          borderRadius: "10px",
          width: "98%",
          margin: "0 auto",
          marginTop: "15px",
          paddingX: "3em",
          height: { md: "300px", xl: "320px" },
          paddingBottom: "1em",
        }}
      >
        <Grid container>
          <Typography variant="h3" marginY={"15px"} color={"#DD4F4A"}>
            Positions Records
          </Typography>
          {isFulfilled(personal_records) ? (
            personal_records.result.noData?.value === false ? (
              <>
                <Grid
                  item
                  container
                  spacing={3}
                  lg={12}
                  xs={12}
                  justifyContent={isMobileScreen ? "center" : ""}
                >
                  {/* Max Ideal TT Road */}
                  {personal_records?.result?.max_road_tt !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <Box
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={() => {
                          setInsights(true);
                          setId(personal_records.result.max_road_tt.session_id);
                        }}
                      >
                        <Box
                          p={"11px"}
                          sx={{
                            background: "#2C262D",
                            borderRadius: "6px",
                            height: "100px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {(
                              personal_records.result.max_road_tt.value * 100
                            ).toFixed(1)}{" "}
                            %
                          </Typography>

                          <Typography variant="body2" color={"#F7F3F0"}>
                            Max Ideal TT
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="subtitle2" color={"#A19D9A"}>
                              {new Date(
                                personal_records.result.max_road_tt.date,
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })}
                            </Typography>
                            <img
                              src={RoadBikeIcon}
                              alt="roadbike-icon"
                              width={"35px"}
                              style={{ marginBottom: "5px" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <PersonalRecordsLockedCard label="Max Ideal TT (ROAD)" />
                    </Grid>
                  )}
                  {/* Max Ideal Aggressive Road */}
                  {personal_records?.result?.max_road_aggressive !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <Box
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={() => {
                          setInsights(true);
                          setId(
                            personal_records.result.max_road_aggressive
                              .session_id,
                          );
                        }}
                      >
                        <Box
                          p={"11px"}
                          sx={{
                            background: "#2C262D",
                            borderRadius: "6px",
                            height: "100px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {(
                              personal_records.result.max_road_aggressive
                                .value * 100
                            ).toFixed(1)}{" "}
                            %
                          </Typography>

                          <Typography variant="body2" color={"#F7F3F0"}>
                            Max Ideal Aggressive
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="subtitle2" color={"#A19D9A"}>
                              {new Date(
                                personal_records.result.max_road_aggressive.date,
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })}
                            </Typography>
                            <img
                              src={RoadBikeIcon}
                              alt="roadbike-icon"
                              width={"35px"}
                              style={{ marginBottom: "5px" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <PersonalRecordsLockedCard label="Max Ideal Aggressive (ROAD)" />
                    </Grid>
                  )}
                  {/* Max Ideal Comfortable Road */}
                  {personal_records?.result?.max_road_comfortable !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <Box
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={() => {
                          setInsights(true);
                          setId(
                            personal_records.result.max_road_comfortable
                              .session_id,
                          );
                        }}
                      >
                        <Box
                          p={"11px"}
                          sx={{
                            background: "#2C262D",
                            borderRadius: "6px",
                            height: "100px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {(
                              personal_records.result.max_road_comfortable
                                .value * 100
                            ).toFixed(1)}{" "}
                            %
                          </Typography>

                          <Typography variant="body2" color={"#F7F3F0"}>
                            Max Ideal Comfortable
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="subtitle2" color={"#A19D9A"}>
                              {new Date(
                                personal_records.result.max_road_comfortable.date,
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })}
                            </Typography>
                            <img
                              src={RoadBikeIcon}
                              alt="roadbike-icon"
                              width={"35px"}
                              style={{ marginBottom: "5px" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <PersonalRecordsLockedCard label="Max Ideal Comfortable (ROAD)" />
                    </Grid>
                  )}
                  {/* Max Standing Road */}
                  {personal_records?.result?.max_road_standing !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <Box
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={() => {
                          setInsights(true);
                          setId(
                            personal_records.result.max_road_standing
                              .session_id,
                          );
                        }}
                      >
                        <Box
                          p={"11px"}
                          sx={{
                            background: "#2C262D",
                            borderRadius: "6px",
                            height: "100px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {(
                              personal_records.result.max_road_standing.value *
                              100
                            ).toFixed(1)}{" "}
                            %
                          </Typography>

                          <Typography variant="body2" color={"#F7F3F0"}>
                            Max Standing
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="subtitle2" color={"#A19D9A"}>
                              {new Date(
                                personal_records.result.max_road_standing.date,
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })}
                            </Typography>
                            <img
                              src={RoadBikeIcon}
                              alt="roadbike-icon"
                              width={"35px"}
                              style={{ marginBottom: "5px" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <PersonalRecordsLockedCard label="Max Standing (ROAD)" />
                    </Grid>
                  )}
                  {/* Max Ideal TT TT */}
                  {personal_records?.result?.max_tt_tt !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <Box
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={() => {
                          setInsights(true);
                          setId(personal_records.result.max_tt_tt.session_id);
                        }}
                      >
                        <Box
                          p={"11px"}
                          sx={{
                            background: "#2C262D",
                            borderRadius: "6px",
                            height: "100px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {(
                              personal_records.result.max_tt_tt.value * 100
                            ).toFixed(1)}{" "}
                            %
                          </Typography>

                          <Typography variant="body2" color={"#F7F3F0"}>
                            Max Ideal TT
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="subtitle2" color={"#A19D9A"}>
                              {new Date(
                                personal_records.result.max_tt_tt.date,
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })}
                            </Typography>
                            <img
                              src={TTBikeIcon}
                              alt="roadbike-icon"
                              width={"35px"}
                              style={{ marginBottom: "5px" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <PersonalRecordsLockedCard label="Max Ideal TT (TT)" />
                    </Grid>
                  )}

                  {/* Max Ideal Comfortable TT */}
                  {personal_records?.result?.max_tt_comfortable !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <Box
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={() => {
                          setInsights(true);
                          setId(
                            personal_records.result.max_tt_comfortable
                              .session_id,
                          );
                        }}
                      >
                        <Box
                          p={"11px"}
                          sx={{
                            background: "#2C262D",
                            borderRadius: "6px",
                            height: "100px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {(
                              personal_records.result.max_tt_comfortable.value *
                              100
                            ).toFixed(1)}{" "}
                            %
                          </Typography>

                          <Typography variant="body2" color={"#F7F3F0"}>
                            Max Ideal Comfortable
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="subtitle2" color={"#A19D9A"}>
                              {new Date(
                                personal_records.result.max_tt_comfortable.date,
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })}
                            </Typography>
                            <img
                              src={TTBikeIcon}
                              alt="roadbike-icon"
                              width={"35px"}
                              style={{ marginBottom: "5px" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <PersonalRecordsLockedCard label="Max Ideal Comfortable (TT)" />
                    </Grid>
                  )}
                  {/* Max Standing TT */}
                  {personal_records?.result?.max_tt_standing !== null ? (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <Box
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={() => {
                          setInsights(true);
                          setId(
                            personal_records.result.max_tt_standing.session_id,
                          );
                        }}
                      >
                        <Box
                          p={"11px"}
                          sx={{
                            background: "#2C262D",
                            borderRadius: "6px",
                            height: "100px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#DD4F4A",
                              fontSize: "1rem",
                              fontWeight: 700,
                              paddingBottom: "3px",
                            }}
                          >
                            {(
                              personal_records.result.max_tt_standing.value *
                              100
                            ).toFixed(1)}{" "}
                            %
                          </Typography>

                          <Typography variant="body2" color={"#F7F3F0"}>
                            Max Standing
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="subtitle2" color={"#A19D9A"}>
                              {new Date(
                                personal_records.result.max_tt_standing.date,
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })}
                            </Typography>
                            <img
                              src={TTBikeIcon}
                              alt="roadbike-icon"
                              width={"35px"}
                              style={{ marginBottom: "5px" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{
                        width: { lg: "20%", md: "20%", sm: "50%", xs: "90%" },
                      }}
                    >
                      <PersonalRecordsLockedCard label="Max Standing (TT)" />
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <Grid item container spacing={3} lg={12} xs={12}>
                <Grid item sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}>
                  <PersonalRecordsLockedCard label={"Max Ideal TT (ROAD)"} />
                </Grid>
                <Grid item sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}>
                  <PersonalRecordsLockedCard
                    label={"Max Ideal Aggressive (ROAD)"}
                  />
                </Grid>
                <Grid item sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}>
                  <PersonalRecordsLockedCard
                    label={"Max Ideal Comfortable (ROAD)"}
                  />
                </Grid>
                <Grid item sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}>
                  <PersonalRecordsLockedCard label={"Max Standing (ROAD)"} />
                </Grid>
                <Grid item sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}>
                  <PersonalRecordsLockedCard label={"Max Ideal TT (TT)"} />
                </Grid>
                <Grid item sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}>
                  <PersonalRecordsLockedCard
                    label={"Max Ideal Comfortable (TT)"}
                  />
                </Grid>
                <Grid item sx={{ width: { lg: "20%", md: "20%", xs: "50%" } }}>
                  <PersonalRecordsLockedCard label={"Max Standing (TT)"} />
                </Grid>
              </Grid>
            )
          ) : (
            <SuspenseLoader />
          )}
        </Grid>
      </Box>
    </>
  );
}
