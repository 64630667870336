import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useEffect,
} from "react";
import { createClient } from "@supabase/supabase-js";
import { Database } from "src/utils/DatabaseDefinitions";
import useAsyncState, { AsyncState, isUnloaded } from "src/utils/Async";

export const SupabaseContext = createContext(
  createClient<Database>(
    process.env.REACT_APP_SUPABASE_DOMAIN,
    process.env.REACT_APP_SUPABASE_ANON_KEY,
    {
      auth: {
        autoRefreshToken: true,
      },
    },
  ),
);
export const useSupabase = () => useContext(SupabaseContext);

export const SessionContext =
  createContext<
    AsyncState<
      Awaited<ReturnType<ReturnType<typeof useSupabase>["auth"]["getSession"]>>
    >
  >(undefined);

export const useSession = () => useContext(SessionContext);

export default function SupabaseProvider(props: PropsWithChildren<{}>) {
  const supabase = useSupabase();
  const session =
    useAsyncState<Awaited<ReturnType<typeof supabase.auth.getSession>>>();

  useEffect(() => {
    if (isUnloaded(session)) {
      session.fire(() => supabase.auth.getSession());
    }
  }, [session]);
  return (
    <SupabaseContext.Provider value={supabase}>
      <SessionContext.Provider value={session}>
        {props.children}
      </SessionContext.Provider>
    </SupabaseContext.Provider>
  );
}
