import { useCallback, useEffect, useMemo, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import IndoorIcon from "../../../assets/img/views/dashboard-progress/indoor-active.svg";
import IndoorInIcon from "../../../assets/img/views/dashboard-progress/indoor-inactive.svg";
import OutdoorIcon from "../../../assets/img/views/dashboard-progress/outdoor-inactive.svg";
import OutdoorActIcon from "../../../assets/img/views/dashboard-progress/outdoor-active.svg";
import dayjs from "dayjs";

import {
  BikePosition,
  allBikePositions,
  allColors,
  roadBikePositions,
  ttBikePositions,
} from "src/utils/common";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { TableRow } from "src/contexts/CacheContext";

type Month =
  | "Jan"
  | "Feb"
  | "Mar"
  | "Apr"
  | "May"
  | "Jun"
  | "Jul"
  | "Aug"
  | "Sep"
  | "Oct"
  | "Nov"
  | "Dec";
const months: Array<Month> = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export type PositionProgressWidgetTabsProps = {
  sessions: {
    date: string;
    session_statistics: {
      cadence_max: number;
      created_at: string;
      default_position_id: number;
      custom_position_id: number;
      heart_rate_average: number;
      heart_rate_max: number;
      id: number;
      pedal_balance_left: number;
      pedal_balance_right: number;
      position_percentage: number;
      power_average: number;
      power_max: number;
      session_id: string;
      slope_average: number;
      slope_negative_percentage: number;
      slope_positive_percentage: number;
      speed_average: number;
      speed_max: number;
    }[];
    ride_types: { name: string };
    athlete_bikes: { bike_types: { name: string } };
  }[];
  defaultPositions: Array<TableRow<"default_positions">>;
  customPositions: Array<TableRow<"custom_positions">>;
};

export default function PositionProgressWidgetTabs(
  props: PositionProgressWidgetTabsProps,
) {
  const rangeHigh = dayjs();
  const rangeLow = rangeHigh.subtract(5, "month");

  const [bicycleType, setBicycleType] = useState<"Road" | "TT">("Road");
  const [currentTab, setCurrentTab] = useState<"Outdoor" | "Indoor">("Indoor");
  const [filterPositions, setFilterPositions] = useState<string[]>([]);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery("(max-width:440px)");

  // FOR BOTH CHARTS
  const categories = useMemo(() => {
    const indexOfMonth = rangeLow.month();
    const months: Array<Month> = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return [
      ...months.slice(indexOfMonth),
      ...months.slice(0, indexOfMonth),
    ].slice(0, 6);
  }, [rangeLow]);

  const filterSessions = useMemo(() => {
    return props.sessions.filter(
      (session) =>
        !!session.session_statistics &&
        Object.keys(session.session_statistics).length > 0 &&
        session.athlete_bikes?.bike_types.name === bicycleType &&
        session.ride_types.name === currentTab,
    );
  }, [bicycleType, rangeLow, rangeHigh, currentTab]);

  const bikePositions = useMemo(() => {
    const bikes =
      filterSessions[0]?.athlete_bikes.bike_types.name === "Road"
        ? roadBikePositions
        : ttBikePositions;
    return bikes.sort(
      (a, b) =>
        allBikePositions.indexOf(
          a as
            | "Standing"
            | "Comfortable - Not Ideal"
            | "Comfortable - Ideal"
            | "Aggressive - Not Ideal"
            | "Aggressive - Ideal"
            | "TT - Not Ideal"
            | "TT - Ideal"
            | "Lower than TT",
        ) -
        allBikePositions.indexOf(
          b as
            | "Standing"
            | "Comfortable - Not Ideal"
            | "Comfortable - Ideal"
            | "Aggressive - Not Ideal"
            | "Aggressive - Ideal"
            | "TT - Not Ideal"
            | "TT - Ideal"
            | "Lower than TT",
        ),
    );
  }, [filterSessions]);

  const bucketsPositions: { [K in Month]: { [P in BikePosition]: number } } =
    useMemo(() => {
      const sumObject = Object.fromEntries(
        categories.map((cat) => [
          cat,
          Object.fromEntries(
            bikePositions.map((pos: BikePosition) => [pos, 0]),
          ),
        ]),
      ) as { [K in Month]: { [P in BikePosition]: number } };

      const countObject = Object.fromEntries(
        categories.map((cat) => [
          cat,
          Object.fromEntries(
            bikePositions.map((pos: BikePosition) => [pos, 0]),
          ),
        ]),
      ) as { [K in Month]: { [P in BikePosition]: number } };

      for (const session of filterSessions) {
        const month = months[new Date(session.date).getMonth()];

        for (const statistic of session.session_statistics) {
          const displayName = ((position) =>
            position?.primary_name +
            (position?.secondary_name ? ` - ${position?.secondary_name}` : ""))(
            statistic?.default_position_id !== null
              ? props.defaultPositions.find(
                  (pos) => pos.id === statistic.default_position_id,
                )
              : props.customPositions.find(
                  (pos) => pos.id === statistic.custom_position_id,
                ),
          );
          sumObject[month][displayName] += statistic.position_percentage * 100;
          countObject[month][displayName] += 1;
        }
      }

      return Object.fromEntries(
        Object.entries(sumObject).map(([month, val]) => [
          month,
          Object.fromEntries(
            Object.entries(val).map(([position, val]) => [
              position,
              val === 0 ? val : (val / countObject[month][position]).toFixed(1),
            ]),
          ),
        ]),
      ) as { [K in Month]: { [P in BikePosition]: number } };
    }, [filterSessions, props.defaultPositions]);

  const positionSeries: ApexAxisChartSeries = useMemo(() => {
    const positionNames =
      filterPositions.length === 0
        ? Object.keys(bucketsPositions[categories[0]])
        : filterPositions;
    const series = positionNames.map((positionName) => {
      const data = categories.map((category) => {
        return bucketsPositions[category][positionName];
      });
      return {
        name: positionName,
        data,
      };
    });
    return series;
  }, [bucketsPositions, filterSessions, filterPositions]);

  const createChartOptions = useCallback(
    (id: string): ApexOptions => ({
      chart: {
        id,
        height: 130,
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        background: "transparent",
      },

      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#FF5630",
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      stroke: {
        curve: "smooth",
        dashArray:
          filterPositions.length === 0
            ? bikePositions.flatMap((position) =>
                position?.includes("Not Ideal") ? [2] : [0],
              )
            : filterPositions.flatMap((position) =>
                position?.includes("Not Ideal") ? [2] : [0],
              ),
        width: 2,
      },
      theme: {
        mode: theme.palette.mode,
      },
      fill: {
        opacity: 0,
        type: "solid",
      },
      markers: {
        hover: {
          sizeOffset: 2,
        },
        shape: "circle",
        size: 3,
        strokeWidth: 3,
        strokeOpacity: 1,
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val + "%";
        },
      },
      grid: {
        show: positionSeries.length !== 0 ? true : false,
        strokeDashArray: 5,
        borderColor: theme.palette.divider,
      },
      legend: {
        show: false,
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val) {
            return val + "%";
          },
        },
        x: {
          show: true,
        },
        marker: {
          show: true,
        },
      },
      yaxis: {
        show: positionSeries.length !== 0 ? true : false,
        min: 0,
        max: (val) => Math.ceil(val),
        labels: {
          formatter: (val) => Math.ceil(val) + "%",
        },
        tickAmount: 4,
      },
      xaxis: {
        categories: categories,
        tickAmount: undefined,
        overwriteCategories: undefined,
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#64605D",
          },
        },
        tooltip: {
          enabled: false, // Hide the small tooltip for categories
        },
      },
      colors: positionSeries.map((position) => allColors[position.name]),
    }),
    [bikePositions],
  );

  const chartOptions = useMemo(
    () => createChartOptions("chart"),
    [positionSeries],
  );

  const handleChangeSelect = (
    event: SelectChangeEvent<typeof filterPositions>,
  ) => {
    const {
      target: { value },
    } = event;
    setFilterPositions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const handleChange = (_, newValue: "Road" | "TT") => {
    setFilterPositions([]);
    setBicycleType(newValue);
  };

  const handleTabsChange = useCallback(
    (_, value: "Outdoor" | "Indoor"): void => {
      setFilterPositions([]);
      setCurrentTab(value);
    },
    [],
  );

  const [isActiveIndoor, setIsActiveIndoor] = useState(true);

  const handleClickIn = () => {
    setIsActiveIndoor(true);
    setIsActiveOutdoor(false);
  };

  const [isActiveOutdoor, setIsActiveOutdoor] = useState(false);

  const handleClickOut = () => {
    setIsActiveIndoor(false);
    setIsActiveOutdoor(true);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#201B20",
          display: "flex",
          flexDirection: "column",
          paddingTop: { xs: "0.2em", xl: "0.5em" },
          paddingLeft: "1em",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">POSITION PROGRESS</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: isMobileScreen ? "column" : "row",
            }}
          >
            <Tabs
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{ minHeight: 0 }}
            >
              <Tab
                icon={
                  isActiveIndoor ? (
                    <img src={IndoorIcon} />
                  ) : (
                    <img src={IndoorInIcon} />
                  )
                }
                value={"Indoor"}
                disableRipple
                onClick={handleClickIn}
                sx={{ minHeight: 0, padding: 0, minWidth: "25px" }}
              />
              <Tab
                icon={
                  isActiveOutdoor ? (
                    <img src={OutdoorActIcon} />
                  ) : (
                    <img src={OutdoorIcon} />
                  )
                }
                value={"Outdoor"}
                disableRipple
                onClick={handleClickOut}
                sx={{ minHeight: 0, padding: 0, minWidth: "50px" }}
              />
            </Tabs>
            <Box>
              <FormControl
                fullWidth
                disabled={
                  Object.keys(bucketsPositions[categories[0]]).length === 0
                }
              >
                {filterPositions.length === 0 && (
                  <InputLabel shrink={false} sx={{ fontSize: ".875rem" }}>
                    {isMobileScreen ? "Positions" : "Select Positions"}
                  </InputLabel>
                )}
                <Select
                  value={filterPositions}
                  onChange={handleChangeSelect}
                  multiple
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiSelect-icon": {
                      color: "#E28E54",
                    },
                    width: isMobileScreen ? "130px" : "180px",
                  }}
                >
                  {Object.keys(bucketsPositions[categories[0]]).map(
                    (position) => (
                      <MenuItem key={position} value={position}>
                        {position}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>

        <TabContext value={bicycleType}>
          <Box
            sx={{
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="Tab list"
              sx={{
                "& .MuiTab-root:not(.Mui-selected)": {
                  opacity: 0.5,
                },
                minHeight: "29px",
              }}
            >
              <Tab
                label="Road Bike"
                value="Road"
                sx={{ padding: 0, minHeight: "20px" }}
              />
              <Tab
                label="TT Bike"
                value="TT"
                sx={{ padding: 0, minHeight: "20px" }}
              />
            </TabList>
            <Box>
              <Typography variant="subtitle1" sx={{ opacity: 0.5 }}>
                Average metrics over the Last Six Months
              </Typography>
            </Box>
          </Box>

          <>
            <TabPanel value="Road" sx={{ padding: 0, margin: 0 }}>
              <Box sx={{ height: { xl: "230px", xs: "186px" } }}>
                <Chart
                  options={chartOptions}
                  series={positionSeries}
                  type="area"
                  height={"88%"}
                />
              </Box>
            </TabPanel>
            <TabPanel value="TT" sx={{ padding: 0, margin: 0 }}>
              <Box sx={{ height: { xl: "230px", xs: "186px" } }}>
                <Chart
                  options={chartOptions}
                  series={positionSeries}
                  type="area"
                  height={"88%"}
                />
              </Box>
            </TabPanel>
          </>
        </TabContext>
        <Box
          alignSelf={"flex-end"}
          paddingRight={"13px"}
          paddingBottom={"0.5em"}
        >
          <Typography
            color={"white"}
            component={Link}
            to={"/dashboard/progress/positions"}
          >
            View More
          </Typography>
        </Box>
      </Box>
    </>
  );
}
