import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
export default function ErrorSupportView() {
  const emailAddress = "support@darefore.com";
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pt: "16.6875rem",
          "@media (max-width: 400px)": {
            alignItems: "flex-start",
          },
        }}
      >
        <Box
          sx={{
            background: "#2A252B",
            width: "47.1875rem",
            height: "18.6875rem",
            borderRadius: "1.25rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h1" color="text.secondary" mt="7.125rem">
            Oops, something went wrong.
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: ".8125rem",
            }}
          >
            <Typography
              variant="h4"
              fontSize={18}
              lineHeight={"24px"}
              textAlign={"center"}
            >
              Please try again later or{" "}
              <a
                href={`mailto:${emailAddress}`}
                style={{ textDecoration: "none", color: "#BC6D29" }}
              >
                contact support
              </a>{" "}
              for assistance.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
