import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { createFormElement } from "./FieldComponent";
import { DraggableAttributes } from "@dnd-kit/core";
import { createIntervalElement } from "./IntervalsContainer";
import { Tables } from "src/utils/DatabaseDefinitions";

export type ElementsType =
  | "Warmup"
  | "Cooldown"
  | "Intervals"
  | "Recovery"
  | "Active"
  | "Other";

export type FormElement = {
  type: ElementsType;
  construct: (id: string) => FormElementInstance;
  designerBtnElement: {
    icon?: React.ReactElement;
    title: string;
  };
  designerComponent: React.FC<{
    elementInstance: FormElementInstance;
    listeners?: SyntheticListenerMap;
    attributes?: DraggableAttributes;
  }>;
};
export type FormElementInstance = {
  id: string;
  type: ElementsType;
  extraAttributes?: Omit<Tables<'workout_step'>, 'id' | 'workout_id' | 'created_at' | 'order'>;
  intervalCounter?: number;
};

type FormElementsType = {
  [key in ElementsType]: FormElement;
};

export const FormElements: FormElementsType = {
  Warmup: createFormElement("Warmup"),
  Cooldown: createFormElement("Cooldown"),
  Recovery: createFormElement("Recovery"),
  Active: createFormElement("Active"),
  Other: createFormElement("Other"),
  Intervals: createIntervalElement("Intervals"),
};