import {
  Box,
  Button,
  Divider,
  Skeleton,
  Typography,
  styled,
  Slider,
  Tooltip,
} from "@mui/material";
import { SupabaseCall, withReference } from "src/utils/common";
import TooltipZonesC from "../../../assets/img/layout/coach/MyAthletes/TooltipZonesC.svg";
import slider from "../../../assets/img/views/profile-setup/slider.svg";
import { useCallback, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { TableRow } from "src/contexts/CacheContext";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, {
  isFulfilled,
  isPending,
  isUnloaded,
} from "src/utils/Async";
import { useSnackbar } from "notistack";
import PreloadComponent from "src/utils/PreloadComponent";
import { Database } from "src/utils/DatabaseDefinitions";

const DisabledSlider = styled(Slider)(({ theme }) => ({
  color: "#3a8589",
  height: 3,
  padding: ".8125rem 0",
  width: "30rem",
  "& .MuiSlider-thumb": {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    border: ".0625rem solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 .5rem rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: "gray",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

export type AtheleteViewProps = {
  heart_rate_zones: Array<number>;
  power_zones: Array<number>;
};

export type PreloadProps = {
  userId: string;
  trainingGoals: Array<TableRow<"training_goals">>;
  experienceLevels: Array<TableRow<"athlete_experience_level">>;
};

export default function CoachAthleteSettingsPreload(props: PreloadProps) {
  return (
    <>
      <PreloadComponent<{
        athleteData: Database["public"]["Tables"]["athlete"]["Row"];
      }>
        promises={{
          athleteData: async (supabase) =>
            supabase
              .from("athlete")
              .select("*")
              .eq("user_id", props.userId)
              .then((res) => res.data[0]),
        }}
        component={(data) => (
          <>
            <CoachAthleteSettingsView
              athleteData={data.athleteData}
              trainingGoals={props.trainingGoals}
              experienceLevels={props.experienceLevels}
            />
          </>
        )}
      />
    </>
  );
}

export type AthleteViewProps = {
  athleteData: Database["public"]["Tables"]["athlete"]["Row"];
  trainingGoals: Array<TableRow<"training_goals">>;
  experienceLevels: Array<TableRow<"athlete_experience_level">>;
};

function CoachAthleteSettingsView(props: AthleteViewProps) {
  const supabase = useSupabase();

  const formik = useFormik<AtheleteViewProps>({
    initialValues: {
      heart_rate_zones: [],
      power_zones: [],
    },
    onSubmit: (values) => {
      if ("fire" in saveProfileSettingsCall) {
        saveProfileSettingsCall.fire(async () =>
          saveProfileSettingsPromise(values),
        );
      }
    },
  });

  const saveProfileSettingsPromise = useCallback(
    (values: AtheleteViewProps) =>
      supabase
        .from("athlete")
        .update({
          heart_rate_zones: values.heart_rate_zones.map(
            (z) => z / props.athleteData.max_heart_rate,
          ),
          power_zones: values.power_zones.map((z) => z / props.athleteData.ftp),
        })
        .eq("user_id", props.athleteData.user_id)
        .then((res) => res.data),
    [supabase],
  );

  const saveProfileSettingsCall =
    useAsyncState<SupabaseCall<typeof saveProfileSettingsPromise>>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isFulfilled(saveProfileSettingsCall)) {
      enqueueSnackbar("Settings saved successfully", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [saveProfileSettingsCall]);

  const heart_rate_zones_colors = useMemo(
    () =>
      withReference(props.athleteData.max_heart_rate / 2, (halfHR) =>
        formik.values.heart_rate_zones.map(
          (zone) => ((zone - halfHR) / halfHR) * 100,
        ),
      ),
    [formik.values.heart_rate_zones, props.athleteData.max_heart_rate],
  );

  const power_zones_colors = useMemo(
    () =>
      formik.values.power_zones.map(
        (zone) => (zone / (props.athleteData.ftp * 1.5)) * 100,
      ),
    [formik.values.power_zones, props.athleteData.ftp],
  );

  const heartRateMarks = useMemo(() => {
    if (
      !formik.values.heart_rate_zones ||
      props.athleteData.max_heart_rate === 0 ||
      (props.athleteData.max_heart_rate as any) === ""
    ) {
      return undefined;
    }

    let res = [];
    let prev = props.athleteData.max_heart_rate * 0.5;
    for (const val of formik.values.heart_rate_zones) {
      res.push((prev + val) / 2);
      prev = val;
    }
    res.push((prev + props.athleteData.max_heart_rate) / 2);
    return res.map((value, i) => ({
      label: `Z${i + 1}`,
      value,
    }));
  }, [formik.values.heart_rate_zones, props.athleteData.max_heart_rate]);

  const powerMarks = useMemo(() => {
    if (
      !formik.values.power_zones ||
      props.athleteData.ftp === 0 ||
      (props.athleteData.ftp as any) === ""
    ) {
      return undefined;
    }

    let res = [];
    let prev = 0;
    for (const val of formik.values.power_zones) {
      res.push((prev + val) / 2);
      prev = val;
    }
    res.push((prev + props.athleteData.ftp * 1.5) / 2);
    return res.map((value, i) => ({
      label: `Z${i + 1}`,
      value,
    }));
  }, [formik.values.power_zones, props.athleteData.ftp]);

  useEffect(() => {
    formik.setFieldValue(
      "heart_rate_zones",
      props.athleteData.heart_rate_zones.map((zone) =>
        Number((zone * props.athleteData.max_heart_rate).toFixed(0)),
      ),
    );

    formik.setFieldValue(
      "power_zones",
      props.athleteData.power_zones.map((zone) =>
        Number((zone * props.athleteData.ftp).toFixed(0)),
      ),
    );
  }, [props.athleteData]);

  const athleteTrainingGoalsPromise = useCallback(
    () =>
      supabase
        .from("athlete_training_goals")
        .select("*")
        .eq("athlete_id", props.athleteData.user_id)
        .then((res) => res.data),
    [supabase],
  );

  const athleteTrainingGoalsCall =
    useAsyncState<SupabaseCall<typeof athleteTrainingGoalsPromise>>();

  useEffect(() => {
    if (isUnloaded(athleteTrainingGoalsCall)) {
      athleteTrainingGoalsCall.fire(async () => athleteTrainingGoalsPromise());
    }
  }, [athleteTrainingGoalsCall, athleteTrainingGoalsPromise]);

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            width: "98%",
            paddingX: "2em",
            paddingY: "1em",
            background: "#201B20",
            borderRadius: "10px",
          }}
        >
          <Typography variant="h5">PERSONAL INFORMATION</Typography>
          <Box
            sx={{
              marginY: "2rem",
              display: "flex",
              gap: 5,
              justifyContent: "center",
            }}
          >
            <Skeleton variant="circular" width={"260px"} height={"260px"} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography variant="h4" sx={{ color: "#A19D9A" }}>
                  Email
                </Typography>
                <Typography variant="h4">{props.athleteData.email}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography variant="h4" sx={{ color: "#A19D9A" }}>
                  Name/Surname
                </Typography>
                <Typography variant="h4">{props.athleteData.name}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography variant="h4" sx={{ color: "#A19D9A" }}>
                  Birth Date
                </Typography>
                <Typography variant="h4">{props.athleteData.dob}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography variant="h4" sx={{ color: "#A19D9A" }}>
                  Gender
                </Typography>
                <Typography variant="h4">
                  {props.athleteData.gender === true ? "Female" : "Male"}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography variant="h4" sx={{ color: "#A19D9A" }}>
                  Weight
                </Typography>
                <Typography variant="h4">
                  {props.athleteData.weight}kg
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography variant="h4" sx={{ color: "#A19D9A" }}>
                  Height
                </Typography>
                <Typography variant="h4">
                  {props.athleteData.height}m
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ marginY: "2rem" }} />
          <Typography variant="h5">ATHLETE INFORMATION</Typography>
          <Box
            sx={{
              marginY: "2rem",
              display: "flex",
              gap: 10,
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}
              >
                <Typography variant="h4" sx={{ color: "#A19D9A" }}>
                  Level Of Experience
                </Typography>
                <Typography variant="h4">
                  {props.experienceLevels.find(
                    (data) => data.id === props.athleteData?.experience_level,
                  )?.experience_level ?? "-"}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}
              >
                <Typography variant="h4" sx={{ color: "#A19D9A" }}>
                  Training Goal
                </Typography>
                {isFulfilled(athleteTrainingGoalsCall) &&
                athleteTrainingGoalsCall.result.length !== 0 ? (
                  props.trainingGoals.map(
                    (datum) =>
                      athleteTrainingGoalsCall.result.some(
                        (data) => data.training_goal_id === datum.id,
                      ) && (
                        <Typography color={"#DD4F4A"} key={datum.id}>
                          {datum.goal}
                        </Typography>
                      ),
                  )
                ) : (
                  <Typography>-</Typography>
                )}
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}
              >
                <Typography variant="h4" sx={{ color: "#A19D9A" }}>
                  LTHR (BPM)
                </Typography>
                <Typography variant="h4">
                  {props.athleteData.lthr ?? "-"}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                width: "500px",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}
              >
                <Typography variant="h4" sx={{ color: "#A19D9A" }}>
                  Max Heart Rate (bpm)
                </Typography>
                <Typography variant="h4">
                  {props.athleteData.max_heart_rate ?? "-"}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}
              >
                <Typography variant="h4" sx={{ color: "#A19D9A" }}>
                  FTP (W)
                </Typography>
                <Typography variant="h4">
                  {props.athleteData.ftp ?? "-"}
                </Typography>
              </Box>
              <Box>
                {withReference(
                  !props.athleteData.max_heart_rate ||
                    props.athleteData.max_heart_rate === 0 ||
                    (props.athleteData.max_heart_rate as any) === "",
                  (disabled) => (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            variant="h4"
                            color="text.primary"
                            sx={{ opacity: "0.5" }}
                          >
                            Heart Rate Zones (BPM)
                          </Typography>
                          <Tooltip
                            title="Your HR training zones are determined using the most common method, i.e. on the % of your maximum heart rate. You can always customize your zones."
                            placement="right"
                            arrow
                          >
                            <img
                              src={TooltipZonesC}
                              alt="info_icon"
                              width="10"
                              height="11"
                            />
                          </Tooltip>
                        </Box>

                        {disabled ? (
                          <Typography
                            variant="h4"
                            color="text.primary"
                            sx={{ opacity: "0.3" }}
                          >
                            (Set Max Heart Rate to unlock)
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Box>
                      {!disabled ? (
                        <Slider
                          track={false}
                          min={Math.floor(
                            0.5 * props.athleteData.max_heart_rate,
                          )}
                          max={Number(props.athleteData.max_heart_rate)}
                          disableSwap
                          valueLabelDisplay="on"
                          marks={heartRateMarks}
                          step={1}
                          value={formik.values.heart_rate_zones}
                          sx={{
                            "& .MuiSlider-valueLabel": {
                              backgroundColor: "transparent",
                              color: "#80822B",
                              margin: 0,
                              fontSize: "10px",
                              marginTop: "5px",
                            },
                            marginTop: "1.25rem",
                            height: "9.5296px",
                            "& .MuiSlider-rail": {
                              background: `linear-gradient(to right, #81A9E7 0%, #81A9E7 ${heart_rate_zones_colors[0]}%, #92E3DA ${heart_rate_zones_colors[0]}%, #92E3DA ${heart_rate_zones_colors[1]}%, #C0DE77 ${heart_rate_zones_colors[1]}%, #C0DE77 ${heart_rate_zones_colors[2]}% , #E28E54 ${heart_rate_zones_colors[2]}%, #E28E54 ${heart_rate_zones_colors[3]}%, #DD4F4A ${heart_rate_zones_colors[3]}%, #DD4F4A 100%)`,
                              opacity: 1,
                            },
                            "& .MuiSlider-thumb": {
                              backgroundImage: `url(${slider})`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundColor: "none",
                              height: ".5rem",
                              width: ".5625rem",
                              boxShadow: "none",
                              top: ".625rem",
                            },
                            "& .MuiSlider-mark": {
                              opacity: 0,
                            },
                            "& .MuiSlider-markLabel": {
                              fontSize: "10px",
                              color: "#F7F3F0",
                            },
                          }}
                          name="heart_rate_zones"
                          onChange={(_, values) =>
                            formik.setFieldValue("heart_rate_zones", values)
                          }
                          key={props.athleteData.max_heart_rate}
                        />
                      ) : (
                        <div style={{ pointerEvents: "none" }}>
                          <DisabledSlider defaultValue={[]} />
                        </div>
                      )}
                    </Box>
                  ),
                )}
                {withReference(
                  !props.athleteData.ftp ||
                    props.athleteData.ftp === 0 ||
                    (props.athleteData.ftp as any) === "",
                  (disabled) => (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            variant="h4"
                            color="text.primary"
                            sx={{ opacity: "0.5" }}
                          >
                            Power Zones (W)
                          </Typography>

                          <Tooltip
                            title="Your Power training zones are determined using the most common method, i.e. on the % of your FTP. You can always customize your zones."
                            placement="right"
                            arrow
                          >
                            <img
                              src={TooltipZonesC}
                              alt="info_icon"
                              width="10"
                              height="11"
                            />
                          </Tooltip>
                        </Box>
                        {disabled ? (
                          <Typography
                            variant="h4"
                            color="text.primary"
                            sx={{ opacity: "0.3" }}
                          >
                            (Set FTP to unlock)
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Box>
                      {!disabled ? (
                        <Slider
                          key={`pwr-${props.athleteData.ftp}`}
                          track={false}
                          min={0}
                          max={(props.athleteData.ftp * 150) / 100}
                          disableSwap
                          valueLabelDisplay="on"
                          marks={powerMarks}
                          step={1}
                          value={formik.values.power_zones}
                          sx={{
                            "& .MuiSlider-valueLabel": {
                              backgroundColor: "transparent",
                              color: "#80822B",
                              margin: 0,
                              fontSize: "10px",
                              marginTop: "5px",
                            },
                            marginTop: "1.25rem",
                            height: "9.5296px",
                            "& .MuiSlider-rail": {
                              background: `linear-gradient(to right, #81A9E7 0%, #81A9E7 ${power_zones_colors[0]}%, #92E3DA ${power_zones_colors[0]}%, #92E3DA ${power_zones_colors[1]}%,#36B37E ${power_zones_colors[1]}%,#36B37E ${power_zones_colors[2]}%, #C0DE77 ${power_zones_colors[2]}%, #C0DE77 ${power_zones_colors[3]}% , #E28E54 ${power_zones_colors[3]}%, #E28E54 ${power_zones_colors[4]}%, #DD4F4A ${power_zones_colors[4]}%, #DD4F4A ${power_zones_colors[5]}%, #EA366F ${power_zones_colors[5]}%, #EA366F 100%)`,
                              opacity: 1,
                            },
                            "& .MuiSlider-thumb": {
                              backgroundImage: `url(${slider})`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundColor: "none",
                              height: ".5rem",
                              width: ".5625rem",
                              boxShadow: "none",
                              top: ".625rem",
                            },
                            "& .MuiSlider-mark": {
                              opacity: 0,
                            },
                            "& .MuiSlider-markLabel": {
                              fontSize: "10px",
                              color: "#F7F3F0",
                            },
                          }}
                          name="power_zones"
                          onChange={(_, values) =>
                            formik.setFieldValue("power_zones", values)
                          }
                        />
                      ) : (
                        <div style={{ pointerEvents: "none" }}>
                          <DisabledSlider defaultValue={[]} />
                        </div>
                      )}
                    </Box>
                  ),
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="coachVariant"
              disabled={isPending(saveProfileSettingsCall)}
              type="submit"
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
}
