import "../../../../assets/scss/components/SessionDataGrid.scss";
import { Database } from "src/utils/DatabaseDefinitions";
import React, { useState, useEffect, useCallback, useMemo } from "react";

import {
  Box,
  Typography,
  InputAdornment,
  TextField,
  styled,
  Button,
  IconButton,
  Popover,
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
  Checkbox,
  ListItemText,
  useMediaQuery,
  useTheme,
  Tooltip,
  Modal,
} from "@mui/material";

import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import CoachFilterDownArrowIcon from "../../../../assets/img/layout/coach/FilterIcon.svg";
import DeleteOutlined from "../../../../assets/img/views/dashboard-sessions/DeleteOutlined.svg";
import ActionsOutlined from "../../../../assets/img/views/dashboard-sessions/ActionsOutlined.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Calendar, DateRange } from "react-date-range";
import "../../../../assets/scss/DatePicker.scss";
import dayjs from "dayjs";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowId,
  GridSortModel,
  gridClasses,
} from "@mui/x-data-grid";

import { SupabaseCall, toHHMMSS, withReference } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import SuspenseLoader from "src/components/SuspenseLoader";
import { Action, useSupabaseReducer } from "src/utils/SupaReducer";
import { Link } from "react-router-dom";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import convert from "convert-units";
import { match, P } from "ts-pattern";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { DataGridFilter, ValueFilter } from "src/types/DataGridFilters";
import { useUser } from "src/components/Authenticated";

type DatabaseWorkouts = Database["public"]["Tables"]["workout"]["Row"];

const workoutsColumns = [
  "id",
  "name",
  "workout_type",
  "duration",
  "distance",
  "created_at",
  "bike_type",
  "tss",
  "position_goal",
] as const;

type WorkoutsColumns = typeof workoutsColumns[number];

type Workouts = Array<Pick<DatabaseWorkouts, WorkoutsColumns>>;

type NewDateFilterComponentProps = {
  onApply: (filter: DataGridFilter<Date>) => void;
  onClose: () => void;
};

function NewDateFilterComponent(props: NewDateFilterComponentProps) {
  const [operation, setOperation] =
    useState<ValueFilter<Date>["operation"]>("less-than-equal");

  //Calendar
  const [date, setDate] = useState<Date>();

  //Date Range

  const [state, setState] = useState<{
    startDate: Date;
    endDate: Date;
    key: "selection";
  }>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleOnChange = useCallback(
    ({ selection }: { selection: typeof state }) => {
      setState(selection);
    },
    [],
  );

  return (
    <>
      <Box sx={{ p: 1, background: "#201B20" }} className="coach-date-picker-wrapper">
        <Box
          sx={{
            display: "flex",
            mb: "10px",
            mt: "16px",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            size="small"
            onClick={(_) => {
              setOperation("less-than-equal");
            }}
            variant={operation === "less-than-equal" ? "coachVariant2" : "customDisabled"}
          >
            Before
          </Button>
          <Button
            size="small"
            onClick={() => {
              setOperation("between");
            }}
            variant={operation === "between" ? "coachVariant2" : "customDisabled"}
          >
            Between
          </Button>

          <Button
            size="small"
            onClick={(_) => {
              setOperation("greater-than-equal");
            }}
            variant={operation === "greater-than-equal" ? "coachVariant2" : "customDisabled"}
          >
            After
          </Button>
        </Box>

        {operation === "between" ? (
          <DateRange
            onChange={handleOnChange}
            ranges={!!state ? [state] : []}
            editableDateInputs
            maxDate={new Date()}
          />
        ) : (
          <Calendar
            onChange={(date) => {
              setDate(date);
            }}
            date={date}
            maxDate={new Date()}
          />
        )}

        <Box sx={{ display: "flex", pt: 1, mb: "24px", ml: "20px" }}>
          <Button
            size="small"
            variant="coachVariant"
            onClick={(_) => {
              props.onApply(
                operation === "between"
                  ? {
                      kind: "value-filter",
                      field: "created_at",
                      operation,
                      startValue: state.startDate,
                      endValue: state.endDate,
                    }
                  : {
                      kind: "value-filter",
                      field: "created_at",
                      operation,
                      value: date,
                    },
              );
              props.onClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </>
  );
}

type NewNumericFilterComponentProps = {
  onApply: (filter: DataGridFilter<number>) => void;
  onClose: () => void;
  metric: string;
  fieldName: string;
};

function NewNumericFilterComponent(props: NewNumericFilterComponentProps) {
  const [operation, setOperation] =
    useState<ValueFilter<number>["operation"]>("between");

  const [min, setMin] = useState<number | "">("");
  const [max, setMax] = useState<number | "">("");

  return (
    <Box
      sx={{
        background: "#201B20",
        padding: "1em",
        height: "250px",
        borderRadius: "4px",
      }}
    >
      <Box>
        <Typography variant="body2">Operator</Typography>
        <Box
          sx={{
            display: "flex",
            mb: "24px",
            mt: "12px",
            gap: 1,
          }}
        >
          <Button
            size="small"
            onClick={() => {
              setOperation("between");
            }}
            variant={operation === "between" ? "coachVariant2" : "customDisabled"}
          >
            <Typography>Between</Typography>
          </Button>
          <Button
            size="small"
            onClick={(_) => {
              setOperation("less-than-equal");
            }}
            variant={
              operation === "less-than-equal" ? "coachVariant2" : "customDisabled"
            }
          >
            <Typography>Less than equal</Typography>
          </Button>

          <Button
            size="small"
            onClick={(_) => {
              setOperation("greater-than-equal");
            }}
            variant={
              operation === "greater-than-equal" ? "coachVariant2" : "customDisabled"
            }
          >
            <Typography>Greater than equal</Typography>
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: { md: "space-between", xs: "space-around" },
          }}
        >
          {operation === "between" ? (
            <>
              <Box>
                <Typography>Value ({props.metric})</Typography>
                <TextField
                  placeholder="Min"
                  value={min}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setMin(Number(e.target.value));
                    }
                  }}
                  sx={{ 
                    width: { xl: "200px", md: "180px", xs: "10rem" },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#DD4F4A",
                    },
                  }}
                  InputProps={{
                    sx: {
                      width: { md: "100%", xs: "90%" },
                    },
                  }}
                />
              </Box>
              <Box>
                <Typography>Value ({props.metric})</Typography>
                <TextField
                  placeholder="Max"
                  value={max}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setMax(Number(e.target.value));
                    }
                  }}
                  sx={{ 
                    width: { xl: "200px", md: "180px", xs: "10rem" },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#DD4F4A",
                    }, 
                  }}
                  InputProps={{
                    sx: {
                      width: { md: "100%", xs: "90%" },
                    },
                  }}
                />
              </Box>
            </>
          ) : (
            <Box>
              <Typography>Value ({props.metric})</Typography>
              <TextField
                placeholder="Value"
                value={min}
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    setMin(Number(e.target.value));
                  }
                }}
                sx={{
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#DD4F4A",
                    },
                }}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", pt: "24px", mb: "24px" }}>
          <Button
            size="small"
            variant="coachVariant"
            disabled={operation === "between" && min > max}
            onClick={(_) => {
              props.onApply(
                operation === "between"
                  ? {
                      kind: "value-filter",
                      field: props.fieldName,
                      operation,
                      startValue: Number(min),
                      endValue: Number(max),
                    }
                  : {
                      kind: "value-filter",
                      field: props.fieldName,
                      operation,
                      value: Number(min),
                    },
              );
              props.onClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

type MultiSelectFilterComponentProps = {
  onApply: (filter: DataGridFilter) => void;
  onClose: () => void;
  fieldName?: string;
  options: Array<{
    label: string;
    value: string;
  }>;
  clearTrigger: boolean;
};

function MultiSelectFilterComponent(props: MultiSelectFilterComponentProps) {
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);

  useEffect(() => {
    setSelectedOptions([]);
  }, [props.clearTrigger]);

  return (
    <Box
      sx={{
        background: "#201B20",
        height: "188px",
        padding: "1em",
        borderRadius: "4px",
      }}
    >
      <Box>
        <Typography variant="body2">Operator</Typography>
        <Box
          sx={{
            display: "flex",
            mb: "48px",
            mt: "12px",
            gap: 1,
            justifyContent: "space-between",
          }}
        >
          {props.options.map((option) => (
            <Button
              size="small"
              key={option.value}
              variant={
                selectedOptions.includes(option.value)
                  ? "coachVariant2"
                  : "customDisabled"
              }
              onClick={(_) =>
                setSelectedOptions((opts) =>
                  opts.includes(option.value)
                    ? opts.filter((opt) => opt !== option.value)
                    : opts.concat([option.value]),
                )
              }
            >
              <Typography>{option.label}</Typography>
            </Button>
          ))}
        </Box>

        <Box sx={{ display: "flex", mb: "24px" }}>
          <Button
            size="small"
            variant="coachVariant"
            onClick={(_) => {
              props.onApply({
                kind: "multiselect-filter",
                field: props.fieldName,
                values: selectedOptions,
              });
              props.onClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export type ShowHideColumnsComponentProps = {
  allColumns: Array<string>;
  defaultSelectedColumns: Array<string>;
  onChange: (selectedColumns: Array<string>) => void;
};

function ShowHideColumnsComponent(props: ShowHideColumnsComponentProps) {
  const [selectedColumns, setSelectedColumns] = useState(
    props.defaultSelectedColumns,
  );

  useEffect(() => {
    props.onChange(selectedColumns);
  }, [selectedColumns]);

  return (
    <Box sx={{ p: 1, background: "#201B20" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: "10px",
          mt: "16px",
        }}
      >
        {props.allColumns.map((option, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              width: "20.625rem",
              '& .Mui-checked, & .MuiCheckbox-root': {
                color: "#DD4F4A",
              },
            }}
            onClick={(_) =>
              selectedColumns.includes(option)
                ? setSelectedColumns(
                    selectedColumns.filter((op) => op !== option),
                  )
                : setSelectedColumns(selectedColumns.concat([option]))
            }
          >
            <Checkbox checked={selectedColumns.includes(option)} />
            <ListItemText primary={option} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default function CoachWorkoutsDataGrid() {
  const user = useUser();
  const supabase = useSupabase();

  const [workouts, dispatch, count] = useSupabaseReducer(
    supabase
      .from("workout")
      .select(
        "*",
        { count: "exact" },
      )
      .eq("user_id", user.id),
    10,
  );

  useEffect(() => {
    dispatch({
      kind: "MODIFY_CALL",
      builder: (builder) => builder.order("created_at", { ascending: false }),
      pageSize: 10,
    });
    dispatch({
      kind: "FETCH",
    });
  }, []);

  return (
    <>
      {workouts === "unloaded" ? (
        <SuspenseLoader color="#DD4F4A"/>
      ) : (
        <WorkoutsDataTableInner
          workouts={workouts as any}
          dispatch={dispatch}
          count={count}
        />
      )}
    </>
  );
}

type WorkoutsDataTableInnerProps = {
  workouts: Workouts;
  dispatch: React.Dispatch<Action<any, any, any>>;
  count: number;
};

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
  border-radius: ${theme.general.borderRadius};

  .MuiOutlinedInput-root {
    width:15.5rem
  } 
`,
);

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "#2C262D",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#201B20",
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    "&:hover": {
      backgroundColor: "#342C35",
    },
  },
}));

function WorkoutsDataTableInner(props: WorkoutsDataTableInnerProps) {
  const supabase = useSupabase();
  const units = useUnits();
  const theme = useTheme();
  const customBreakpoint = useMediaQuery(theme.breakpoints.down("custom"));
  const isMobileScreen = useMediaQuery("(max-width:480px)");
  const isSmallTablet = useMediaQuery("(max-width:710px)");
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [clearTrigger, setClearTrigger] = useState(false);

  const deleteWorkoutPromise = useCallback(
    (workout_id: number) =>
      supabase
        .from("workout")
        .delete()
        .eq("id", workout_id)
        .throwOnError()
        .then((_) => workout_id),
    [supabase],
  );

  const deleteWorkoutCall = useAsyncState<number>();

  const [fieldToDelete, setFieldToDelete] =
    useState<GridRenderCellParams<any, any, any>>(null);

  const allColumns: Array<GridColDef> = useMemo(
    () => [
      {
        field: "created_at",
        headerName: "Created At",
        flex: 1,
        renderCell: (field) =>
          withReference(new Date(field.value), (date) => (
            <Box
              sx={{
                width: "100%",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Box>
                <Typography variant="h4">
                  {date.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </Typography>
              </Box>
            </Box>
          )),
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        filterable: false,
        editable: false,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Tooltip title={field.value}>
              <Box
                sx={{
                  width: "100%",
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {field.value}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        ),
      },
      {
        field: "distance",
        headerName: "Distance",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Box>
              <Typography variant="h4">
                {units === "metric"
                  ? `${field?.value?.toFixed(2) ?? "0"} km`
                  : `${convert(field?.value)
                      ?.from("km")
                      .to("mi")
                      ?.toFixed(2)} mi`}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "duration",
        headerName: "Duration",
        flex: 1,
        filterable: false,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Box>
              <Typography variant="h4">
                {toHHMMSS(field.value)}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "workout_type",
        headerName: "Workout Type",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Box>
              <Typography variant="h4">
                {field.value}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "bike_type",
        headerName: "Bike Type",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Box>
              <Typography variant="h4">
                {field.value}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "tss",
        headerName: "TSS",
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        hideSortIcons: true,
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "15px",
            }}
          >
            <Box>
              <Typography variant="h4">{field.value}</Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "position_goal",
        headerName: "Position Goal",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "15px",
            }}
          >
            <Typography variant="h4">{field.value}</Typography>
          </Box>
        ),
      },
      {
        field: "undefined",
        headerName: "Actions",
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        hideSortIcons: true,

        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "6px",
            }}
          >
            <Stack direction={"row"}>
              <IconButton
                onClick={() => {
                  setOpenDialog(true);
                  setFieldToDelete(field);
                }}
              >
                <img src={DeleteOutlined} alt="delete-icon" width={"15px"} />
              </IconButton>

              <IconButton
                component={Link}
                to={`/coach/library/workouts/edit_workout/${field.row.id}`}
              >
                <img src={ActionsOutlined} alt="actions-icon" width={"15px"} />
              </IconButton>
            </Stack>
          </Box>
        ),
      }
    ],
    [],
  );

  const [columns, setColumns] = useState<Array<GridColDef>>(allColumns);

  const [deletedWorkouts, setDeletedWorkouts] = useState<Array<number>>([]);

  // When a user delete's a workout append the deleted workout id to the deletedWorkouts state
  useEffect(() => {
    if (isFulfilled(deleteWorkoutCall)) {
      setDeletedWorkouts((workouts) =>
        workouts.concat([deleteWorkoutCall.result]),
      );
      deleteWorkoutCall.unload();
    }
  }, [deleteWorkoutCall]);

  const rows = useMemo(
    () =>
      props.workouts?.filter(
        (workout) => !deletedWorkouts.includes(workout.id),
      ),
    [props.workouts, deletedWorkouts],
  );

  const [search, setSearch] = useState("");
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [filters, setFilters] = useState<
    Array<DataGridFilter<number | Date | string>>
  >([]);

  const constructCall: (
    searchInput?: string,
    sortModelInput?: GridSortModel,
  ) => Action<any, any, any> = useCallback(
    (searchInput?, sortModelInput?) => ({
      kind: "MODIFY_CALL",
      pageSize,
      builder: (builder) =>
        withReference(searchInput ?? search, (search) =>
          withReference(
            search !== ""
              ? builder.ilike("name", `%${search}%`)
              : builder,
            (builder) =>
              withReference(sortModelInput ?? sortModel, (sortModel) =>
                withReference(
                  sortModel.length !== 0 && !!sortModel[0].sort
                    ? builder.order(
                        sortModel[0].field,
                        {
                          ascending: sortModel[0].sort === "asc",
                        },
                      )
                    : builder.order("created_at", { ascending: false }),
                  (builder) =>
                    filters.reduce(
                      (currentBuilder, filter) =>
                        match(filter)
                          .with(
                            {
                              field: "distance",
                              operation: "between",
                              startValue: P.select("startValue"),
                              endValue: P.select("endValue"),
                            },
                            ({ startValue, endValue }) =>
                              currentBuilder
                                .gte(
                                  "distance",
                                  units === "metric"
                                    ? startValue
                                    : convert(startValue as number)
                                        .from("mi")
                                        .to("km"),
                                )
                                .lte(
                                  "distance",
                                  units === "metric"
                                    ? endValue
                                    : convert(endValue as number)
                                        .from("mi")
                                        .to("km"),
                                ),
                          )
                          .with(
                            {
                              field: "distance",
                              operation: "less-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.lte(
                                "distance",
                                units === "metric"
                                  ? value
                                  : convert(value as number)
                                      .from("mi")
                                      .to("km"),
                              ),
                          )
                          .with(
                            {
                              field: "distance",
                              operation: "greater-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.gte(
                                "distance",
                                units === "metric"
                                  ? value
                                  : convert(value as number)
                                      .from("mi")
                                      .to("km"),
                              ),
                          )
                          .with(
                            {
                              field: "duration",
                              operation: "between",
                              startValue: P.select("startValue"),
                              endValue: P.select("endValue"),
                            },
                            ({ startValue, endValue }) =>
                              currentBuilder
                                .gte(
                                  "duration",
                                  Number(startValue) * 60
                                )

                                .lte(
                                  "duration",
                                  Number(endValue) * 60
                                ),
                          )

                          .with(
                            {
                              field: "duration",
                              operation: "less-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.lte(
                                "duration",
                                Number(value) * 60
                              ),
                          )
                          .with(
                            {
                              field: "duration",
                              operation: "greater-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.gte(
                                "duration",
                                Number(value) * 60
                              ),
                          )
                          .with(
                            {
                              field: "workout_type",
                              values: P.select("values"),
                            },
                            ({ values }) =>
                              (values as Array<string>).length === 0
                                ? currentBuilder
                                : currentBuilder.in(
                                    "workout_type",
                                    values as Array<string>,
                                  ),
                          )
                          .with(
                            {
                              field: "position_goal",
                              values: P.select("values"),
                            },
                            ({ values }) =>
                              (values as Array<string>).length === 0
                                ? currentBuilder
                                : currentBuilder.in(
                                    "position_goal",
                                    values as Array<string>,
                                  ),
                          )
                          .with(
                            {
                              field: "bike_type",
                              values: P.select("values"),
                            },
                            ({ values }) =>
                              (values as Array<string>).length === 0
                                ? currentBuilder
                                : currentBuilder
                                    .in(
                                      "bike_type",
                                      values as Array<string>,
                                    ),
                          )
                          .with(
                            {
                              field: "created_at",
                              operation: "less-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.lte(
                                "created_at",
                                dayjs(value as Date).format("MM/DD/YYYY"),
                              ),
                          )
                          .with(
                            {
                              field: "created_at",
                              operation: "greater-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.gte(
                                "created_at",
                                dayjs(value as Date).format("MM/DD/YYYY"),
                              ),
                          )
                          .with(
                            {
                              field: "created_at",
                              operation: "between",
                              startValue: P.select("startValue"),
                              endValue: P.select("endValue"),
                            },
                            ({ startValue, endValue }) =>
                              currentBuilder
                                .gte(
                                  "created_at",
                                  dayjs(startValue as Date).format(
                                    "MM/DD/YYYY",
                                  ),
                                )
                                .lte(
                                  "created_at",
                                  dayjs(endValue as Date).format("MM/DD/YYYY"),
                                ),
                          )
                          .otherwise(
                            (_) => (console.log("unmatched", _), builder),
                          ),
                      builder,
                    ),
                ),
              ),
          ),
        ),
    }),
    [search, sortModel, units, filters],
  );

  const triggerFetch = useCallback(
    (searchInput?: string, sortModelInput?: GridSortModel) => {
      props.dispatch(constructCall(searchInput, sortModelInput));
      props.dispatch({ kind: "FETCH" });
      setCurrentPage(0);
    },
    [constructCall],
  );

  const [currentPage, setCurrentPage] = useState(0);

  //Date Popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  //Values Popover

  //Distance

  const [anchorElVal, setAnchorElVal] = useState(null);

  const handlePopoverOpenVal = (event) => {
    setAnchorElVal(event.currentTarget);
  };

  const handlePopoverCloseVal = () => {
    setAnchorElVal(null);
  };

  const openVal = Boolean(anchorElVal);

  //Speed

  const [anchorElValS, setAnchorElValS] = useState(null);

  const handlePopoverOpenValS = (event) => {
    setAnchorElValS(event.currentTarget);
  };

  const handlePopoverCloseValS = () => {
    setAnchorElValS(null);
  };

  const openValS = Boolean(anchorElValS);

  //Power

  const [anchorElValP, setAnchorElValP] = useState(null);

  const handlePopoverOpenValP = (event) => {
    setAnchorElValP(event.currentTarget);
  };

  const handlePopoverCloseValP = () => {
    setAnchorElValP(null);
  };

  const openValP = Boolean(anchorElValP);

  //Bike Type

  const [anchorElSelectB, setAnchorElSelectB] = useState(null);

  const handlePopoverOpenSelectB = (event) => {
    setAnchorElSelectB(event.currentTarget);
  };

  const handlePopoverCloseSelectB = () => {
    setAnchorElSelectB(null);
  };

  const openSelectB = Boolean(anchorElSelectB);

  // Provider

  const [anchorElSelectP, setAnchorElSelectP] = useState(null);

  const handlePopoverOpenSelectP = (event) => {
    setAnchorElSelectP(event.currentTarget);
  };

  const handlePopoverCloseSelectP = () => {
    setAnchorElSelectP(null);
  };

  const openSelectP = Boolean(anchorElSelectP);

  //Visibility

  const [anchorElMulti, setAnchorElMulti] = useState(null);

  const handlePopoverOpenMulti = (event) => {
    setAnchorElMulti(event.currentTarget);
  };

  const handlePopoverCloseMulti = () => {
    setAnchorElMulti(null);
  };

  const openMulti = Boolean(anchorElMulti);

  //Filters

  useEffect(() => {
    triggerFetch();
  }, [filters]);

  const handleFilterApply = useCallback(
    (filter) => {
      setFilters((filters) =>
        !!filters.find((f) => f.field === filter.field)
          ? filters.map((f) => (f.field === filter.field ? { ...filter } : f))
          : filters.concat([filter]),
      );
    },
    [setFilters],
  );
  return (
    <>
      <Modal open={openDialog} onClose={setOpenDialog}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#2A252B",
            border: "3px solid #DD4F4A",
            borderRadius: "6px",
            p: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="coach700" component="h2" mt={4}>
            Remove Workout
          </Typography>
          <Typography component="h2" mt={4}>
            Are you sure you want to remove this workout?
          </Typography>
          <Typography component="h2" mt={1}>
            All your data will be lost.
          </Typography>
          <Box sx={{ mt: 6, display: "flex", gap: 2 }}>
            <Button
              onClick={() => setOpenDialog(false)}
              variant="coachVariant2"
              size="small"
              sx={{ width: "155px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={(_) => {
                if (isUnloaded(deleteWorkoutCall)) {
                  deleteWorkoutCall.fire(async () =>
                    deleteWorkoutPromise(fieldToDelete.row.id),
                  );
                  setOpenDialog(false);
                }
              }}            
              variant="coachVariant"
              size="small"
              sx={{ width: "155px" }}
              color="error"
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          display: "flex",
          flexDirection: customBreakpoint ? "column" : "row",
          justifyContent: customBreakpoint ? "center" : "space-between",
          alignItems: customBreakpoint ? "center" : "none",
          marginBottom: "25px",
          gap: customBreakpoint ? "2rem" : "none",
        }}
      >
        {!isMobileScreen && (
          <Box
            sx={{
              width: customBreakpoint ? "100%" : "65%",
              padding: customBreakpoint ? "0.5em" : "none",
              backgroundColor: "#2C262D",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              height: "38px",
            }}
          >
            <Box
              onClick={handlePopoverOpen}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Created At</Typography>
              <IconButton>
                <img src={CoachFilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>
            <Box
              onClick={handlePopoverOpenVal}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Distance</Typography>
              <IconButton>
                <img src={CoachFilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>
            <Box
              onClick={handlePopoverOpenValS}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Duration</Typography>
              <IconButton>
                <img src={CoachFilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>
            <Box
              onClick={handlePopoverOpenValP}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Workout Type</Typography>
              <IconButton>
                <img src={CoachFilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>

            <Box
              onClick={handlePopoverOpenSelectB}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Bike Type</Typography>
              <IconButton>
                <img src={CoachFilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>

            <Box
              onClick={handlePopoverOpenSelectP}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Position Goal</Typography>
              <IconButton>
                <img src={CoachFilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <NewDateFilterComponent
                onApply={handleFilterApply}
                onClose={handlePopoverClose}
              />
            </Popover>
            <Popover
              open={openVal}
              anchorEl={anchorElVal}
              onClose={handlePopoverCloseVal}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <NewNumericFilterComponent
                metric={units === "metric" ? "km" : "mi"}
                fieldName="distance"
                onApply={handleFilterApply}
                onClose={handlePopoverCloseVal}
              />
            </Popover>
            <Popover
              open={openValS}
              anchorEl={anchorElValS}
              onClose={handlePopoverCloseValS}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <NewNumericFilterComponent
                metric={"minutes"}
                fieldName="duration"
                onApply={handleFilterApply}
                onClose={handlePopoverCloseValS}
              />
            </Popover>
            <Popover
              open={openValP}
              anchorEl={anchorElValP}
              onClose={handlePopoverCloseValP}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <MultiSelectFilterComponent
                fieldName="workout_type"
                onApply={handleFilterApply}
                onClose={handlePopoverCloseValP}
                options={[
                  { label: "Base", value: "Base" },
                  { label: "Recovery", value: "Recovery" },
                  { label: "Endurance", value: "Endurance" },
                  { label: "Intervals", value: "Intervals" },
                  { label: "Tempo", value: "Tempo" },
                ]}
                clearTrigger={clearTrigger}
              />
            </Popover>
            <Popover
              open={openSelectB}
              anchorEl={anchorElSelectB}
              onClose={handlePopoverCloseSelectB}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <MultiSelectFilterComponent
                fieldName="bike_type"
                onApply={handleFilterApply}
                onClose={handlePopoverCloseSelectB}
                options={[
                  { label: "Road", value: "Road" },
                  { label: "TT", value: "TT" },
                ]}
                clearTrigger={clearTrigger}
              />
            </Popover>
            <Popover
              open={openSelectP}
              anchorEl={anchorElSelectP}
              onClose={handlePopoverCloseSelectP}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <MultiSelectFilterComponent
                fieldName="position_goal"
                onApply={handleFilterApply}
                onClose={handlePopoverCloseSelectP}
                options={[
                  { label: "Free", value: "Free" },
                  { label: "Comfortable", value: "Comfortable" },
                  { label: "Aggressive", value: "Aggressive" },
                  { label: "TT", value: "TT" },
                  { label: "Standing", value: "Standing" },
                ]}
                clearTrigger={clearTrigger}
              />
            </Popover>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: customBreakpoint ? "100%" : "58%",
            justifyContent: customBreakpoint ? "center" : "flex-end",
            gap: "1rem",
            flexDirection: isSmallTablet ? "column" : "row",
          }}
        >
          <Box sx={{ display: "flex", gap: "1rem" }}>
            {!isMobileScreen && (
              <Button
                variant="text"
                sx={{ color: "#F7F3F0", border: "1px solid #F7F3F0" }}
                onClick={(_) => (setFilters([]), setClearTrigger((tr) => !tr))}
              >
                Reset Filters
              </Button>
            )}

            <SearchInputWrapper
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon sx={{ opacity: "0.5" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "15.5rem",
                "& .MuiOutlinedInput-root": {
                  width: isMobileScreen ? "12rem" : "15.5rem",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#DD4F4A",
                },
              }}
              placeholder="Search workouts..."
              onKeyDown={(e) => e.code === "Enter" && triggerFetch()}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>

          <Box sx={{ display: "flex" }}>
            {!isMobileScreen && (
              <IconButton
                sx={{ color: "#DD4F4A", p: 1 }}
                onClick={handlePopoverOpenMulti}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            )}
          </Box>
          <Popover
            open={openMulti}
            anchorEl={anchorElMulti}
            onClose={handlePopoverCloseMulti}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <ShowHideColumnsComponent
              allColumns={allColumns
                .filter(
                  (col) =>
                    col.headerName !== "Actions" &&
                    col.headerName !== "Selection",
                )
                .map((col) => col.headerName)}
              defaultSelectedColumns={columns.map((col) => col.headerName)}
              onChange={(cols) =>
                setColumns(
                  allColumns.filter(
                    (col) =>
                      cols.includes(col.headerName) ||
                      col.headerName === "Actions" ||
                      col.headerName === "Selection",
                  ),
                )
              }
            />
          </Popover>
        </Box>
      </Box>
      <CustomDataGrid
        disableColumnMenu
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        sortingMode="server"
        filterMode="server"
        onSortModelChange={(model) => {
          if (model?.[0]?.field !== "undefined") {
            setSortModel(model);
            triggerFetch(search, model);
          }
        }}
        rowCount={props.count}
        pageSize={pageSize}
        page={currentPage}
        onPageChange={(newPage) => {
          if (newPage > currentPage) {
            props.dispatch({ kind: "NEXT_PAGE" });
          } else {
            props.dispatch({ kind: "PREV_PAGE" });
          }
          setCurrentPage(newPage);
        }}
        onPageSizeChange={(newPageSize) => {
          props.dispatch({ kind: "CHANGE_PAGE_SIZE", pageSize: newPageSize });
          setPageSize(newPageSize);
          setCurrentPage(0);
        }}
        rowsPerPageOptions={[10, 20, 50]}
        paginationMode="server"
        autoHeight
        keepNonExistentRowsSelected
        columnTypes={{
          [GRID_CHECKBOX_SELECTION_COL_DEF.type]:
            GRID_CHECKBOX_SELECTION_COL_DEF,
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        components={{
          ColumnSortedAscendingIcon: ExpandLessIcon,
          ColumnSortedDescendingIcon: ExpandMoreIcon,
        }}
        columnVisibilityModel={{
          position_goal: isMobileScreen ? false : true,
          distance: isMobileScreen ? false : true,
          time: isMobileScreen ? false : true,
          bike_type: isMobileScreen ? false : true,
          undefined: isMobileScreen ? false : true,
        }}
        sx={{
          "& .MuiDataGrid-cell[data-field='undefined']": {
            padding: 0,
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "solid #DD4F4A 1px"
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "solid rgba(221, 79, 74, 0.5) 1px"
          },
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "none",
            },
          "& .MuiDataGrid-columnHeaders": {
            color: "#DD4F4A"
          },
          "& .MuiDataGrid-sortIcon": {
            color: "#DD4F4A"
          },
        }}
      />
    </>
  );
}
