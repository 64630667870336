import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useFormik } from "formik";
import { useMemo, useCallback, useEffect, useState } from "react";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import * as yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatchNotification } from "src/contexts/NotificationContext";
import { Json } from "src/utils/DatabaseDefinitions";
import { useSnackbar } from "notistack";

type ListType = "General" | "Maintenance" | "Subscription" | "Offer";

const types: Array<ListType> = [
  "General",
  "Maintenance",
  "Subscription",
  "Offer",
];

interface NotificationFormValues {
  type: string;
  description: string;
  dismissable: boolean;
  user_ids: Array<string>;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AdminNotificationsView() {
  const supabase = useSupabase();
  const dispatcher = useDispatchNotification();

  const [value, setValue] = useState([]);

  const allUsersPromise = useCallback(
    () => supabase.functions.invoke("list-all-users"),
    [supabase],
  );

  const allUsersCall = useAsyncState<any>();

  useEffect(() => {
    if (isUnloaded(allUsersCall)) {
      allUsersCall.fire(async () => allUsersPromise());
    }
  }, [allUsersPromise, allUsersCall]);

  // Admin Related
  const adminPromise = useCallback(
    () =>
      supabase
        .from("admin")
        .select()
        .throwOnError()
        .then((res) => res.data),
    [supabase],
  );

  const adminCall = useAsyncState<SupabaseCall<typeof adminPromise>>();

  useEffect(() => {
    if (isUnloaded(adminCall)) {
      adminCall.fire(async () => adminPromise());
    }
  }, [adminCall, adminPromise]);

  const allUsers = useMemo(() => {
    if (isFulfilled(allUsersCall) && isFulfilled(adminCall)) {
      return allUsersCall.result.data.data.filter((user) =>
        adminCall.result.every((admin) => admin.user_id !== user.id),
      );
    }
  }, [allUsersCall, adminCall]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        type: yup
          .string()
          .required("Type is required")
          .oneOf(types, "Please select a valid type"),
        description: yup.string().required("Description is required").max(50),
        dismissable: yup
          .boolean()
          .required("Dismissable must be either true or false.")
          .oneOf([true, false], "Dismissable must be either true or false."),
        user_ids: yup
          .array()
          .min(1)
          .required("at least one item needs to be here"),
      }),
    [],
  );

  const notificationFormik = useFormik<NotificationFormValues>({
    initialValues: {
      type: "",
      description: "",
      dismissable: true,
      user_ids: [],
    },
    validationSchema,
    onSubmit: (values) => {
      values.user_ids.map((id) => {
        dispatcher({
          type: values.type,
          content: { description: values.description },
          dismissable: values.dismissable,
          user_id: id,
        });
      });
      notificationFormik.resetForm();
      setValue([]);
    },
  });

  useEffect(() => {
    const ids = value.map((item) => item["id"]);
    notificationFormik.setFieldValue("user_ids", ids);
  }, [value]);

  // useEffect(() => {
  //   dispatcher((notificiation) => console.log(notificiation))
  // }, [dispatcher]);
  // //SnackBar
  // const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   enqueueSnackbar("Notifications Sent!", {
  //     anchorOrigin: {
  //       horizontal: "center",
  //       vertical: "top",
  //     },
  //     variant: "success",
  //     autoHideDuration: 2000,
  //   });
  // }, []);

  return (
    isFulfilled(allUsersCall) &&
    isFulfilled(adminCall) && (
      <form noValidate onSubmit={notificationFormik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "20px",
            ml: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <FormControl>
              <InputLabel id="experience_level">Type</InputLabel>
              <Select
                id="type"
                name="type"
                value={notificationFormik.values.type ?? ""}
                onChange={notificationFormik.handleChange}
                label="Type"
                error={
                  notificationFormik.touched.type &&
                  Boolean(notificationFormik.errors.type)
                }
              >
                {types.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <TextField
                name="description"
                variant="outlined"
                label="Description"
                onBlur={notificationFormik.handleBlur}
                value={notificationFormik.values.description ?? ""}
                onChange={notificationFormik.handleChange}
                error={
                  notificationFormik.touched.description &&
                  Boolean(notificationFormik.errors.description)
                }
                helperText={
                  notificationFormik.touched.description &&
                  notificationFormik.errors.description
                }
              />
            </FormControl>
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormLabel sx={{ alignSelf: "center" }}>
                <Typography
                  color="text.primary"
                  sx={{
                    opacity: "0.5",
                    marginRight: "1.25rem",
                    fontSize: { xs: "12px", xl: "12px" },
                  }}
                >
                  Dismissable
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                onChange={notificationFormik.handleChange}
                value={notificationFormik.values.dismissable}
                name="dismissable"
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  "& .MuiFormControlLabel-label": { opacity: 0.5 },
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label={<span style={{ fontSize: "12px" }}>True</span>}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label={<span style={{ fontSize: "12px" }}>False</span>}
                />
              </RadioGroup>
            </FormControl>
            <Autocomplete
              multiple
              fullWidth
              value={value}
              limitTags={3}
              filterOptions={(options, params) => {
                const filter = createFilterOptions();
                const filtered = filter(options, params);
                return [{ email: "Select All...", all: true }, ...filtered];
              }}
              onChange={(event, newValue) => {
                if (newValue.find((option) => option.all))
                  return setValue(
                    value.length === allUsers?.length ? [] : allUsers,
                  );
                setValue(newValue);
              }}
              options={allUsers}
              disableCloseOnSelect
              getOptionLabel={(option) => option.email}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={
                      option.all
                        ? !!(value.length === allUsers?.length)
                        : selected
                    }
                  />
                  {option.all
                    ? option.email
                    : `${option.user_metadata?.name}-${option.email}`}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Users" />}
            />
            <Button
              variant="contained"
              disabled={!notificationFormik.isValid}
              type="submit"
            >
              Send
            </Button>
          </Box>
        </Box>
      </form>
    )
  );
}
