import { Box, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Database } from "src/utils/DatabaseDefinitions";
import MyRidesMap from "./MyRidesMap";
import { useCallback, useMemo } from "react";
import IndoorPlaceholder from "../../../assets/img/views/dashboard-widgets/IndoorPlaceholder.svg";
import { Link } from "react-router-dom";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import convert from "convert-units";
import { withReference } from "src/utils/common";

export type MyRidesCardProps = {
  metrics: Database["public"]["Tables"]["metrics"]["Row"][];
  sessions: Database["public"]["Tables"]["session"]["Row"];
  ride_type: string;
};

export default function MyRidesCard(props: MyRidesCardProps) {
  const units = useUnits();
  const isMobileScreen = useMediaQuery("(max-width:440px)");
  const timeFormatter = useCallback((valStr) => {
    const val = Number(valStr);
    const hours = Math.floor(Math.floor(val / 60) / 60);
    const minutes = Math.trunc((val % 3600) / 60);
    const seconds = Math.trunc(val % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  }, []);

  const filteredMarkers = useCallback(
    (markers: [number, number][]) =>
      withReference(
        markers.findIndex((el) => !!el[0] && !!el[1]),
        (index) =>
          index === -1
            ? []
            : markers
                .slice(0, index)
                .map((el, i, arr) => markers[index])
                .concat(markers.slice(index))
                .map((el, i, arr) =>
                  !!el[0] && !!el[1]
                    ? el
                    : arr.slice(0, i).reverse()[
                        arr
                          .slice(0, i)
                          .reverse()
                          .findIndex((el) => !!el[0] && !!el[1])
                      ],
                ),
      ),
    [],
  );
  const allFilteredMarkers = useMemo(
    () =>
      filteredMarkers(
        props.metrics
          .filter((metric) => metric.longitude !== 0)
          .map((metric) => [metric.latitude, metric.longitude]),
      ),
    [props.metrics],
  );

  return (
    <>
      {props.ride_type === "Outdoor" ? (
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            alignItems: "center",
            height: {
              xs: "214px",
              xl: "260px",
            },
            paddingRight: "1rem",
            overflow: "hidden",
          }}
        >
          <MyRidesMap markers={allFilteredMarkers} />
        </Box>
      ) : (
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            alignItems: "center",
            height: {
              xs: "214px",
              xl: "260px",
            },
            paddingRight: "1rem",
            overflow: "hidden",
          }}
        >
          <img src={IndoorPlaceholder} width={"95%"} />
        </Box>
      )}

      <Box marginTop={{ xs: "12px", xl: "40px" }}>
        <Typography
          variant="h5"
          color={"white"}
          component={Link}
          to={`/dashboard/session/${props?.sessions?.id}`}
          sx={{
            textTransform: "uppercase",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1 /* Number of lines to show */,
            WebkitBoxOrient: "vertical",
            textDecoration: "none",
          }}
        >
          {props.sessions?.recording_name ?? "-"}
        </Typography>

        <Typography
          sx={{
            fontSize: isMobileScreen ? "1.5rem" : { xs: "2.5rem", md: "3rem" },
            fontWeight: 700,
            color: "#BC6D29",
          }}
        >
          {units === "metric"
            ? `${
                (props.sessions?.total_distance?.toFixed(0) || undefined) ?? "-"
              } km`
            : `${
                (props.sessions?.total_distance
                  ? convert(props.sessions.total_distance)
                      ?.from("km")
                      ?.to("mi")
                      ?.toFixed(2)
                  : `-`) + ` mi`
              }`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Grid
          container
          width={"50%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {units === "metric"
                ? `${
                    (props.sessions?.average_speed?.toFixed(0) || undefined) ??
                    "-"
                  } kph`
                : `${
                    props.sessions?.average_speed
                      ? (props.sessions?.average_speed * 0.6213711922)?.toFixed(
                          0,
                        )
                      : "-"
                  } mi`}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Avg Speed
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {`${
                (props.sessions?.average_heart_rate?.toFixed(0) || undefined) ??
                "-"
              } bpm`}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Average Heart Rate
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {units === "metric"
                ? `${
                    (props.sessions?.elevation_gain?.toFixed(0) || undefined) ??
                    "-"
                  } m`
                : `${
                    (props.sessions?.elevation_gain
                      ? convert(props.sessions.elevation_gain)
                          ?.from("m")
                          ?.to("ft")
                          ?.toFixed(0)
                      : `-`) + ` ft`
                  }`}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Elevation Gain
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {`${
                (props.sessions?.average_power?.toFixed(0) || undefined) ?? "-"
              } W`}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Weighted Avg. Power
            </Typography>
            <Grid item>
              <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
                {`${props.sessions?.provider ?? "-"}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          width={"50%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-end",
            textAlign: "end",
            marginRight: "15px",
          }}
        >
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {`${
                props.sessions?.total_time
                  ? timeFormatter(props.sessions?.total_time / 1000)
                  : "-"
              }`}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Moving Time
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {`${
                (props.sessions?.average_cadence?.toFixed(0) || undefined) ??
                "-"
              } rpm`}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Average Cadence
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {units === "metric"
                ? `${
                    (props.sessions?.elevation_loss?.toFixed(0) || undefined) ??
                    "-"
                  } m`
                : `${
                    (props.sessions?.elevation_loss
                      ? convert(props.sessions.elevation_loss)
                          ?.from("m")
                          ?.to("ft")
                          ?.toFixed(0)
                      : `-`) + ` ft`
                  }`}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Elevation Loss
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {props.sessions?.calories?.toFixed(0) ?? 0}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              kcals
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography
        color={"white"}
        component={Link}
        to={`/dashboard/session/${props?.sessions?.id}`}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          marginRight: "0.5rem",
          marginTop: { xs: "0.4rem", xl: "5rem" },
        }}
      >
        View More
      </Typography>
    </>
  );
}
