import {
  Card,
  List,
  Button,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  styled,
  Grid,
  useMediaQuery,
} from "@mui/material";

import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import SuspenseLoader from "src/components/SuspenseLoader";

export type ConnectedAppComponentProps = {
  title: string;
  // description: string;
  image: JSX.Element;
  connected: boolean;
  connectComponent: JSX.Element;
  revokeComponent: JSX.Element;
  isLoading: boolean;
};

const RootWrapper = styled(Card)(
  ({ theme }) => `
    background: ${theme.colors.gradients.black2};
    color: ${theme.colors.alpha.trueWhite[100]};
    
    .MuiIconButton-root {
      background: ${theme.colors.alpha.trueWhite[10]};
      color: ${theme.colors.alpha.trueWhite[70]};
      
      &:hover {
        background: ${theme.colors.alpha.trueWhite[10]};
        color: ${theme.colors.alpha.trueWhite[100]};
      }
    }
    
    .MuiAvatarGroup-avatar {
      background: ${theme.colors.alpha.trueWhite[10]};
      color: ${theme.colors.alpha.trueWhite[70]} !important;
    }
    
    .MuiList-root {
      .MuiButtonBase-root {
        color: ${theme.colors.alpha.trueWhite[70]};
      
        &:hover, &:active, .active {
            background: ${theme.colors.alpha.trueWhite[10]};
            color: ${theme.colors.alpha.trueWhite[100]};
        }
      }
      
      .MuiListItemAvatar-root {
           
            margin-right: ${theme.spacing(1.5)};
            width: ${theme.spacing(5)};
            height: ${theme.spacing(5)};
            display: flex;
            align-items: center;
            justify-content: center;
      }
    }
`,
);

const ListItemWrapper = styled(ListItemButton)(
  () => `
    border-radius: 0;
`,
);

export default function ConnectedAppComponent(
  props: ConnectedAppComponentProps,
) {
  const isMobileScreen = useMediaQuery("(max-width:400px)");

  return (
    <>
      <Grid
        container
        sx={{
          paddingTop: "2em",
          justifyContent: "center",
        }}
      >
        <Grid item md={6} sm={8} xs={12}>
          <RootWrapper
            sx={{
              height: "100%",
            }}
          >
            <List
              disablePadding
              sx={{ border: "1px solid #FFFFFF", borderRadius: "6px" }}
            >
              <ListItemWrapper>
                <ListItemAvatar
                  sx={{
                    minWidth: "36px",
                  }}
                >
                  {props.image}
                </ListItemAvatar>

                {!isMobileScreen && (
                  <ListItemText
                    primary={props.title}
                    primaryTypographyProps={{
                      variant: "h5",
                      noWrap: true,
                      color: "#F7F3F0",
                    }}
                  />
                )}

                {props.isLoading ? (
                  <SuspenseLoader />
                ) : !props.connected ? (
                  <>{props.connectComponent}</>
                ) : (
                  <>{props.revokeComponent}</>
                )}
                <ChevronRightTwoToneIcon />
              </ListItemWrapper>
            </List>
          </RootWrapper>
        </Grid>
      </Grid>
    </>
  );
}
