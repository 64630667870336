import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  CircleMarker,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import AntPath from "../../AntPath";
import { useCallback, useEffect, useMemo, useState } from "react";
import blueMarkerIcon from "leaflet/dist/images/marker-icon.png";
import redMarkerIcon from "../../../../assets/marker-icon-red.png";
import { allColors, type BikePosition, withReference } from "src/utils/common";
import type im from "immutable";
import type { PlaybackLabels, Speciments } from "../AnalyticsNative";
import { Box, Typography } from "@mui/material";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import "../../../../assets/scss/MapComponent.scss";

export type MapProps = {
  markers?: Array<[number, number]>;
  currentHover?: [number, number];
  currentPlayingIndex?: number;
  playbackLabels?: PlaybackLabels;
  selectedPositions?: im.Set<BikePosition>;
  allSpeciments?: Array<Speciments>;
  zoom?: {
    xaxis: "distance" | "time" | "previous" | "last" | "next";
    min: number;
    max: number;
  };
};

export default function MapNative(props: MapProps) {
  const [mapFitted, setMapFitted] = useState(false);
  const blueMarker = useMemo(
    () =>
      new L.Icon({
        iconUrl: blueMarkerIcon,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
      }),
    [],
  );

  const redMarker = useMemo(
    () =>
      new L.Icon({
        iconUrl: redMarkerIcon,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
      }),
    [],
  );

  const filteredMarkers = useCallback(
    (markers: [number, number][]) =>
      withReference(
        markers.findIndex((el) => !!el[0] && !!el[1]),

        (index) =>
          index === -1
            ? []
            : markers
                .slice(0, index)
                .map((el, i, arr) => markers[index])
                .concat(markers.slice(index))
                .map((el, i, arr) =>
                  !!el[0] && !!el[1]
                    ? el
                    : arr.slice(0, i).reverse()[
                        arr
                          .slice(0, i)
                          .reverse()
                          .findIndex((el) => !!el[0] && !!el[1])
                      ],
                ),
      ),
    [],
  );

  const zoomMarkers: {
    before: Array<[number, number]>;
    after: Array<[number, number]>;
    between: Array<[number, number]>;
  } = useMemo(() => {
    if (!props.zoom) {
      return undefined;
    }
    const before = [];
    const between = [];
    const after = [];

    for (const speciment of props.allSpeciments) {
      const val =
        props.zoom.xaxis === "distance"
          ? speciment.distance
          : speciment.timestamp;
      if (val <= props.zoom.min) {
        before.push([speciment.latitude, speciment.longitude] as const);
      } else if (val <= props.zoom.max) {
        between.push([speciment.latitude, speciment.longitude] as const);
      } else {
        after.push([speciment.latitude, speciment.longitude] as const);
      }
    }

    return {
      before: filteredMarkers(before),
      between: filteredMarkers(between),
      after: filteredMarkers(after),
    };
  }, [props.zoom, props.markers]);

  const units = useUnits();

  return (
    <MapContainer
      zoom={13}
      scrollWheelZoom={true}
      style={{
        height: "450px",
        width: "90%",
        margin: "auto",
        zIndex: 0,
        borderRadius: "6px",
      }}
      attributionControl={false}
    >
      <Inner
        markers={props.markers}
        currentHover={props.currentHover}
        fitted={mapFitted}
        setFitted={() => setMapFitted(true)}
      />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {/* <TileLayer
          url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://carto.com/">Carto</a>'
        /> */}
      {zoomMarkers ? (
        <>
          <AntPath
            positions={zoomMarkers.before}
            options={{
              dashArray: [8, 20],
              weight: 3,
              color: "#141C22",
              pulseColor: "#141C22",
              paused: true,
              reverse: false,
              opacity: 0.3,
            }}
          />
          <AntPath
            positions={zoomMarkers.between}
            options={{
              dashArray: [1, 1],
              weight: 3,
              color: "white",
              pulseColor: "#141C22",
              paused: true,
              reverse: false,
              opacity: 1,
            }}
          />
          <AntPath
            positions={zoomMarkers.after}
            options={{
              dashArray: [8, 20],
              weight: 3,
              color: "#141C22",
              pulseColor: "#141C22",
              paused: true,
              reverse: false,
              opacity: 0.3,
            }}
          />
        </>
      ) : (
        <AntPath
          positions={props.markers}
          options={{
            dashArray: [1, 1],
            weight: 3,
            color: "white",
            pulseColor: "#141C22",
            paused: true,
            reverse: false,
            opacity: 1,
          }}
        />
      )}
      {!!props.currentHover && (
        <Marker position={props.currentHover} icon={blueMarker} />
      )}
      {!!props.currentPlayingIndex && (
        <Marker
          position={[
            props.markers[props.currentPlayingIndex][0],
            props.markers[props.currentPlayingIndex][1],
          ]}
          icon={redMarker}
        >
          {Object.values(props.playbackLabels).every((val) => val === false) ? (
            <></>
          ) : (
            <Tooltip permanent className="map-tooltip" direction="center">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: props.playbackLabels.position ? "260px" : "unset",
                }}
              >
                <Box sx={{ opacity: "2 !important" }}>
                  <Typography>
                    {props.playbackLabels.position && (
                      <>
                        Position:{" "}
                        <Typography
                          component="span"
                          style={{ color: "#323232", fontSize: "14px" }}
                        >
                          {
                            props.allSpeciments[props.currentPlayingIndex]
                              .position
                          }
                        </Typography>
                      </>
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    {props.playbackLabels.bodyAngle && (
                      <>
                        Body Angle:{" "}
                        <Typography
                          component="span"
                          style={{ color: "#323232", fontSize: "14px" }}
                        >
                          {props.allSpeciments[
                            props.currentPlayingIndex
                          ].torso_degrees?.toFixed(1)}
                          °
                        </Typography>
                      </>
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    {props.playbackLabels.heartRate && (
                      <>
                        HR:{" "}
                        <Typography
                          component="span"
                          style={{ color: "#323232", fontSize: "14px" }}
                        >
                          {props.allSpeciments[
                            props.currentPlayingIndex
                          ].heartrate?.toFixed(0)}{" "}
                          bpm
                        </Typography>
                      </>
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    {props.playbackLabels.power && (
                      <>
                        Power:{" "}
                        <Typography
                          component="span"
                          style={{ color: "#323232", fontSize: "14px" }}
                        >
                          {props.allSpeciments[props.currentPlayingIndex].power}{" "}
                          W
                        </Typography>
                      </>
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    {props.playbackLabels.cadence && (
                      <>
                        Cadence:{" "}
                        <Typography
                          component="span"
                          style={{ color: "#323232", fontSize: "14px" }}
                        >
                          {props.allSpeciments[
                            props.currentPlayingIndex
                          ].cadence?.toFixed(1)}{" "}
                          rpm
                        </Typography>
                      </>
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    {props.playbackLabels.speed && (
                      <>
                        Speed:{" "}
                        <Typography
                          component="span"
                          style={{ color: "#323232", fontSize: "14px" }}
                        >
                          {units === "metric"
                            ? props.allSpeciments[
                                props.currentPlayingIndex
                              ].speed?.toFixed(1)
                            : (
                                props.allSpeciments[props.currentPlayingIndex]
                                  .speed * 0.6213711922
                              )?.toFixed(1)}{" "}
                          {units === "metric" ? " kph" : " mph"}
                        </Typography>
                      </>
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    {props.playbackLabels.cda && (
                      <>
                        CdA:{" "}
                        <Typography
                          component="span"
                          style={{ color: "#323232", fontSize: "14px" }}
                        >
                          {props.allSpeciments[
                            props.currentPlayingIndex
                          ].cda?.toFixed(3)}
                        </Typography>
                      </>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          )}
        </Marker>
      )}
      {!!props.selectedPositions &&
        props.allSpeciments
          .filter((speciment) =>
            props.selectedPositions.has(speciment.position),
          )
          .map((speciment, i) => (
            <CircleMarker
              key={i}
              center={[speciment.latitude, speciment.longitude]}
              fill
              fillOpacity={1}
              radius={1}
              color={allColors[speciment.position]}
            />
          ))}
    </MapContainer>
  );
}

type InnerProps = {
  markers: Array<[number, number]>;
  currentHover?: [number, number];
  fitted: boolean;
  setFitted: () => void;
};

function Inner(props: InnerProps) {
  const map = useMap();

  useEffect(() => {
    if (!props.fitted && !!props.markers && props.markers.length > 0) {
      props.setFitted();
      map.fitBounds(L.latLngBounds(props.markers));
    }
  }, [props.markers, props.fitted, props.setFitted]);

  return <></>;
}
