import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import type { ApexOptions } from "apexcharts";
import { useState, useMemo, useCallback, useEffect } from "react";
import Chart from "react-apexcharts";
import { type SupabaseCall, withReference } from "src/utils/common";
import dayjs from "dayjs";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { C, ck } from "@fullcalendar/core/internal-common";

type Month =
  | "Jan"
  | "Feb"
  | "Mar"
  | "Apr"
  | "May"
  | "Jun"
  | "Jul"
  | "Aug"
  | "Sep"
  | "Oct"
  | "Nov"
  | "Dec";

type Day = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";

const days: Array<Day> = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const months: Array<Month> = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// type Week = { [K in Day]: { [P in BikePosition]: number } };

export type BucketCreationProps = {
  data:
    | {
        athlete_id: string;
        created_at: string;
      }[]
    | any;
  categories: Day[] | Month[] | number[];
  trend: "active athletes" | "active users" | "new users" | "total sessions";
  date: dayjs.Dayjs;
  dateHigh?: dayjs.Dayjs;
};

function DailyBucketCreation(props: BucketCreationProps) {
  const { data, categories, trend, date } = props;
  if (trend === "active athletes") {
    const dailyData = categories.map((day, index) => {
      const newDate = date.add(index, "day");
      const daily = data.filter((datum) =>
        newDate.isSame(new Date(datum.created_at), "day"),
      );
      const uniqueObjects: Array<{ athlete_id: string }> = [];
      const uniqueAthleteIds = new Set<string>();

      daily.forEach((row) => {
        if (!uniqueAthleteIds.has(row.athlete_id)) {
          uniqueAthleteIds.add(row.athlete_id);
          uniqueObjects.push(row);
        }
      });

      return uniqueObjects.length;
    });
    return dailyData;
  }if (trend === "active users") {
    const newUsers = data.filter(
      (user) => user.last_sign_in_at > date.toISOString(),
    );
    const dailyData = categories.map((day, index) => {
      const newDate = date.add(index, "day");
      const daily = newUsers.filter((datum) =>
        newDate.isSame(new Date(datum.last_sign_in_at), "day"),
      );
      return daily.length;
    });
    return dailyData;
  }if (trend === "new users") {
    const newUsers = data.filter(
      (user) => user.created_at > date.toISOString(),
    );
    const dailyData = categories.map((day, index) => {
      const newDate = date.add(index, "day");
      const daily = newUsers.filter((datum) =>
        newDate.isSame(new Date(datum.created_at), "day"),
      );
      return daily.length;
    });
    return dailyData;
  }if (trend === "total sessions") {
    const dailyData = categories.map((day, index) => {
      const newDate = date.add(index, "day");
      const daily = data.filter((datum) =>
        newDate.isSame(new Date(datum.created_at), "day"),
      );
      return daily.length;
    });
    return dailyData;
  }return [];
}

function MonthlyBucketCreation(props: BucketCreationProps) {
  const { data, categories, trend, date } = props;
  if (trend === "active athletes") {
    const monthlyData = categories.map((month, index) => {
      const newRangeLow = date.add(index, "month").startOf("month");
      const newRangeHigh = date.add(index, "month").endOf("month");
      const monthly = data.filter(
        (datum) =>
          newRangeLow.isSameOrBefore(new Date(datum.created_at), "month") &&
          newRangeHigh.isSameOrAfter(new Date(datum.created_at), "month"),
      );
      const uniqueObjects: Array<{ athlete_id: string }> = [];
      const uniqueAthleteIds = new Set<string>();

      monthly.forEach((row) => {
        if (!uniqueAthleteIds.has(row.athlete_id)) {
          uniqueAthleteIds.add(row.athlete_id);
          uniqueObjects.push(row);
        }
      });

      return uniqueObjects.length;
    });
    return monthlyData;
  }if (trend === "active users") {
    const newUsers = data.filter(
      (user) => user.last_sign_in_at > date.toISOString(),
    );
    const monthlyData = categories.map((day, index) => {
      const newRangeLow = date.add(index, "month").startOf("month");
      const newRangeHigh = date.add(index, "month").endOf("month");
      const monthly = newUsers.filter(
        (datum) =>
          newRangeLow.isSameOrBefore(
            new Date(datum.last_sign_in_at),
            "month",
          ) &&
          newRangeHigh.isSameOrAfter(new Date(datum.last_sign_in_at), "month"),
      );
      return monthly.length;
    });
    return monthlyData;
  }if (trend === "new users") {
    const newUsers = data.filter(
      (user) => user.created_at > date.toISOString(),
    );
    const monthlyData = categories.map((day, index) => {
      const newRangeLow = date.add(index, "month").startOf("month");
      const newRangeHigh = date.add(index, "month").endOf("month");
      const monthly = newUsers.filter(
        (datum) =>
          newRangeLow.isSameOrBefore(new Date(datum.created_at), "month") &&
          newRangeHigh.isSameOrAfter(new Date(datum.created_at), "month"),
      );
      return monthly.length;
    });
    return monthlyData;
  }if (trend === "total sessions") {
    const monthlyData = categories.map((day, index) => {
      const newRangeLow = date.add(index, "month").startOf("month");
      const newRangeHigh = date.add(index, "month").endOf("month");
      const monthly = data.filter(
        (datum) =>
          newRangeLow.isSameOrBefore(new Date(datum.created_at), "month") &&
          newRangeHigh.isSameOrAfter(new Date(datum.created_at), "month"),
      );
      return monthly.length;
    });
    return monthlyData;
  }return [];
}

function YearlyBucketCreation(props: BucketCreationProps) {
  const { data, categories, trend, date } = props;
  if (trend === "active athletes") {
    const yearlyData = categories.map((year, index) => {
      const newRangeLow = date.add(index, "year").startOf("year");
      const newRangeHigh = date.add(index, "year").endOf("year");
      const yearly = data.filter(
        (datum) =>
          newRangeLow.isSameOrBefore(new Date(datum.created_at), "year") &&
          newRangeHigh.isSameOrAfter(new Date(datum.created_at), "year"),
      );
      const uniqueObjects: Array<{ athlete_id: string }> = [];
      const uniqueAthleteIds = new Set<string>();

      yearly.forEach((row) => {
        if (!uniqueAthleteIds.has(row.athlete_id)) {
          uniqueAthleteIds.add(row.athlete_id);
          uniqueObjects.push(row);
        }
      });

      return uniqueObjects.length;
    });
    return yearlyData;
  }if (trend === "active users") {
    const newUsers = data.filter(
      (user) => user.last_sign_in_at > date.toISOString(),
    );
    const yearlyData = categories.map((year, index) => {
      const newRangeLow = date.add(index, "year").startOf("year");
      const newRangeHigh = date.add(index, "year").endOf("year");
      const yearly = newUsers.filter(
        (datum) =>
          newRangeLow.isSameOrBefore(new Date(datum.last_sign_in_at), "year") &&
          newRangeHigh.isSameOrAfter(new Date(datum.last_sign_in_at), "year"),
      );
      return yearly.length;
    });
    return yearlyData;
  }if (trend === "new users") {
    const newUsers = data.filter(
      (user) => user.created_at > date.toISOString(),
    );
    const yearlyData = categories.map((year, index) => {
      const newRangeLow = date.add(index, "year").startOf("year");
      const newRangeHigh = date.add(index, "year").endOf("year");
      const yearly = newUsers.filter(
        (datum) =>
          newRangeLow.isSameOrBefore(new Date(datum.created_at), "year") &&
          newRangeHigh.isSameOrAfter(new Date(datum.created_at), "year"),
      );
      return yearly.length;
    });
    return yearlyData;
  }if (trend === "total sessions") {
    const yearlyData = categories.map((year, index) => {
      const newRangeLow = date.add(index, "year").startOf("year");
      const newRangeHigh = date.add(index, "year").endOf("year");
      const yearly = data.filter(
        (datum) =>
          newRangeLow.isSameOrBefore(new Date(datum.created_at), "year") &&
          newRangeHigh.isSameOrAfter(new Date(datum.created_at), "year"),
      );
      return yearly.length;
    });
    return yearlyData;
  }return [];
}

function WeeklyBucketCreation(props: BucketCreationProps) {
  const { data, trend, date, dateHigh } = props;
  const indexOfDay = date.isoWeekday();
  const indexOfLastDay = dateHigh.isoWeekday();
  const days: Array<Day> = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const firstWeek = [...days.slice(indexOfDay - 1)];
  const newRangeLow = date.add(firstWeek.length, "day");
  const lastWeek = [...days.slice(0, indexOfLastDay)];
  const newRangeHigh = dateHigh.subtract(lastWeek.length, "day");
  if (trend === "active athletes") {
    // Data for the first week
    const firstWeekData = data.filter(
      (datum) =>
        date.isSameOrBefore(new Date(datum.created_at), "day") &&
        date
          .add(firstWeek.length - 1, "day")
          .isSameOrAfter(new Date(datum.created_at), "day"),
    );

    // Data week sessions
    const lastWeekData = data.filter(
      (datum) =>
        dateHigh
          .subtract(lastWeek.length - 1, "day")
          .isSameOrBefore(new Date(datum.created_at), "day") &&
        dateHigh.isSameOrAfter(new Date(datum.created_at), "day"),
    );

    // sessions between new low and new high
    const filterData = data.filter(
      (datum) =>
        newRangeLow.isSameOrBefore(new Date(datum.created_at), "day") &&
        newRangeHigh.isSameOrAfter(new Date(datum.created_at), "day"),
    );

    const weeklyCounts: number[] = [];
    for (let i = 1; i <= newRangeHigh.diff(newRangeLow, "week") + 1; i++) {
      // Filtering Sessions for Each Week
      const weekData = filterData.filter(
        (session) =>
          newRangeLow
            .add((i - 1) * 7, "day")
            .isSameOrBefore(new Date(session.created_at), "day") &&
          newRangeLow
            .add(i, "week")
            .subtract(1, "day")
            .isSameOrAfter(new Date(session.created_at), "day"),
      );
      const uniqueObjects: Array<{ athlete_id: string }> = [];
      const uniqueAthleteIds = new Set<string>();
      weekData.forEach((row) => {
        if (!uniqueAthleteIds.has(row.athlete_id)) {
          uniqueAthleteIds.add(row.athlete_id);
          uniqueObjects.push(row);
        }
      });

      weeklyCounts.push(uniqueObjects.length);
    }

    const uniqueObjects: Array<{ athlete_id: string }> = [];
    const uniqueAthleteIds = new Set<string>();
    firstWeekData.forEach((row) => {
      if (!uniqueAthleteIds.has(row.athlete_id)) {
        uniqueAthleteIds.add(row.athlete_id);
        uniqueObjects.push(row);
      }
    });
    weeklyCounts.splice(0, 0, uniqueObjects.length);
    const uniqueObjectsLast: Array<{ athlete_id: string }> = [];
    const uniqueAthleteIdsLast = new Set<string>();

    lastWeekData.forEach((row) => {
      if (!uniqueAthleteIdsLast.has(row.athlete_id)) {
        uniqueAthleteIdsLast.add(row.athlete_id);
        uniqueObjectsLast.push(row);
      }
    });
    const finalData = [...weeklyCounts, uniqueObjectsLast.length];
    return finalData;
  }if (trend === "active users") {
    // Data for the first week
    const newUsers = data.filter(
      (user) => user.last_sign_in_at > date.toISOString(),
    );

    const firstWeekData = newUsers.filter(
      (datum) =>
        date.isSameOrBefore(new Date(datum.last_sign_in_at), "day") &&
        date
          .add(firstWeek.length - 1, "day")
          .isSameOrAfter(new Date(datum.last_sign_in_at), "day"),
    );

    // Data week sessions
    const lastWeekData = newUsers.filter(
      (datum) =>
        dateHigh
          .subtract(lastWeek.length - 1, "day")
          .isSameOrBefore(new Date(datum.last_sign_in_at), "day") &&
        dateHigh.isSameOrAfter(new Date(datum.last_sign_in_at), "day"),
    );

    // sessions between new low and new high
    const filterData = newUsers.filter(
      (datum) =>
        newRangeLow.isSameOrBefore(new Date(datum.last_sign_in_at), "day") &&
        newRangeHigh.isSameOrAfter(new Date(datum.last_sign_in_at), "day"),
    );

    const weeklyCounts: number[] = [];
    for (let i = 1; i <= newRangeHigh.diff(newRangeLow, "week") + 1; i++) {
      // Filtering Sessions for Each Week
      const weekData = filterData.filter(
        (session) =>
          newRangeLow
            .add((i - 1) * 7, "day")
            .isSameOrBefore(new Date(session.last_sign_in_at), "day") &&
          newRangeLow
            .add(i, "week")
            .subtract(1, "day")
            .isSameOrAfter(new Date(session.last_sign_in_at), "day"),
      );
      weeklyCounts.push(weekData.length);
    }
    weeklyCounts.splice(0, 0, firstWeekData.length);
    const finalData = [...weeklyCounts, lastWeekData.length];
    return finalData;
  }if (trend === "new users") {
    // Data for the first week
    const newUsers = data.filter(
      (user) => user.created_at > date.toISOString(),
    );

    const firstWeekData = newUsers.filter(
      (datum) =>
        date.isSameOrBefore(new Date(datum.created_at), "day") &&
        date
          .add(firstWeek.length - 1, "day")
          .isSameOrAfter(new Date(datum.created_at), "day"),
    );

    // Data week sessions
    const lastWeekData = newUsers.filter(
      (datum) =>
        dateHigh
          .subtract(lastWeek.length - 1, "day")
          .isSameOrBefore(new Date(datum.created_at), "day") &&
        dateHigh.isSameOrAfter(new Date(datum.created_at), "day"),
    );

    // sessions between new low and new high
    const filterData = newUsers.filter(
      (datum) =>
        newRangeLow.isSameOrBefore(new Date(datum.created_at), "day") &&
        newRangeHigh.isSameOrAfter(new Date(datum.created_at), "day"),
    );

    const weeklyCounts: number[] = [];
    for (let i = 1; i <= newRangeHigh.diff(newRangeLow, "week") + 1; i++) {
      // Filtering Sessions for Each Week
      const weekData = filterData.filter(
        (session) =>
          newRangeLow
            .add((i - 1) * 7, "day")
            .isSameOrBefore(new Date(session.created_at), "day") &&
          newRangeLow
            .add(i, "week")
            .subtract(1, "day")
            .isSameOrAfter(new Date(session.created_at), "day"),
      );
      weeklyCounts.push(weekData.length);
    }
    weeklyCounts.splice(0, 0, firstWeekData.length);
    const finalData = [...weeklyCounts, lastWeekData.length];
    return finalData;
  }if (trend === "total sessions") {
    // Data for the first week

    const firstWeekData = data.filter(
      (datum) =>
        date.isSameOrBefore(new Date(datum.created_at), "day") &&
        date
          .add(firstWeek.length - 1, "day")
          .isSameOrAfter(new Date(datum.created_at), "day"),
    );

    // Data week sessions
    const lastWeekData = data.filter(
      (datum) =>
        dateHigh
          .subtract(lastWeek.length - 1, "day")
          .isSameOrBefore(new Date(datum.created_at), "day") &&
        dateHigh.isSameOrAfter(new Date(datum.created_at), "day"),
    );

    // sessions between new low and new high
    const filterData = data.filter(
      (datum) =>
        newRangeLow.isSameOrBefore(new Date(datum.created_at), "day") &&
        newRangeHigh.isSameOrAfter(new Date(datum.created_at), "day"),
    );

    const weeklyCounts: number[] = [];
    for (let i = 1; i <= newRangeHigh.diff(newRangeLow, "week") + 1; i++) {
      // Filtering Sessions for Each Week
      const weekData = filterData.filter(
        (session) =>
          newRangeLow
            .add((i - 1) * 7, "day")
            .isSameOrBefore(new Date(session.created_at), "day") &&
          newRangeLow
            .add(i, "week")
            .subtract(1, "day")
            .isSameOrAfter(new Date(session.created_at), "day"),
      );
      weeklyCounts.push(weekData.length);
    }
    weeklyCounts.splice(0, 0, firstWeekData.length);
    const finalData = [...weeklyCounts, lastWeekData.length];
    return finalData;
  }return [];
}

export type AdminTrendsGraphProps = {
  allUsers: any;
};

export default function AdminTrendsGraph(props: AdminTrendsGraphProps) {
  const theme = useTheme();
  const supabase = useSupabase();
  const [currentPeriod, setCurrentPeriod] = useState<
    "Daily" | "Weekly" | "Monthly" | "Yearly"
  >("Daily");

  const rangeHigh = useMemo(() => {
    return dayjs();
  }, []);
  //   Calculation for our rangeLow Date
  const rangeLow = useMemo(
    () =>
      withReference(
        currentPeriod === "Daily"
          ? ([6, "days"] as const)
          : currentPeriod === "Weekly"
          ? ([11, "week"] as const)
          : currentPeriod === "Monthly"
          ? ([11, "month"] as const)
          : ([4, "year"] as const),
        (args) => rangeHigh.subtract(args[0], args[1]),
      ),
    [rangeHigh, currentPeriod],
  );

  const periods = useMemo(
    () => [
      {
        value: "Daily",
        label: "Daily",
      },
      {
        value: "Weekly",
        label: "Weekly",
      },
      {
        value: "Monthly",
        label: "Monthly",
      },
      {
        value: "Yearly",
        label: "Yearly",
      },
    ],
    [],
  );

  const handleTabsPeriodChange = useCallback(
    (_, value: "Daily" | "Weekly" | "Monthly" | "Yearly"): void => {
      setCurrentPeriod(value);
    },
    [],
  );

  // CALLS
  const [activeAthletes, setActiveAthletes] = useState<
    {
      athlete_id: string;
      created_at: string;
    }[]
  >([]);

  const activePromise = useCallback(
    (date: dayjs.Dayjs) =>
      supabase
        .from("session")
        .select("athlete_id,created_at")
        .gte("created_at", date.toISOString().split("T")[0])
        .order("created_at", { ascending: true })
        .then((res) => res.data),
    [supabase],
  );

  const activeCall = useAsyncState<SupabaseCall<typeof activePromise>>();

  useEffect(() => {
    if (isUnloaded(activeCall)) {
      activeCall.fire(async () => activePromise(rangeLow));
    }
  }, [rangeLow]);

  useEffect(() => {
    if (isFulfilled(activeCall)) {
      setActiveAthletes(activeCall.result);
      activeCall.unload();
    }
  }, [activeCall]);

  // CALLS
  const [totalSessions, setTotalSessions] = useState<
    {
      id: string;
      created_at: string;
    }[]
  >([]);

  const sessionsPromise = useCallback(
    (date: dayjs.Dayjs) =>
      supabase
        .from("session")
        .select("id,created_at")
        .gte("created_at", date.toISOString().split("T")[0])
        .order("created_at", { ascending: true })
        .then((res) => res.data),
    [supabase],
  );

  const sessionsCall = useAsyncState<SupabaseCall<typeof sessionsPromise>>();

  useEffect(() => {
    if (isUnloaded(sessionsCall)) {
      sessionsCall.fire(async () => sessionsPromise(rangeLow));
    }
  }, [rangeLow]);

  useEffect(() => {
    if (isFulfilled(sessionsCall)) {
      setTotalSessions(sessionsCall.result);
      sessionsCall.unload();
    }
  }, [sessionsCall]);

  //Chart labels. Finding the correct starting month
  const categories = useMemo(() => {
    if (currentPeriod === "Monthly") {
      const indexOfMonth = rangeLow.month();
      return [...months.slice(indexOfMonth), ...months.slice(0, indexOfMonth)];
    }if (currentPeriod === "Yearly") {
      const year = rangeLow.year();
      return Array.from({ length: 5 }, (_, index) => year + index);
    }if (currentPeriod === "Daily") {
      const indexOfDay = rangeLow.day();
      return [...days.slice(indexOfDay), ...days.slice(0, indexOfDay)];
    }if (currentPeriod === "Weekly") {
      const indexOfDay = rangeLow.isoWeekday();
      const indexOfLastDay = rangeHigh.isoWeekday();
      const firstWeek = [...days.slice(indexOfDay - 1)];
      const lastWeek = [...days.slice(0, indexOfLastDay)];
      const newRangeLow = rangeLow.add(firstWeek.length, "day");
      const newRangeHigh = rangeHigh.subtract(lastWeek.length, "day");
      const lastWeekDay = newRangeHigh.add(1, "day");
      const labels = [];
      for (let i = 0; i <= newRangeHigh.diff(newRangeLow, "week"); i++) {
        const newMonday = newRangeLow.add(i * 7, "day");
        labels.push(newMonday.format("DD/MM"));
      }
      const addfirstDay = [rangeLow.format("DD/MM"), ...labels];
      const addLastDay = [...addfirstDay, lastWeekDay.format("DD/MM")];
      return addLastDay;
    }return [];
  }, [rangeLow, currentPeriod]);

  // Active Athletes
  const activeAthletesSeries = useMemo(() => {
    return currentPeriod === "Daily"
      ? DailyBucketCreation({
          data: activeAthletes,
          categories: categories,
          trend: "active athletes",
          date: rangeLow,
        })
      : currentPeriod === "Monthly"
      ? MonthlyBucketCreation({
          data: activeAthletes,
          categories: categories,
          trend: "active athletes",
          date: rangeLow,
        })
      : currentPeriod === "Yearly"
      ? YearlyBucketCreation({
          data: activeAthletes,
          categories: categories,
          trend: "active athletes",
          date: rangeLow,
        })
      : currentPeriod === "Weekly"
      ? WeeklyBucketCreation({
          data: activeAthletes,
          categories: categories,
          trend: "active athletes",
          date: rangeLow,
          dateHigh: rangeHigh,
        })
      : [];
  }, [categories, activeAthletes]);

  // Active Users
  const activeUsersSeries = useMemo(() => {
    return currentPeriod === "Daily"
      ? DailyBucketCreation({
          data: props.allUsers,
          categories: categories,
          trend: "active users",
          date: rangeLow,
        })
      : currentPeriod === "Monthly"
      ? MonthlyBucketCreation({
          data: props.allUsers,
          categories: categories,
          trend: "active users",
          date: rangeLow,
        })
      : currentPeriod === "Yearly"
      ? YearlyBucketCreation({
          data: props.allUsers,
          categories: categories,
          trend: "active users",
          date: rangeLow,
        })
      : currentPeriod === "Weekly"
      ? WeeklyBucketCreation({
          data: props.allUsers,
          categories: categories,
          trend: "active users",
          date: rangeLow,
          dateHigh: rangeHigh,
        })
      : [];
  }, [categories, props.allUsers]);

  // New Users
  const newUsersSeries = useMemo(() => {
    return currentPeriod === "Daily"
      ? DailyBucketCreation({
          data: props.allUsers,
          categories: categories,
          trend: "new users",
          date: rangeLow,
        })
      : currentPeriod === "Monthly"
      ? MonthlyBucketCreation({
          data: props.allUsers,
          categories: categories,
          trend: "new users",
          date: rangeLow,
        })
      : currentPeriod === "Yearly"
      ? YearlyBucketCreation({
          data: props.allUsers,
          categories: categories,
          trend: "new users",
          date: rangeLow,
        })
      : currentPeriod === "Weekly"
      ? WeeklyBucketCreation({
          data: props.allUsers,
          categories: categories,
          trend: "new users",
          date: rangeLow,
          dateHigh: rangeHigh,
        })
      : [];
  }, [categories, props.allUsers]);

  // Total Sessions
  const totalSessionsSeries = useMemo(() => {
    return currentPeriod === "Daily"
      ? DailyBucketCreation({
          data: totalSessions,
          categories: categories,
          trend: "total sessions",
          date: rangeLow,
        })
      : currentPeriod === "Monthly"
      ? MonthlyBucketCreation({
          data: totalSessions,
          categories: categories,
          trend: "total sessions",
          date: rangeLow,
        })
      : currentPeriod === "Yearly"
      ? YearlyBucketCreation({
          data: totalSessions,
          categories: categories,
          trend: "total sessions",
          date: rangeLow,
        })
      : currentPeriod === "Weekly"
      ? WeeklyBucketCreation({
          data: totalSessions,
          categories: categories,
          trend: "total sessions",
          date: rangeLow,
          dateHigh: rangeHigh,
        })
      : [];
  }, [categories, totalSessions]);

  const chartSeriesTrends = useMemo(() => {
    return [
      {
        name: "Active Users",
        data: activeUsersSeries,
      },
      {
        name: "Active Athletes",
        data: activeAthletesSeries,
      },
      {
        name: "New Users",
        data: newUsersSeries,
      },
      {
        name: "Total Sessions",
        data: totalSessionsSeries,
      },
    ];
  }, [
    activeAthletesSeries,
    activeUsersSeries,
    newUsersSeries,
    totalSessionsSeries,
  ]);

  const createChartOptions = useCallback(
    (id: string): ApexOptions => ({
      chart: {
        id,
        height: 320,
        type: "bar",
        toolbar: {
          show: false,
        },
        background: "transparent",
      },
      theme: {
        mode: theme.palette.mode,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#64605D",
          },
        },
        tooltip: {
          enabled: false, // Hide the small tooltip for categories
        },
      },
      colors: ["#4ECDC4", "#C7F464", "#9C27B0", "#FD6A6A"],
    }),
    [],
  );
  const trendsOptions = useMemo(() => createChartOptions("Trends"), []);

  useEffect(() => {
    if (
      currentPeriod === "Daily" ||
      currentPeriod === "Monthly" ||
      currentPeriod === "Yearly" ||
      currentPeriod === "Weekly"
    ) {
      ApexCharts.exec("Trends", "updateOptions", {
        xaxis: {
          type: "categories",
          categories,
          tickAmount: "dataPoints",
        },
      });
    }
  }, [categories]);

  return (
    <>
      <Tabs
        centered
        onChange={handleTabsPeriodChange}
        value={currentPeriod}
        scrollButtons="auto"
        textColor="primary"
        indicatorColor="primary"
        TabIndicatorProps={{
          style: { display: "none" },
        }}
        sx={{
          "& .MuiTab-root": {
            border: ".0625rem solid #64605D",
            color: "#64605D",
            fontSize: ".75rem",
            borderRadius: "6px",
            minHeight: "30px",
            marginTop: "10px",
            padding: "10px",
            minWidth: "50px",
          },
          "& .Mui-selected": {
            color: "#E28E54",
            fontSize: ".75rem",
            border: ".0625rem solid #E28E54",
            borderRadius: "6px",
            minHeight: "30px",
          },
        }}
      >
        {periods.map((period) => (
          <Tab
            key={period.value}
            label={period.label}
            value={period.value}
            sx={{ marginLeft: "10px" }}
          />
        ))}
      </Tabs>
      <Typography display={"flex"} justifyContent={"flex-end"}>
        {rangeLow.format("DD/MM/YYYY")} - {rangeHigh.format("DD/MM/YYYY")}
      </Typography>
      <Chart
        options={trendsOptions}
        series={chartSeriesTrends}
        type="bar"
        height={320}
      />
    </>
  );
}
