import {
  Box,
  Tooltip,
  useTheme,
  Grid,
  Typography,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import { ApexOptions } from "apexcharts";
import { useMemo, useState } from "react";
import { PositionStatistics } from "src/utils/types";
import Chart from "react-apexcharts";
import { allBikePositions, allColors, withReference } from "src/utils/common";
import "../../assets/scss/DonutChart.scss";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";

export type PositionDonutProps = {
  positionStatistics: (Omit<PositionStatistics, "positionType"> & {
    positionType: string;
  })[];
  onPositionHoverChange?: (input: string) => void;
};

export default function PositionDonut(props: PositionDonutProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery("(max-width:480px)");

  const [positionSeriesDomRects, setPositionSeriesDomRects] =
    useState<Array<DOMRect>>(undefined);

  const [displayingTotal, setDisplayingTotal] = useState(false);
  const units = useUnits();

  const [position, setPosition] = useState<
    Omit<PositionStatistics, "positionType"> & { positionType: string }
  >(undefined);

  const sortedStatistics = useMemo(() => {
    return props.positionStatistics.sort((a, b) => {
      return (
        allBikePositions.indexOf(a.positionType as any) -
        allBikePositions.indexOf(b.positionType as any)
      );
    });
  }, [props.positionStatistics]);

  const chartOptions: ApexOptions = useMemo(
    () => ({
      chart: {
        id: "donut",
        background: "transparent",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "30%",
            labels: {
              show: false,
              name: {
                fontSize: "9",
                fontWeight: "40pt",
              },
              value: {
                formatter: (val) => `${Number(val).toFixed(1)}%`,
              },
            },
          },
        },
      },
      colors: sortedStatistics.map((stat) => allColors[stat.positionType]),
      tooltip: {
        followCursor: true,
        custom: (...args) => {
          const index = args[0].seriesIndex;
          const statistic = sortedStatistics[index];
          const color = allColors[statistic.positionType];
          const bikePosition = statistic.positionType;
          props.onPositionHoverChange(bikePosition);
          return `
          <div class="tooltip" style="background: ${color};">
            <div class="tooltip-row">
              <div>${bikePosition}</div>
            </div>
            <div class="tooltip-row">
         
                <div>Avg Speed:</div>
                <div>${
                  units === "metric"
                    ? `${statistic?.speed_average?.toFixed(1) ?? 0} kph`
                    : `${
                        (statistic?.speed_average * 0.6213711922).toFixed(1) ??
                        0
                      } mph`
                }</div>
            </div>
            <div class="tooltip-row">
                <div>Avg HR:</div>
                <div>${statistic?.heart_rate_average?.toFixed(0)} bpm</div>
            </div>
            <div class="tooltip-row">
                <div>Avg Power:</div>
                <div>${statistic?.power_average?.toFixed(0)} W</div>
            </div>
          </div>`;
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return `${Number(val).toFixed(1)}%`;
        },
        style: {
          colors: ["white"],
        },
      },
      fill: {
        opacity: 1,
      },
      labels: sortedStatistics.map((stat) => stat.positionType),
      legend: {
        labels: {
          colors: theme.colors.alpha.trueWhite[100],
        },
        show: false,
        position: "bottom",
      },
      stroke: {
        width: 0,
      },
    }),
    [sortedStatistics],
  );

  const chartSeries = sortedStatistics.map(
    (stat) => stat.position_percentage * 100,
  );

  const ProgressBar = ({ value, label, color }) => {
    return (
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1.5rem",
          justifyContent: isMobileScreen ? "center" : "",
          gap: 2,
        }}
      >
        <Grid item>
          <Typography
            sx={{
              minWidth: "30px",
              color: { color },
              fontSize: ".8125rem",
              "@media (min-width: 1840px)": {
                fontSize: ".9375rem",
              },
            }}
          >
            {`${value.toFixed(0)}%`}
          </Typography>
        </Grid>
        <Grid item>
          <LinearProgress
            variant="determinate"
            value={value}
            sx={{
              width: "172px",
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: color,
              },
              borderRadius: "4px",
            }}
            style={{ backgroundColor: "#45413F", fill: "none" }}
          />
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontSize: ".75rem",
              "@media (min-width: 1840px)": {
                fontSize: ".9375rem",
              },
            }}
          >
            {label}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Grid
        container
        sx={{
          justifyContent: "center",
          "@media (max-width: 959px)": {
            width: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Grid item>
          <Box style={{ zIndex: 11, position: "relative" }}>
            <Tooltip
              style={{ padding: 0 }}
              title={
                !!position ? (
                  <div
                    className="tooltip"
                    style={{
                      background: allColors[position.positionType],
                    }}
                  >
                    <div className="tooltip-row">
                      <div>Avg Speed:</div>
                      <div>
                        {units === "metric"
                          ? `${position.speed_average.toFixed(1)} kph`
                          : `${(position.speed_average * 0.6213711922).toFixed(
                              1,
                            )} mph`}
                      </div>
                    </div>
                    <div className="tooltip-row">
                      <div>Avg HR:</div>
                      <div>
                        {position.heart_rate_average.toFixed(0)}
                        bpm
                      </div>
                    </div>
                    <div className="tooltip-row">
                      <div>Avg Power:</div>
                      <div>{position.power_average.toFixed(0)} W</div>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
              open={!!position && !!positionSeriesDomRects}
              onMouseLeave={(_) => setPosition(undefined)}
              PopperProps={{
                style: {
                  padding: 0,
                },
                onMouseLeave: (_) => setPosition(undefined),
                anchorEl:
                  !!position && !!positionSeriesDomRects
                    ? ({
                        clientHeight: 0,
                        clientWidth: 0,
                        getBoundingClientRect: () =>
                          withReference(
                            sortedStatistics.findIndex(
                              (pos) =>
                                pos.positionType === position.positionType,
                            ),
                            (i) => ({
                              width: 0,
                              height: 0,
                              x: positionSeriesDomRects[i].x,
                              y: positionSeriesDomRects[i].y,
                            }),
                          ),
                      } as any)
                    : null,
              }}
            >
              <Box>
                <Chart
                  options={chartOptions}
                  series={chartSeries}
                  type="donut"
                  height={isMobileScreen ? 220 : 320}
                />
              </Box>
            </Tooltip>
          </Box>
        </Grid>
        <Box
          width={{ md: "100%" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "1em",
          }}
        >
          <Box marginTop={"2rem"}>
            {sortedStatistics.map((stat, index) => (
              <ProgressBar
                key={index}
                value={chartSeries[index]}
                label={stat.positionType}
                color={allColors[stat.positionType]}
              />
            ))}
          </Box>
        </Box>
      </Grid>
    </>
  );
}
