import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import check from "../../assets/img/views/success-reset-password/Check.svg";

export default function SuccessResetPasswordView() {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pt: "17.1875rem",
          "@media (max-width: 400px)": {
            alignItems: "flex-start",
          },
        }}
      >
        <Box
          sx={{
            background: "#2A252B",
            width: "47.1875rem",
            height: "19.5rem",
            borderRadius: "1.25rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box mt={"2.875rem"}>
            <img src={check} alt="checkmark" />
          </Box>
          <Typography variant="h1" color="text.secondary" mt="2.875rem">
            Success
          </Typography>
          <Typography
            variant="body2"
            fontSize={13}
            mt={"0.1rem"}
            mb={"1.75rem"}
            textAlign={"center"}
            lineHeight={"20px"}
          >
            Your password has been reset!
          </Typography>
          <Button
            variant="contained"
            size="small"
            sx={{
              width: "17.0625rem",
              height: "1.875rem",
            }}
            onClick={(_) => navigate("/dashboard")}
          >
            <Typography variant="h5">Go to dashboard</Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
}
