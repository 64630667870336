import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";

type TabValue = "previous" | "last" | "next";

export default function MyRidesCard() {
  const units = useUnits();
  const timeFormatter = useCallback((valStr) => {
    const val = Number(valStr);
    const hours = Math.floor(Math.floor(val / 60) / 60);
    const minutes = Math.trunc((val % 3600) / 60);
    const seconds = Math.trunc(val % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  }, []);

  const [value, setValue] = useState<TabValue>("last");

  const handleChange = (_, newValue: TabValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#201B20",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "1em",
          borderRadius: "10px",
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="Tab list"
              sx={{
                "& .MuiTab-root:not(.Mui-selected)": {
                  opacity: 0.5,
                },
                minHeight: "1.5rem",
              }}
            >
              <Tab
                label="Previous"
                value="previous"
                disabled
                sx={{ minHeight: 0, padding: "1px" }}
              />
              <Tab
                label="Last"
                value="last"
                sx={{ minHeight: 0, padding: "5x" }}
              />
              {/* <Tab
                label="Next"
                value="next"
                disabled
                sx={{ minHeight: 0, padding: "5px" }}
              /> */}
            </TabList>
          </Box>
          <TabPanel
            value="previous"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          ></TabPanel>
          <TabPanel
            value="next"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          ></TabPanel>
          <TabPanel
            value="last"
            sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
          ></TabPanel>
        </TabContext>
      </Box>
      <Box
        sx={{
          borderRadius: "5px",
          background: "#3D343E",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "214px", xl: "220px" },
          width: "95%",
        }}
      >
        <Typography variant="body1" sx={{ color: "#FF5630" }}>
          No Data
        </Typography>
      </Box>
      <Box marginTop={{ xs: "13px", xl: "40px" }}>
        <Typography
          variant="h5"
          sx={{
            textTransform: "uppercase",
          }}
        >
          NO SESSION RECORDED
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "2.5rem", md: "3rem" },
            fontWeight: 700,
            color: "#BC6D29",
          }}
        >
          {units === "metric" ? "- km" : "- mi"}
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-around" }}
        marginTop={{ xl: "20px" }}
      >
        <Grid
          container
          width={"50%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "10px", xl: "30px" },
          }}
        >
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {units === "metric" ? "- kph" : "- mi"}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Avg Speed
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {"- bpm"}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Average Heart Rate
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {units === "metric" ? "- m" : "- ft"}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Elevation Gain
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {"- W"}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Weighted Avg. Power
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          width={"50%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "10px", xl: "30px" },
            alignItems: "flex-end",
            textAlign: "end",
            marginRight: "15px",
          }}
        >
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {timeFormatter(0)}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Moving Time
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {"- rpm"}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Average Cadence
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              {units === "metric" ? "- m" : "- ft"}
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              Elevation Loss
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
              0
            </Typography>
            <Typography
              sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
            >
              kcals
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography
        color={"white"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          marginRight: "0.5rem",
          marginTop: "2.6rem",
          textDecoration: "underline",
        }}
      >
        View More
      </Typography>
    </>
  );
}
