
import { createContext, useContext } from "react";
import { valueInZone, Zone, zoneEdgesToZoneType } from "src/utils/common";
import { WithCache } from "src/contexts/CacheContext";
import CoachAthleteRideInsightsView from "../../MyAthletes/CoachRideInsightsView";
import { useMetrics, useRideSession } from "./CoachSessionViewPreload";

export type ZoneWithStats = Zone & {
  total_time: number;
  percentage: number;
  index: number;
  name: string;
  color: string;
};

const HeartRateZonesContext = createContext<ZoneWithStats[]>(undefined);
const PowerZonesContext = createContext<ZoneWithStats[]>(undefined);

export const useHeartRateZones = () => useContext(HeartRateZonesContext);
export const usePowerZones = () => useContext(PowerZonesContext);

export default function CoachSessionOverviewPreload() {
  const metrics = useMetrics();
  const session = useRideSession();

  return (
    <WithCache
      tables={["athlete"]}
      component={(cached) => {
        const heartRate = cached.athlete[0].heart_rate_zones?.map((val) =>
          Math.round(val * cached.athlete[0].max_heart_rate),
        );

        const power = cached.athlete[0].power_zones?.map((val) =>
          Math.round(val * cached.athlete[0].ftp),
        );

        const total_time = session.total_time;

        const hrz: Array<Zone> = zoneEdgesToZoneType(heartRate ?? []);
        const prz: Array<Zone> = zoneEdgesToZoneType(power ?? []);

        const hrz_names = [
          "Warm Up",
          "Easy",
          "Aerobic",
          "Threshold",
          "Maximum",
        ];

        const prz_names = [
          "Active Recovery",
          "Endurance",
          "Tempo",
          "Threshold",
          "VO2 Max",
          "Anaerobic",
          "Neuromuscular",
        ];

        const hrz_colors = [
          "#92E3DA",
          "#36B37E",
          "#C0DE77",
          "#E28E54",
          "#DD4F4A",
        ];

        const prz_colors = [
          "#81A9E7",
          "#92E3DA",
          "#36B37E",
          "#C0DE77",
          "#E28E54",
          "#DD4F4A",
          "#EA366F",
        ];

        const hrz_count = hrz.map((_) => 0);
        const prz_count = prz.map((_) => 0);

        for (const metric of metrics) {
          hrz_count[hrz.findIndex((z) => valueInZone(metric.heart_rate, z))]++;
          prz_count[prz.findIndex((z) => valueInZone(metric.bike_power, z))]++;
        }

        const hrz_percentages = hrz_count.map((v) => v / metrics.length);
        const prz_percentages = prz_count.map((v) => v / metrics.length);

        const hrz_time = hrz_percentages.map((p) => total_time * p);
        const prz_time = prz_percentages.map((p) => total_time * p);

        const hrz_zone_with_stats: Array<ZoneWithStats> = hrz.map((z, i) => ({
          ...z,
          index: i + 1,
          name: hrz_names[i],
          percentage: hrz_percentages[i],
          total_time: hrz_time[i],
          color: hrz_colors[i],
        }));

        const prz_zone_with_stats: Array<ZoneWithStats> = prz.map((z, i) => ({
          ...z,
          index: i + 1,
          name: prz_names[i],
          percentage: prz_percentages[i],
          total_time: prz_time[i],
          color: prz_colors[i],
        }));

        return (
          <HeartRateZonesContext.Provider value={hrz_zone_with_stats}>
            <PowerZonesContext.Provider value={prz_zone_with_stats}>
              <CoachAthleteRideInsightsView />
            </PowerZonesContext.Provider>
          </HeartRateZonesContext.Provider>
        );
      }}
    />
  );
}
