import {
  Box,
  Button,
  Card,
  Toolbar,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import Logo from "../../assets/img/layout/Logo_new.svg";
import { useCallback, useEffect } from "react";
import useAsyncState, { isFulfilled } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import { useSession, useSupabase } from "src/contexts/SupabaseContext";
import SidebarAdmin from "./SidebarAdmin";
import { Outlet } from "react-router-dom";

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    right: 0;
    z-index: 6;
    background-color: #2C262D;
    position: fixed;
    width: 100%;
  `,
);
export default function AdminDashboardLayout() {
  const theme = useTheme();
  const supabase = useSupabase();

  //Sign Out Function
  const logOutCall =
    useAsyncState<SupabaseCall<typeof supabase.auth.signOut>>();

  const session = useSession();

  useEffect(() => {
    if (isFulfilled(logOutCall)) {
      if ("unload" in session) {
        session.unload();
      }
    }
  }, [logOutCall]);

  const handleLogout = useCallback(() => {
    if ("fire" in logOutCall) {
      logOutCall.fire(() => supabase.auth.signOut());
    }
  }, [supabase]);

  return (
    <>
      <HeaderWrapper
        sx={{
          background: "#434244",
          paddingY: "0.5em",
          paddingLeft: "1.5em",
        }}
      >
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <img src={Logo} alt="Logo" className="Logo-dashboard" />
          <Typography variant="h2">Admin Dashboard</Typography>
          <Box padding={"1rem"}>
            <Button onClick={handleLogout}>Log Out</Button>
          </Box>
        </Toolbar>
      </HeaderWrapper>
      <SidebarAdmin />
      <Box>
        <Box
          sx={{
            position: "relative",
            zIndex: 5,
            flex: 1,
            display: "flex",
            pt: `${theme.header.height}`,
            pl: "75px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box flexGrow={1}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
