import { Box } from "@mui/material";

type ProgressBarProps = {
  valuePercentage: number;
};

export default function ProgressBar(props: ProgressBarProps) {
  const { valuePercentage: value } = props;
  const fillerRelativePercentage = (100 / value) * 100;

  return (
    <Box
      className="wrapper"
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={value}
    >
      <Box
        sx={{
          flex: 1,
          height: "10px",
          background: "#3D343E",
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <Box
          style={{
            width: `${value}%`,
            height: "inherit",
            transition: "width 2s ease-i-out",
            background:
              "linear-gradient(90deg, #E28E54 0.97%, #726174 50.49%, #EA366F 100%)",
          }}
        >
          <Box
            style={{
              width: `${fillerRelativePercentage}%`,
              transition: "width 2s ease-i-out",
              height: "inherit",
              borderRadius: "inherit",
              overflow: "hidden",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
