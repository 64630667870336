import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  Box,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Popover,
  Select,
  MenuItem,
  FormLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
} from "@mui/material";
import { isFulfilled, isPending } from "@reduxjs/toolkit";
import convert from "convert-units";
import { useFormik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useUser } from "src/components/Authenticated";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState from "src/utils/Async";
import { type SupabaseCall, imperialToMeters } from "src/utils/common";
import * as yup from "yup";
import editInput from "../../assets/img/views/settings/editInput.svg";
// Date
import dayjs from "dayjs";
import { Calendar } from "react-date-range";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../../assets/scss/Calendar.scss";

export type UserAthleteProps = {
  email: string;
  name: string;
  dateofbirth: Date;
  gender: "male" | "female";
  weight: number;
  height: number;
  weight_pounds: number;
  height_feet: number;
  height_inches: number;
  units: "metric" | "imperial";
};

export type UserDetailsProps = {
  filteredAthlete: {
    age: number;
    country_code: string;
    dob: string;
    experience_level: number;
    firebase_id: string;
    ftp: number;
    gender: boolean;
    heart_rate_zones: number[];
    height: number;
    lthr: number;
    max_avg_cadence_session_id: string;
    max_avg_heart_rate_session_id: string;
    max_avg_power_session_id: string;
    max_avg_speed_session_id: string;
    max_distance_session_id: string;
    max_duration_session_id: string;
    max_elevation_gain_session_id: string;
    max_heart_rate: number;
    max_heart_rate_session_id: string;
    max_power_session_id: string;
    max_speed_session_id: string;
    min_avg_cadence_session_id: string;
    name: string;
    power_zones: number[];
    road_bike_name: string;
    road_bike_weight: number;
    tt_bike_name: string;
    tt_bike_weight: number;
    units: boolean;
    user_id: string;
    weight: number;
  }[];
  email: string;
};

export default function AdminDashboardUserDetails(props: UserDetailsProps) {
  const user = useUser();
  const supabase = useSupabase();
  const [openDate, setOpenDate] = useState(false);
  const buttonRef = useRef(null);
  const inputEmailRef = useRef(null);
  const inputNameRef = useRef(null);
  const inputWeightRef = useRef(null);
  const inputWeightPoundRef = useRef(null);
  const inputHeightRef = useRef(null);
  const inputHeightFeetRef = useRef(null);
  const inputHeightInchRef = useRef(null);

  // Formik For User Details
  const userFormik = useFormik<UserAthleteProps>({
    initialValues: {
      email: props.email,
      name: undefined,
      dateofbirth: undefined,
      gender: "male",
      weight: undefined,
      height: undefined,
      weight_pounds: undefined,
      height_feet: undefined,
      height_inches: undefined,
      units: "metric",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("The email provided should be a valid email address")
        .max(255)
        .required("Email is required"),
      name: yup.string().required("Name is required"),
      dateofbirth: yup
        .date()
        .max(new Date(Date.now() - 378683112000), "Minimum age is 12 years old")
        .min(
          new Date(Date.now() - 3155692600000.1),
          "Maximum age is 100 years old",
        )
        .required("Date of birth is required"),
      gender: yup
        .string()
        .oneOf(["male", "female"], "Please select a valid gender")
        .required("Please select a valid gender"),
      weight: yup
        .number()
        .test(
          "minimum metric weight",
          "Weight cannot be lower than 30kg",
          (value, { parent }) =>
            !value || parent.units === "imperial" || parent.weight >= 30,
        )
        .test(
          "maximum metric weight",
          "Weight cannot be greater than 250kg",
          (value, { parent }) =>
            !value || parent.units === "imperial" || parent.weight <= 250,
        )
        .test(
          "metric weight is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "imperial",
        )
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
      height: yup
        .number()
        .test(
          "minimum metric height",
          "Height cannot be lower than 1m",
          (value, { parent }) =>
            !value || parent.units === "imperial" || parent.height >= 1,
        )
        .test(
          "maximum metric height",
          "Height cannot be greater than 2.3m",
          (value, { parent }) =>
            !value || parent.units === "imperial" || parent.height <= 2.3,
        )
        .test(
          "metric height is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "imeprial",
        )
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
      height_inches: yup
        .number()
        .min(0, "Inches should be positive")
        .max(11, "Inches should be less than 11")
        .test(
          "imperial height is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "metric",
        )
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
      height_feet: yup
        .number()
        .test(
          "minimum imperial height",
          "Height cannot be lower than 3ft 4in",
          (value, { parent }) =>
            !value && !parent.height_inches && parent.height_inches !== 0
              ? true
              : value +
                  (parent.height_inches
                    ? convert(parent.height_inches).from("in").to("ft")
                    : 0) >=
                3 + 1 / 3,
        )
        .test(
          "maximum imperial height",
          "Height cannot be greater than 7ft 6in",
          (value, { parent }) =>
            !value ||
            parent.units === "metric" ||
            imperialToMeters(parent.height_feet, parent.height_inches) <= 2.3,
        )
        .test(
          "imperial height is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "metric",
        )
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
      weight_pounds: yup
        .number()
        .test(
          "minimum imperial weight",
          "Weight cannot be lower than 66lbs",
          (value, { parent }) =>
            !value || parent.units === "metric" || parent.weight_pounds >= 66,
        )
        .test(
          "maximum imperial weight",
          "Weight cannot be greater than 551lbs",
          (value, { parent }) =>
            !value || parent.units === "metric" || parent.weight_pounds <= 551,
        )
        .test(
          "imperial weight is required",
          "Field is required",
          (value, { parent }) => !!value || parent.units === "metric",
        )
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
      units: yup
        .string()
        .oneOf(["metric", "imperial"], "Please select a valid unit"),
    }),
    onSubmit: (values) => {
      if ("fire" in saveAthleteSettingsCall) {
        saveAthleteSettingsCall.fire(async () =>
          saveAthleteSettingsPromise(values),
        );
      }
    },
  });

  // Setting User Details Formik Values
  useEffect(() => {
    const heightInMeters = props.filteredAthlete[0]?.height;
    const heightInFeet = convert(heightInMeters).from("m").to("ft");
    const heightInInches = (heightInFeet % 1) * 12;

    userFormik.setFieldValue("name", props.filteredAthlete[0]?.name);
    userFormik.setFieldValue("height_feet", Math.floor(heightInFeet));
    userFormik.setFieldValue("height_inches", Math.round(heightInInches));

    userFormik.setFieldValue("height", props.filteredAthlete[0]?.height);
    userFormik.setFieldValue("weight", props.filteredAthlete[0]?.weight);
    userFormik.setFieldValue(
      "weight_pounds",
      (props.filteredAthlete[0]?.weight * 2.20462).toFixed(0),
    );
    userFormik.setFieldValue(
      "dateofbirth",
      new Date(props.filteredAthlete[0]?.dob),
    );
    userFormik.setFieldValue(
      "gender",
      props.filteredAthlete[0]?.gender === false ? "male" : "female",
    );
    userFormik.setFieldValue(
      "units",
      props.filteredAthlete[0]?.units === false ? "metric" : "imperial",
    );
  }, [props.filteredAthlete]);

  //Updating Data in DB Calls
  const saveAthleteSettingsPromise = useCallback(
    (values: UserAthleteProps) =>
      supabase
        .from("athlete")
        .update({
          height:
            values.units === "metric"
              ? values.height
              : Number(
                  imperialToMeters(
                    values.height_feet,
                    values.height_inches,
                  ).toFixed(2),
                ),
          weight:
            values.units === "metric"
              ? values.weight
              : Math.round(convert(values.weight_pounds).from("lb").to("kg")),
          name: values.name,
          gender: values.gender !=="male",
          units: values.units !=="metric",
          dob: values.dateofbirth.toISOString(),
        })
        .eq("user_id", user.id)
        .then((res) => res.data),
    [supabase],
  );

  const saveAthleteSettingsCall =
    useAsyncState<SupabaseCall<typeof saveAthleteSettingsPromise>>();

  return (
    <form noValidate onSubmit={userFormik.handleSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <FormControl>
          <TextField
            name="name"
            onBlur={userFormik.handleBlur}
            value={userFormik.values.name ?? ""}
            onChange={userFormik.handleChange}
            error={userFormik.touched.name && Boolean(userFormik.errors.name)}
            helperText={userFormik.touched.name && userFormik.errors.name}
          />
        </FormControl>
        <FormControl>
          <TextField
            name="email"
            onBlur={userFormik.handleBlur}
            value={userFormik.values.email}
            error={userFormik.touched.email && Boolean(userFormik.errors.email)}
            helperText={userFormik.touched.email && userFormik.errors.email}
            onChange={userFormik.handleChange}
          />
        </FormControl>

        <FormControl>
          <TextField
            name="dateofbirth"
            error={
              userFormik.touched.dateofbirth &&
              Boolean(userFormik.errors.dateofbirth)
            }
            helperText={
              userFormik.touched.dateofbirth && userFormik.errors.dateofbirth
            }
            value={
              userFormik.values.dateofbirth
                ? dayjs(userFormik.values.dateofbirth).format("DD/MM/YYYY")
                : ""
            }
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setOpenDate(!openDate)}
                    ref={buttonRef}
                  >
                    <CalendarMonthIcon
                      sx={{ color: "#BC6D29" }}
                      fontSize="small"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Popover
          open={openDate}
          anchorEl={buttonRef.current}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={(_) => setOpenDate(false)}
          PaperProps={{
            style: {
              background: "none",
            },
          }}
        >
          <Calendar
            onChange={(date) => {
              userFormik.setFieldTouched("dateofbirth");
              userFormik.setFieldValue("dateofbirth", date);
            }}
            date={new Date(userFormik.values.dateofbirth)}
            maxDate={new Date()}
          />
        </Popover>

        <FormControl>
          <Select
            id="gender"
            name="gender"
            value={userFormik.values.gender ?? ""}
            onChange={userFormik.handleChange}
            sx={{
              "& .MuiSelect-icon": { marginRight: ".4375rem" },
            }}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
          </Select>
        </FormControl>

        {userFormik.values.units === "metric" ? (
          <TextField
            error={
              userFormik.touched.weight && Boolean(userFormik.errors.weight)
            }
            helperText={userFormik.touched.weight && userFormik.errors.weight}
            label="Weight (kg)"
            name="weight"
            onBlur={userFormik.handleBlur}
            onChange={(e) => {
              if (/^[0-9]*\.?\d{0,2}$/.test(e.target.value)) {
                userFormik.handleChange(e);
              }
            }}
            value={userFormik.values.weight ?? ""}
            variant="outlined"
            inputRef={inputWeightRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => inputWeightRef.current.focus()}>
                    <img src={editInput}  alt="edit"/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <TextField
            error={
              userFormik.touched.weight_pounds &&
              Boolean(userFormik.errors.weight_pounds)
            }
            helperText={
              userFormik.touched.weight_pounds &&
              userFormik.errors.weight_pounds
            }
            label="Weight (lb)"
            name="weight_pounds"
            onBlur={userFormik.handleBlur}
            onChange={(e) => {
              if (/^[0-9]*\.?\d{0,2}$/.test(e.target.value)) {
                userFormik.handleChange(e);
              }
            }}
            value={userFormik.values.weight_pounds ?? ""}
            variant="outlined"
            inputRef={inputWeightPoundRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => inputWeightPoundRef.current.focus()}
                  >
                    <img src={editInput} alt="edit"/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}

        {userFormik.values.units === "metric" ? (
          <TextField
            error={
              userFormik.touched.height && Boolean(userFormik.errors.height)
            }
            helperText={userFormik.touched.height && userFormik.errors.height}
            name="height"
            label="Height (m)"
            onBlur={userFormik.handleBlur}
            onChange={(e) => {
              if (/^[0-9]*\.?\d{0,2}$/.test(e.target.value)) {
                userFormik.handleChange(e);
              }
            }}
            value={userFormik.values.height ?? ""}
            variant="outlined"
            inputRef={inputHeightRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => inputHeightRef.current.focus()}>
                    <img src={editInput}  alt="edit"/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <Box>
            <TextField
              error={
                userFormik.touched.height_feet &&
                Boolean(userFormik.errors.height_feet)
              }
              helperText={
                userFormik.touched.height_feet && userFormik.errors.height_feet
              }
              label="Height (ft)"
              name="height_feet"
              onBlur={userFormik.handleBlur}
              onChange={(e) => {
                if (/^[0-9]*$/.test(e.target.value)) {
                  userFormik.handleChange(e);
                }
              }}
              value={userFormik.values.height_feet ?? ""}
              variant="outlined"
              sx={{
                width: "10rem",
                "& .MuiInputBase-root": { width: "10rem" },
                "& .MuiFormHelperText-root": {
                  width: "initial",
                },
              }}
              inputRef={inputHeightFeetRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => inputHeightFeetRef.current.focus()}
                    >
                      <img src={editInput}  alt="edit"/>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Height (in)"
              name="height_inches"
              onBlur={userFormik.handleBlur}
              error={Boolean(
                userFormik.touched.height_inches &&
                  userFormik.errors.height_inches,
              )}
              helperText={
                userFormik.touched.height_inches &&
                userFormik.errors.height_inches
              }
              onChange={(e) => {
                if (/^[0-9]*$/.test(e.target.value)) {
                  userFormik.handleChange(e);
                }
                if (e.target.value === "") {
                  userFormik.setFieldValue("height_inches", null);
                }
              }}
              value={userFormik.values.height_inches ?? ""}
              variant="outlined"
              sx={{
                width: "10rem",
                "& .MuiInputBase-root": { width: "10rem" },
                "& .MuiFormHelperText-root": {
                  width: "max-content",
                },
                marginLeft: "6px",
              }}
              inputRef={inputHeightInchRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => inputHeightInchRef.current.focus()}
                    >
                      <img src={editInput}  alt="edit"/>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormLabel sx={{ alignSelf: "center" }}>
            <Typography
              color="text.primary"
              sx={{
                opacity: "0.5",
                marginRight: "1.25rem",
                fontSize: { xs: "12px", xl: "12px" },
              }}
            >
              Units of Measure
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            onChange={userFormik.handleChange}
            value={userFormik.values.units}
            name="units"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              "& .MuiFormControlLabel-label": { opacity: 0.5 },
            }}
          >
            <FormControlLabel
              value="metric"
              control={<Radio color="primary" />}
              label={<span style={{ fontSize: "12px" }}>Metric</span>}
            />
            <FormControlLabel
              value="imperial"
              control={<Radio color="primary" />}
              label={<span style={{ fontSize: "12px" }}>Imperial</span>}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          startIcon={
            isPending(saveAthleteSettingsCall) ? (
              <CircularProgress size="1rem" />
            ) : null
          }
          disabled={isPending(saveAthleteSettingsCall)}
          type="submit"
        >
          Save
        </Button>
      </Box>
    </form>
  );
}
