import { useRef, useMemo, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Grid, Box, styled, Typography } from "@mui/material";
import { Database } from "src/utils/DatabaseDefinitions";
import cyclistSession from "../../../assets/img/views/calendar/cyclistIconSession.svg";
import noData from "../../../../assets/img/views/calendar/noData.svg";
import calendarCyclist from "../../../assets/img/views/dashboard-library/cyclistcalendar.svg";
import moment from "moment";
import dayjs from "dayjs";

const FullCalendarWrapper = styled(Box)(
  ({ theme }) => `

  padding-bottom : ${theme.spacing(1)};

  & .fc-license-message {
    display: none;
  }
  .fc {

    --fc-page-bg-color: none;

    .fc-col-header-cell {
      padding: ${theme.spacing(1)};
      background: none;
      border: none !important;
    }

    .fc-scrollgrid {
      border:none !important;
    }

    .fc-scroller {
      overflow-y: hidden !important;
   }

    .fc-scrollgrid-section-header {
      border: none;
    }

    &.fc-theme-standard td, 
    &.fc-theme-standard th,
    &.fc-theme-standard .fc-list {
      border : none !important
    }

    .fc-col-header {
      background : none;
    }

    .fc-daygrid-day.fc-day-other {
      opacity: 0.5;
    }

    // .fc-daygrid-day-frame {
    //   border: 1px solid #64605D;
    //   height: 170px;
    // }

    .fc-daygrid-day-top {
      display : flex;
      flex-direction : row !important;
      justify-content: center;
      align-items: center;
    }

    .fc-cell-shaded,
    .fc-list-day-cushion {
      background: ${theme.colors.alpha.black[5]};
    }

    .fc-list-event-graphic {
      padding-right: ${theme.spacing(1)};
    }

    .fc-event {
      cursor : pointer;
    }

    .fc-event-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #E3DFDC;
      font-family: 'DejaVu Sans';
      font-size: 10px;
      margin-bottom:10px;
    }

    .fc-popover,
    .fc-more-popover {
      background-color : #201B20 !important;
      box-shadow : none !important;
    }

    .fc-daygrid-day.fc-day-today {
        // background-color: #24211F;
        background-color : transparent;
    }

    // .fc-list-event:hover td,
    // td.fc-daygrid-day.fc-day-today {
    //   background-color: #24211F;
    //   border-radius :6px;
    // }

    // td.fc-daygrid-day:hover,
    // .fc-highlight {
    //   background: #24211F;
    //   border-radius :6px;
    // }
    
    // .fc-daygrid-dot-event:hover, 
    // .fc-daygrid-dot-event.fc-event-mirror {
    //   background: #24211F;
    //   border-radius :6px;
    // }

    .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
      min-height:0px;
    }

    .fc-daygrid-day-number {
      font-weight: bold;
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
    }

    .fc-list-sticky .fc-list-day > * {
      background: ${theme.colors.alpha.black[5]} !important;
    }

    .fc-cell-shaded, 
    .fc-list-day-cushion {
      background: ${theme.colors.alpha.black[10]} !important;
      color: ${theme.colors.alpha.black[70]} !important;
    }

    .fc-col-header-cell-cushion {
      color : #64605D;
    }

    .fc-more-link {
      display: none !important;
    }
`,
);

export type Dates = {
  date_session?: Pick<
    Database["public"]["Tables"]["session"]["Row"],
    "date" | "recording_name" | "total_time" | "total_distance" | "tss"
  >[];
  startOfWeek?: dayjs.Dayjs;
  endOfWeek?: dayjs.Dayjs;
};

export default function CoachCalendarWidget(props: Dates) {
  const calendarRef = useRef<FullCalendar | null>(null);
  const currentDate = new Date();

  const filterSessions: {
    date: Date;
    total_distance: number;
    total_time: number;
    tss: number;
  }[] = useMemo(() => {
    const aggregatedSessions: {
      [date: string]: {
        date: Date;
        tss: number;
        total_distance: number;
        total_time: number;
      };
    } = {};
    if (props.date_session) {
      props.date_session.forEach((session) => {
        const sessionDate = dayjs(session.date);
        const formattedDate = sessionDate.format("YYYY-MM-DD");
        if (!aggregatedSessions[formattedDate]) {
          aggregatedSessions[formattedDate] = {
            date: sessionDate?.toDate(),
            tss: session.tss,
            total_distance: session.total_distance,
            total_time: session.total_time,
          };
        } else {
          aggregatedSessions[formattedDate].tss += session.tss;
          aggregatedSessions[formattedDate].total_distance +=
            session.total_distance;
          aggregatedSessions[formattedDate].total_time += session.total_time;
        }
      });
    }
    const aggregatedSessionsArray = Object.values(aggregatedSessions);
    return aggregatedSessionsArray;
  }, [props.startOfWeek, props.endOfWeek, props.date_session]);

  const eventDates = useMemo(
    () =>
      filterSessions?.map((data) => {
        return {
          date: new Date(data.date),
          extendedProps: {
            distance: data.total_distance ?? 0,
            total_time: data.total_time ?? 0,
            tss: data.tss?.toFixed(0) ?? 0,
          },
        };
      }),
    [filterSessions, props.date_session],
  );

  // useEffect(() => {
  //   const calendarApi = calendarRef.current?.getApi();
  //   calendarApi.gotoDate(props.startOfWeek?.toDate());
  // }, [props.startOfWeek, props.endOfWeek, calendarRef.current]);

  function formatSecondsToHHMM(seconds) {
    const val = Number(seconds);
    const hours = Math.floor(Math.floor(val / 60) / 60);
    const minutes = Math.trunc((val % 3600) / 60);
    const formattedTime = `${hours.toString().padStart(2, "0")}h${minutes
      .toString()
      .padStart(2, "0")}’`;
    return formattedTime;
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Grid item xs={12} sx={{ marginX: "8px" }}>
            <FullCalendarWrapper
              height={{
                xs: "120px",
                xl: "150px",
              }}
            >
              <FullCalendar
                // initialDate={date}
                initialView={"dayGridWeek"}
                eventDisplay="block"
                eventColor="transparent"
                events={eventDates}
                dayHeaders={false}
                dayCellContent={(arg) => {
                  const dayOfWeek = arg.date
                    .toLocaleString("default", { weekday: "short" })
                    .slice(0, 3);

                  // Check if the day has events
                  const hasEvents = eventDates?.some((event) => {
                    const eventDate = new Date(event.date);
                    return eventDate.getDate() === arg.date.getDate();
                  });
                  if (!hasEvents) {
                    return (
                      <>
                        <Box
                          sx={{
                            "&:hover .hovered-area": {
                              color: "white",
                            },
                            "&:hover": {
                              background: "#24211F",
                              borderRadius: "6px",
                            },
                            paddingX: "1rem",
                          }}
                        >
                          <Typography
                            className="hovered-area"
                            sx={{
                              color: "#64605D",
                              marginTop: "1rem",
                              textAlign: "center",
                            }}
                          >
                            {dayOfWeek}
                          </Typography>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              marginTop: "1.5rem",
                            }}
                          >
                            {/* change icon */}
                            <img src={calendarCyclist} /> 
                            {/* <img src={noData} width={"20px"} /> */}
                          </Box>
                        </Box>
                      </>
                    );
                  }
                  return <></>;
                }}
                eventDidMount={(info) => {
                  const eventTitle = info.el.querySelector(
                    ".fc-event-title",
                  ) as HTMLElement;

                  if (eventTitle) {
                    eventTitle.style.display = "flex";
                    eventTitle.style.flexDirection = "column";
                    eventTitle.style.alignItems = "center";
                    eventTitle.style.justifyContent = "center";

                    const iconElement = document.createElement("img");
                    iconElement.alt = "Cyclist Icon";
                    iconElement.src = cyclistSession;
                    iconElement.style.width = "25px";
                    iconElement.style.marginBottom = "5px";

                    const totalTime = document.createElement("div");
                    totalTime.textContent = formatSecondsToHHMM(
                      info.event.extendedProps.total_time / 1000,
                    );
                    totalTime.classList.add("total-time");

                    const distance = document.createElement("div");
                    distance.textContent = `${info.event.extendedProps.distance?.toFixed(
                      1,
                    )} km`;
                    distance.classList.add("distance");

                    const tssLabel = document.createElement("div");
                    tssLabel.textContent = "TSS®";
                    tssLabel.classList.add("tss-label");

                    const tssValue = document.createElement("div");
                    tssValue.textContent = info.event.extendedProps.tss;
                    tssValue.classList.add("tss-value");

                    eventTitle.appendChild(iconElement);
                    eventTitle.appendChild(totalTime);
                    eventTitle.appendChild(distance);
                    eventTitle.appendChild(tssLabel);
                    eventTitle.appendChild(tssValue);
                  }
                }}
                dayMaxEvents={1}
                displayEventTime={false}
                headerToolbar={false}
                height={"100%"}
                ref={calendarRef}
                rerenderDelay={10}
                weekends
                weekNumberCalculation={"ISO"}
                plugins={[dayGridPlugin]}
              />
            </FullCalendarWrapper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}