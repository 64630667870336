import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useTitleCoach } from "../Dashboard/CoachDashboardLayout";
import { useCallback, useEffect, useState } from "react";
import CoachSettingsProfile from "./CoachSettingsProfile";
import CoachSettingsAccount from "./CoachSettingsAccount";
import { TableRow } from "src/contexts/CacheContext";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import { useSnackbar } from "notistack";
import useAsyncState, { isFulfilled } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";

type TabValue = "coach_profile" | "my_account";

export type CoachSettingsAccountType = {
  name: string;
  gender: string;
  qualifications: string;
  expertise: Array<string>;
  phone: string;
  other_info: string;
  units: "metric" | "imperial";
};

export type SettingsProps = {
  tab?: TabValue;
  coach: TableRow<"coach">;
};

export default function CoachSettingsView(props: SettingsProps) {
  const user = useUser();
  const supabase = useSupabase();
  const [, setTitle] = useTitleCoach();
  const [value, setValue] = useState<TabValue>("coach_profile");

  const formik = useFormik<CoachSettingsAccountType>({
    initialValues: {
      name: props.coach.name,
      gender: props.coach.gender ? "female" : "male",
      units: props.coach.units === false ? "metric" : "imperial",
      qualifications: props.coach.qualifications,
      expertise: props.coach.expertise,
      phone: props.coach.phone,
      other_info: props.coach.other_info
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      gender: yup
        .string()
        .oneOf(["male", "female"], "Please select a valid gender")
        .required("Please select a valid gender"),
    }),
    onSubmit: (values) => {
      if ("fire" in saveProfileSettingsCall) {
        saveProfileSettingsCall.fire(async () =>
            saveProfileSettingsPromise(values),
          );
        }
      },
    });

    const saveProfileSettingsPromise = useCallback(
    (values: CoachSettingsAccountType) =>
      supabase
        .from("coach")
        .update({
          ...values,
          units: values.units !== "metric",
          gender: values.gender !== "male",
        })
        .eq("user_id", user.id),
    [supabase, user],
  );

  const saveProfileSettingsCall =
    useAsyncState<SupabaseCall<typeof saveProfileSettingsPromise>>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isFulfilled(saveProfileSettingsCall)) {
      enqueueSnackbar("Settings saved successfully", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [saveProfileSettingsCall]);

  useEffect(() => {
    if (!!props?.tab) {
      setValue(props?.tab);
    }
  }, [props]);

  useEffect(() => {
    setTitle("Settings");
  }, []);

  const handleChange = useCallback((_, newValue: TabValue) => {
    setValue(newValue);
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          paddingTop: "15px",
          paddingLeft: "25px",
          paddingBottom: "20px",
        }}
      >
        <Box>
          <TabContext value={value}>
            <Box
              sx={{
                borderColor: "divider",
                display: "flex",
                paddingRight: "20px",
                justifyContent: "space-between",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="Tab list"
                sx={{
                  "& .MuiTab-root:not(.Mui-selected)": {
                    opacity: 0.5,
                  },
                  minHeight: "1.5rem",
                  "& .Mui-selected": {
                    color: "#DD4F4A !important",
                  },
                }}
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: "#DD4F4A",
                  },
                }}
              >
                <Tab
                  label="My Coach Profile"
                  value="coach_profile"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
                <Tab
                  label="My Account"
                  value="my_account"
                  sx={{ minHeight: 0, padding: "10px" }}
                />
              </TabList>
            </Box>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TabPanel
                value="coach_profile"
                sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
              >
                <CoachSettingsProfile coach={props.coach} formik={formik}/>
              </TabPanel>
              <TabPanel
                value="my_account"
                sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
              >
                <CoachSettingsAccount coach={props.coach} formik={formik}/>
              </TabPanel>
            </form>
          </TabContext>
        </Box>
      </Box>
    </>
  );
}
