import { Box, Grid } from "@mui/material";
import { useMemo } from "react";
import { useRideSession } from "./CoachSessionViewPreload";
import { useRideSession as RideSession } from "../../Sessions/CoachSessionViewsPreload";
import CoachPerformanceIndexCard from "./CoachPerformanceIndexCard";
import CoachNotesComponent from "../CoachNotesComponent";

export default function CoachPerformanceIndexView() {
  const session =
    location.pathname.startsWith("/coach/my_athletes") &&
    !location.pathname.startsWith("/coach/my_athletes/session")
      ? useRideSession()
      : RideSession();
  const TrendsInfo = useMemo(
    () => [
      {
        index: 1,
        title: "Ideal Positions",
        metric: Number(session.position_score * 100)?.toFixed(1),
        description: "Percentage of time in all IDEAL positions",
      },
      {
        index: 2,
        title: "Efficiency Factor",
        metric: session.efficiency_factor?.toFixed(2),
        description:
          "EF compares a cyclist's average power output during a ride to their average heart rate.",
      },
      {
        index: 3,
        title: "Effort Score",
        metric: session.effort_score?.toFixed(2),
        description:
          "It is a subjective toughness rating of the training, based on your HR zones. More points = more effort (harder)",
      },
      {
        index: 4,
        title: "Speed efficiency",
        metric: session.speed_efficiency?.toFixed(2),
        description:
          "Speed efficiency= Optimal Power needed for the actual average speed / Actual average power.Optimal power is the power needed to overcome all forces in an aerodynamic position in the given speed and slope.",
      },
      {
        index: 5,
        title: "Normalised Power®",
        metric: session.normalized_power?.toFixed(0),
        description:
          "NP is a weighted average of a cyclist's power output, taking into account the variability of their effort throughout the ride. This metric provides a more accurate picture of the actual physiological stress placed on the body than simply looking at average power output.",
      },
      {
        index: 6,
        title: "TSS®",
        metric: session.tss?.toFixed(1),
        description:
          "TSS is a metric that takes into account the intensity and duration of a ride to estimate the amount of stress placed on the body. It provides a more comprehensive view of training load than just looking at distance or time alone.TSS = (sec x IF)/ (FTP x 3600) x 100",
      },
      {
        index: 7,
        title: "Intensity Factor®",
        metric: session.intensity_factor?.toFixed(2),
        description:
          "IF is a metric that compares a cyclist's average power output during a ride to their FTP. IF provides an indication of how hard the ride was relative to the athlete's capabilities.IF = AVG_Power/ FTP OR IF = AVH_HR/LTHR",
      },
      {
        index: 8,
        title: "Workload",
        metric: session.workload?.toFixed(1),
        description:
          "It shows a more accurate picture of how hard you are training based on your power zones. Workload = Volume (in minutes) x Intensity (in Power Training Zone).",
      },
      {
        index: 9,
        title: "Variability Index",
        metric: session.variability_index?.toFixed(2),
        description:
          "VI is a measure of how variable a cyclist's power output is during a ride. A low VI indicates that the athlete maintained a consistent effort throughout the ride, while a high VI indicates that there were significant fluctuations in effort.VI = NP / Average Power",
      },
    ],
    [],
  );

  return (
    <>
      <Box
        sx={{
          background: "#201B20",
          borderRadius: "10px",
          width: "98.5%",
          paddingY: "2em",
          height: { md: "580px", xl: "720px" },
          display: "flex",
        }}
      >
        <Grid
          container
          gap={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {TrendsInfo.map((cardInfo, index) => (
            <CoachPerformanceIndexCard
              key={index}
              index={cardInfo.index}
              title={cardInfo.title}
              description={cardInfo.description}
              metric={cardInfo.metric}
            />
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <CoachNotesComponent sessionId={session.id}/>
      </Box>
    </>
  );
}
