import "../../../assets/scss/layouts/DashboardLayout.scss";
import Logo from "../../../assets/img/layout/Logo.svg";
import Logo2 from "../../../assets/img/layout/Logo_new.svg";
// import Avatar from "../../../assets/img/layout/avatar-dashboard.svg";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import dayjs from "dayjs";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import { Card, Skeleton, styled } from "@mui/material";

import {
  Badge,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { useSupabase, useSession } from "src/contexts/SupabaseContext";
import { SupabaseCall } from "src/utils/common";
import { useUser } from "src/components/Authenticated";
import NotificationPopover from "./NotificationPopover";
import { WithCache } from "src/contexts/CacheContext";

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
  height: ${theme.header.height};
  right: 0;
  z-index: 6;
  background-color: #2C262D;
  position: fixed;
  width: 100%;
`,
);

const settings = [
  {
    name: "Settings",
    icon: <SettingsOutlinedIcon />,
    url: "/dashboard/settings",
  },
  { name: "Sign Out", icon: <LogoutOutlinedIcon /> },
];

export default function Navbar() {
  const navigate = useNavigate();
  const user = useUser();
  const supabase = useSupabase();
  const lastMonth = dayjs().startOf("day").utc(true).subtract(1, "month");

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (url: string) => {
    navigate(url);
    setAnchorElUser(null);
  };

  //Sign Out Function

  const logOutCall =
    useAsyncState<SupabaseCall<typeof supabase.auth.signOut>>();

  const session = useSession();

  useEffect(() => {
    if (isFulfilled(logOutCall)) {
      if ("unload" in session) {
        session.unload();
      }
    }
  }, [logOutCall]);

  const handleLogout = useCallback(() => {
    if ("fire" in logOutCall) {
      logOutCall.fire(() => supabase.auth.signOut());
    }
  }, [supabase]);

  //For Avatar we fetch name

  const profileSettingsPromise = useCallback(
    () =>
      supabase
        .from("athlete")
        .select("name")
        .eq("user_id", user.id)
        .then((res) => res.data[0]),
    [supabase],
  );

  const storedProfileSettingsCall =
    useAsyncState<SupabaseCall<typeof profileSettingsPromise>>();

  // Loading effect
  useEffect(() => {
    if (isUnloaded(storedProfileSettingsCall)) {
      storedProfileSettingsCall.fire(async () => profileSettingsPromise());
    }
  }, [storedProfileSettingsCall, profileSettingsPromise]);

  const avatar = useMemo(() => {
    if (isFulfilled(storedProfileSettingsCall)) {
      return createAvatar(initials, {
        size: 30,
        radius: 55,
        seed: storedProfileSettingsCall.result.name,
      }).toDataUriSync();
    }
  }, [storedProfileSettingsCall]);

  //Notification

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <HeaderWrapper
        sx={{
          background: "#2C262D",
          paddingY: "0.5em",
          paddingLeft: "1.5em",
        }}
      >
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Box
            onClick={() => navigate(`/dashboard/`)}
            sx={{ cursor: "pointer" }}
          >
            <img src={Logo2} alt="Logo" className="Logo-dashboard" />
          </Box>
          <Box>
            {/* <Tooltip title="Achievements">
              <IconButton size="large">
                <Badge color="error">
                  <EmojiEventsOutlinedIcon sx={{ color: "#E28E54" }} />
                </Badge>
              </IconButton>
            </Tooltip>

            <Tooltip title="Messages">
              <IconButton size="large">
                <Badge color="error">
                  <EmailOutlinedIcon sx={{ color: "#E28E54" }} />
                </Badge>
              </IconButton>
            </Tooltip> */}
            <WithCache
              tables={["notifications"]}
              component={(cached) => (
                <>
                  <Tooltip title="Notifications">
                    <IconButton size="large" onClick={handleClick}>
                      <Badge
                        color="error"
                        badgeContent={
                          cached.notifications?.filter(
                            (noti) =>
                              noti.read === false &&
                              noti.created_at > lastMonth.toISOString(),
                          ).length
                        }
                      >
                        <NotificationsNoneOutlinedIcon
                          sx={{ color: "#E28E54" }}
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <NotificationPopover
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    notifications={cached.notifications.filter(
                      (noti) => noti.created_at > lastMonth.toISOString(),
                    )}
                  />
                </>
              )}
            />

            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu}>
                {isFulfilled(storedProfileSettingsCall) ? (
                  <img src={avatar} alt="avatar" />
                ) : (
                  <Skeleton variant="circular" width={"30px"} height={"30px"} />
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              MenuListProps={{
                style: {
                  background: "#2C262D",
                  borderColor: "#2C262D",
                  padding: 0,
                },
              }}
              PaperProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.name}
                  onClick={
                    setting.name === "Sign Out"
                      ? handleLogout
                      : () => handleMenuItemClick(setting.url)
                  }
                >
                  <ListItemIcon
                    sx={{
                      "&:hover": {
                        color: "#E28E54",
                      },
                    }}
                  >
                    {setting.icon}
                  </ListItemIcon>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </HeaderWrapper>
    </>
  );
}
