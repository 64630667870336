import Bluebird from "bluebird";
import React, {
  Context,
  DependencyList,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Fun, withReference } from "./common";
import { useNavigate } from "react-router-dom";

export type Unloaded<T> = {
  kind: "unloaded";
  fire: (promise: () => Promise<T>) => void;
};

export type Pending = {
  kind: "pending";
  cancel: () => void;
};

export type Rejected<T> = {
  kind: "rejected";
  error: Error;
  fire: (promise: () => Promise<T>) => void;
  unload: () => void;
};

export type Fulfilled<T> = {
  kind: "fulfilled";
  result: T;
  fire: (promise: () => Promise<T>) => void;
  unload: () => void;
};

export type AsyncState<T> = Unloaded<T> | Pending | Rejected<T> | Fulfilled<T>;

export const mapAsyncState = <T1, T2>(
  asyncState: AsyncState<T1>,
  callback: Fun<T1, T2>,
  deps?: DependencyList,
): AsyncState<T2> =>
  useMemo(
    () =>
      isFulfilled(asyncState)
        ? ({
            ...asyncState,
            result: callback(asyncState.result),
          } as unknown as AsyncState<T2>)
        : (asyncState as AsyncState<T2>),
    !!deps && deps?.length > 0 ? [asyncState, ...deps] : [asyncState],
  );

export const mapAsyncContext = <T1, T2>(
  asyncContext: Context<AsyncState<T1>>,
  callback: Fun<T1, T2>,
): AsyncState<T2> =>
  withReference(useContext(asyncContext), (asyncState) =>
    mapAsyncState(asyncState, callback),
  );

export const useCombinedAsyncState = <T1, T2>(
  asyncState1: AsyncState<T1>,
  asyncState2: AsyncState<T2>,
): AsyncState<[T1, T2]> =>
  useMemo(
    () =>
      (isFulfilled(asyncState1) && isFulfilled(asyncState2)
        ? {
            ...asyncState1,
            result: [asyncState1.result, asyncState2.result],
            unload: () => {
              asyncState1.unload();
              asyncState2.unload();
            },
          }
        : isFulfilled(asyncState1)
        ? asyncState2
        : isFulfilled(asyncState2)
        ? asyncState1
        : isRejected(asyncState1)
        ? asyncState1
        : isRejected(asyncState2)
        ? asyncState2
        : isPending(asyncState1) && isPending(asyncState2)
        ? {
            ...asyncState1,
            cancel: () => {
              asyncState1.cancel();
              asyncState2.cancel();
            },
          }
        : isPending(asyncState1)
        ? asyncState1
        : isPending(asyncState2)
        ? asyncState2
        : asyncState1) as unknown as AsyncState<[T1, T2]>,
    [asyncState1, asyncState2],
  );

export const useCombinedAsyncContext = <T1, T2>(
  asyncContext1: Context<AsyncState<T1>>,
  asyncContext2: Context<AsyncState<T2>>,
): AsyncState<[T1, T2]> =>
  withReference(useContext(asyncContext1), (asyncState1) =>
    withReference(useContext(asyncContext2), (asyncState2) =>
      useCombinedAsyncState(asyncState1, asyncState2),
    ),
  );

export function isUnloaded<T>(
  asyncState: AsyncState<T>,
): asyncState is Unloaded<T> {
  return !!asyncState && asyncState.kind === "unloaded";
}

export function isPending<T>(asyncState: AsyncState<T>): asyncState is Pending {
  return !!asyncState && asyncState.kind === "pending";
}

export function isRejected<T>(
  asyncState: AsyncState<T>,
): asyncState is Rejected<T> {
  return (
    !!asyncState &&
    (asyncState.kind === "rejected" ||
      (asyncState.kind === "fulfilled" && !!asyncState.result?.["error"]))
  );
}

export function isFulfilled<T>(
  asyncState: AsyncState<T>,
): asyncState is Fulfilled<T> {
  return (
    !!asyncState &&
    asyncState.kind === "fulfilled" &&
    !asyncState.result?.["error"]
  );
}

//Combinator
export function isSettled<T>(
  asyncState: AsyncState<T>,
): asyncState is Rejected<T> | Fulfilled<T> {
  return isRejected(asyncState) || isFulfilled(asyncState);
}

export function useAsyncContext<T>(context: Context<AsyncState<T>>) {
  return useContext(context);
}

export default function useAsyncState<T>(): AsyncState<T> {
  const isMounted = useRef(false);

  const navigate = useNavigate();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // @ts-ignore
  Bluebird.config({ cancellation: true });

  const constructedFire = async (promise: () => Promise<T>) => {
    const constructedUnload = () => {
      setValue({
        kind: "unloaded",
        fire: constructedFire,
      });
    };

    const constructedCancel = () => {
      bluebird.cancel();
      constructedUnload();
    };

    const bluebird = Bluebird.resolve(promise());

    setValue({
      kind: "pending",
      cancel: constructedCancel,
    });

    bluebird.then(
      (res) => {
        try {
          if (!!res?.["error"]?.message) {
            if (
              res?.["error"]?.message ===
              "Failed to send a request to the Edge Function"
            ) {
              navigate("/account/other-error");
            }
            setValue({
              kind: "rejected",
              error: res?.["error"],
              fire: constructedFire,
              unload: constructedUnload,
            });
          } else if (res?.message) {
            throw res?.message;
          } else {
            setValue({
              kind: "fulfilled",
              result: res,
              fire: constructedFire,
              unload: constructedUnload,
            });
          }
        } catch (error) {
          setValue({
            kind: "rejected",
            error,
            fire: constructedFire,
            unload: constructedUnload,
          });
        }
      },
      (error) => {
        if (error?.message === "JWT expired") {
          window.localStorage.removeItem("sb-localhost-auth-token");
          window.location.href = window.location.origin;
        } else {
          setValue({
            kind: "rejected",
            error,
            fire: constructedFire,
            unload: constructedUnload,
          });
        }
      },
    );
  };

  const [value, setValueNative] = useState<AsyncState<T>>({
    kind: "unloaded",
    fire: constructedFire,
  });

  const setValue: typeof setValueNative = (val) =>
    isMounted.current ? setValueNative(val) : null;

  return value;
}

export function createAsyncContext<T>() {
  return React.createContext<AsyncState<T> | undefined>(undefined);
}
