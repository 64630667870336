import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
  } from "react";
  import SuspenseLoader from "src/components/SuspenseLoader";
  import { TableRow } from "src/contexts/CacheContext";
  import { useSupabase } from "src/contexts/SupabaseContext";
  import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
  import { BikePosition, SupabaseCall } from "src/utils/common";
  import { Database } from "src/utils/DatabaseDefinitions";
  import PreloadComponent from "src/utils/PreloadComponent";
  import CoachSessionOverviewPreload from "./CoachSessionOverviewPreload";
  
  export type Speciments = {
    timestamp: number;
    distance: number;
    power: number;
    heartrate: number;
    elevation: number;
    position: BikePosition;
    speed: number;
    longitude: number;
    latitude: number;
    cadence: number;
    torso_degrees: number;
    cda:number
  };
  
  export const MetricsContext = createContext<
    Database["public"]["Tables"]["metrics"]["Row"][]
  >([]);
  
  export const SpecimentsContext = createContext<Array<Speciments>>([]);
  export const RideSessionContext = createContext<
    Database["public"]["Tables"]["session"]["Row"] & {
      ride_types: { name: string };
      athlete_bikes: {bike_name:string, bike_types: { name: string } };
      session_statistics: {
        cadence_max: number;
        created_at: string;
        default_position_id: number;
        custom_position_id: number;
        heart_rate_average: number;
        heart_rate_max: number;
        id: number;
        pedal_balance_left: number;
        pedal_balance_right: number;
        position_percentage: number;
        power_average: number;
        power_max: number;
        session_id: string;
        slope_average: number;
        slope_negative_percentage: number;
        slope_positive_percentage: number;
        speed_average: number;
        speed_max: number;
        cadence_average: number;
        cda_average:number;
      }[];
    }
  >(undefined);
  
  export const useMetrics = () => useContext(MetricsContext);
  export const useSpeciments = () => useContext(SpecimentsContext);
  export const useRideSession = () => useContext(RideSessionContext);
  
  export type SessionsViewsPreloadProps = {
    defaultPositions: Array<TableRow<"default_positions">>;
    sessionId: string;
  };
  
  export default function CoachSessionViewPreload(
    viewProps: SessionsViewsPreloadProps,
  ) {
    const supabase = useSupabase();
  
    const sessionPromise = useCallback(
      () =>
        supabase
          .from("session")
          .select(
            "*,ride_types(name),athlete_bikes(bike_name,bike_types(name)),session_statistics(*)",
          )
          .eq("id", viewProps.sessionId)
          .then((res) => res.data[0]),
      [supabase, viewProps.sessionId],
    );
  
    const session = useAsyncState<SupabaseCall<typeof sessionPromise>>();
  
    const lazyLoadPromise = useCallback(
      () =>
        supabase.functions.invoke("fetch-strava-streams", {
          body: JSON.stringify({
            activity_id: viewProps.sessionId,
          }),
        }),
      [supabase, viewProps.sessionId],
    );
  
    const lazyLoad = useAsyncState<SupabaseCall<typeof lazyLoadPromise>>();
  
    useEffect(() => {
      if (isUnloaded(session)) {
        session.fire(async () => sessionPromise());
      } else if (isFulfilled(session)) {
        if (session.result.is_lazy === true) {
          if (isUnloaded(lazyLoad)) {
            lazyLoad.fire(async () => lazyLoadPromise());
          }
        }
      }
    }, [session, lazyLoad]);
  
    const shouldShow = useMemo(() => {
      if (isFulfilled(session)) {
        if (session.result.is_lazy === false) {
          return true;
        }
        if (isFulfilled(lazyLoad)) {
          return true;
        }
      }
      return false;
    }, [session, lazyLoad]);
  
    return (
      <>
        {shouldShow && isFulfilled(session) && session.result !== undefined  ? (
          <PreloadComponent<{
            metrics: Database["public"]["Tables"]["metrics"]["Row"][];
            customPositions: Array<TableRow<"custom_positions">>;
          }>
            promises={{
              metrics: async (supabase) =>
                supabase
                  .from("metrics")
                  .select("*")
                  .eq("session_id", viewProps.sessionId)
                  .order("timestamp", { ascending: true })
                  .then((res) =>
                    res.data
                      .filter((el) => el.heart_rate !== 0)
                      .filter((_, i) => i % 5 === 0),
                  ),
                  customPositions: async (supabase) => {
                    if (session?.result?.athlete_bike_id === null) {
                      return [];
                    }
                    const { data } = await supabase
                      .from("custom_positions")
                      .select("*")
                      .eq("bike_id", session?.result?.athlete_bike_id);
                    return data;
                  },
            }}
            component={(props) => {
              const rideType =
                isFulfilled(session) &&
                (session.result.ride_types["name"] === "Indoor"
                  ? "indoor"
                  : session.result.ride_types["name"] === "Outdoor"
                  ? "outdoor"
                  : "virtual");
  
                  const initialTime = new Date(props.metrics[0]?.timestamp).getTime();
                  const timestampSpeciment = props.metrics.map((datum) => {
                    const currentTime = new Date(datum.timestamp).getTime();
                    const relativeTime = currentTime - initialTime;
                    return Math.floor(relativeTime / 1000);
                  });

              const distanceSpeciment = (() => {
                if (rideType === "indoor") {
                  let accum = [0];
                  for (let i = 1; i < props.metrics.length; i++) {
                    const lastDistance = accum[accum.length - 1];
                    const prevMetric = props.metrics[i - 1];
                    const currMetric = props.metrics[i];
                    const timestampDifferenceMS =
                      new Date(currMetric.timestamp).getTime() -
                      new Date(prevMetric.timestamp).getTime();
                    const timestampDifferenceH =
                      timestampDifferenceMS / 1000 / 3600;
                    const elapsedDistance =
                      timestampDifferenceH * currMetric.speed;
                    accum.push(lastDistance + elapsedDistance);
                  }
                  return accum;
                } else {
                  return props.metrics.map((datum) => datum.distance);
                }
              })();
  
              const elevationSpeciment = (() => {
                const min = Math.min(
                  ...props.metrics.map((datum) => datum.device_altitude),
                );
                if (min < 0) {
                  return props.metrics.map((datum) =>
                    Number((datum.device_altitude - min).toFixed(2)),
                  );
                } else {
                  return props.metrics.map((datum) =>
                    Number(datum.device_altitude?.toFixed(2)),
                  );
                }
              })();
  
              const allSpeciments: Array<Speciments> = (() => {
                if (isFulfilled(session)) {
                  let rval = [];
                  for (let i = 0; i < props.metrics.length; i++) {
                    rval.push({
                      timestamp: timestampSpeciment[i],
                      distance: distanceSpeciment[i],
                      power: props.metrics[i].bike_power,
                      heartrate: props.metrics[i].heart_rate,
                      elevation: elevationSpeciment[i],
                      speed: props.metrics[i].speed,
                   position:
                      session.result.session_statistics.length !== 0
                        ? ((position) =>
                            position.primary_name +
                            (position.secondary_name
                              ? ` - ${position.secondary_name}`
                              : ""))(
                            props.metrics[i].default_position_id !== null
                              ? viewProps.defaultPositions?.find(
                                  (pos) =>
                                    pos.id ===
                                    props.metrics[i].default_position_id,
                                )
                              : props.customPositions?.find(
                                  (pos) =>
                                    pos.id ===
                                    props.metrics[i].custom_position_id,
                                ),
                          )
                        : null,
                      longitude: props.metrics[i].longitude,
                      latitude: props.metrics[i].latitude,
                      cadence: props.metrics[i].cadence,
                      torso_degrees: props.metrics[i].torso_degrees,
                    });
                  }
                  return rval;
                }
              })();
  
              return (
                <MetricsContext.Provider value={props.metrics}>
                  <SpecimentsContext.Provider value={allSpeciments}>
                    <RideSessionContext.Provider
                      value={isFulfilled(session) && (session.result as any)}
                    >
                      <CoachSessionOverviewPreload />
                    </RideSessionContext.Provider>
                  </SpecimentsContext.Provider>
                </MetricsContext.Provider>
              );
            }}
          />
        ) : (
          <SuspenseLoader color="#DD4F4A"/>
        )}
      </>
    );
  }