import { Box, Grid, Typography } from "@mui/material";
import PlanComponent from "./PlanComponent";
import { useCallback, useEffect, useMemo } from "react";
import useAsyncState, { isFulfilled, isPending, isUnloaded } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import SuspenseLoader from "src/components/SuspenseLoader";

export default function PlansDashboard() {
  const supabase = useSupabase()
  const user = useUser()
  
  const trainingPlansPromise = useCallback(
    () =>
      supabase
        .from("training_plan")
        .select("*, training_plan_step(*)")
        .order("created_at", {ascending: true})
        .eq("user_id", user.id)
    ,[supabase],
  );

  const trainingPlansCall = useAsyncState<SupabaseCall<typeof trainingPlansPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(trainingPlansCall)) {
      trainingPlansCall.fire(async () => trainingPlansPromise());
    }
  }, [supabase, trainingPlansCall]);

  const trainingPlans = useMemo(
    () => {
      if(isFulfilled(trainingPlansCall)) {
        return trainingPlansCall.result.data
      }
      return []
    },
    [trainingPlansCall],
  );

  if (isPending(trainingPlansCall)) {
    return <SuspenseLoader color="#DD4F4A"/>
  }
  return (
    <Box
      sx={{
        width: "98%",
        padding: "1.5em",
        background: "#201B20",
        borderRadius: "10px",
      }}
    >
      <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
        LIBRARY
      </Typography>
      <Grid 
        container 
        gap={2} 
        justifyContent={"space-between"} 
        display={"grid"} 
        sx={{
          gridTemplateColumns:"repeat(auto-fill,350px)",
          "@media (max-width: 1840px)": {
            gridTemplateColumns:"repeat(auto-fill,260px)"
          }
        }} 
      >
        {
          trainingPlans.length === 0 ? (
            <Typography>No plans found.</Typography>
          ) : (
            trainingPlans.map((trainingPlan) => (
              <Grid item key={trainingPlan.id}>
                <PlanComponent key={trainingPlan.id} trainingPlan={trainingPlan} />
              </Grid>
            ))
          )
        }
      </Grid>
    </Box>
  );
}