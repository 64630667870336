import { Box, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useMemo } from "react";

export type SensorProps = {
  sensor: {
    id: string;
    name: string;
    icon: string;
  };
  formik: Array<string>;
  handleSensor: (id: string) => void;
};

export default function SensorComponent(props: SensorProps) {
  const { sensor } = props;
  const isTabletScreen = useMediaQuery("(max-width:1160px)");

  const contained = useMemo(() => {
    return props.formik.includes(sensor.id) ? true : false;
  }, [props.formik]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "center",
          textAlign: isTabletScreen ? "center" : "",
        }}
      >
        <img
          src={sensor.icon}
          width={"50px"}
          style={{ opacity: contained ? 1 : 0.2 }}
        />
        <Typography variant="body2" style={{ opacity: contained ? 1 : 0.2 }}>
          {sensor.name}
        </Typography>
        <Box sx={{ display: "flex", gap: 3 }}>
          <Typography
            color={contained ? "#DD4F4A" : "#F7F3F0"}
            onClick={() => props.handleSensor(sensor.id)}
            sx={{ cursor: "pointer" }}
          >
            {contained ? "Remove" : "Add"}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
